import React from 'react';
import css from './NeedHelpInfo.css';
import { FormattedMessage } from 'react-intl';
import { HiLightBulb } from 'react-icons/hi';

function NeedHelpInfo({ arcticleId }) {
  const handleOpenArticle = arcticleId => {
    // article not found
    if (typeof window === 'object') {
      window.Intercom('showArticle', arcticleId);
    }
  };

  return (
    <div className={css.container}>
      <div className={css.icon}>
        <HiLightBulb className={css.icon} />
      </div>
      <div className={css.needHelpText}>
        <FormattedMessage id="NeedHelpInfo.NeedHelp" />
        &nbsp;
      </div>
      <div className={css.openArticle1}>
        <FormattedMessage id="NeedHelpInfo.openArticle1" />
        &nbsp;
      </div>
      <div className={css.openArticle2} onClick={() => handleOpenArticle(arcticleId)}>
        <FormattedMessage id="NeedHelpInfo.openArticle2" />
      </div>
    </div>
  );
}

export default NeedHelpInfo;
