import React, { useState } from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, FieldTextEditor, NamedLink, Modal } from '../../components';
import { ensureCurrentUser } from '../../util/data';
import * as validators from '../../util/validators';

import css from './ProjectProposalForm.css';

const ProjectProposalFormComponent = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          currentUser,
          handleSubmit,
          submitInProgress,
          intl,
          onManageDisableScrolling,
          values,
        } = fieldRenderProps;

        const bestFitValue = values['bestFit'];
        const similarWorkValue = values['similarWork'];
        const questionsValue = values['questions'];

        const submitDisabled =
          submitInProgress || !bestFitValue || !similarWorkValue || !questionsValue;

        const ensuredCurrentUser = ensureCurrentUser(currentUser);
        const currentUserLoaded = !!ensuredCurrentUser.id;

        const cantSendProposals =
          currentUserLoaded &&
          currentUser?.profileImage === null &&
          currentUser?.attributes.profile.bio === null;

        const heading = intl.formatMessage({
          id: 'ProjectProposalForm.heading',
        });

        const bestFitLabel = intl.formatMessage({
          id: 'ProjectProposalForm.bestFitLabel',
        });

        const similarWorkLabel = intl.formatMessage({
          id: 'ProjectProposalForm.similarWorkLabel',
        });

        const questionsLabel = intl.formatMessage({
          id: 'ProjectProposalForm.questionsLabel',
        });

        const submitButtonText = intl.formatMessage({
          id: 'ProjectProposalForm.submitButtonText',
        });

        const bestFitPlaceholder = intl.formatMessage({
          id: 'ProjectProposalForm.bestFitPlaceholder',
        });

        const similarWorkPlaceholder = intl.formatMessage({
          id: 'ProjectProposalForm.similarWorkPlaceholder',
        });

        const questionsPlaceholder = intl.formatMessage({
          id: 'ProjectProposalForm.questionsPlaceholder',
        });

        const requiredMessage = intl.formatMessage({
          id: 'ProjectProposalForm.requiredMessage',
        });

        return (
          <div className={css.root}>
            {cantSendProposals ? (
              <div className={css.inputContainer}>
                <h2>
                  <FormattedMessage id="ProjectProposalForm.headingCantEnquire" />
                </h2>
                <p>
                  <FormattedMessage id="ProjectProposalForm.messageCantEnquire" />
                </p>
                <NamedLink name="ProfileSettingsPage">
                  <FormattedMessage id="ProjectProposalForm.buttonCantEnquire" />
                </NamedLink>
              </div>
            ) : (
              <Form onSubmit={handleSubmit} className={css.formContainer}>
                <div className={css.inputContainer}>
                  <h3 className={css.heading}>{heading}</h3>
                  <FieldTextEditor
                    className={css.quill}
                    name="bestFit"
                    id="bestFit"
                    label={bestFitLabel}
                    placeholder={bestFitPlaceholder}
                    validate={validators.required(requiredMessage)}
                  />
                  <FieldTextEditor
                    className={css.quill}
                    name="similarWork"
                    id="similarWork"
                    label={similarWorkLabel}
                    placeholder={similarWorkPlaceholder}
                    validate={validators.required(requiredMessage)}
                  />
                  <FieldTextEditor
                    className={css.quill}
                    name="questions"
                    id="questions"
                    label={questionsLabel}
                    placeholder={questionsPlaceholder}
                    validate={validators.required(requiredMessage)}
                  />
                </div>
                <div className={css.submitContainer}>
                  <PrimaryButton
                    type="button"
                    className={css.submitButton}
                    onClick={() => setModalOpen(true)}
                    disabled={submitDisabled}
                  >
                    {submitButtonText}
                  </PrimaryButton>
                </div>
                <Modal
                  id="ProjectProposalForm.terms"
                  isOpen={modalOpen}
                  onClose={() => setModalOpen(false)}
                  onManageDisableScrolling={onManageDisableScrolling}
                >
                  <div className={css.checkboxContainer}>
                    <input
                      name="readAndAccept"
                      type="checkbox"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <div className={css.tosText}>
                      <FormattedMessage id="ProjectProposalForm.checkboxTOS" />
                      <FormattedMessage id="ProjectProposalForm.checkboxTOS1" />
                      <FormattedMessage id="ProjectProposalForm.checkboxTOS2" />
                    </div>
                  </div>
                  <PrimaryButton
                    type="submit"
                    className={css.submitButton}
                    disabled={!checked || submitInProgress}
                  >
                    {submitButtonText}
                  </PrimaryButton>
                </Modal>
              </Form>
            )}
          </div>
        );
      }}
    />
  );
};

const ProjectProposalForm = compose(injectIntl)(ProjectProposalFormComponent);

ProjectProposalForm.displayName = 'ProjectProposalForm';

export default ProjectProposalForm;
