import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './SectionContact.css';
import { NamedLink } from '../../components';

const SectionContact = ({ title, subtitle, btnText }) => {
  return (
    <div className={css.container}>
      <div className={css.texts}>
        <h2 className={css.title}>
          <FormattedMessage id={title} />
        </h2>
        <p className={css.subtitle}>
          <FormattedMessage
            id={subtitle}
            values={{
              b: chunk => <b>{chunk}</b>,
            }}
          />
        </p>
      </div>
      <NamedLink className={css.contactButton} name="ProLandingPage">
        <FormattedMessage id={btnText} />
      </NamedLink>
    </div>
  );
};

export default SectionContact;
