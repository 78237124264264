import React from 'react';
import PropTypes from 'prop-types';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureListing, ensureCurrentUser } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
} from '../../components';
import { ProjectProposalForm } from '../../forms';
import SectionIconsMaybe from '../ProjectListingPage/SectionIconsMaybe';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { loadData, setInitialValues, sendProposal } from './ProjectProposalPage.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';

import SectionDetails from './SectionDetails';

import css from './ProjectProposalPage.css';

const { UUID } = sdkTypes;

const filterConfig = config.custom.filters;

const ProjectProposalPageComponent = props => {
  const {
    intl,
    currentUser,
    scrollingDisabled,
    getListing,
    params,
    onSendProposal,
    history,
    onManageDisableScrolling,
    sendProposalInProgress,
  } = props;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const listingId = new UUID(params.id);
  const currentListing = ensureListing(getListing(listingId));
  const listingTitle = currentListing.attributes.title;
  const descriptionText = currentListing.attributes.publicData.descriptionText;

  const isCustomer =
    currentUser && currentUser.attributes.profile.publicData.account_role === 'customer';
  const canSendProposals = !isCustomer;

  const categoryOptions = findOptionsForSelectFilter('category', filterConfig);

  const publicData = currentListing.attributes.publicData || {};

  if (!canSendProposals) {
    return <NamedRedirect name="LandingPage" />;
  }

  const onSubmitProposal = values => {
    const { bestFit, similarWork, questions } = values;
    const routes = routeConfiguration();
    const protectedData = {
      listingTitle,
      bestFit,
      similarWork,
      questions,
    };

    onSendProposal(listingId, ensuredCurrentUser, protectedData)
      .then(txId => {
        if (typeof window === 'object') {
          window.dataLayer.push({
            event: 'contact_author',
            ecommerce: {
              items: [
                {
                  item_name: currentListing.attributes.title,
                  item_id: currentListing.id.uuid,
                  price: '' + currentListing.attributes.price.amount / 100,
                  item_brand: currentListing.author.attributes.profile.displayName,
                  item_category: currentListing.attributes.publicData.category,
                  item_list_name: 'listing_page',
                },
              ],
            },
          });
        }

        // Redirect to ApplicationOrderPage
        history.push(
          createResourceLocatorString('ApplicationOrderPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  const schemaTitle = intl.formatMessage({
    id: 'ProjectProposalPage.schemaTitle',
  });

  const pageHeading = intl.formatMessage({
    id: 'ProjectProposalPage.pageHeading',
  });

  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>
            <div className={css.main}>
              <h1 className={css.pageHeading}>{pageHeading}</h1>
              <div className={css.detailsSection}>
                <SectionDetails
                  descriptionText={descriptionText}
                  publicData={publicData}
                  categoryOptions={categoryOptions}
                  listingTitle={listingTitle}
                />
                <SectionIconsMaybe
                  publicData={publicData}
                  wrapperClassName={css.sectionIconsWrapper}
                />
              </div>
              <ProjectProposalForm
                onSubmit={onSubmitProposal}
                submitInProgress={sendProposalInProgress}
                onManageDisableScrolling={onManageDisableScrolling}
                currentUser={currentUser}
              />
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, func, shape, string } = PropTypes;

ProjectProposalPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
  }).isRequired,

  scrollingDisabled: bool.isRequired,
  getListing: func.isRequired,
  onSendProposal: func.isRequired,
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { sendProposalInProgress } = state.ProjectProposalPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    scrollingDisabled: isScrollingDisabled(state),
    getListing,
    currentUser,
    sendProposalInProgress,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSendProposal: (listingId, currentUser, protectedData) =>
      dispatch(sendProposal(listingId, currentUser, protectedData)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  };
};

const ProjectProposalPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProjectProposalPageComponent);

ProjectProposalPage.setInitialValues = initialValues => setInitialValues(initialValues);
ProjectProposalPage.loadData = loadData;

export default ProjectProposalPage;
