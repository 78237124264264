import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import { injectIntl } from '../../util/reactIntl';
import { Form, FieldTextInput, Button } from '../../components';
import { required } from '../../util/validators';

import css from './SignupProviderForms.css';

const SignupProviderWhoAmIFrom = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const { rootClassName, className, handleSubmit, form, intl, invalid } = formRenderProps;

        const submitDisabled = invalid;

        const classes = classNames(rootClassName || css.root, className);

        const heading = intl.formatMessage({ id: 'SignupProviderForms.whoAmIHeading' });
        const whoAmIPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.whoAmIPlaceholder',
        });

        const point1 = intl.formatMessage({ id: 'SignupProviderForms.whoAmIPoint1' });
        const point2 = intl.formatMessage({ id: 'SignupProviderForms.whoAmIPoint2' });
        const point3 = intl.formatMessage({ id: 'SignupProviderForms.whoAmIPoint3' });
        const point4 = intl.formatMessage({ id: 'SignupProviderForms.whoAmIPoint4' });

        const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.nextButton' });

        const whoAmIRequired = intl.formatMessage({ id: 'SignupProviderForms.whoAmIRequired' });

        return (
          <div className={classes}>
            <h2 className={css.mainHeading}>{heading}</h2>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <FieldTextInput
                type="text"
                id="whoAmI"
                name="whoAmI"
                maxLength="50"
                placeholder={whoAmIPlaceholder}
                validate={required(whoAmIRequired)}
              />
              <ul className={css.pointerList}>
                <li>{point1}</li>
                <li>{point2}</li>
                <li>{point4}</li>
                <li>{point3}</li>
              </ul>
              <div className={css.submitContainer}>
                <div></div>
                <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                  {nextButtonText}
                </Button>
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

export default injectIntl(SignupProviderWhoAmIFrom);
