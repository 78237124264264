import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '..';

import css from './SectionFreedomlyStats.css';

const StatCard = ({ title, value }) => {
  return (
    <div className={css.statCard}>
      <div className={css.statCardTitle}>{title}</div>
      <div className={css.statCardValue}>{value}</div>
    </div>
  );
};

const SectionFreedomlyStats = () => {
  const statCardTitle1 = <FormattedMessage id="SectionFreedomlyStats.statCardTitle1" />;
  const statCardTitle2 = <FormattedMessage id="SectionFreedomlyStats.statCardTitle2" />;
  const statCardTitle3 = <FormattedMessage id="SectionFreedomlyStats.statCardTitle3" />;

  const statCardValue1 = <FormattedMessage id="SectionFreedomlyStats.statCardValue1" />;
  const statCardValue2 = <FormattedMessage id="SectionFreedomlyStats.statCardValue2" />;
  const statCardValue3 = <FormattedMessage id="SectionFreedomlyStats.statCardValue3" />;

  return (
    <div className={css.container}>
      <div className={css.title}>
        <FormattedMessage id="SectionFreedomlyStats.title" />
      </div>
      <div className={css.statCards}>
        <StatCard title={statCardTitle1} value={statCardValue1} />
        <StatCard title={statCardTitle2} value={statCardValue2} />
        <StatCard title={statCardTitle3} value={statCardValue3} />
      </div>
      <div className={css.ctaContainer}>
        <NamedLink name="SignupPage" className={css.sectionFreedomlyStatsButton}>
          <FormattedMessage id="SectionBestTalent.sectionBestTalentButton" />
        </NamedLink>
        <div className={css.subtitle}>
          <FormattedMessage id="SectionFreedomlyStats.subTitle" />
        </div>
      </div>
    </div>
  );
};

export default SectionFreedomlyStats;
