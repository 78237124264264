import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    addPackage,
    listingPackages,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;

  // Service package prices and names

  const package1Name = publicData.package1Name;
  const package2Name = publicData.package2Name;
  const package3Name = publicData.package3Name;
  const package4Name = publicData.package4Name;
  const package5Name = publicData.package5Name;

  const package1Description = publicData.package1Description;
  const package2Description = publicData.package2Description;
  const package3Description = publicData.package3Description;
  const package4Description = publicData.package4Description;
  const package5Description = publicData.package5Description;

  const package2Price =
    typeof publicData.package2Price === 'number'
      ? new Money(publicData.package2Price, 'EUR')
      : null;
  const package3Price =
    typeof publicData.package3Price === 'number'
      ? new Money(publicData.package3Price, 'EUR')
      : null;
  const package4Price =
    typeof publicData.package4Price === 'number'
      ? new Money(publicData.package4Price, 'EUR')
      : null;
  const package5Price =
    typeof publicData.package5Price === 'number'
      ? new Money(publicData.package5Price, 'EUR')
      : null;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{
        package1Name,
        package1Description,
        price,
        package2Name,
        package2Description,
        package2Price,
        package3Name,
        package3Description,
        package3Price,
        package4Name,
        package4Description,
        package4Price,
        package5Name,
        package5Description,
        package5Price,
      }}
      onSubmit={values => {
        const {
          package1Name,
          package1Description,
          price,
          package2Name,
          package2Description,
          package2Price,
          package3Name,
          package3Description,
          package3Price,
          package4Name,
          package4Description,
          package4Price,
          package5Name,
          package5Description,
          package5Price,
        } = values;
        const updateValues = {
          price,
          publicData: {
            package1Name: package1Name ? package1Name.trim() : null,
            package1Description,
            package2Name: package2Name ? package2Name.trim() : null,
            package2Description,
            package2Price: package2Price ? package2Price.amount : null,
            package3Name: package3Name ? package3Name.trim() : null,
            package3Description,
            package3Price: package3Price ? package3Price.amount : null,
            package4Name: package4Name ? package4Name.trim() : null,
            package4Description,
            package4Price: package4Price ? package4Price.amount : null,
            package5Name: package5Name ? package5Name.trim() : null,
            package5Description,
            package5Price: package5Price ? package5Price.amount : null,
          },
        };

        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      addPackage={addPackage}
      listingPackages={listingPackages}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
