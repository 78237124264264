import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';

import {
  Page,
  Footer,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  NamedLink,
  ProFaqSection,
  BookCallBanner,
  HowItWorksTiles,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage } from '../../util/reactIntl';

import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';

import css from './DashboardProPage.css';

const DashboardProPageComponent = props => {
  const { scrollingDisabled, intl } = props;

  const schemaTitle = intl.formatMessage({ id: 'DashboardProPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({ id: 'DashboardProPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutWrapperTopbar>
        <TopbarContainer currentPage="DashboardProPage" />
      </LayoutWrapperTopbar>
      <LayoutWrapperMain>
        <div className={css.sections}>
          <div className={css.headerSection}>
            <div className={css.headerBreadcrumbs}>
              <FormattedMessage
                id="DashboardProPage.breadcrumbsDashboard"
                values={{
                  a: chunks => <NamedLink name="DashboardPage">{chunks}</NamedLink>,
                }}
              />
              <FormattedMessage
                id="DashboardProPage.breadcrumbsDashboardProPage"
                values={{
                  a: chunks => <NamedLink name="DashboardProPage">{chunks}</NamedLink>,
                }}
              />
            </div>
            <div className={css.headerTitle}>
              <FormattedMessage id="DashboardProPage.title" />
            </div>
            <div className={css.headerDescription}>
              <FormattedMessage id="DashboardProPage.description" />
            </div>
          </div>
          <HowItWorksTiles />
          <BookCallBanner />
          <ProFaqSection />
        </div>
      </LayoutWrapperMain>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const DashboardProPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(DashboardProPageComponent);

export default DashboardProPage;
