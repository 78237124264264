// An array of categories to show in the CategoryKeywordsAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
/*  {
    id: 'default-digital-marketing',
    key: 'digital_marketing',
    label: 'Digital Marketing',
    isCategory: true,
  },
  {
    id: 'default-ecommerce-n-websites',
    key: 'ecommerce_n_websites',
    label: 'Ecommerce & websites',
    isCategory: true,
  },
  {
    id: 'default-photography-n-video',
    key: 'photography_n_video',
    label: 'Photography & video',
    isCategory: true,
  },
  {
    id: 'default-programming',
    key: 'programming',
    label: 'Programming',
    isCategory: true,
  },
  {
    id: 'default-writing-and-translation',
    key: 'writing_and_translation',
    label: 'Writing and translation',
    isCategory: true,
  },
  {
    id: 'default-audio',
    key: 'audio',
    label: 'Audio',
    isCategory: true,
  },*/
];
