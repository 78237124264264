import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton, SecondaryButton, NamedLink } from '../../components';
import css from './ApplicationPanel.css';

const Actions = props => {
  const {
    transaction,
    transactionId,
    showActionButtons,
    showAcceptAfterSkipButton,
    sendAcceptApplicationInProgress,
    showProfileListingInProgress,
    sendCreateReverseTransactionInProgress,
    sendSkipApplicationInProgress,
    sendAcceptAfterSkipInProgress,
    handleAcceptApplication,
    onSkipApplication,
    handleAcceptApplicationAfterSkip,
    showToNewTransactionButton,
    isCustomer,
  } = props;

  const buttonsDisabled =
    sendAcceptApplicationInProgress ||
    showProfileListingInProgress ||
    sendCreateReverseTransactionInProgress ||
    sendSkipApplicationInProgress ||
    sendAcceptAfterSkipInProgress;

  const newTransactionId = transaction.attributes.protectedData.newTransactionId;

  return (
    <>
      {showActionButtons && (
        <div className={css.actionButtonsWrapper}>
          <PrimaryButton
            className={css.actionButton}
            inProgress={
              sendAcceptApplicationInProgress ||
              showProfileListingInProgress ||
              sendCreateReverseTransactionInProgress
            }
            disabled={buttonsDisabled}
            onClick={() => handleAcceptApplication(transactionId)}
          >
            <FormattedMessage id="ApplicationPanel.startConversation" />
          </PrimaryButton>
          <SecondaryButton
            className={css.actionButton}
            inProgress={sendSkipApplicationInProgress}
            disabled={buttonsDisabled}
            onClick={() => onSkipApplication(transactionId)}
          >
            <FormattedMessage id="ApplicationPanel.freezeApplication" />
          </SecondaryButton>
        </div>
      )}
      {showAcceptAfterSkipButton && (
        <div className={css.actionButtonsWrapper}>
          <PrimaryButton
            className={css.actionButton}
            inProgress={
              sendAcceptAfterSkipInProgress ||
              showProfileListingInProgress ||
              sendCreateReverseTransactionInProgress
            }
            disabled={buttonsDisabled}
            onClick={() => handleAcceptApplicationAfterSkip(transactionId)}
          >
            <FormattedMessage id="ApplicationPanel.startConversation" />
          </PrimaryButton>
        </div>
      )}
      {showToNewTransactionButton && (
        <div className={css.actionButtonsWrapper}>
          <NamedLink
            className={css.toNewTransactionButton}
            name={isCustomer ? 'SaleDetailsPage' : 'OrderDetailsPage'}
            params={{ id: newTransactionId }}
          >
            <FormattedMessage id="ApplicationPanel.toNewTransaction" />
          </NamedLink>
        </div>
      )}
    </>
  );
};

export default Actions;
