import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '..';

import { FaStar } from 'react-icons/fa';

import freelancerImage1 from '../../assets/freelancer-highlight-1.webp';
import freelancerImage2 from '../../assets/freelancer-highlight-2.webp';
import freelancerImage3 from '../../assets/freelancer-highlight-3.webp';
import freelancerImage4 from '../../assets/freelancer-highlight-4.webp';
import freelancerImage5 from '../../assets/freelancer-highlight-5.webp';

import css from './SectionBestTalent.css';

const SectionBestTalent = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const freelancerTitle1 = <FormattedMessage id="SectionBestTalent.freelancerTitle1" />;
  const freelancerTitle2 = <FormattedMessage id="SectionBestTalent.freelancerTitle2" />;
  const freelancerTitle3 = <FormattedMessage id="SectionBestTalent.freelancerTitle3" />;
  const freelancerTitle4 = <FormattedMessage id="SectionBestTalent.freelancerTitle4" />;
  const freelancerTitle5 = <FormattedMessage id="SectionBestTalent.freelancerTitle5" />;

  const freelancerName1 = <FormattedMessage id="SectionBestTalent.freelancerName1" />;
  const freelancerName2 = <FormattedMessage id="SectionBestTalent.freelancerName2" />;
  const freelancerName3 = <FormattedMessage id="SectionBestTalent.freelancerName3" />;
  const freelancerName4 = <FormattedMessage id="SectionBestTalent.freelancerName4" />;
  const freelancerName5 = <FormattedMessage id="SectionBestTalent.freelancerName5" />;

  const FreelancerCard = ({ title, name, img }) => {
    return (
      <NamedLink name="SignupPage" className={css.freelancerCard}>
        <div className={css.freelancerCardImage}>
          <img src={img} alt={name} />
        </div>
        <div className={css.freelancerCardContent}>
          <div className={css.freelancerCardTopRow}>
            <div className={css.freelancerCardName}>{name}</div>
            <div className={css.freelancerCardReviews}>
              <div className={css.freelancerCardReviewCount}>5.0</div>
              <div className={css.freelancerCardReviewIcon}>
                <FaStar />
              </div>
            </div>
          </div>
          <div className={css.freelancerCardTitle}>{title}</div>
        </div>
      </NamedLink>
    );
  };

  return (
    <div className={classes}>
      <div className={css.container}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionBestTalent.title" />
        </h2>
        <div className={css.freelancerCards}>
          <FreelancerCard title={freelancerTitle1} name={freelancerName1} img={freelancerImage1} />
          <FreelancerCard title={freelancerTitle2} name={freelancerName2} img={freelancerImage2} />
          <FreelancerCard title={freelancerTitle3} name={freelancerName3} img={freelancerImage3} />
          <FreelancerCard title={freelancerTitle4} name={freelancerName4} img={freelancerImage4} />
          <FreelancerCard title={freelancerTitle5} name={freelancerName5} img={freelancerImage5} />
        </div>
        <NamedLink name="SignupPage" className={css.sectionBestTalentButton}>
          <FormattedMessage id="SectionBestTalent.sectionBestTalentButton" />
        </NamedLink>
        <div className={css.subtitle}>
          <FormattedMessage id="SectionBestTalent.subTitle" />
        </div>
      </div>
    </div>
  );
};

SectionBestTalent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionBestTalent.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionBestTalent;
