import React from 'react';
import classNames from 'classnames';
import { MdVerified } from 'react-icons/md';

import { Tooltip } from '../../components';

import css from './VerifiedUserMark.module.css';

const VerifiedUserMark = props => {
  const { className, rootClassName, user, heroSection } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (heroSection) {
    return (
      <Tooltip messageId="Tooltip.verifiedMark">
        <span>
          <MdVerified className={classes} />
        </span>
      </Tooltip>
    );
  } else if (!user?.attributes?.profile?.metadata?.verified) {
    return null;
  } else {
    return (
      <Tooltip messageId="Tooltip.verifiedMark">
        <span>
          <MdVerified className={classes} />
        </span>
      </Tooltip>
    );
  }
};

export default VerifiedUserMark;
