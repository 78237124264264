import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { propTypes } from '../../util/types';
import { required, textEditorValidation, composeValidators } from '../../util/validators';
import { InfoIcon, Form, Button, FieldTextEditor, UnsavedInfoPrompt } from '../../components';

import css from './EditProjectListingDescriptionForm.css';

const EditProjectListingDescriptionFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          dirty,
        } = formRenderProps;

        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionRequiredMessage',
        });
        const projectIndustryExperienceRequiredMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.projectIndustryExperienceRequiredMessage',
        });
        const projectProblemRequiredMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.projectProblemRequiredMessage',
        });

        const descriptionInfoFirst = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoFirst',
        });
        const descriptionInfoSecond = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoSecond',
        });
        const descriptionInfoThird = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoThird',
        });
        const problemInfoFirst = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.problemInfoFirst',
        });
        const industryExperienceInfoFirst = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.industryExperienceInfoFirst',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionPlaceholder',
        });
        const projectProblemPlaceholderMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.projectProblemPlaceholder',
        });

        const projectIndustryExperiencePlaceholderMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.projectIndustryExperiencePlaceholder',
        });

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const externalLinksMessage = intl.formatMessage({
          id: 'RichTextEditor.externalLinksError',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            {typeof window === 'object' ? <ReactTooltip /> : null}

            <div className={css.titleBlock}>
              <InfoIcon
                className={css.infoIcon}
                data-tip={intl.formatMessage({
                  id: 'EditProjectListingDescriptionForm.descriptionTooltip',
                })}
                data-border={true}
                data-text-color={'black'}
                data-background-color={'white'}
                data-border-color={'darkgrey'}
                data-class={css.tooltipText}
                data-place={'bottom'}
              />
            </div>
            <div>
              <label htmlFor="projectProblem" className={css.label}>
                {projectProblemPlaceholderMessage}
              </label>
              <ul className={css.descriptionInfoList}>
                <li className={css.infoListItem}>{problemInfoFirst}</li>
              </ul>
              <FieldTextEditor
                id="projectProblem"
                name="projectProblem"
                className={css.description}
                type="textarea"
                placeholder={projectProblemPlaceholderMessage}
                validate={composeValidators(
                  required(projectProblemRequiredMessage),
                  textEditorValidation(externalLinksMessage)
                )}
              />
            </div>
            <div>
              <label htmlFor="descriptionText" className={css.label}>
                {descriptionPlaceholderMessage}
              </label>
              <ul className={css.descriptionInfoList}>
                <li className={css.infoListItem}>{descriptionInfoFirst}</li>
                <li className={css.infoListItem}>{descriptionInfoSecond}</li>
                <li className={css.infoListItem}>{descriptionInfoThird}</li>
              </ul>
              <FieldTextEditor
                id="descriptionText"
                name="descriptionText"
                className={css.description}
                type="textarea"
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(
                  required(descriptionRequiredMessage),
                  textEditorValidation(externalLinksMessage)
                )}
              />
            </div>
            <div>
              <label htmlFor="projectIndustryExperience" className={css.label}>
                {projectIndustryExperiencePlaceholderMessage}
              </label>
              <ul className={css.descriptionInfoList}>
                <li className={css.infoListItem}>{industryExperienceInfoFirst}</li>
              </ul>
              <FieldTextEditor
                id="projectIndustryExperience"
                name="projectIndustryExperience"
                className={css.description}
                type="textarea"
                placeholder={projectIndustryExperiencePlaceholderMessage}
                validate={composeValidators(
                  required(projectIndustryExperienceRequiredMessage),
                  textEditorValidation(externalLinksMessage)
                )}
              />
            </div>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
            <UnsavedInfoPrompt when={dirty} />
          </Form>
        );
      }}
    />
  );
};

EditProjectListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditProjectListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditProjectListingDescriptionFormComponent);
