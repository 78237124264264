import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import renderHTML from 'react-render-html';
import css from './ListingPage.css';

const SectionDescriptionMaybe = props => {
  const { description, publicData } = props;
  const { descriptionText } = publicData || {};
  const descriptionTextHtml = descriptionText ? renderHTML(descriptionText) : description;
  return description ?? descriptionText ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <div className={css.descriptionText}>{descriptionTextHtml}</div>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
