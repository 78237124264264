import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ApplicationPanel.css';

export const INSTRUCTION_ENQUIRED = 'enquired';
export const INSTRUCTION_SKIPPED = 'skipped';
export const INSTRUCTION_ACCEPTED = 'accepted';
export const INSTRUCTION_EXPIRED = 'expired';

const Instructions = props => {
  const { instructionsState, isCustomer, actionError, user } = props;
  const displayName = user?.attributes?.profile?.displayName;

  switch (instructionsState) {
    case INSTRUCTION_ENQUIRED:
      return isCustomer ? (
        <Instruction
          headerId="Instructions.headerEnquiredCustomer"
          messageId="Instructions.messageEnquiredCustomer"
          actionError={actionError}
        />
      ) : (
        <Instruction
          headerId="Instructions.headerEnquiredProvider"
          messageId="Instructions.messageEnquiredProvider"
          actionError={actionError}
          displayName={displayName}
        />
      );
    case INSTRUCTION_SKIPPED:
      return isCustomer ? (
        <Instruction
          headerId="Instructions.headerSkippedCustomer"
          messageId="Instructions.messageSkippedCustomer"
          actionError={actionError}
        />
      ) : (
        <Instruction
          headerId="Instructions.headerSkippedProvider"
          messageId="Instructions.messageSkippedProvider"
          actionError={actionError}
        />
      );
    case INSTRUCTION_ACCEPTED:
      return isCustomer ? (
        <Instruction
          headerId="Instructions.headerAcceptedCustomer"
          messageId="Instructions.messageAcceptedCustomer"
          actionError={actionError}
        />
      ) : (
        <Instruction
          headerId="Instructions.headerAcceptedProvider"
          messageId="Instructions.messageAcceptedProvider"
          actionError={actionError}
        />
      );
    case INSTRUCTION_EXPIRED:
      return isCustomer ? (
        <Instruction
          headerId="Instructions.headerExpiredCustomer"
          messageId="Instructions.headerExpiredCustomer"
          actionError={actionError}
        />
      ) : (
        <Instruction
          headerId="Instructions.headerExpiredProvider"
          messageId="Instructions.messageExpiredProvider"
          actionError={actionError}
        />
      );
    default:
      return null;
  }
};

const Instruction = ({ headerId, messageId, actionError, displayName }) => (
  <div className={css.instruction}>
    <h2 className={css.instructionHeader}>
      <FormattedMessage id={headerId} values={{ displayName }} />
    </h2>
    <p>
      <FormattedMessage id={messageId} />
    </p>
    {actionError ? (
      <div className={css.errorMessage}>
        <FormattedMessage id="Instructions.errorMessage" />
      </div>
    ) : null}
  </div>
);

export default Instructions;
