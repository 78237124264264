import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATE } from '../../util/types';
import { BookingBreakdown } from '../../components';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import css from './TransactionPanel.css';
import { formatMoney } from '../../util/currency';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    listing,
    intl,
  } = props;
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const lastTransition = transaction && (transaction.attributes.lastTransition || '');
  const { listingType } = (listing && listing.attributes.publicData) || {};
  const hideBreakdown = listingType === 'project' && lastTransition === TRANSITION_ENQUIRE;

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  const projectBudget =
    listing && listing.attributes.price ? formatMoney(intl, listing.attributes.price) : null;

  return loaded ? (
    <div className={classes}>
      <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={config.bookingUnitType}
        transaction={transaction}
        listing={listing}
        booking={transaction.booking}
        dateType={DATE_TYPE_DATE}
      />
    </div>
  ) : projectBudget && !hideBreakdown && listingType === 'project' ? (
    <div className={css.projectBudgetLineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.baseUnitProject" />
      </span>
      <span className={css.itemValue}>{projectBudget}</span>
    </div>
  ) : null;
};

export default injectIntl(BreakdownMaybe);
