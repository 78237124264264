// ================ Action types ================ //

export const SET_PROJECT_CATEGORY_REQUEST =
  'app/ProjectCategoryLandingPage/SET_PROJECT_CATEGOR_REQUEST';
export const SET_PROJECT_CATEGORY_SUCCESS =
  'app/ProjectCategoryLandingPage/SET_PROJECT_CATEGORY_SUCCESS';
export const SET_PROJECT_CATEGORY_ERROR =
  'app/ProjectCategoryLandingPage/SET_PROJECT_CATEGORY_ERROR';
export const SET_FORM_CONTENT_REQUEST = 'app/ProjectCategoryLandingPage/SET_FORM_CONTENT_REQUEST';
export const SET_FORM_CONTENT_SUCCESS = 'app/ProjectCategoryLandingPage/SET_FORM_CONTENT_SUCCESS';
export const SET_FORM_CONTENT_ERROR = 'app/ProjectCategoryLandingPage/SET_FORM_CONTENT_ERROR';

// ================ Reducer ================ //

const initialState = {
  projectCategory: null,
  formContent: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_PROJECT_CATEGORY_REQUEST:
      return {
        ...state,
        projectCategory: payload,
      };
    case SET_PROJECT_CATEGORY_SUCCESS:
      return {
        ...state,
        projectCategory: payload,
      };
    case SET_PROJECT_CATEGORY_ERROR:
      return {
        ...state,
        projectCategory: payload,
      };
    case SET_FORM_CONTENT_REQUEST:
      return {
        ...state,
        formContent: payload,
      };
    case SET_FORM_CONTENT_SUCCESS:
      return {
        ...state,
        formContent: payload,
      };
    case SET_FORM_CONTENT_ERROR:
      return {
        ...state,
        formContent: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setProjectCategoryRequest = () => ({
  type: SET_PROJECT_CATEGORY_REQUEST,
});

export const setProjectCategorySuccess = category => ({
  type: SET_PROJECT_CATEGORY_SUCCESS,
  payload: category,
});

export const setProjectCategoryError = error => ({
  type: SET_PROJECT_CATEGORY_ERROR,
  payload: error,
});

export const setFormContentRequest = () => ({
  type: SET_FORM_CONTENT_REQUEST,
});

export const setFormContentSuccess = formContent => ({
  type: SET_FORM_CONTENT_SUCCESS,
  payload: formContent,
});

export const setFormContentError = error => ({
  type: SET_FORM_CONTENT_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const setProjectCategory = (category, formContent) => dispatch => {
  dispatch(setProjectCategoryRequest());
  dispatch(setProjectCategorySuccess(category));
  dispatch(setFormContentRequest());
  dispatch(setFormContentSuccess(formContent));
  return category;
};
