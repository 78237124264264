import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>Last updated: 27.04.2023</p>
      <h1 id="1-general-terms">1. General Terms</h1>
      <p>1.1 User Registration and Acceptance</p>
      <p>
        The use of Freedomly.io is restricted to individuals or businesses who have been registered
        and accepted as users (&quot;User&quot;). Users can be service providers (sellers) or
        service seekers (buyers). Freedomly.io acts as the service provider.
      </p>
      <p>1.2 Responsibilities of Sellers and Accuracy of Listings</p>
      <p>
        Sellers are responsible for their listings and agreements with customers. Freedomly.io does
        not assume responsibility for the accuracy of listings or agreements between customers and
        sellers.
      </p>
      <p>1.3 Limitation of Liability</p>
      <p>
        Freedomly.io and its partners are not liable for any damages resulting from listings,
        delays, errors, or actions of third parties.
      </p>
      <p>#</p>
      <h1 id="2-user-agreement">2. User Agreement</h1>
      <p>2.1 Truthful and Accurate Information</p>
      <p>
        By registering with the Freedomly.io service, users agree to provide truthful and accurate
        information during registration and agree to update their information if changes occur.
      </p>
      <p>2.2 Ownership and Rights of Uploaded Content</p>
      <p>
        Users agree to only upload or share content (including images, text, and other media) that you own or have explicit rights to use. Users also ensure that their content does not violate any third-party rights, such as copyrights, trademarks, or other intellectual property laws.
      </p>
      <p>2.3 Access to Service</p>
      <p>
        Upon registration, users receive a personalized profile and username. However, the service
        provider reserves the right to deny access if deemed necessary.
      </p>
      <p>2.4 Confidentiality of Personal Information</p>
      <p>
        The service provider handles the personal information of registered users confidentially and
        in accordance with privacy legislation.
      </p>
      <p>2.5 Disclosure of Contact Information</p>
      <p>
        User contact information may be disclosed to their contractual partner after a buyer and
        seller agreement has been established.
      </p>
      <p>2.6 Limitation on Profiles and Usernames</p>
      <p>
        Each user is allowed only one profile address and username with one email address unless
        otherwise approved by the service provider.
      </p>
      <p>2.7 Usage Rights and Login Credentials</p>
      <p>
        Users do not have the right to transfer their usage rights to third parties. Usernames and
        passwords are personal and should not be shared with third parties. Users are responsible
        for all actions taken using their login credentials. In the event of unauthorized use, users
        must promptly notify the service provider.
      </p>
      <p>2.8 Account Closure</p>
      <p>
        The service provider reserves the right to close a user&#39;s account without prior notice
        if there are suspicions of the user violating these Terms, laws, or good practices, or if
        the user has not logged into the service for twelve (12) months.
      </p>
      <h1 id="3-additional-seller-terms">3. Additional Seller Terms</h1>
      <p>3.1 Accuracy of Information and Listings</p>
      <p>
        Sellers must ensure the accuracy of the information provided and the content of their
        listings. If the service provider identifies any deficiencies or errors in the seller&#39;s
        information or listings, the provider is entitled to make corrections by contacting the
        seller and, if necessary, to suspend the account.
      </p>
      <p>3.2 Communication on the Platform</p>
      <p>
        To ensure a secure and transparent environment, it is strictly prohibited for sellers to
        include external contact information in their listings. All communication with customers
        must be conducted exclusively on the platform.
      </p>
      <p>Violation of the communication policy may result in the following consequences:</p>
      <ol>
        <li>
          Suspension: If a user is found to have violated the communication policy, their account
          may be suspended for a period of 90 days as a warning and reminder to adhere to the
          guidelines.
        </li>
        <li>
          Account Deletion: In the event of a repeated violation of the communication policy, the
          user&#39;s account may be permanently deleted, leading to a termination of their access to
          the platform.
        </li>
      </ol>
      <p>
        By following the communication guidelines and conducting all interactions with customers
        within the platform, sellers contribute to maintaining a trusted and secure environment for
        all users.
      </p>
      <p>3.3 Restrictions on Company Name or Business Identification</p>
      <p>
        Sellers are not allowed to include references to their company name or business
        identification number in their profiles or listings.
      </p>
      <p>3.4 Account Closure for Violation</p>
      <p>
        In the event of a seller violating Freedomly.io&#39;s terms of service, the service provider
        has the right to close or freeze the account.
      </p>
      <h1 id="4-payment-processing">4. Payment Processing</h1>
      <p>4.1 Third-Party Payment Provider</p>
      <p>
        The actual payment processing is facilitated through a third-party service provider, Stripe.
        Please refer to Stripe&#39;s instructions for further details. The service provider is not
        responsible for marketplace payment transactions but acts solely as an intermediary between
        the customer and seller. In case of disputes, the service provider has the right to assess
        the violation of the agreement and thereby facilitate the transfer of funds between the
        customer and seller.
      </p>
      <p>4.2 Payment - Buying</p>
      <p>
        When a customer purchases services from a seller, the customer&#39;s account is charged with
        a deposit corresponding to the agreed price of the contract. Once the customer has received
        the purchased service from the seller, the deposit is released, and the payment is
        transferred to the seller&#39;s account. If, for any reason, the seller is unable to fulfill
        the service, the deposit will be refunded to the customer&#39;s account.
      </p>
      <p>4.3 Payment Options</p>
      <p>Payment can be made using commonly accepted bank and credit cards.</p>
      <p>4.4 Payment - Selling</p>
      <p>
        Sellers determine the price for their services. After the contract is established, the
        customer&#39;s account is charged with the deposit corresponding to the service price. Once
        the seller has completed the agreed service, the customer releases the funds to the seller.
        The seller&#39;s account receives the agreed-upon fee, which has been reduced by the
        commission charged by the service provider.
      </p>
      <p>4.5 Stripe User Account</p>
      <p>
        VAT Sellers must create a Stripe user account to receive payments. Ligh-entrepreneurs need
        to send an invoice to service provider about the service they have provided.
      </p>
      <p>#</p>
      <h1 id="5-taxes">5. Taxes</h1>
      <p>
        Users of Freedomly.io are responsible for reporting any necessary information to the tax
        authorities. Freedomly.io does not assume responsibility for the tax reporting of sellers or
        buyers.
      </p>
      <h1 id="6-pricing">6. Pricing</h1>
      <p>
        Freedomly.io reserves the right to change the pricing of the service. The price of the
        service is communicated to each user based on the current pricing structure.
      </p>
      <h1 id="7-commission">7. Commission</h1>
      <p>
        The service provider charges a 15% commission on marketplace transactions. The commission is
        included in the seller&#39;s determined service price. For example, if a seller sets the
        price of a service at €100, the service provider will charge a commission of €15.
      </p>
      <p>
        Please note that for Freedomly Pro, the commission fee is set at 15% per hire. However,
        it&#39;s important to acknowledge that the service provider retains the right to modify the
        pricing structure for this service.
      </p>
      <h1 id="8-service-functionality-and-changes">8. Service Functionality and Changes</h1>
      <p>8.1 Access and Modification of Service</p>
      <p>
        Freedomly.io&#39;s service is accessible via common internet browsers. The service provider
        has the right to modify or renew the content of the service as deemed appropriate.
        Freedomly.io aims to provide continuous and uninterrupted service availability and to
        promptly address any disruptions.
      </p>
      <p>8.2 Limitation of Liability for Technical Issues</p>
      <p>
        However, the service provider is not responsible for technical issues, interruptions caused
        by maintenance or installation actions, or any resulting harm to the user, including changes
        or loss of data. The maximum liability for damages resulting from technical problems or
        service termination is limited to the fee paid for the service. Users should not assume that
        any information or content stored in the service will remain available indefinitely.
      </p>
      <p>#</p>
      <h1 id="9-intellectual-property-rights">9. Intellectual Property Rights</h1>
      <p>9.1 Transfer of Intellectual Property Rights</p>
      <p>
        Sellers commit to transferring registered and unregistered intellectual property rights
        resulting from their work or as by-products to the buyer, without separate compensation
        unless otherwise required by law. Intellectual property rights include, among others,
        copyrights, industrial property rights, unregistered designs, patents, utility models,
        trademarks, and design rights that arise between the seller and buyer. When a buyer
        purchases services from a seller, intellectual property rights are immediately transferred
        to the buyer.
      </p>
      <p>9.2 Publication of Materials</p>
      <p>
        Sellers are not permitted to publish materials that fall under intellectual property rights,
        which they have created for the buyer during their collaboration, on the internet without
        appropriate protection. Sellers must not directly copy or quote assignments they have
        performed in their own publicly accessible portfolios.
      </p>
      <h1 id="10-submitting-offers">10. Submitting Offers</h1>
      <p>10.1 Use of Offer Form</p>
      <p>
        Sellers commit to submitting their bids to buyers through the offer form provided by the
        Freedomly.io service. Sellers must clearly state the content and price of the offer.
      </p>
      <p>10.2 Communication within the Platform</p>
      <p>
        Both the seller and buyer agree to conduct discussions regarding the assignment&#39;s
        content within the Freedomly.io platform and not through alternative means such as email.
      </p>
      <h1 id="11-service">11. Service</h1>
      <p>11.1 Independent Contractor Status</p>
      <p>
        Sellers and buyers acknowledge that the seller delivers services as an independent
        contractor. The seller is not an employee or representative of the buyer. The service
        provider is not responsible for the seller&#39;s work, and the seller is solely responsible
        for the services they provide. Sellers commit to delivering their services to the buyer
        professionally, within the agreed-upon timeframe, and according to the assignment&#39;s
        agreed content.
      </p>
      <h1 id="12-confidentiality">12. Confidentiality</h1>
      <p>12.1 Recommendation for Separate Non-Disclosure Agreement</p>
      <p>
        The service provider, Freedomly.io, strongly recommends that buyers and sellers consider
        entering into a separate Non-Disclosure Agreement (NDA) or other confidentiality contract,
        as necessary, to protect any confidential information that may be shared during the course
        of their interaction and service provision. This is particularly important if the service or
        project involves sharing sensitive business information, trade secrets, or other proprietary
        data.
      </p>
      <p>12.2 Responsibility for NDA Compliance</p>
      <p>
        Please note that this NDA or confidentiality contract is a bilateral agreement between the
        buyer and the seller. Freedomly.io is not a party to this agreement and does not assume any
        responsibility for enforcing or monitoring compliance with these additional agreements.
      </p>
      <p>12.3 Default Confidentiality Agreement</p>
      <p>
        If users do not establish any other confidentiality agreements, they agree to the following:
      </p>
      <ul>
        <li>
          <p>
            When a user discloses confidential information to another user, the recipient agrees to
            protect the confidentiality of the information provided.
          </p>
        </li>
        <li>
          <p>
            At the written request of the user providing the confidential information, the party
            receiving the information must promptly destroy or return all copies of the confidential
            information in its possession, whether within its premises, systems, or any other
            devices under its control.
          </p>
        </li>
      </ul>
      <h1 id="13-applicable-law">13. Applicable Law</h1>
      <p>
        Unless otherwise required by mandatory legislation, the Freedomly.io service and these terms
        of service are governed by Finnish law.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
