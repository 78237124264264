import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SelectCategoryFilterPlain.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const isOptionSelected = (initialValue, option) => {
  if(typeof initialValue !== 'string' || initialValue.length === 0){
    return false;
  }

  if(typeof option !== 'string' || option.length === 0){
    return false;
  }

  const selectedOptions = initialValue.split(',');

  return selectedOptions.indexOf(option) > -1;
};

class SelectCategoryFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.limitCategoryList = this.limitCategoryList.bind(this);
  }

  selectOption(initialValue, option, e) {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);

    if(option) {
      const selectedOptions = typeof initialValue === 'string' && initialValue.length > 0 ?
        initialValue.split(',') : [];

      const optionIndex = selectedOptions.indexOf(option);
      if(optionIndex > -1){
        selectedOptions.splice(optionIndex, 1);
      } else {
        selectedOptions.push(option);
      }

      if(selectedOptions.length > 0) {
        onSelect({ [queryParamName]: selectedOptions.join(',') });
      } else {
        onSelect({ [queryParamName]: null });
      }
    } else {
      onSelect({ [queryParamName]: null });
    }

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  limitCategoryList(e) {
    if(typeof e.target.value === 'string' && e.target.value.length > 0){
      this.setState({ searchCategory: e.target.value });
    } else {
      this.setState({ searchCategory: null });
    }
  };

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      twoColumns,
      useBullets,
    } = this.props;

    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;
    const labelClass = initialValue ? css.filterLabelSelected : css.filterLabel;

    const hasBullets = useBullets || twoColumns;
    const optionsContainerClass = classNames({
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.hasBullets]: hasBullets,
      [css.twoColumns]: twoColumns,
    });

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={labelClass}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={labelClass}>{label}</span>
          </button>
          <button className={css.clearButton} onClick={e => this.selectOption(initialValue, null, e)}>
            <FormattedMessage id={'SelectSingleFilter.plainClear'} />
          </button>
        </div>
        <div className={optionsContainerClass}>
          {options.map(option => {
            // check if this option is selected
            const selected = isOptionSelected(initialValue, option.key);
            const optionClass = hasBullets && selected ? css.optionSelected : css.option;
            const optionFontClass = option.parent ? css.subCategoryText : css.mainCategoryText;
            // menu item selected bullet or border class
            const optionBorderClass = hasBullets
              ? classNames({
                  [css.optionBulletSelected]: selected,
                  [css.optionBullet]: !selected,
                })
              : classNames({
                  [css.optionBorderSelected]: selected,
                  [css.optionBorder]: !selected,
                });
            return (
              <button
                key={option.key}
                className={classNames(optionClass, optionFontClass)}
                onClick={() => this.selectOption(initialValue, option.key)}
              >
                <span className={optionBorderClass} />
                {option.label}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

SelectCategoryFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useBullets: false,
};

SelectCategoryFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useBullets: bool,
};

export default SelectCategoryFilterPlain;
