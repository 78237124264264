/**
 * A text field with phone number formatting. By default uses formatting
 * rules defined in the fiFormatter.js file. To change the formatting
 * provide alternative implementations for the format and parse functions
 * that are passed to the input field.
 */
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';

import { ValidationError } from '../../components';
import { injectIntl } from '../../util/reactIntl';
import { Field } from 'react-final-form';
import startsWith from 'lodash/startsWith';

import css from './FieldPhoneNumberInput.css';

const PhoneInputField = ({ id, input, meta, label, warning, intl, ...rest }) => {
  const { touched, error, valid } = meta;
  const showError = touched && error;
  const defaultErrorMessage = intl.formatMessage({ id: 'FieldPhoneNumberInput.formatWarning' });
  const searchPlaceholder = intl.formatMessage({ id: 'FieldPhoneNumberInput.searchPlaceholder' });
  const searchNotFoundMessage = intl.formatMessage({ id: 'FieldPhoneNumberInput.searchNotFound' });

  const inputProps = {
    ...input,
    ...rest,
  };

  const inputClasses = classNames(css.input, {
    [css.inputValid]: valid,
    [css.inputError]: showError,
  });

  const isValid = (inputNumber, country, countries) => {
    return countries.some(country => {
      return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
    });
  };

  return (
    <div>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <PhoneInput
        className={inputClasses}
        {...inputProps}
        country={'fi'}
        isValid={warning && isValid}
        defaultErrorMessage={defaultErrorMessage}
        enableSearch
        disableSearchIcon
        searchPlaceholder={searchPlaceholder}
        searchNotFound={searchNotFoundMessage}
        enableLongNumbers={true}
      />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

const FieldPhoneInput = props => <Field component={PhoneInputField} {...props} />;

export default injectIntl(FieldPhoneInput);
