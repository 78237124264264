import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, KeywordSearchBar } from '../../components';

import css from './ModalKeywordSearch.css';

const ModalKeywordSearch = withRouter(props => {
  const {
    modalId,
    searchId,
    isOpen,
    onClose,
    urlParam,
    onManageDisableScrolling,
    history,
    handleSubmit,
    containerClass,
  } = props;

  const containerClassName = containerClass || css.modalContainer;

  return (
    <Modal
      id={modalId}
      containerClassName={containerClassName}
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.searchContainer}>
        <KeywordSearchBar
          onSubmit={values => {
            handleSubmit(values, history);
          }}
          name={urlParam}
          id={searchId}
          rootClassName={css.keywordSearchBarRoot}
        />
      </div>
    </Modal>
  );
});

export default ModalKeywordSearch;
