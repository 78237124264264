import React from 'react';
import { Avatar, NamedLink } from '../../components';
import { MdMessage } from 'react-icons/md';
import { formatDate } from '../../util/dates';
import {
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PROJECT,
  TRANSITION_RELEASE_FUNDS,
  TRANSITION_JOB_DONE_RELEASE_FUNDS,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_JOB_DONE,
  TRANSITION_JOB_DONE_CUSTOMER,
  TRANSITION_MAKE_PROJECT_BID_AFTER_ENQUIRY,
} from '../../util/transaction';

import css from './NotificationFeed.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

const NotificationFeed = ({
  currentUser,
  currentUserNotificationTxs,
  notifications,
  isFreelancer,
  intl,
}) => {
  const allMessagesMessage = intl.formatMessage({
    id: 'NotificationFeed.allMessages',
  });

  const fetchedNotifications = notifications.filter(item =>
    currentUserNotificationTxs.some(tx => tx.id.uuid === item.txId)
  );

  return (
    <div className={css.container}>
      <NamedLink
        className={css.linkToInbox}
        name="InboxPage"
        params={{ tab: isFreelancer ? 'sales' : 'orders' }}
      >
        <span>{allMessagesMessage}</span>
        <MdMessage />
      </NamedLink>
      {fetchedNotifications.map(item => {
        const transaction = currentUserNotificationTxs.find(tx => tx.id.uuid === item.txId);
        return transaction ? (
          <NotificationFeedItem
            key={item.txId}
            currentUser={currentUser}
            message={item}
            transaction={transaction}
            intl={intl}
          />
        ) : null;
      })}
    </div>
  );
};

const NotificationFeedItem = ({ currentUser, message, transaction, intl }) => {
  const sender =
    currentUser.id.uuid === transaction.provider.id.uuid
      ? transaction.customer
      : transaction.provider;

  const transactionRole =
    transaction.provider.id.uuid === currentUser.id.uuid ? PROVIDER : CUSTOMER;
  const isCustomerRole = transactionRole === CUSTOMER;

  // No messages in transition notifications
  const isMessage = message.message !== undefined;

  const findTransitionTranslation = transition => {
    switch (transition) {
      case TRANSITION_MAKE_PROJECT_BID_AFTER_ENQUIRY:
        return intl.formatMessage({ id: 'NotificationFeed.newOffer' });
      case TRANSITION_CONFIRM_PAYMENT:
      case TRANSITION_CONFIRM_PROJECT:
        return intl.formatMessage({ id: 'NotificationFeed.acceptOffer' });
      case TRANSITION_RELEASE_FUNDS:
      case TRANSITION_JOB_DONE_RELEASE_FUNDS:
        return intl.formatMessage({ id: 'NotificationFeed.paymentReleased' });
      case TRANSITION_JOB_DONE:
      case TRANSITION_JOB_DONE_CUSTOMER:
        return intl.formatMessage({ id: 'NotificationFeed.jobDone' });
      case TRANSITION_REVIEW_1_BY_CUSTOMER:
      case TRANSITION_REVIEW_2_BY_CUSTOMER:
      case TRANSITION_REVIEW_1_BY_PROVIDER:
      case TRANSITION_REVIEW_2_BY_PROVIDER:
        return intl.formatMessage({ id: 'NotificationFeed.newReview' });
      default:
        return intl.formatMessage({ id: 'NotificationFeed.newEvent' });
    }
  };

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  const content = isMessage
    ? message.message.attributes.content
    : findTransitionTranslation(transaction.attributes.lastTransition);

  const timeStamp = isMessage
    ? formatDate(intl, todayString, new Date(message.message.attributes.createdAt))
    : formatDate(intl, todayString, transaction.attributes.lastTransitionedAt);

  return (
    <div className={css.item}>
      <Avatar user={sender} />
      <NamedLink
        className={css.linkToDetails}
        name={isCustomerRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        params={{ id: transaction.id.uuid }}
      >
        <div>
          <p className={css.message}>{content}</p>
          <p className={css.timeStamp}>{timeStamp}</p>
        </div>
      </NamedLink>
    </div>
  );
};

export default NotificationFeed;
