import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const images = listing.images;
  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;
  const [heroImage, setHeroImage] = useState(firstImage);
  const [activeIndex, setActiveIndex] = useState(0);

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage id="ListingPage.viewImagesButton" />
    </button>
  ) : null;

  const handleImageClick = (i, ind) => {
    setHeroImage(i);
    if (ind > 0 && ind < images.length - 1) setActiveIndex(ind - 1);
  };

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          {actionBar}
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={heroImage}
            variants={[
              'landscape-crop',
              'landscape-crop2x',
              'landscape-crop4x',
              'landscape-crop6x',
              'square-small2x',
            ]}
          />
          {viewPhotosButton}
        </div>
        <ul className={css.imagesList}>
          {images.map((i, ind) =>
            ind < activeIndex + 3 && ind >= activeIndex ? (
              <li className={css.imagesListItem} onClick={() => handleImageClick(i, ind)} key={ind}>
                <ResponsiveImage alt="Listing image" image={i} variants={['square-small2x']} />
                {ind > activeIndex + 1 && ind !== images.length - 1 ? (
                  <div className={css.arrowContainer}>
                    <FaArrowRight className={css.arrowRight} />
                  </div>
                ) : null}
                {ind < activeIndex + 1 && ind !== 0 ? (
                  <div className={css.arrowContainer}>
                    <FaArrowLeft className={css.arrowLeft} />
                  </div>
                ) : null}
              </li>
            ) : null
          )}
        </ul>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={images} />
      </Modal>
    </div>
  );
};

export default SectionImages;
