import React from 'react';
import { Prompt } from 'react-router-dom';
import { injectIntl } from 'react-intl';

const UnsavedInfoPrompt = ({ when, intl, message }) => {
  const defaultMessage = intl.formatMessage({ id: 'DirtyFormAlert' });
  return <Prompt when={when} message={message || defaultMessage} />;
};

export default injectIntl(UnsavedInfoPrompt);
