import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FaSearch } from 'react-icons/fa';

import { injectIntl } from '../../util/reactIntl';
import {
  Form,
  SecondaryButton,
  Button,
  FieldSelectModern,
  IconClose,
  InlineTextButton,
} from '../../components';
import config from '../../config';
import { required } from '../../util/validators';

import css from './SignupProviderForms.css';

const hobbyOptions = config.custom.hobbyOptions;

const Placeholder = () => (
  <div className={css.placeholderContainer}>
    <FaSearch className={css.searchIcon} />
  </div>
);

const ProfileSettingsHobbiesForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          form: {
            mutators: { push },
          },
          invalid,
          values,
          isModal,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid;

        const heading = intl.formatMessage({
          id: 'SignupProviderForms.hobbiesHeading',
        });
        const modalHeading = intl.formatMessage({
          id: 'SignupProviderForms.modalHobbiesHeading',
        });

        const nextButtonText = intl.formatMessage({
          id: 'SignupProviderForms.nextButton',
        });
        const previousButtonText = intl.formatMessage({
          id: 'SignupProviderForms.previousButton',
        });
        const addButtonText = intl.formatMessage({
          id: 'SignupProviderForms.addButton',
        });
        const okButtonText = intl.formatMessage({
          id: 'SignupProviderForms.okButton',
        });

        const point1 = intl.formatMessage({
          id: 'SignupProviderForms.hobbiesPoint1',
        });
        const point2 = intl.formatMessage({
          id: 'SignupProviderForms.hobbiesPoint2',
        });

        const categoryRequired = intl.formatMessage({
          id: 'SignupProviderForms.skillsCategoryRequired',
        });

        const showAddToArray = values.hobbies?.length < 10 || !values.hobbies;

        // Function to filter out selected skills
        const getAvailableHobbyOptions = index => {
          const selectedHobbies = values.hobbies
            .map((item, idx) => idx !== index && item.category && item.category.value)
            .filter(Boolean);
          const availableHobbyOptions = hobbyOptions.filter(
            option => !selectedHobbies.includes(option.value)
          );

          return availableHobbyOptions.map(option => ({
            ...option,
            label: intl.formatMessage({ id: option.label.props.id }),
          }));
        };

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <h2 className={css.skillsHeading}>
                {!isModal ? heading : modalHeading}
                {isModal ? (
                  <div className={css.submitContainer}>
                    <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                      {okButtonText}
                    </Button>
                  </div>
                ) : null}
              </h2>
              <FieldArray name="hobbies">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <div key={name} className={css.skillsFieldArray}>
                        <FieldSelectModern
                          id={`${name}.category`}
                          name={`${name}.category`}
                          options={getAvailableHobbyOptions(index)}
                          components={{ Placeholder }}
                          className={classNames(css.fieldArrayOption, css.skillsCategory)}
                          validate={required(categoryRequired)}
                        />
                        <span
                          className={css.skillsDelete}
                          onClick={() =>
                            fields?.length === 1 ? fields.update(index, {}) : fields.remove(index)
                          }
                        >
                          <IconClose rootClassName={css.deleteIcon} />
                        </span>
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>
              {showAddToArray && (
                <InlineTextButton
                  type="button"
                  className={css.addToArray}
                  onClick={() => push('hobbies', {})}
                >
                  {addButtonText}
                </InlineTextButton>
              )}
              <ul className={css.pointerList}>
                <li>{point1}</li>
                <li>{point2}</li>
              </ul>

              <div className={css.submitContainer}>
                {!isModal && (
                  <SecondaryButton rootClassName={css.backButton} onClick={onPrev}>
                    {previousButtonText}
                  </SecondaryButton>
                )}
                {!isModal ? (
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {nextButtonText}
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

ProfileSettingsHobbiesForm.defaultProps = {
  headingNeeded: false,
};

export default injectIntl(ProfileSettingsHobbiesForm);
