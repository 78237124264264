import pick from 'lodash/pick';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { markApplicationNew } from '../../util/api';
import { addMarketplaceEntities, getListingsById } from '../../ducks/marketplaceData.duck';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ProjectProposalPage/SET_INITIAL_VALUES';

export const SHOW_PROJECT_REQUEST = 'app/ProjectProposalPage/SHOW_PROJECT_REQUEST';
export const SHOW_PROJECT_ERROR = 'app/ProjectProposalPage/SHOW_PROJECT_ERROR';

export const SEND_PROPOSAL_REQUEST = 'app/ProjectProposalPage/SEND_PROPOSAL_REQUEST';
export const SEND_PROPOSAL_SUCCESS = 'app/ProjectProposalPage/SEND_PROPOSAL_SUCCESS';
export const SEND_PROPOSAL_ERROR = 'app/ProjectProposalPage/SEND_PROPOSAL_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  sendProposalInProgress: false,
  sendProposalError: null,
};

export default function projectProposalPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_PROJECT_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_PROJECT_ERROR:
      return { ...state, showListingError: payload };

    case SEND_PROPOSAL_REQUEST:
      return { ...state, sendProposalInProgress: true, sendProposalError: null };
    case SEND_PROPOSAL_SUCCESS:
      return { ...state, sendProposalInProgress: false };
    case SEND_PROPOSAL_ERROR:
      return { ...state, sendProposalInProgress: false, sendProposalError: payload };

    default:
      return state;
  }
}

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_PROJECT_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_PROJECT_ERROR,
  error: true,
  payload: e,
});

export const sendProposalRequest = () => ({ type: SEND_PROPOSAL_REQUEST });
export const sendProposalSuccess = () => ({ type: SEND_PROPOSAL_SUCCESS });
export const sendProposalError = e => ({ type: SEND_PROPOSAL_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const showListing = listingId => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  return sdk.listings
    .show(params)
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .then(response => {
      const listings = getListingsById(getState(), [getState().ProjectProposalPage.id]);

      if (Array.isArray(listings) && listings.length > 0) {
        const listing = listings[0];

        if (typeof window === 'object') {
          window.dataLayer.push({
            event: 'view_item',
            ecommerce: {
              items: [
                {
                  item_name: listing.attributes.title,
                  item_id: listing.id.uuid,
                  price: '' + listing.attributes.price.amount / 100,
                  item_brand: listing.author.attributes.profile.displayName,
                  item_category: listing.attributes.publicData.category,
                  item_list_name: 'search_results',
                  quantity: '1',
                },
              ],
            },
          });
        }
      }

      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch(showListingError(storableError(e)));
    });
};

export const sendProposal = (listingId, currentUser, protectedData) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(sendProposalRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.projectProcessAlias,
    params: {
      listingId,
      protectedData,
    },
  };
  return sdk.transactions
    .initiate(bodyParams, { expand: true, include: ['provider'] })
    .then(response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const provider = transaction.relationships.provider.data;
      const recipientId = provider.id;

      return markApplicationNew({
        transactionId: transactionId.uuid,
        recipientId: recipientId.uuid,
      }).then(() => {
        dispatch(sendProposalSuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return response;
      });
    })
    .then(response => {
      if (typeof window === 'object') {
        window.Intercom('trackEvent', 'New project enquiry', {
          transactionId: response.data.data.id.uuid,
          listingId: listingId.uuid,
          customerId: response.data.data.relationships.provider.data.id.uuid,
          providerId: currentUser.id.uuid,
          transition: 'TRANSITION_ENQUIRE',
          listingType: 'project',
          processName: 'flex-project-process',
        });
      }
      return response.data.data.id;
    })
    .catch(e => {
      dispatch(sendProposalError(storableError(e)));
      throw e;
    });
};

export const loadData = params => dispatch => {
  const listingId = new UUID(params.id);
  return dispatch(showListing(listingId));
};
