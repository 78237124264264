import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import css from './SectionWhyFreedomly.css';

import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';

const testimonials = [
  {
    text: 'SectionWhyFreedomly.testimonial2',
    from: 'SectionWhyFreedomly.from2',
    company: 'SectionWhyFreedomly.company2',
  },
  {
    text: 'SectionWhyFreedomly.testimonial1',
    from: 'SectionWhyFreedomly.from1',
    company: 'SectionWhyFreedomly.company1',
  },
  {
    text: 'SectionWhyFreedomly.testimonial3',
    from: 'SectionWhyFreedomly.from3',
    company: 'SectionWhyFreedomly.company3',
  },
];

const SectionWhyFreedomly = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const nextTestimonial = () => {
    if (currentTestimonial === testimonials.length - 1) {
      setCurrentTestimonial(0);
    } else {
      setCurrentTestimonial(currentTestimonial + 1);
    }
  };

  const prevTestimonial = () => {
    if (currentTestimonial === 0) {
      setCurrentTestimonial(testimonials.length - 1);
    } else {
      setCurrentTestimonial(currentTestimonial - 1);
    }
  };

  const TestimonialContent = ({ testimonialId }) => {
    return (
      <>
        <div className={css.text}>
          <FormattedMessage id={testimonials[testimonialId].text} />
          <div className={css.fromContainer}>
            <div className={css.textFrom}>
              <FormattedMessage id={testimonials[testimonialId].from} />
            </div>
            <div className={css.textCompany}>
              <FormattedMessage id={testimonials[testimonialId].company} />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={css.container}>
      <TestimonialContent testimonialId={currentTestimonial} />
      <div className={css.arrows}>
        <BsArrowLeftCircle className={css.arrow} onClick={() => prevTestimonial()} />
        <BsArrowRightCircle className={css.arrow} onClick={() => nextTestimonial()} />
      </div>
    </div>
  );
};

export default SectionWhyFreedomly;
