import React from 'react';

import css from './IntegrateFreelancersBanner.css';

import { FormattedMessage } from 'react-intl';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const IntegrateFreelancersBanner = props => {
  const { imgSrc, titleId, textId } = props;

  const title = <FormattedMessage id={titleId} />;
  const text = <FormattedMessage id={textId} />;

  if (typeof window === 'object') {
    if (window.innerWidth <= MAX_MOBILE_SCREEN_WIDTH) {
      return (
        <div className={css.container}>
          <div className={css.imageContainerMobile}>
            <img src={imgSrc} alt="Integrate Freelancers Banner" />
            <div className={css.mobileTitle}>{title}</div>
          </div>
          <div className={css.contentContainer}>
            <div className={css.text}>{text}</div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={css.container}>
      <div className={css.imageContainer}>
        <img src={imgSrc} alt="Integrate Freelancers Banner" />
      </div>
      <div className={css.contentContainer}>
        <div className={css.title}>{title}</div>
        <div className={css.text}>{text}</div>
      </div>
    </div>
  );
};

export default IntegrateFreelancersBanner;
