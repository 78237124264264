import React from 'react';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './AdvertiseProjectsCard.css';

const AdvertiseProjectsCard = props => {
  const {
    className,
    rootClassName,
    onSetSignUpFrom,
    onSetSignUpFromPath,
    history,
    currentUser,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const userIsLoggedIn = !!currentUser?.id;
  const isCustomer = currentUser?.attributes?.profile?.publicData?.account_role === 'customer';

  const handleOpenChat = () => {
    if (typeof window === 'object') {
      if (userIsLoggedIn) {
        history.push('/pl/new');
      } else {
        onSetSignUpFrom(onSetSignUpFromPath);
        history.push('/tyoilmoitus');
      }
    }
  };

  const cardTitle = (
    <div className={css.cardTitle}>
      <FormattedMessage id="AdvertiseProjectsCard.title" />
    </div>
  );

  const cardDescription = (
    <div className={css.cardDescription}>
      <FormattedMessage id="AdvertiseProjectsCard.description" />
    </div>
  );

  const ctaButton = (
    <div className={css.buttonContainer} onClick={handleOpenChat}>
      <div name="ProLandingPage" className={css.ctaButton}>
        <FormattedMessage id="AdvertiseProjectsCard.ctaButton" />
      </div>
    </div>
  );

  if (!userIsLoggedIn || isCustomer) {
    return (
      <div className={classes}>
        <div className={css.cardContent}>
          {cardTitle}
          {cardDescription}
        </div>
        {ctaButton}
      </div>
    );
  }
  if (userIsLoggedIn && !isCustomer) {
    return null;
  }
};

export default injectIntl(AdvertiseProjectsCard);
