import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import * as validators from '../../util/validators';
import { Button, Form, FieldSelect, UnsavedInfoPrompt } from '../../components';
import ReactTooltip from 'react-tooltip';
import css from './SignUpProjectForms.css';

export const EditProjectListingBudgetDurationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        previousActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        dirty,
        onPrev,
      } = formRenderProps;

      const translationKey = 'EditProjectListingBudgetForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const projectDurationTitle = intl.formatMessage({
        id: 'EditProjectListingBudgetForm.projectDurationTitle',
      });

      const budgetInfoText = intl.formatMessage({
        id: 'EditProjectListingBudgetForm.budgetInfoText',
      });

      const priceEstimatePlaceholderMessage = intl.formatMessage({
        id: 'EditProjectListingBudgetForm.priceEstimatePlaceholder',
      });

      const durationProjectLabel = intl.formatMessage({
        id: 'EditProjectListingBudgetForm.durationProjectLabel',
      });
      const durationContinuousLabel = intl.formatMessage({
        id: 'EditProjectListingBudgetForm.durationContinuousLabel',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditProjectListingBudgetForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditProjectListingBudgetForm.showListingFailed" />
            </p>
          ) : null}
          {typeof window === 'object' ? <ReactTooltip /> : null}
          <div>
            <label htmlFor="projectDuration" className={css.projectDurationTitle}>
              {projectDurationTitle}
            </label>
            <FieldSelect
              id="projectDuration"
              name="projectDuration"
              className={css.projectDuration}
              validate={validators.required(projectDurationTitle)}
            >
              <option disabled value="">
                {projectDurationTitle}
              </option>
              <option value="project">{durationProjectLabel}</option>
              <option value="continuous">{durationContinuousLabel}</option>
            </FieldSelect>
            <label htmlFor="priceEstimate" className={css.priceEstimateTitle}>
              {pricePerUnitMessage}
            </label>
            <FieldSelect
              id="priceEstimate"
              name="priceEstimate"
              className={css.priceEstimate}
              validate={validators.required(priceEstimatePlaceholderMessage)}
            >
              <option disabled value="">
                {priceEstimatePlaceholderMessage}
              </option>
              <option value="0-1 000,00 €">0-1000 €</option>
              <option value="1 000-3 000,00 €">1000-3000 €</option>
              <option value="3 000-5 000,00 €">3000-5000 €</option>
              <option value="5 000-10 000,00 €">5000-10000 €</option>
              <option value="10 000,00 € +">10000 € +</option>ß
            </FieldSelect>
            <div className={css.budgetInfoText}>{budgetInfoText}</div>
          </div>
          <div className={css.buttonContainer}>
            <Button className={css.previousButton} onClick={onPrev}>
              {previousActionMsg}
            </Button>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>

          <UnsavedInfoPrompt when={dirty} />
        </Form>
      );
    }}
  />
);

export default compose(injectIntl)(EditProjectListingBudgetDurationFormComponent);
