import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';
import css from './RecommendCustomerContainer.css';

const RecommendCustomerContainer = () => {
  const title = <FormattedMessage id="RecommendCustomerContainer.title" />;
  const content = <FormattedMessage id="RecommendCustomerContainer.content" />;
  const ctaText = <FormattedMessage id="RecommendCustomerContainer.ctaText" />;

  return (
    <div className={css.container}>
      <div className={css.title}>{title}</div>
      <div className={css.content}>{content}</div>
      <div className={css.cta}>
        <ExternalLink href="https://help.freedomly.io/fi/articles/8848008-suosittele-ja-tienaa-jopa-1-000">
          {ctaText}
        </ExternalLink>
      </div>
    </div>
  );
};

export default RecommendCustomerContainer;
