import React from 'react';
import { Logo, NamedLink } from '../../components';

import css from './TopbarBlank.css';

const TopbarBlank = () => {
  return (
    <div className={css.root}>
      <div className={css.container}>
        <NamedLink className={css.logoLinkDesktop} name="LandingPage">
          <Logo format="desktop" className={css.logo} alt="Logo" />
        </NamedLink>
      </div>
    </div>
  );
};

export default TopbarBlank;
