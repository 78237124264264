import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import renderHTML from 'react-render-html';
import css from './ListingPage.css';
import { MultiPurposeInfo } from '../../components';
import { HiLightBulb } from 'react-icons/hi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const SectionHeading = props => {
  const {
    publicData,
    priceTitle,
    formattedPrice,
    priceTitle2,
    formattedPrice2,
    priceTitle3,
    formattedPrice3,
    priceTitle4,
    formattedPrice4,
    priceTitle5,
    formattedPrice5,
  } = props;

  const {
    package1Name,
    package1Description,
    package2Name,
    package2Description,
    package3Name,
    package3Description,
    package4Name,
    package4Description,
    package5Name,
    package5Description,
  } = publicData;

  const pricingPackagesInfo = <FormattedMessage id={'ListingPage.pricingPackagesInfo'} />;
  const packageStarting = <FormattedMessage id={'ListingPage.packageStarting'} />;

  const [packageIsShown, setPackageIsShown] = useState([
    {
      id: 1,
      isShown: true,
    },
    {
      id: 2,
      isShown: false,
    },
    {
      id: 3,
      isShown: false,
    },
    {
      id: 4,
      isShown: false,
    },
    {
      id: 5,
      isShown: false,
    },
  ]);

  function handleShowPackageClick(index) {
    const newArray = packageIsShown.map(obj => ({
      id: obj.id,
      isShown: obj.id === index ? !obj.isShown : false,
    }));
    setPackageIsShown([...newArray]);
  }

  return (
    <>
      <h2 className={css.packagesTitle}>
        <FormattedMessage id={'ListingPage.pricingPackagesSubtitle'} />
      </h2>
      <div className={css.MultiPurposeInfoContainer}>
        <MultiPurposeInfo
          icon={<HiLightBulb />}
          warningMessage={pricingPackagesInfo}
          showWarning={true}
          
        />
      </div>
      {formattedPrice && package1Name ? (
        <div className={css.pricingPackageBlock} onClick={() => handleShowPackageClick(1)}>
          <div className={css.desktopPriceContainer}>
            <div className={css.desktopPriceContainerLeft}>
              <div className={css.desktopPackageName} title={package1Name}>
                {package1Name} {packageStarting}
              </div>
              <div className={css.desktopPriceValue} title={priceTitle}>
                {formattedPrice}
              </div>
            </div>
            <div className={css.desktopPriceContainerRight}>
              {packageIsShown[0].isShown ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          </div>
          <div className={css.desktopDescriptionContainer}>
            {packageIsShown[0].isShown ? renderHTML(package1Description) : null}
          </div>
        </div>
      ) : null}
      {formattedPrice2 && package2Name ? (
        <div className={css.pricingPackageBlock} onClick={() => handleShowPackageClick(2)}>
          <div className={css.desktopPriceContainer}>
            <div className={css.desktopPriceContainerLeft}>
              <div className={css.desktopPackageName} title={package2Name}>
                {package2Name} {packageStarting}
              </div>
              <div className={css.desktopPriceValue} title={priceTitle2}>
                {formattedPrice2}
              </div>
            </div>
            <div className={css.desktopPriceContainerRight}>
              {packageIsShown[1].isShown ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          </div>
          <div className={css.desktopDescriptionContainer}>
            {packageIsShown[1].isShown ? renderHTML(package2Description) : null}
          </div>
        </div>
      ) : null}
      {formattedPrice3 && package3Name ? (
        <div className={css.pricingPackageBlock} onClick={() => handleShowPackageClick(3)}>
          <div className={css.desktopPriceContainer}>
            <div className={css.desktopPriceContainerLeft}>
              <div className={css.desktopPackageName} title={package3Name}>
                {package3Name} {packageStarting}
              </div>
              <div className={css.desktopPriceValue} title={priceTitle3}>
                {formattedPrice3}
              </div>
            </div>
            <div className={css.desktopPriceContainerRight}>
              {packageIsShown[2].isShown ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          </div>
          <div className={css.desktopDescriptionContainer}>
            {packageIsShown[2].isShown ? renderHTML(package3Description) : null}
          </div>
        </div>
      ) : null}
      {formattedPrice4 && package4Name ? (
        <div className={css.pricingPackageBlock} onClick={() => handleShowPackageClick(4)}>
          <div className={css.desktopPriceContainer}>
            <div className={css.desktopPriceContainerLeft}>
              <div className={css.desktopPackageName} title={package4Name}>
                {package4Name} {packageStarting}
              </div>
              <div className={css.desktopPriceValue} title={priceTitle4}>
                {formattedPrice4}
              </div>
            </div>
            <div className={css.desktopPriceContainerRight}>
              {packageIsShown[3].isShown ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          </div>
          <div className={css.desktopDescriptionContainer}>
            {packageIsShown[3].isShown ? renderHTML(package4Description) : null}
          </div>
        </div>
      ) : null}
      {formattedPrice5 && package5Name ? (
        <div className={css.pricingPackageBlock} onClick={() => handleShowPackageClick(5)}>
          <div className={css.desktopPriceContainer}>
            <div className={css.desktopPriceContainerLeft}>
              <div className={css.desktopPackageName} title={package5Name}>
                {package5Name} {packageStarting}
              </div>
              <div className={css.desktopPriceValue} title={priceTitle5}>
                {formattedPrice5}
              </div>
            </div>
            <div className={css.desktopPriceContainerRight}>
              {packageIsShown[4].isShown ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          </div>
          <div className={css.desktopDescriptionContainer}>
            {packageIsShown[4].isShown ? renderHTML(package5Description) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SectionHeading;
