import React from 'react';
import classNames from 'classnames';

import css from './TransactionPanel.css';

// Functional component as a helper to build AddressLinkMaybe
const DetailCardImage = props => {
  const { className, rootClassName, listingTitle } = props;
  const classes = classNames(rootClassName || css.detailCardImageWrapper, className);

  return (
    <React.Fragment>
      <div className={classes}>
        <div className={css.projectCardWrapper}>
          <div className={css.rootForProjectCard}>
            <div className={css.projectTitle}>{listingTitle}</div>
            {/* <div className={css.projectDescription}>
              {projectBidDescription ? projectBidDescription : listingDescriptionHtml}
            </div> */}
          </div>
        </div>
      </div>
      {/* {isCustomer ? (
        <div className={avatarWrapperClassName || css.avatarWrapper}>
          <AvatarMedium user={provider} />
        </div>
      ) : null} */}
    </React.Fragment>
  );
};

export default DetailCardImage;
