import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Select from 'react-select';
import { ValidationError } from '../../components';

import css from './FieldSelectEdit.css';

const buildOptionsHash = options => {
  const optionsHash = {};

  options.forEach(option => {
    optionsHash[option.value] = option;
  });

  return optionsHash;
};

const FieldSelectEditComponent = props => {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    selectedValue,
    input,
    meta,
    options,
    ...rest
  } = props;
  const optionsHash = buildOptionsHash(options);

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames(css.input, {
    [css.inputSuccess]: valid,
    [css.inputError]: hasError,
  });
  const selectProps = { className: selectClasses, id, name, ...input, ...rest };
  selectProps.value = selectedValue ? optionsHash[selectedValue] : null;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={id} className={css.label}>
          {label}{' '}
        </label>
      ) : null}
      <Select
        {...selectProps}
        className={selectClasses}
        options={options}
        isOptionDisabled={option => !option.parent}
        classNamePrefix="react-select"
      />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldSelectEditComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
  children: null,
};

const { string, object, node } = PropTypes;

FieldSelectEditComponent.propTypes = {
  rootClassName: string,
  className: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldSelectEdit = props => {
  return <Field component={FieldSelectEditComponent} {...props} />;
};

export default FieldSelectEdit;
