import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';
import bookCallIcon from '../../assets/dashboard-pro-bookcall.svg';

import css from './BookCallBanner.css';

const BookCallBanner = () => {
  const handleBookCall = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  return (
    <div className={css.bookCallBanner}>
      <div className={css.bookCallBannerContent}>
        <div className={css.bookCallBannerTitle}>
          <FormattedMessage id="DashboardProPage.bookCallBannerTitle" />
        </div>
        <div className={css.bookCallBannerDescriptionSection}>
          <div className={css.bookCallBannerDescriptionIcon}>
            <img src={bookCallIcon} alt="Book a call icon" />
          </div>
          <div className={css.bookCallBannerDescription}>
            <FormattedMessage id="DashboardProPage.bookCallBannerDescription" />
          </div>
        </div>
      </div>
      <ExternalLink className={css.bookCallBannerCTA} onClick={handleShowSpaceHome}>
        <FormattedMessage id="ProLandingPage.heroContactButton" />
      </ExternalLink>
    </div>
  );
};

export default BookCallBanner;
