import React, { Component } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { compose } from 'redux';
import { Menu, MenuContent, MenuItem, MenuLabel } from '..';
import { NamedLink } from '../../components';
import css from './SelectCategoryFilterPopup.css';
import { FaSearch } from 'react-icons/fa';

const optionLabel = (label, initialValue) => {
  if (!initialValue) {
    return label;
  }

  const selectedOptions = initialValue.split(',');

  return `${label} • ${selectedOptions.length}`;
};

const isOptionSelected = (initialValue, option) => {
  if (typeof initialValue !== 'string' || initialValue.length === 0) {
    return false;
  }

  if (typeof option !== 'string' || option.length === 0) {
    return false;
  }

  const selectedOptions = initialValue.split(',');

  return selectedOptions.indexOf(option) > -1;
};

class SelectCategoryFilterPopupComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      searchCategory: null,
    };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.limitCategoryList = this.limitCategoryList.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(initialValue, queryParamName, option) {
    if (option) {
      const selectedOptions =
        typeof initialValue === 'string' && initialValue.length > 0 ? initialValue.split(',') : [];

      const optionIndex = selectedOptions.indexOf(option);
      if (optionIndex > -1) {
        selectedOptions.splice(optionIndex, 1);
      } else {
        selectedOptions.push(option);
      }

      if (selectedOptions.length > 0) {
        // this.props.onSelect({ [queryParamName]: selectedOptions.join(',') });
        this.props.onSelect({ [queryParamName]: option });
      } else {
        this.props.onSelect({ [queryParamName]: null });
      }
    } else {
      this.props.onSelect({ [queryParamName]: null });
    }
  }

  limitCategoryList(e) {
    if (typeof e.target.value === 'string' && e.target.value.length > 0) {
      this.setState({ searchCategory: e.target.value });
    } else {
      this.setState({ searchCategory: null });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      //contentPlacementOffset,
      intl,
    } = this.props;

    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;

    // resolve menu label text and class
    const menuLabel = optionLabel(label);
    const menuLabelClass = initialValue ? css.menuLabelSelected : css.menuLabel;

    const classes = classNames(rootClassName || css.root, className);

    const categorySearchMessage = intl.formatMessage({
      id: 'SelectCategoryFilterPopup.categorySearch',
    });

    // const categorySearchPlaceholderMessage = intl.formatMessage({
    //   id: 'SelectCategoryFilterPopup.categorySearchPlaceholder',
    // });

    const optionElement = option => {
      // check if this option is selected
      const selected = isOptionSelected(initialValue, option.key);
      const menuItemClass = selected ? css.menuItemSelected : css.menuItem;
      const menuItemFontClass = option.parent ? css.subCategoryText : css.mainCategoryText;
      // menu item border class
      const menuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;

      return (
        <NamedLink className={classNames(menuItemClass, menuItemFontClass)} name={option.name}>
          <span className={menuItemBorderClass} />
          {option.label}
        </NamedLink>
      );
    };

    const filteredOptions = options.filter(
      option =>
        !this.state.searchCategory ||
        option.label.indexOf(this.state.searchCategory) > -1 ||
        option.label.toLowerCase().includes(this.state.searchCategory.toLowerCase())
    );

    return (
      <>
        <Menu
          className={classes}
          useArrow={false}
          onToggleActive={this.onToggleActive}
          isOpen={this.state.isOpen}
        >
          <MenuLabel className={menuLabelClass}>{menuLabel}</MenuLabel>
          <MenuContent className={css.menuContent}>
            <MenuItem className={css.categoryActions} key={'categoryActions'}>
              {/* <label className={css.searchCategoryLabel} htmlFor="searchCategory">
                {categorySearchMessage}:
              </label> */}
              <div className={css.searchContainer}>
                <FaSearch />
                <input
                  id="searchCategory"
                  name="searchCategory"
                  type="text"
                  className={css.searchCategoryItem}
                  placeholder={categorySearchMessage}
                  onChange={this.limitCategoryList}
                />
              </div>
              {/* <button
                className={css.clearMenuItem}
                onClick={() => this.selectOption(initialValue, queryParamName, null)}
              >
                <FormattedMessage id={'SelectSingleFilter.popupClear'} />
              </button> */}
            </MenuItem>
            <MenuItem key="categories" className={css.categoriesMenuItem}>
              {options.map((mainCategory, index) => {
                return mainCategory.parent === '' ? (
                  <div className={css.categoryMenuBox} key={index}>
                    <div className={css.categoryMenuItem}>{optionElement(mainCategory)}</div>
                    {filteredOptions.map((subCategory, i) => {
                      return subCategory.parent === mainCategory.key ? (
                        <div className={css.categoryMenuItem} key={i}>
                          {optionElement(subCategory)}
                        </div>
                      ) : null;
                    })}
                  </div>
                ) : null;
              })}
            </MenuItem>
          </MenuContent>
        </Menu>
      </>
    );
  }
}

SelectCategoryFilterPopupComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectCategoryFilterPopupComponent.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: object.isRequired,
    })
  ).isRequired,
  initialValues: object,
  contentPlacementOffset: number,
};

const SelectCategoryFilterPopup = compose(injectIntl)(SelectCategoryFilterPopupComponent);

export default SelectCategoryFilterPopup;
