import React from 'react';
import { useSelector } from 'react-redux';
import { ensureCurrentUser, ensurePaymentMethodCard, ensureStripeCustomer } from '../../util/data';
import { getStripeAccountData, hasRequirements } from '../EditListingWizard/EditListingWizard';
import { ProgressBar } from '../../components';
import css from './ModalCompleteProfile.css';
import { useHistory } from 'react-router-dom';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { FormattedMessage } from '../../util/reactIntl';
import { BsFillCheckCircleFill } from 'react-icons/bs';

const STEPS = {
  ADD_PROFILE_PIC: 'ADD_PROFILE_PIC',
  ADD_BIO: 'ADD_BIO',
  ADD_LISTING: 'ADD_LISTING',
  ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD',
  ADD_PAYOUT_DETAILS: 'ADD_PAYOUT_DETAILS',
  ADD_EDUCATION: 'ADD_EDUCATION',
  ADD_EXPERIENCE: 'ADD_EXPERIENCE',
  ADD_HOBBIES: 'ADD_HOBBIES',
  ADD_LANGUAGES: 'ADD_LANGUAGES',
  ADD_WHOAMI: 'ADD_WHOAMI',
  ADD_WORKEXPERIENCE: 'ADD_WORKEXPERIENCE',
  ADD_LOCATION: 'ADD_LOCATION',
  ADD_IDEALCUSTOMER: 'ADD_IDEALCUSTOMER',
  ADD_HOURLYFEE: 'ADD_HOURLYFEE',
  ADD_ACCEPT_COC: 'ADD_ACCEPT_COC',
};

export const USER_ROLES = {
  FREELANCER: 'freelancer',
  LIGHT_ENTERPRENEUR: 'light-entrepreneur',
  CUSTOMER: 'customer',
};

function ModalCompleteProfile() {
  const { user, stripeAccount, hasListing } = useSelector(store => {
    const {
      currentUser,
      hasAppropriateListing,
      interestListingsLoading,
      interestListingIds,
      interestListingsError,
    } = store.user;
    const { isAuthenticated } = store.Auth;
    const { stripeAccount } = store.stripeConnectAccount;

    const interestListings = getListingsById(store, interestListingIds);

    return {
      user: currentUser,
      isAuthenticated,
      stripeAccount,
      hasListing: hasAppropriateListing,
      interestListingsLoading,
      interestListings,
      interestListingsError,
    };
  });

  const history = useHistory();

  const currentUser = ensureCurrentUser(user);

  const profile = currentUser?.attributes?.profile;
  const publicData = profile?.publicData;
  const accountRole = publicData.account_role;
  const profileImage = currentUser?.profileImage?.id?.uuid;
  const bio = profile?.bio;

  const education = publicData?.education?.length === 0 ? false : true;
  const experience = publicData?.experience?.length === 0 ? false : true;
  const hobbies =
    publicData?.hobbies?.length === 0 ||
    !publicData?.hobbies ||
    publicData?.hobbies[0]?.category === null
      ? false
      : true;
  const languages = publicData?.languages?.length === 0 ? false : true;
  const whoami = publicData?.whoAmI;
  const workExperience = publicData?.workExperience?.length === 0 ? false : true;
  const location = publicData?.location;
  const idealCustomer = publicData?.idealCustomer;
  const hourlyFee = publicData?.hourlyFee;
  const cocAccepted = publicData?.cocAccepted;

  const ensuredStripeCustomer = ensureStripeCustomer(currentUser?.stripeCustomer);
  const cardAdded = ensurePaymentMethodCard(ensuredStripeCustomer?.defaultPaymentMethod);

  const stripeConnected = !!currentUser?.stripeAccount?.id;
  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const hasPayoutDetails = stripeConnected && !requirementsMissing;

  const bioDone = !bio ? false : bio === '<p><br></p>' ? false : true;

  const LIGHT_ENTERPRENEUR_STEPS = [
    {
      key: STEPS.ADD_PROFILE_PIC,
      name: <FormattedMessage id="DashboardPage.stepProfilePic" />,
      description: <FormattedMessage id="DashboardPage.stepProfilePicDescription" />,
      done: !!profileImage,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_LISTING,
      name: <FormattedMessage id="DashboardPage.stepListing" />,
      description: <FormattedMessage id="DashboardPage.stepListingDescription" />,
      done: hasListing,
      link: '/l/new',
    },
    {
      key: STEPS.ADD_WHOAMI,
      name: <FormattedMessage id="DashboardPage.stepWhoAmI" />,
      description: <FormattedMessage id="DashboardPage.stepWhoAmIDescription" />,
      done: !!whoami,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_EXPERIENCE,
      name: <FormattedMessage id="DashboardPage.stepExperience" />,
      description: <FormattedMessage id="DashboardPage.stepExperienceDescription" />,
      done: experience,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_HOBBIES,
      name: <FormattedMessage id="DashboardPage.stepHobbies" />,
      description: <FormattedMessage id="DashboardPage.stepHobbiesDescription" />,
      done: hobbies,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_BIO,
      name: <FormattedMessage id="DashboardPage.stepBio" />,
      description: <FormattedMessage id="DashboardPage.stepBioDescription" />,
      done: bioDone,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_LOCATION,
      name: <FormattedMessage id="DashboardPage.stepLocation" />,
      description: <FormattedMessage id="DashboardPage.stepLocationDescription" />,
      done: !!location,
      link: '/profile-settings',
    },

    {
      key: STEPS.ADD_WORKEXPERIENCE,
      name: <FormattedMessage id="DashboardPage.stepWorkExperience" />,
      description: <FormattedMessage id="DashboardPage.stepWorkExperienceDescription" />,
      done: workExperience,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_LANGUAGES,
      name: <FormattedMessage id="DashboardPage.stepLanguages" />,
      description: <FormattedMessage id="DashboardPage.stepLanguagesDescription" />,
      done: languages,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_EDUCATION,
      name: <FormattedMessage id="DashboardPage.stepEducation" />,
      description: <FormattedMessage id="DashboardPage.stepEducationDescription" />,
      done: !!education,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_IDEALCUSTOMER,
      name: <FormattedMessage id="DashboardPage.stepIdealCustomer" />,
      description: <FormattedMessage id="DashboardPage.stepIdealCustomerDescription" />,
      done: !!idealCustomer,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_HOURLYFEE,
      name: <FormattedMessage id="DashboardPage.stepHourlyFee" />,
      description: <FormattedMessage id="DashboardPage.stepHourlyFeeDescription" />,
      done: !!hourlyFee,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_ACCEPT_COC,
      name: <FormattedMessage id="DashboardPage.stepAcceptCOC" />,
      description: <FormattedMessage id="DashboardPage.stepAcceptCOCDescription" />,
      done: !!cocAccepted,
      link: '/profile-settings',
    },
  ];

  const FREELANCER_STEPS = [
    ...LIGHT_ENTERPRENEUR_STEPS,
    {
      key: STEPS.ADD_PAYOUT_DETAILS,
      name: <FormattedMessage id="DashboardPage.stepPayoutDetails" />,
      description: <FormattedMessage id="DashboardPage.stepPayoutDetailsDescription" />,
      done: !!hasPayoutDetails,
      link: '/account/payments',
    },
  ];

  const CUSTOMER_STEPS = [
    {
      key: STEPS.ADD_PROFILE_PIC,
      name: <FormattedMessage id="DashboardPage.stepProfilePic" />,
      description: <FormattedMessage id="DashboardPage.stepProfilePicDescription" />,
      done: !!profileImage,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_BIO,
      name: <FormattedMessage id="DashboardPage.stepBio" />,
      description: <FormattedMessage id="DashboardPage.stepBioDescription" />,
      done: bioDone,
      link: '/profile-settings',
    },
    {
      key: STEPS.ADD_LISTING,
      name: <FormattedMessage id="DashboardPage.stepProjectListing" />,
      description: <FormattedMessage id="DashboardPage.stepProjectListingDescription" />,
      done: hasListing,
      link: '/pl/new',
    },
    {
      key: STEPS.ADD_PAYMENT_METHOD,
      name: <FormattedMessage id="DashboardPage.stepPaymentBuyer" />,
      description: <FormattedMessage id="DashboardPage.stepPaymentBuyerDescription" />,
      done: !!cardAdded?.id?.uuid,
      link: '/account/payment-methods',
    },
  ];

  const roleBasedSteps =
    accountRole === USER_ROLES.CUSTOMER
      ? CUSTOMER_STEPS
      : accountRole === USER_ROLES.LIGHT_ENTERPRENEUR
      ? LIGHT_ENTERPRENEUR_STEPS
      : FREELANCER_STEPS;

  const completedValue = (
    (roleBasedSteps.reduce((acc, item) => (item.done ? acc + 1 : acc), 0) / roleBasedSteps.length) *
    100
  ).toFixed(2);

  const stepsDone = roleBasedSteps.filter(item => item.done);

  const onChangePage = link => history.push(link);

  // if (completedValue >= 100) return null;

  return (
    <div className={css.completeCustomerContainer}>
      <header className={css.header}>
        {completedValue < 100 ? (
          <FormattedMessage id="DashboardPage.tips" />
        ) : (
          <FormattedMessage id="DashboardPage.tipsProfileDone" />
        )}
      </header>
      <div>
        <FormattedMessage id="DashboardPage.tipsDescription" />
      </div>

      {completedValue < 100 && (
        <div>
          <ProgressBar
            value={completedValue}
            steps={roleBasedSteps.length}
            stepsDone={stepsDone.length}
          />
          <div className={css.stepsContainer}>
            {roleBasedSteps.map((r, index) => {
              const onClickMaybe = { onClick: () => onChangePage(r.link) };
              return (
                <div key={r.key} className={css.step}>
                  <div key={r.key} className={css.stepHeader}>
                    <BsFillCheckCircleFill
                      className={r.done ? css.stepIconGreen : css.stepIconGrey}
                    />
                    <div {...onClickMaybe}>
                      <span className={css.linkText}>{r.name}</span>
                    </div>
                  </div>
                  <div className={css.stepDescription}>{r.description}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(ModalCompleteProfile);
