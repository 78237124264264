import React from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import css from './FaqDropdown.css';

const FaqDropdown = ({ faqIsShown, setFaqIsShown }) => {
  function handleShowFaqClick(index) {
    const newArray = faqIsShown.map(obj =>
      obj.index === index && obj.isShow === false
        ? {
            question: obj.question,
            answer: obj.answer,
            index: obj.index,
            isShow: !obj.isShow,
            arrow: <IoIosArrowUp />,
          }
        : obj.index === index && obj.isShow === true
        ? {
            question: obj.question,
            answer: obj.answer,
            index: obj.index,
            isShow: !obj.isShow,
            arrow: <IoIosArrowDown />,
          }
        : {
            question: obj.question,
            answer: obj.answer,
            index: obj.index,
            isShow: false,
            arrow: <IoIosArrowDown />,
          }
    );
    setFaqIsShown([...newArray]);
  }

  const faqItems = faqIsShown.map(item => (
    <FAQdropdownItem
      key={item.index}
      faqIsShown={faqIsShown}
      question={item}
      handleShowFaqClick={handleShowFaqClick}
    />
  ));

  return <>{faqItems}</>;
};

const FAQdropdownItem = ({ faqIsShown, question, handleShowFaqClick }) => {
  return (
    <div className={css.faqItem} onClick={() => handleShowFaqClick(question.index)}>
      <div className={css.faqItemClosed}>
        {question.question}
        {question.arrow}
      </div>
      <div
        className={
          faqIsShown[question.index].isShow ? css.faqItemAnswerOpen : css.faqItemAnswerClosed
        }
      >
        {question.answer}
      </div>
    </div>
  );
};

export default FaqDropdown;
