import React, { Component, useState } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { FormSpy, Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { isUploadImageOverLimitError } from '../../util/errors';
import { Prompt } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import { Form, Avatar, Button, ImageFromFile, IconSpinner, Modal } from '../../components';

import css from './ProfileSettingsForm.css';

// const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const MAX_MOBILE_SCREEN_WIDTH = 962;

function CropImage({ src, onCropChange, onImageLoaded }) {
  const [crop, setCrop] = useState({
    aspect: 1,
    unit: 'px',
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });

  const updateCrop = image => {
    const widthHeight = Math.min(image.width, image.height);
    const newCrop = {
      aspect: 1,
      x: (image.width - widthHeight) / 2,
      y: (image.height - widthHeight) / 2,
      width: widthHeight,
      height: widthHeight,
    };

    setCrop(newCrop);
    onCropChange(newCrop);
    onImageLoaded(image);

    return false;
  };

  const imageStyle = window.innerWidth > 1024 ? { maxWidth: '65vw', maxHeight: '65vh' } : {};

  return (
    <ReactCrop
      src={src}
      className={css.cropImage}
      imageStyle={{ ...imageStyle }}
      crop={crop}
      ruleOfThirds={true}
      circularCrop={true}
      onChange={newCrop => {
        setCrop(newCrop);
        onCropChange(newCrop);
      }}
      onImageLoaded={updateCrop}
    />
  );
}

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.imageReader = new FileReader();
    this.imageReader.addEventListener(
      'load',
      () => {
        // convert image file to base64 string
        this.setState({ cropImageSrc: this.imageReader.result });
      },
      false
    );
    this.state = { uploadDelay: false, isSelectionModalOpen: false };
    this.submittedValues = {};

    this.onCropChange = this.onCropChange.bind(this);
    this.handleSubmitSelection = this.handleSubmitSelection.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onDirtyChange = this.onDirtyChange.bind(this);
    this.handleSetImageToBeDeleted = this.handleSetImageToBeDeleted.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onCropChange(newCrop) {
    this.setState({ imageCrop: newCrop });
  }

  onImageLoaded(newCropElement) {
    this.setState({ imageCropElement: newCropElement });
  }

  onDirtyChange({ dirty }) {
    setTimeout(() => this.setState({ formIsDirty: dirty }), 0);
  }

  /**
   * @param {HTMLImageElement} image - Image File Object
   * @param {Object} crop - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  }

  handleSubmitSelection() {
    const imageFile = this.state.imageCropElement;
    const tempId = `${imageFile.name}_${Date.now()}`;

    this.setState({ isSelectionModalOpen: false });
    this.getCroppedImg(imageFile, this.state.imageCrop, imageFile.name).then(resultFile => {
      this.props.onImageUpload({ id: tempId, file: resultFile });
    });
  }

  onUnload = e => {
    if (this.state.formIsDirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  handleSetImageToBeDeleted(form) {
    form.change('profileImage', null);
    form.blur(`profileImage`);
    this.props.setImageToBeDeleted(true);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{
          ...arrayMutators,
        }}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
            nameLocationWorkingMethod,
            bioWhoAmI,
            links,
            codeOfConduct,
            hourlyFeeIdealCustomer,
            topSkills,
            hobbies,
            languages,
            workExperience,
            education,
            viewport,
            showContainers,
            isValidCustomer,
            isValidProvider,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          const dirtyFormAlert = intl.formatMessage({
            id: 'DirtyFormAlert',
          });

          // Account role info
          const accountRoleCustomerOption = intl.formatMessage({
            id: 'ProfileSettingsForm.accountRoleCustomerOption',
          });
          const accountRoleFreelancerOption = intl.formatMessage({
            id: 'ProfileSettingsForm.accountRoleFreelancerOption',
          });
          const accountRoleLightEntrepreneurOption = intl.formatMessage({
            id: 'ProfileSettingsForm.accountRoleLightEntrepreneurOption',
          });

          const accountRoleCustomerInformation = intl.formatMessage({
            id: 'ProjectListingPage.bookingSubTitleNoBids',
          });
          const accountRoleSellerInformation = intl.formatMessage({
            id: 'ProjectListingPage.bookingSubTitleNoBidsCustomer',
          });

          const isFreelancerAccount =
            user.attributes.profile.publicData.account_role === 'freelancer';
          const isLightEntrepreneurAccount =
            user.attributes.profile.publicData.account_role === 'light-entrepreneur';
          const accountRoleName = isFreelancerAccount
            ? accountRoleFreelancerOption
            : isLightEntrepreneurAccount
            ? accountRoleLightEntrepreneurOption
            : accountRoleCustomerOption;
          const accountRoleLabel =
            intl.formatMessage({
              id: 'ProfileSettingsForm.yourAccountRole',
            }) + accountRoleName;

          const accountRoleInformation = isFreelancerAccount
            ? accountRoleSellerInformation
            : isLightEntrepreneurAccount
            ? accountRoleSellerInformation
            : accountRoleCustomerInformation;

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 186px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sidebar}>
                <div className={css.sectionContainer}>
                  {/* {deleteAvatar} */}
                  <Field
                    accept={ACCEPT_IMAGES}
                    id="profileImage"
                    name="profileImage"
                    label={chooseAvatarLabel}
                    type="file"
                    form={null}
                    uploadImageError={uploadImageError}
                    disabled={uploadInProgress}
                  >
                    {fieldProps => {
                      const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        form.change(`profileImage`, file);
                        form.blur(`profileImage`);
                        if (file != null) {
                          const tempId = `${file.name}_${Date.now()}`;
                          onImageUpload({ id: tempId, file });

                          this.imageReader.readAsDataURL(file);
                          this.setState({ isSelectionModalOpen: true });
                        }
                      };
                      const onClick = e => {
                        e.target.value = null;
                      };

                      let error = null;

                      if (isUploadImageOverLimitError(uploadImageError)) {
                        error = (
                          <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                          </div>
                        );
                      } else if (uploadImageError) {
                        error = (
                          <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                          </div>
                        );
                      }

                      return (
                        <div className={css.uploadAvatarWrapper}>
                          <label htmlFor={id}>{label}</label>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            className={css.uploadAvatarInput}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                            onClick={onClick}
                          />
                          {error}
                        </div>
                      );
                    }}
                  </Field>
                </div>
                {submitError}
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={pristineSinceLastSubmit}
                >
                  <FormattedMessage id="ProfileSettingsForm.saveProfilePic" />
                </Button>
                {viewport.width >= MAX_MOBILE_SCREEN_WIDTH && (isValidCustomer || isValidProvider)
                  ? nameLocationWorkingMethod
                  : null}
                {viewport.width >= MAX_MOBILE_SCREEN_WIDTH && isValidProvider
                  ? codeOfConduct
                  : null}
                {viewport.width >= MAX_MOBILE_SCREEN_WIDTH && showContainers
                  ? hourlyFeeIdealCustomer
                  : null}
                {viewport.width >= MAX_MOBILE_SCREEN_WIDTH && showContainers ? topSkills : null}
                {viewport.width >= MAX_MOBILE_SCREEN_WIDTH && showContainers ? hobbies : null}

                {viewport.width >= MAX_MOBILE_SCREEN_WIDTH && showContainers ? languages : null}
              </div>
              <div className={css.mainContent}>
                <div className={css.sectionContainer}>
                  {viewport.width >= MAX_MOBILE_SCREEN_WIDTH && (isValidCustomer || isValidProvider)
                    ? bioWhoAmI
                    : null}
                  {viewport.width >= MAX_MOBILE_SCREEN_WIDTH && isValidProvider ? links : null}

                  {viewport.width <= MAX_MOBILE_SCREEN_WIDTH && showContainers
                    ? nameLocationWorkingMethod
                    : null}
                  {viewport.width <= MAX_MOBILE_SCREEN_WIDTH && showContainers
                    ? codeOfConduct
                    : null}
                  {viewport.width <= MAX_MOBILE_SCREEN_WIDTH && (isValidCustomer || isValidProvider)
                    ? bioWhoAmI
                    : null}
                  {viewport.width <= MAX_MOBILE_SCREEN_WIDTH && isValidProvider ? links : null}
                  {showContainers && workExperience}
                  {/* {showContainers && workedWith} */}
                  {showContainers && education}
                  {viewport.width <= MAX_MOBILE_SCREEN_WIDTH && showContainers ? topSkills : null}
                  {viewport.width <= MAX_MOBILE_SCREEN_WIDTH && showContainers ? hobbies : null}
                  {viewport.width <= MAX_MOBILE_SCREEN_WIDTH && showContainers ? languages : null}
                  {viewport.width <= MAX_MOBILE_SCREEN_WIDTH && showContainers
                    ? hourlyFeeIdealCustomer
                    : null}
                </div>
                <div className={classNames(css.sectionContainer, css.lastSection)}>
                  <h3 className={css.sectionTitle}>{accountRoleLabel}</h3>
                  <p className={css.accountRoleInformation}>{accountRoleInformation}</p>
                </div>

                <Modal
                  id="ProfileSettingsForm.selectImageArea"
                  contentClassName={css.selectImageAreaModalContent}
                  isOpen={this.state.isSelectionModalOpen}
                  onClose={() => this.setState({ isSelectionModalOpen: false })}
                  usePortal
                  onManageDisableScrolling={this.props.onManageDisableScrolling}
                >
                  <div className={css.cropImageWrapper}>
                    <CropImage
                      src={this.state.cropImageSrc}
                      onCropChange={this.onCropChange}
                      onImageLoaded={this.onImageLoaded}
                    />
                  </div>
                  <Button
                    className={css.submitImageButton}
                    type="button"
                    onClick={this.handleSubmitSelection}
                    inProgress={false}
                    disabled={false}
                    ready={false}
                  >
                    <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                  </Button>
                </Modal>
              </div>
              <Prompt when={this.state.formIsDirty} message={dirtyFormAlert} />
              <FormSpy subscription={{ dirty: true }} onChange={this.onDirtyChange} />
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
