import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_PROJECT_OFFERS_REQUEST = 'app/ApplicationInboxPage/FETCH_PROJECT_OFFERS_REQUEST';
export const FETCH_PROJECT_OFFERS_SUCCESS = 'app/ApplicationInboxPage/FETCH_PROJECT_OFFERS_SUCCESS';
export const FETCH_PROJECT_OFFERS_ERROR = 'app/ApplicationInboxPage/FETCH_PROJECT_OFFERS_ERROR';
export const FETCH_PROJECT_OFFERS_RESET = 'app/ApplicationInboxPage/FETCH_PROJECT_OFFERS_RESET';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchProjectOffersInProgress: false,
  fetchProjectOffersError: null,
  pagination: null,
  transactionRefs: [],
  projectTransactionRefs: [],
};

export default function applicationInboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PROJECT_OFFERS_REQUEST: {
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    }
    case FETCH_PROJECT_OFFERS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        projectTransactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
        fetchInProgress: false,
      };
    }
    case FETCH_PROJECT_OFFERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchProjectOffersError: payload };
    default: {
      return state;
    }
  }
}

// ================ Action creators ================ //

const fetchProjectOffersRequest = () => ({ type: FETCH_PROJECT_OFFERS_REQUEST });
const fetchProjectOffersSuccess = response => {
  return { type: FETCH_PROJECT_OFFERS_SUCCESS, payload: response };
};
const fetchProjectOffersError = e => ({
  type: FETCH_PROJECT_OFFERS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchProjectOffersRequest());
  const { page = 1 } = parse(search);

  dispatch(fetchCurrentUser());

  const apiQueryParams = {
    processNames: 'flex-project-process',
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'processVersion',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };
  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchProjectOffersSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchProjectOffersError(storableError(e)));
      throw e;
    });
};
