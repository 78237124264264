import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProfileSettingsContainer as Container } from '..';
import css from './Container.css';

const HourlyFeeIdealCustomerContainer = ({ profileUser, button }) => {
  const hourlyFeeKey = profileUser?.attributes?.profile?.publicData?.hourlyFee;

  const hourlyFreeOptions = [
    {
      key: '20-39 / h',
      label: '20-39 €',
    },
    {
      key: '40-59 / h',
      label: '40-59 €',
    },
    {
      key: '60-79 / h',
      label: '60-79 €',
    },
    {
      key: '80-99 / h',
      label: '80-99 €',
    },
    {
      key: '+ 100 / h',
      label: '+ 100 €',
    },
  ];

  const heading = <FormattedMessage id="ProfileSettingsForm.hourlyFeeIdealCustomerHeading" />;
  const hourlyFeeHeading = <FormattedMessage id="ProfileSettingsForm.hourlyFeeLabel" />;
  const idealCustomerHeading = <FormattedMessage id="ProfileSettingsForm.idealCustomerLabel" />;

  const hourlyFee = hourlyFreeOptions.find(m => m.key === hourlyFeeKey);
  const hourlyFeeLabel = hourlyFee ? hourlyFee.label : null;

  const idealCustomerArray = profileUser?.attributes?.profile?.publicData?.idealCustomer;
  const idealCustomer = idealCustomerArray?.length ? idealCustomerArray.join(', ') : null;

  const container = (
    <Container heading={heading} button={button}>
      <div className={css.container}>
        <div className={css.contentContainer}>
          <div className={css.contentItem}>
            <div className={css.contentHeading}>{hourlyFeeHeading}</div>
            <div className={css.text}>{hourlyFeeLabel}</div>
          </div>
          <div className={css.contentItem}>
            <div className={css.contentHeading}>{idealCustomerHeading}</div>
            <div className={css.text}>{idealCustomer}</div>
          </div>
        </div>
      </div>
    </Container>
  );

  if (!button) {
    return null;
  }
  return container;
};

export default HourlyFeeIdealCustomerContainer;
