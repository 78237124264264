import React from 'react';

import { parseDateFromISO8601, getRelativeTime } from '../../util/dates';
import { FormattedMessage, FormattedRelativeTime } from '../../util/reactIntl';

import {
  TRANSITION_RELEASE_FUNDS,
  TRANSITION_JOB_DONE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
} from '../../util/transaction';

import css from './BookingBreakdown.css';

const DeliveryDates = ({ transaction }) => {
  const customOfferDeliveryDate = transaction.attributes.protectedData?.customOfferDeliveryDate;
  const projectDeliveryDate = transaction.attributes.protectedData?.projectBidDeliveryDate;

  const getDeliveryTime = date => {
    return date ? getRelativeTime(new Date(Date.now()), parseDateFromISO8601(date)) : null;
  };

  const isDone = [
    TRANSITION_RELEASE_FUNDS,
    TRANSITION_JOB_DONE,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_REVIEW_PERIOD,
  ].includes(transaction.attributes.lastTransition);

  return !isDone ? (
    <>
      {customOfferDeliveryDate ? (
        <div className={css.deliveryLineItem}>
          <span className={css.itemlabel}>
            <FormattedMessage id="BookingBreakdown.deadline" />
            <FormattedRelativeTime
              value={getDeliveryTime(customOfferDeliveryDate)}
              unit="days"
              numeric="auto"
            />
          </span>
          <hr className={css.deliveryDivider} />
        </div>
      ) : null}

      {projectDeliveryDate ? (
        <div className={css.deliveryLineItem}>
          <span className={css.itemlabel}>
            <FormattedMessage id="BookingBreakdown.deadline" />
            <FormattedRelativeTime
              value={getDeliveryTime(projectDeliveryDate)}
              unit="days"
              numeric="auto"
            />
          </span>
          <hr className={css.deliveryDivider} />
        </div>
      ) : null}
    </>
  ) : null;
};

export default DeliveryDates;
