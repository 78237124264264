import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton, Modal } from '../../components';

import css from './TransactionPanel.css';

const ReleaseFundsModal = ({
  id,
  isOpen,
  onClose,
  onManageDisableScrolling,
  header,
  message,
  inProgress,
  disabled,
  onSubmit,
  onCancel,
  cancelLabel,
  errorMessage,
}) => {
  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div>
        <h2 className={css.releaseFundsHeading}>{header}</h2>
        <p>{message}</p>
        <div className={css.releaseFundsActions}>
          <PrimaryButton inProgress={inProgress} disabled={disabled} onClick={onSubmit}>
            <FormattedMessage id="TransactionPanel.releaseFundsButton" />
          </PrimaryButton>
          <SecondaryButton onClick={onCancel} disabled={disabled} className={css.cancelButton}>
            {cancelLabel}
          </SecondaryButton>
        </div>
        <p>{errorMessage}</p>
      </div>
    </Modal>
  );
};

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    acceptInProgress,
    declineInProgress,
    releaseFundsInProgress,
    acceptSaleError,
    declineSaleError,
    releaseFundsError,
    stateData,
    onAcceptSale,
    onDeclineSale,
    onReleaseFunds,
    onManageDisableScrolling,
    onJobDoneReleaseFunds,
    jobDoneReleaseFundsInProgress,
    jobDoneReleaseFundsError,
    onJobReady,
    onJobReadyCustomer,
    onJobReadyPro,
    onAcceptProJob,
    isProOrInvoiceCustomer,
    customOfferFormAccepted,
    intl,
  } = props;

  const [releaseFundsModalOpen, setReleaseFundsModalOpen] = useState(false);
  const [jobDoneReleaseFundsModalOpen, setJobDoneReleaseFundsModalOpen] = useState(false);

  const buttonsDisabled =
    acceptInProgress ||
    declineInProgress ||
    releaseFundsInProgress ||
    jobDoneReleaseFundsInProgress;

  const {
    showSaleButtons,
    showJobReadyButton,
    showJobDoneReleaseFundsButton,
    showReleaseFundsButton,
    showJobDoneCustomerButton,
    showAcceptProButton,
    showCustomOfferFormAccepted,
  } = stateData;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;
  const releaseFundsErrorMessage = releaseFundsError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.releaseFundsFailed" />
    </p>
  ) : null;
  const jobDoneReleaseFundsErrorMessage = jobDoneReleaseFundsError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.releaseFundsFailed" />
    </p>
  ) : null;

  const providerName = currentTransaction.provider.attributes.profile.displayName;

  const modalHeader = intl.formatMessage({ id: 'TransactionPanel.releaseFundsModalHeader' });
  const modalMessage = intl.formatMessage({ id: 'TransactionPanel.releaseFundsModalMessage' });
  const modalCancel = intl.formatMessage({ id: 'TransactionPanel.releaseFundsModalCancel' });

  const classes = classNames(rootClassName || css.actionButtons, className);

  const listing = currentTransaction.listing;
  const pricingPackage = currentTransaction.attributes.protectedData.pricingPackage;
  const customOfferAmount = currentTransaction.attributes.protectedData.customOfferAmount;
  const packagePriceAmount =
    pricingPackage === 'package1'
      ? listing.attributes?.price?.amount
      : pricingPackage && listing.attributes.publicData[`${pricingPackage}Price`]
      ? listing.attributes.publicData[`${pricingPackage}Price`]
      : 0;
  const eventPrice = customOfferAmount ? customOfferAmount / 100 : packagePriceAmount / 100;
  const pricingPackageName =
    pricingPackage && listing.attributes.publicData[`${pricingPackage}Name`]
      ? listing.attributes.publicData[`${pricingPackage}Name`]
      : '';

  const jobDoneOnClick = isProOrInvoiceCustomer ? onJobReadyPro : onJobReady;

  const handleReleaseFunds = id => {
    if (typeof window === 'object') {
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currency: 'EUR',
          value: currentTransaction.attributes.payinTotal.amount / 100,
          tax: (currentTransaction.attributes.payinTotal.amount * 25.5) / 12550,
          transaction_id: currentTransaction.id.uuid,
          items: [
            {
              item_name: listing.attributes.title,
              item_id: listing.id.uuid,
              price: '' + eventPrice,
              item_brand: listing.author.attributes.profile.displayName,
              item_variant: customOfferAmount ? 'custom_offer' : pricingPackageName,
              item_category: currentTransaction.listing.attributes.publicData.category,
              item_list_name: 'chat_window',
              custom_offer: !!customOfferAmount,
              custom_offer_value: customOfferAmount ? '' + customOfferAmount / 100 : null,
              quantity: '1',
            },
          ],
        },
      });
    }
    onReleaseFunds(id);
  };

  if (showReleaseFundsButton) {
    return (
      <>
        <div className={classes}>
          <div className={css.actionButtonWrapper}>
            <PrimaryButton onClick={() => setReleaseFundsModalOpen(true)}>
              <FormattedMessage id="TransactionPanel.releaseFundsButton" />
            </PrimaryButton>
          </div>
        </div>
        <ReleaseFundsModal
          id="TransactionPage.releaseFunds"
          isOpen={releaseFundsModalOpen}
          onClose={() => setReleaseFundsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          header={modalHeader}
          message={modalMessage}
          inProgress={releaseFundsInProgress}
          disabled={buttonsDisabled}
          onSubmit={handleReleaseFunds}
          onCancel={() => setReleaseFundsModalOpen(false)}
          cancelLabel={modalCancel}
          errorMessage={releaseFundsErrorMessage}
        />
      </>
    );
  }

  if (showJobDoneReleaseFundsButton) {
    return (
      <>
        <div className={classes}>
          <div className={css.actionButtonWrapper}>
            <PrimaryButton onClick={() => setJobDoneReleaseFundsModalOpen(true)}>
              <FormattedMessage id="TransactionPanel.releaseFundsButton" />
            </PrimaryButton>
          </div>
        </div>
        <ReleaseFundsModal
          id="TransactionPage.jobDoneReleaseFunds"
          isOpen={jobDoneReleaseFundsModalOpen}
          onClose={() => setJobDoneReleaseFundsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          header={modalHeader}
          message={modalMessage}
          inProgress={jobDoneReleaseFundsInProgress}
          disabled={buttonsDisabled}
          onSubmit={onJobDoneReleaseFunds}
          onCancel={() => setJobDoneReleaseFundsModalOpen(false)}
          cancelLabel={modalCancel}
          errorMessage={jobDoneReleaseFundsErrorMessage}
        />
      </>
    );
  }

  if (showJobReadyButton) {
    return (
      <div>
        {customOfferFormAccepted}
        <div className={css.jobReadyButtonDesktop}>
          <div className={css.jobReadyAction}>
            <div className={css.actionButtonWrapper}>
              <PrimaryButton onClick={jobDoneOnClick}>
                <FormattedMessage id="TransactionPanel.jobReadyButton" />
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showAcceptProButton) {
    return (
      <div className={css.jobReadyButtonDesktop}>
        <div className={css.jobReadyAction}>
          <div className={css.actionButtonWrapper}>
            <PrimaryButton onClick={onAcceptProJob}>
              <FormattedMessage id="TransactionPanel.acceptProButton" />
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }

  if (showCustomOfferFormAccepted) {
    return <div>{customOfferFormAccepted}</div>;
  }
  if (showJobDoneCustomerButton) {
    return (
      <div className={css.jobReadyButtonDesktop}>
        <div className={css.jobReadyAction}>
          <div className={css.actionButtonWrapper}>
            <PrimaryButton onClick={onJobReadyCustomer}>
              <FormattedMessage id="TransactionPanel.jobReadyButton" />
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }

  return showSaleButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <SecondaryButton
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={onDeclineSale}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>
        <PrimaryButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptSale}
        >
          <FormattedMessage id="TransactionPanel.acceptButton" />
        </PrimaryButton>
      </div>
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;
