import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { injectIntl } from '../../util/reactIntl';
import {
  Modal,
  NamedLink,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TopbarBlank,
} from '../../components';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { AuthenticationPage } from '../../containers';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import css from './SignupPage.css';

const SignupPageComponent = props => {
  const { onManageDisableScrolling, scrollingDisabled, intl } = props;
  const [isAuthOpen, setIsAuthOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      sessionStorage.removeItem('providerFormData');
    }
  }, []);

  const heading = intl.formatMessage({ id: 'ModalSignup.heading' });
  const caption = intl.formatMessage({ id: 'ModalSignup.caption' });

  const forCustomer = intl.formatMessage({ id: 'ModalSignup.customer' });
  const forProvider = intl.formatMessage({ id: 'ModalSignup.provider' });

  const start = intl.formatMessage({ id: 'ModalSignup.start' });

  const accountExists = intl.formatMessage({ id: 'ModalSignup.alreadySigned' });
  const login = intl.formatMessage({ id: 'ModalSignup.login' });

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'AuthenticationPage.schemaTitleSignup' },
    { siteTitle }
  );

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarBlank />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>
            <div>
              <div className={css.caption}>{caption}</div>
              <h2 className={css.heading}>{heading}</h2>
            </div>
            <div className={css.startRow}>
              <div className={css.startBox}>
                <span className={css.focus}>{forCustomer}</span>
                <div className={css.customerLink} onClick={() => setIsAuthOpen(true)}>
                  {start}
                </div>
              </div>
              <div className={css.startBox}>
                <span className={css.focus}>{forProvider}</span>
                <NamedLink className={css.providerLink} name="SignupProviderPage">
                  {start}
                </NamedLink>
              </div>
            </div>
            <span className={css.bodyBold}>
              {accountExists} <NamedLink name="LoginPage">{login}</NamedLink>
            </span>
          </div>
          <Modal
            id="SignupCustomerAuthModal"
            containerClassName={css.modal}
            isOpen={isAuthOpen}
            onClose={() => setIsAuthOpen(false)}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <AuthenticationPage tab="signup" />
          </Modal>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SignupPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SignupPageComponent);

export default SignupPage;
