import React, { useState } from 'react';

import config from '../../config';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import classNames from 'classnames';

import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import isEqual from 'lodash/isEqual';

import { LayoutWrapperTopbar, Page, Modal } from '../../components';
import {
  SignUpProjectBudgetDurationForm,
  SignUpProjectDescriptionForm,
  SignUpProjectNameForm,
  SignUpProjectRequirementsForm,
  SignUpProjectCategoryForm,
} from '../../forms';
import { AuthenticationPage } from '../../containers';
import { TopbarContainer } from '..';

import { FaCheckCircle } from 'react-icons/fa';
import { MdArrowForwardIos } from 'react-icons/md';

import css from './SignUpProjectPage.css';

const buildCategoryHash = categories => {
  const categoriesHash = {};

  categories.forEach(category => {
    categoriesHash[category.key] = category;
  });

  return categoriesHash;
};

const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
const categoryHash = buildCategoryHash(categoryOptions);

const SignUpProjectPageComponent = props => {
  const {
    projectCategory,
    formContent,
    signUpFrom,
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    className,
    rootClassName,
  } = props;
  const [isAuthOpen, setIsAuthOpen] = useState(false);
  const [step, setStep] = useState(projectCategory ? 2 : 1);

  const rootClasses = rootClassName || css.root;
  const classes = classNames(rootClasses, className);

  const initialFormData = {
    // Array with one empty object for FieldArrays initially to have one set of fields visible on first render.
    title: '',
    category: projectCategory || '',
    categoryNodes: [],
    descriptionText: '',
    priceEstimate: '',
    projectDuration: '',
    projectTier: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const schemaTitle = intl.formatMessage({ id: 'SignUpProjectCategoryForm.schemaTitle' });
  const schemaDescription = intl.formatMessage({
    id: 'SignUpProjectCategoryForm.schemaDescriptionschemaDescription',
  });

  const nextActionMessage = <FormattedMessage id="SignUpProjectCategoryForm.nextActionMessage" />;
  const saveActionMessage = <FormattedMessage id="SignUpProjectCategoryForm.saveActionMessage" />;
  const previousActionMessage = (
    <FormattedMessage id="SignUpProjectCategoryForm.previousActionMessage" />
  );

  const titlePlaceholder = formContent
    ? intl.formatMessage({
        id: formContent.titlePlaceholder,
      })
    : intl.formatMessage({
        id: 'SignUpProjectCategoryForm.titlePlaceholder',
      });

  const nameInfoListItem1 = formContent
    ? intl.formatMessage({
        id: formContent.nameInfoListItem1,
      })
    : intl.formatMessage({
        id: 'SignUpProjectCategoryForm.nameInfoListItem1',
      });
  const nameInfoListItem2 = formContent
    ? intl.formatMessage({
        id: formContent.nameInfoListItem2,
      })
    : intl.formatMessage({
        id: 'SignUpProjectCategoryForm.nameInfoListItem2',
      });

  const infoTitle = formContent
    ? intl.formatMessage({ id: formContent.formTitle })
    : intl.formatMessage({ id: 'SignUpProjectCategoryForm.formTitle' });
  const infoListItem1 = formContent
    ? intl.formatMessage({ id: formContent.formListItem1 })
    : intl.formatMessage({ id: 'SignUpProjectCategoryForm.formListItem1' });
  const infoListItem2 = formContent
    ? intl.formatMessage({ id: formContent.formListItem2 })
    : intl.formatMessage({ id: 'SignUpProjectCategoryForm.formListItem2' });
  const infoListItem3 = formContent
    ? intl.formatMessage({ id: formContent.formListItem3 })
    : intl.formatMessage({ id: 'SignUpProjectCategoryForm.formListItem3' });
  const formCategory = formContent
    ? intl.formatMessage({ id: formContent.formCategory })
    : intl.formatMessage({ id: 'SignUpProjectCategoryForm.formCategory' });

  const infoContainerDesktop = (
    <div className={css.infoContainerDesktop}>
      <div className={css.infoContent}>
        <h1 className={css.infoTitle}>{infoTitle}</h1>
        <ul className={css.infoList}>
          <li className={css.infoListItem}>
            <FaCheckCircle className={css.infoIcon} />
            {infoListItem1}
          </li>
          <li className={css.infoListItem}>
            <FaCheckCircle className={css.infoIcon} />
            {infoListItem2}
          </li>
          <li className={css.infoListItem}>
            <FaCheckCircle className={css.infoIcon} />
            {infoListItem3}
          </li>
        </ul>
      </div>
    </div>
  );

  const infoContainer = (
    <div className={css.infoContainer}>
      <div className={css.infoContentMobile}>
        <ul className={css.infoListMobile}>
          <li className={css.infoListItem}>
            <FaCheckCircle className={css.infoIcon} />
            {infoListItem1}
          </li>
          <li className={css.infoListItem}>
            <FaCheckCircle className={css.infoIcon} />
            {infoListItem2}
          </li>
          <li className={css.infoListItem}>
            <FaCheckCircle className={css.infoIcon} />
            {infoListItem3}
          </li>
        </ul>
      </div>
    </div>
  );

  const renderNewProjectStep = (step, next, prev, formData) => {
    switch (step) {
      case 1:
        return (
          <SignUpProjectCategoryForm
            onSubmit={next}
            initialValues={formData}
            categories={categoryOptions}
          />
        );
      case 2:
        return (
          <SignUpProjectNameForm
            onSubmit={next}
            onPrev={prev}
            initialValues={formData}
            saveActionMsg={nextActionMessage}
            previousActionMsg={previousActionMessage}
            infoListFirst={nameInfoListItem1}
            infoListSecond={nameInfoListItem2}
            infoContainer={infoContainer}
            projectCategory={projectCategory}
            titlePlaceholder={titlePlaceholder}
          />
        );
      case 3:
        return (
          <SignUpProjectBudgetDurationForm
            onSubmit={next}
            onPrev={prev}
            initialValues={formData}
            saveActionMsg={nextActionMessage}
            previousActionMsg={previousActionMessage}
          />
        );
      case 4:
        return (
          <SignUpProjectRequirementsForm
            onSubmit={next}
            onPrev={prev}
            initialValues={formData}
            saveActionMsg={nextActionMessage}
            previousActionMsg={previousActionMessage}
          />
        );
      case 5:
        return (
          <SignUpProjectDescriptionForm
            onSubmit={next}
            onPrev={prev}
            initialValues={formData}
            saveActionMsg={saveActionMessage}
            previousActionMsg={previousActionMessage}
            descriptionInfo={formContent?.descriptionInfo}
            formContent={formContent}
          />
        );
      default:
        return null;
    }
  };

  const formStepsNameLabel = intl.formatMessage({
    id: 'SignUpProjectCategoryForm.stepsNameLabel',
  });
  const formStepsBudgetLabel = intl.formatMessage({
    id: 'SignUpProjectCategoryForm.stepsBudgetLabel',
  });
  const formStepsSkillsLabel = intl.formatMessage({
    id: 'SignUpProjectCategoryForm.stepsSkillsLabel',
  });
  const formStepsDescriptionLabel = intl.formatMessage({
    id: 'SignUpProjectCategoryForm.stepsDescriptionLabel',
  });

  const signUpFromPath = signUpFrom || '/tyoilmoitus';

  const next = data => {
    if (step === 2) {
      const categoryObject = projectCategory
        ? categoryHash[projectCategory]
        : categoryHash[data.category];
      const categoryNodes = [data.category];
      if (typeof categoryObject.parent === 'string' && categoryObject.parent.length > 0)
        categoryNodes.push(categoryObject.parent);
      data.categoryNodes = categoryNodes;
    }

    const newFormData = {
      ...formData,
      ...data,
    };

    setFormData(newFormData);

    return step === 5 ? setUpLastStep(newFormData) : setStep(prevStep => prevStep + 1);
  };

  const prev = e => {
    e.preventDefault();
    setStep(prevStep => prevStep - 1);
  };

  const setUpLastStep = data => {
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      sessionStorage.setItem('signUpProjectFormData', JSON.stringify(data));
      sessionStorage.setItem(
        'signUpFromProjectCategoryLandingPage',
        JSON.stringify(signUpFromPath)
      );
    }
    setIsAuthOpen(true);
  };

  const showUnsavedPrompt = !isEqual(formData, initialFormData);

  const handleStepClick = step => {
    switch (step) {
      case 1:
        setStep(step);
        break;
      case 2:
        if (formData.category !== '') {
          setStep(step);
        }
        break;
      case 3:
        if (formData.title !== '') {
          setStep(step);
        }
        break;
      case 4:
        if (formData.priceEstimate !== '' && formData.projectDuration !== '') {
          setStep(step);
        }
        break;
      case 5:
        if (formData.projectTier !== '') {
          setStep(step);
        }
        break;
      default:
        break;
    }
  };

  const formStepsMobile = (
    <div className={css.formStepsMobile}>
      <div className={css.formStepsMobileNav}>
        {!projectCategory ? (
          <div className={css.formStepMobile} onClick={() => handleStepClick(1)}>
            {formCategory}
          </div>
        ) : null}

        <div
          className={formData.category === '' ? css.formStepMobileInactive : css.formStepMobile}
          onClick={() => handleStepClick(2)}
        >
          {formStepsNameLabel}
        </div>
        <div
          className={formData.title === '' ? css.formStepMobileInactive : css.formStepMobile}
          onClick={() => handleStepClick(3)}
        >
          {formStepsBudgetLabel}
        </div>
        <div
          className={
            formData.priceEstimate === '' ? css.formStepMobileInactive : css.formStepMobile
          }
          onClick={() => handleStepClick(4)}
        >
          {formStepsSkillsLabel}
        </div>
        <div
          className={formData.projectTier === '' ? css.formStepMobileInactive : css.formStepMobile}
          onClick={() => handleStepClick(5)}
        >
          {formStepsDescriptionLabel}
        </div>
      </div>
    </div>
  );

  const formStepsDesktop = (
    <div className={css.formSteps}>
      {!projectCategory ? (
        <div className={css.formSteps} onClick={() => handleStepClick(1)}>
          {formCategory}
          <MdArrowForwardIos className={css.formStepSeparator} />
        </div>
      ) : null}
      <div
        className={formData.category === '' ? css.formStepsInactive : css.formSteps}
        onClick={() => handleStepClick(2)}
      >
        {formStepsNameLabel}
        <MdArrowForwardIos className={css.formStepSeparator} />
      </div>
      <div
        className={formData.title === '' ? css.formStepsInactive : css.formSteps}
        onClick={() => handleStepClick(3)}
      >
        {formStepsBudgetLabel}
        <MdArrowForwardIos className={css.formStepSeparator} />
      </div>
      <div
        className={formData.priceEstimate === '' ? css.formStepsInactive : css.formSteps}
        onClick={() => handleStepClick(4)}
      >
        {formStepsSkillsLabel}
        <MdArrowForwardIos className={css.formStepSeparator} />
      </div>
      <div
        className={formData.projectTier === '' ? css.formStepsInactive : css.formSteps}
        onClick={() => handleStepClick(5)}
      >
        {formStepsDescriptionLabel}
      </div>
    </div>
  );

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutWrapperTopbar>
        <TopbarContainer
          currentPage="SignUpProjectPage"
          projectCategoryTopBar={true}
          dontShowprojectCategoryTopBarButton={true}
        />
      </LayoutWrapperTopbar>
      <div className={classes}>
        {infoContainerDesktop}
        <div className={css.formContainer}>
          <div className={css.formSteps}>{formStepsDesktop}</div>
          <div className={css.formStepsMobile}>{formStepsMobile}</div>
          {renderNewProjectStep(step, next, prev, formData)}
        </div>
      </div>
      <Modal
        id="SignupProviderAuthModal"
        containerClassName={css.modal}
        isOpen={isAuthOpen}
        onClose={() => setIsAuthOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <AuthenticationPage tab="signup" showUnsavedPrompt={showUnsavedPrompt} />
      </Modal>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { projectCategory, formContent } = state.ProjectCategoryLandingPage;
  const { signUpFrom } = state.SignUpProjectPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    projectCategory,
    formContent,
    signUpFrom,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const SignUpProjectPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SignUpProjectPageComponent);

export default SignUpProjectPage;
