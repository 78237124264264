import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProfileSettingsContainer as Container } from '../../components';
import config from '../../config';
import css from './Container.css';

const LanguagesContainer = ({ profileUser, button }) => {
  const languages = profileUser?.attributes?.profile?.publicData?.languages;
  const languageLevelOptions = config.custom.languageLevelOptions;
  const languageOptions = config.custom.filters[3].config.options;

  const container = (
    <Container heading={<FormattedMessage id="ProfilePage.languages" />} button={button}>
      {languages &&
        languages.map(e => (
          <div key={e.language}>
            <div className={css.heading}>
              {languageOptions.find(l => l.key === e.language)?.label}
            </div>
            <div className={css.years}>
              {e.language && (
                <FormattedMessage id={languageLevelOptions.find(l => l.key === e.level)?.label} />
              )}
            </div>
          </div>
        ))}
    </Container>
  );

  if (!button) {
    if (!languages?.length || (languages?.length === 0 && Object.keys(languages[0]).length === 0))
      return null;
  }
  return container;
};

export default LanguagesContainer;
