import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';

import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  Button,
  NamedLink,
  ExternalLink,
  IntegrateFreelancersBanner,
} from '../../components';
import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import SolutionPageHeroSection from './SolutionPageHeroSection';
import SolutionPageProblemsSection from './SolutionPageProblemsSection';
import SolutionPageInfoSection from './SolutionPageInfoSection';
import OptionBanner from './OptionBanner';

import heroImage from '../../assets/solutionspage-marketingteams-freelancer-woman-heroimage.webp';
import infoImage from '../../assets/freelancer-image-solutions.webp';
import testimonialSectionImage from '../../assets/freelanceri-at-cafe.webp';
import miikkaImg from '../../assets/pro-miikka-salminen.webp';
import IntegrateFreelancersBannerImg from '../../assets/integrate-freelancers-banner-image.webp';

import css from './SolutionPages.css';

const MarketingTeamSolutionPageComponent = props => {
  const { intl, scrollingDisabled } = props;

  const schemaTitle = intl.formatMessage({ id: 'MarketingTeamSolutionPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.schemaDescription',
  });

  const heroCaption = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.heroCaption',
  });
  const heroTitle = intl.formatMessage({ id: 'MarketingTeamSolutionPage.heroTitle' });
  const heroSubtitle = intl.formatMessage({ id: 'MarketingTeamSolutionPage.heroSubtitle' });
  const heroButtonText = intl.formatMessage({ id: 'MarketingTeamSolutionPage.heroButtonText' });

  const problemsTitle = intl.formatMessage({ id: 'MarketingTeamSolutionPage.problemsTitle' });
  const problemsCardTitle1 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.problemsCardTitle1',
  });
  const problemsCardText1 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.problemsCardText1',
  });
  const problemsCardTitle2 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.problemsCardTitle2',
  });
  const problemsCardText2 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.problemsCardText2',
  });
  const problemsCardTitle3 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.problemsCardTitle3',
  });
  const problemsCardText3 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.problemsCardText3',
  });
  const problemsCardTitle4 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.problemsCardTitle4',
  });
  const problemsCardText4 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.problemsCardText4',
  });

  const infoTitle = intl.formatMessage({ id: 'MarketingTeamSolutionPage.infoTitle' });
  const infoImageName = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoImageName',
  });
  const infoImageText1 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoImageText1',
  });
  const infoImageText2 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoImageText2',
  });
  const infoImageText3 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoImageText3',
  });
  const infoImageText4 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoImageText4',
  });
  const infoCardTitle1 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoCardTitle1',
  });
  const infoCardSubtitle1 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoCardSubtitle1',
  });
  const infoCardText11 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoCardText11',
  });
  const infoCardText12 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoCardText12',
  });
  const infoCardTitle2 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoCardTitle2',
  });
  const infoCardSubtitle2 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoCardSubtitle2',
  });
  const infoCardText2 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoCardText2',
  });
  const infoCardText3 = intl.formatMessage({
    id: 'MarketingTeamSolutionPage.infoCardText3',
  });
  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  const optionProBannerFooter = (
    <div className={css.optionProFooter}>
      <Button className={css.contactButton} onClick={handleShowSpaceHome}>
        <FormattedMessage id="StartBuyingInfoPage.contactButton" />
      </Button>
      <NamedLink name="ProLandingPage">
        <FormattedMessage id="StartBuyingInfoPage.proLandingPageLink" />
      </NamedLink>
    </div>
  );

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="MarketingTeamSolutionPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.sections}>
            <SolutionPageHeroSection
              heroCaption={heroCaption}
              title={heroTitle}
              subTitle={heroSubtitle}
              buttonText={heroButtonText}
              imgSrc={heroImage}
              handleShowSpaceHome={handleShowSpaceHome}
            />
            <SolutionPageProblemsSection
              title={problemsTitle}
              cardTitle1={problemsCardTitle1}
              cardText1={problemsCardText1}
              cardTitle2={problemsCardTitle2}
              cardText2={problemsCardText2}
              cardTitle3={problemsCardTitle3}
              cardText3={problemsCardText3}
              cardTitle4={problemsCardTitle4}
              cardText4={problemsCardText4}
            />
            <SolutionPageInfoSection
              title={infoTitle}
              imageName={infoImageName}
              imageText1={infoImageText1}
              imageText2={infoImageText2}
              imageText3={infoImageText3}
              imageText4={infoImageText4}
              cardTitle1={infoCardTitle1}
              cardSubtitle1={infoCardSubtitle1}
              cardText11={infoCardText11}
              cardText12={infoCardText12}
              cardTitle2={infoCardTitle2}
              cardSubtitle2={infoCardSubtitle2}
              cardText2={infoCardText2}
              cardText3={infoCardText3}
              imgSrc={infoImage}
            />
            <IntegrateFreelancersBanner
              imgSrc={IntegrateFreelancersBannerImg}
              titleId="DigitalAgencySolutionPage.integrateFreelancersBannerTitle"
              textId="DigitalAgencySolutionPage.integrateFreelancersBannerText"
            />
            <OptionBanner
              rootClass={classNames(css.optionBannerRoot)}
              optionLabelId="MarketingTeamSolutionPage.optionBannerLabel"
              titleId="MarketingTeamSolutionPage.optionBannerTitle"
              titleDescriptionId="MarketingTeamSolutionPage.optionBannerDescription"
              step1TitleId="MarketingTeamSolutionPage.optionBannerStep1Title"
              step2TitleId="MarketingTeamSolutionPage.optionBannerStep2Title"
              step3TitleId="MarketingTeamSolutionPage.optionBannerStep3Title"
              step1DescriptionId="MarketingTeamSolutionPage.optionBannerStep1Description"
              step2DescriptionId="MarketingTeamSolutionPage.optionBannerStep2Description"
              step3DescriptionId="MarketingTeamSolutionPage.optionBannerStep3Description"
              footerElement={optionProBannerFooter}
            />
            <div className={css.testimonialSection}>
              <div className={css.testimonialSectionTitle}>
                <FormattedMessage id="MarketingTeamSolutionPage.testimonialSectionTitle" />
              </div>
              <div className={css.testimonialSectionContent}>
                <div className={css.testimonialSectionContentText}>
                  <div className={css.testimonialSectionListItem}>
                    <div className={css.testimonialSectionListItemText}>
                      <FormattedMessage id="MarketingTeamSolutionPage.testimonialSectionText1" />
                    </div>
                    <div className={css.testimonialSectionListItemUser}>
                      <FormattedMessage id="ProLandingPage.proTestimonialSectionUser1" />
                    </div>
                  </div>
                  <div className={css.testimonialSectionListItem}>
                    <div className={css.testimonialSectionListItemText}>
                      <FormattedMessage id="MarketingTeamSolutionPage.testimonialSectionText2" />
                    </div>
                    <div className={css.testimonialSectionListItemUser}>
                      <FormattedMessage id="ProLandingPage.proTestimonialSectionUser2" />
                    </div>
                  </div>
                </div>
                <div className={css.testimonialSectionImageContainer}>
                  <img
                    src={testimonialSectionImage}
                    alt="Testimonial Section"
                    className={css.testimonialSectionContentImage}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={css.contactSection}>
            <div className={css.contactSectionContactCard}>
              <div className={css.contactCard}>
                <div className={css.contactCardAvatar}>
                  <img src={miikkaImg} alt="Contact" />
                </div>
                <div className={css.contactCardName}>Miikka Salminen, CEO</div>
                <div className={css.contactCardInformation}>miikka@freedomly.io</div>
                <div className={css.contactCardInformation}>+358 45 268 2218</div>
                <div>
                  <ExternalLink
                    className={css.contactSectionContactButton}
                    onClick={handleShowSpaceHome}
                  >
                    <FormattedMessage id="ProLandingPage.heroContactButton" />
                  </ExternalLink>
                </div>
              </div>
            </div>
            <div className={css.contactSectionTextContent}>
              <div className={css.contactSectionTitle}>
                <FormattedMessage id="DigitalAgencySolutionPage.contactSectionTitle" />
              </div>
              <div className={css.contactSectionContentText}>
                <FormattedMessage id="DigitalAgencySolutionPage.contactSectionText" />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { number, shape } = PropTypes;

MarketingTeamSolutionPageComponent.propTypes = {
  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired, // from injectIntl
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const MarketingTeamSolutionPage = compose(
  withRouter,
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(MarketingTeamSolutionPageComponent);

export default MarketingTeamSolutionPage;
