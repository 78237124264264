import React from 'react';

import css from './ProgressBar.css';

const ProgressBar = props => {
  const { value, steps, stepsDone, hideStepCount } = props;
  return (
    <div className={css.progressContainer}>
      <div className={hideStepCount ? css.progressBarFull : css.progressBar}>
        <div className={css.progressValue} style={{ width: `${value}%` }} />
      </div>
      {hideStepCount ? (
        <p></p>
      ) : (
        <p className={css.progressValue}>
          {stepsDone}/{steps}
        </p>
      )}
    </div>
  );
};

export default ProgressBar;
