import React, { Component } from 'react';
import css from './ProfileSettingsContainer.css';

export default class ProfileSettingsContainer extends Component {
  render() {
    const heading = this.props.heading && (
      <h1 className={css.desktopHeading}>{this.props.heading}</h1>
    );

    if (this.props.heading !== ' ') {
      return (
        <div className={css.container}>
          {this.props.button ? (
            <div className={css.desktopHeadingWithEdit}>
              {heading}
              {this.props.button}
            </div>
          ) : (
            <>{heading}</>
          )}
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div className={css.containerNoHeading}>
          {this.props.children}

          {this.props.button ? (
            <div className={css.desktopHeadingWithEditNoHeading}>
              {heading}
              {this.props.button}
            </div>
          ) : (
            <>{heading}</>
          )}
        </div>
      );
    }
  }
}
