import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { loadData } from './ApplicationInboxPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import classNames from 'classnames';

import {
  Avatar,
  NamedLink,
  Footer,
  IconSpinner,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  PaginationLinks,
  TabNav,
  UserDisplayName,
} from '../../components';
import {
  txLastTransition,
  TRANSITION_ENQUIRE,
  TRANSITION_ACCEPT_APPLICATION,
  TRANSITION_SKIP_APPLICATION,
  TRANSITION_ACCEPT_AFTER_SKIP,
  TRANSITION_EXPIRE_ENQUIRY,
  TRANSITION_EXPIRE_APPLICATION,
  TRANSITION_MAKE_PROJECT_BID,
  TRANSITION_MAKE_PROJECT_BID_AFTER_ENQUIRY,
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_DECLINE_OFFER,
  TRANSITION_EXPIRE_OFFER,
} from '../../util/transaction';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import config from '../../config';

import css from './ApplicationInboxPage.css';
import { ensureCurrentUser } from '../../util/data';
import { BsArrow90DegRight } from 'react-icons/bs';

export const ApplicationInboxPageComponent = props => {
  const {
    unitType,
    scrollingDisabled,
    providerNotificationBadge,
    fetchInProgress,
    fetchOrdersOrSalesError,
    transactions,
    intl,
    currentUser,
    currentUserNotificationTxIds,
    currentUserApplicationTxIds,
    params,
    pagination,
  } = props;
  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const formatDate = (intl, date) => {
    return {
      short: intl.formatDate(date, {
        month: 'short',
        day: 'numeric',
      }),
      long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
    };
  };

  const isOrders =
    tab === 'orders' || tab === 'enquiries' || tab === 'completed' || tab === 'ongoing'; // && userRole === 'customer';

  const isCustomer =
    currentUser &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.account_role === 'customer';

  const InboxItem = props => {
    const { type, tx, intl, stateData, notificationTxIds } = props;
    const { customer, provider } = tx;
    const isOrder = type === 'order';
    const deleted = tx.listing.attributes.deleted;
    const userDeleted = !tx?.customer.attributes.profile.displayName;

    const otherUser = isOrder ? provider : customer;
    const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
    const isOtherUserBanned = otherUser.attributes.banned;

    const isSaleNotification = notificationTxIds?.includes(tx.id.uuid);
    const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
    const lastTransitionedAt = formatDate(intl, tx.attributes.lastTransitionedAt);

    const deletedTitle = intl.formatMessage({
      id: 'InboxPage.deletedListing',
    });
    const userDeletedTitle = intl.formatMessage({
      id: 'InboxPage.deletedUser',
    });

    const linkClasses = classNames(css.itemLink, {
      [css.bannedUserLink]: isOtherUserBanned,
      [css.deletedItem]: deleted,
    });

    if (deleted) {
      return (
        <div className={css.item}>
          <NamedLink
            className={linkClasses}
            name={!isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
            params={{ id: tx.id.uuid }}
          >
            <div className={css.itemInfo}>
              <div className={css.itemAvatar}>
                <Avatar disableProfileLink={true} />
              </div>
              <div className={classNames(css.itemUsername)}>{deletedTitle}</div>
              {/* Blank div to ease styling */}
              <div className={css.rowNotificationDot}></div>
            </div>
            <div className={css.itemState}>
              <div className={classNames(css.stateName, stateData.stateClassName)}>
                {stateData.state || <FormattedMessage id="InboxPage.application" />}
              </div>
            </div>
          </NamedLink>
          <div
            className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
            title={lastTransitionedAt.long}
          >
            {lastTransitionedAt.short}
          </div>
        </div>
      );
    } else if (!deleted && userDeleted) {
      return (
        <div className={css.item}>
          <NamedLink
            className={linkClasses}
            name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
            params={{ id: tx.id.uuid }}
          >
            <div className={css.itemInfo}>
              <div className={css.itemAvatar}>
                <Avatar disableProfileLink={true} />
              </div>
              <div className={classNames(css.itemUsername)}>{userDeletedTitle}</div>
            </div>
            <div className={css.itemState}>
              <div className={classNames(css.stateName, stateData.stateClassName)}>
                {stateData.state || <FormattedMessage id="InboxPage.application" />}
              </div>
              <div
                className={classNames(
                  css.lastTransitionedAt,
                  stateData.lastTransitionedAtClassName
                )}
                title={lastTransitionedAt.long}
              >
                {lastTransitionedAt.short}
              </div>
            </div>
          </NamedLink>
        </div>
      );
    } else {
      return (
        <div className={css.item}>
          <NamedLink
            className={linkClasses}
            name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
            params={{ id: tx.id.uuid }}
          >
            <div className={css.itemInfo}>
              <div className={css.itemAvatar}>
                <Avatar user={otherUser} disableProfileLink={true} />
              </div>
              <div className={classNames(css.itemUsername)}>{otherUserDisplayName}</div>
              <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
            </div>
            <div className={css.itemState}>
              <div className={classNames(css.stateName, stateData.stateClassName)}>
                {stateData.state || <FormattedMessage id="InboxPage.application" />}
              </div>
            </div>
            <div className={css.itemTitle}>{tx.listing.attributes.title}</div>
          </NamedLink>
          <div
            className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
            title={lastTransitionedAt.long}
          >
            {lastTransitionedAt.short}
          </div>
        </div>
      );
    }
  };

  const ApplicationInboxItem = props => {
    const { type, tx, intl, stateData, notificationTxIds } = props;
    const { customer, provider } = tx;
    const isOrder = type === 'order';

    const otherUser = isOrder ? provider : customer;
    const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
    const isOtherUserBanned = otherUser.attributes.banned;

    const isSaleNotification = notificationTxIds?.includes(tx.id.uuid);
    const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
    const lastTransitionedAt = formatDate(intl, tx.attributes.lastTransitionedAt);

    const linkClasses = classNames(css.itemLink, {
      [css.bannedUserLink]: isOtherUserBanned,
    });

    return (
      <div className={css.item}>
        <NamedLink
          className={linkClasses}
          name={isOrder ? 'ApplicationOrderPage' : 'ApplicationSalePage'}
          params={{ id: tx.id.uuid }}
        >
          <div className={css.itemInfo}>
            <div className={css.itemAvatar}>
              <Avatar user={otherUser} disableProfileLink={true} />
            </div>
            <div className={classNames(css.itemUsername)}>{otherUserDisplayName}</div>
            <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
          </div>
          <div className={css.itemState}>
            <div className={classNames(css.stateName, stateData.stateClassName)}>
              {stateData.state || <FormattedMessage id="InboxPage.application" />}
            </div>
          </div>
          <div className={css.itemTitle}>{tx.listing.attributes.title}</div>
        </NamedLink>
        <div
          className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
          title={lastTransitionedAt.long}
        >
          {lastTransitionedAt.short}
        </div>
      </div>
    );
  };

  const txState = (intl, tx, type) => {
    const isOrder = type === 'order';

    const isStaging =
      process.env.REACT_APP_FINNISH_ROOT_URL === 'https://freedomly-staging.freedomly.io';
    const legacyProcessVersion = isStaging ? 20 : 8;
    const isLegacyApplication = tx.attributes.processVersion <= legacyProcessVersion;

    if ([TRANSITION_ENQUIRE].includes(txLastTransition(tx))) {
      if (isLegacyApplication) {
        return {
          nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
          bookingClassName: css.bookingActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'ApplicationInboxPage.stateEnquiry',
          }),
          legacyApplication: true,
        };
      } else {
        return {
          nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
          bookingClassName: css.bookingActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'ApplicationInboxPage.stateEnquiry',
          }),
          legacyApplication: false,
        };
      }
    } else if ([TRANSITION_SKIP_APPLICATION].includes(txLastTransition(tx))) {
      return {
        nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'ApplicationInboxPage.stateSkipped',
        }),
        legacyApplication: false,
      };
    } else if ([TRANSITION_ACCEPT_APPLICATION].includes(txLastTransition(tx))) {
      return {
        nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'ApplicationInboxPage.stateAccepted',
        }),
        legacyApplication: false,
      };
    } else if ([TRANSITION_ACCEPT_AFTER_SKIP].includes(txLastTransition(tx))) {
      return {
        nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'ApplicationInboxPage.stateAccepted',
        }),
        legacyApplication: false,
      };
    } else if ([TRANSITION_EXPIRE_APPLICATION].includes(txLastTransition(tx))) {
      return {
        nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'ApplicationInboxPage.stateExpired',
        }),
        legacyApplication: false,
      };
    } else if ([TRANSITION_EXPIRE_ENQUIRY].includes(txLastTransition(tx))) {
      return {
        nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'ApplicationInboxPage.stateExpired',
        }),
        legacyApplication: true,
      };
    } else if ([TRANSITION_MAKE_PROJECT_BID].includes(txLastTransition(tx))) {
      return {
        nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'InboxPage.stateOffer',
        }),
        legacyApplication: true,
      };
    } else if ([TRANSITION_MAKE_PROJECT_BID_AFTER_ENQUIRY].includes(txLastTransition(tx))) {
      return {
        nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'InboxPage.stateOffer',
        }),
        legacyApplication: true,
      };
    } else if ([TRANSITION_ACCEPT_OFFER].includes(txLastTransition(tx))) {
      return {
        nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'ApplicationInboxPage.stateAccepted',
        }),
        legacyApplication: true,
      };
    } else if ([TRANSITION_DECLINE_OFFER].includes(txLastTransition(tx))) {
      return {
        nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'ApplicationInboxPage.stateDeclined',
        }),
        legacyApplication: true,
      };
    } else if ([TRANSITION_EXPIRE_OFFER].includes(txLastTransition(tx))) {
      return {
        nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'ApplicationInboxPage.stateExpired',
        }),
        legacyApplication: true,
      };
    }
  };

  const toTxItem = tx => {
    const type = isCustomer ? 'sale' : 'order';
    const stateData = txState(intl, tx, type);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.

    return stateData && !stateData.legacyApplication ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <ApplicationInboxItem
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          notificationTxIds={currentUserApplicationTxIds}
        />
      </li>
    ) : stateData && stateData.legacyApplication ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          notificationTxIds={currentUserNotificationTxIds}
        />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage id={'ApplicationInboxPage.noApplicationsFound'} />
      </li>
    ) : null;

  const visibleTxs = transactions.filter(
    tx => tx.listing?.id.uuid !== process.env.REACT_APP_LE_PROFILE_LISTING_ID
  );

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab ? user.id && tx && tx.length > 0 : user.id && tx && tx.length > 0;
  };

  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);

  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ApplicationInboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  let tabs = [];
  tabs.push({
    text: (
      <span>
        <BsArrow90DegRight className={css.icon} />
        <FormattedMessage id="ApplicationInboxPage.toProjectManagement" />
      </span>
    ),
    linkProps: {
      name: 'InboxPage',
      params: { tab: 'orders' },
    },
  });
  if (isCustomer) {
    tabs.push({
      text: (
        <span>
          <BsArrow90DegRight className={css.icon} />
          <FormattedMessage id="ApplicationInboxPage.toMyProjects" />
        </span>
      ),
      linkProps: {
        name: 'ManageProjectListingsPage',
      },
    });
  }

  const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

  const infoForCurrentUser = isCustomer
    ? 'ApplicationInboxPage.infoForCustomer'
    : 'ApplicationInboxPage.infoForFreelancer';

  const schemaTitle = intl.formatMessage({
    id: 'ApplicatonInboxPage.schemaTitle',
  });

  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="ApplicationInboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={css.navigation}>
          <div className={css.navcontainer}>
            <h1 className={css.title}>
              <FormattedMessage id="ApplicationInboxPage.title" />
              {providerNotificationBadge}
            </h1>
            <p className={css.info}>
              <FormattedMessage id={infoForCurrentUser} />
            </p>
            {nav}
          </div>
        </LayoutWrapperSideNav>

        <LayoutWrapperMain className={css.listings}>
          <div>
            {error}
            <ul className={css.itemList}>
              {!fetchInProgress ? (
                visibleTxs.map(toTxItem)
              ) : (
                <li className={css.listItemsLoading}>
                  <IconSpinner />
                </li>
              )}
              {noResults}
            </ul>
            {pagingLinks}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ApplicationInboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  providerApplicationNotificationCount: 0,
  sendVerificationEmailError: null,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchProjectOffersInProgress,
    fetchProjectOffersError,
    pagination,
    projectTransactionRefs,
  } = state.ApplicationInboxPage;
  const {
    currentUser,
    currentUserNotificationCount: providerNotificationCount,
    currentUserApplicationCount: providerApplicationNotificationCount,
    currentUserNotificationTx,
    currentUserApplicationTx,
  } = state.user;
  const transactions = getMarketplaceEntities(state, projectTransactionRefs);
  const currentUserNotificationTxIds = currentUserNotificationTx.map(tx => tx.txId);
  const currentUserApplicationTxIds = currentUserApplicationTx.map(tx => tx.txId);

  return {
    currentUser,
    fetchInProgress,
    fetchProjectOffersInProgress,
    fetchProjectOffersError,
    pagination,
    providerNotificationCount,
    currentUserNotificationTxIds,
    providerApplicationNotificationCount,
    currentUserApplicationTxIds,
    scrollingDisabled: isScrollingDisabled(state),
    transactions,
  };
};

const ApplicationInboxPage = compose(
  connect(mapStateToProps),
  injectIntl
)(ApplicationInboxPageComponent);

ApplicationInboxPage.loadData = loadData;

export default ApplicationInboxPage;
