import React from 'react';
import { bool } from 'prop-types';
import SelectCategoryFilterPlain from './SelectCategoryFilterPlain';
import SelectCategoryFilterPopup from './SelectCategoryFilterPopup';

const SelectCategoryFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <SelectCategoryFilterPopup {...rest} />
  ) : (
    <SelectCategoryFilterPlain {...rest} />
  );
};

SelectCategoryFilter.defaultProps = {
  showAsPopup: false,
};

SelectCategoryFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectCategoryFilter;
