import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, FieldSelect, NamedLink } from '../../components';

import css from './TopbarSearchForm.css';

const identity = v => v;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
  }

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const { rootClassName, className } = formRenderProps;

          const classes = classNames(rootClassName, className);

          // Allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();

          // Language selection dropdown
          const handleLanguageChange = e => {
            if (e.target.value === 'en') {
              window.location.assign(process.env.REACT_APP_ENGLISH_ROOT_URL);
            } else if (e.target.value === 'fi') {
              window.location.assign(process.env.REACT_APP_FINNISH_ROOT_URL);
            }
          };

          const languageSelect = (
            <FieldSelect
              id="language"
              name="language"
              onChange={handleLanguageChange}
              className={css.languageSelect}
            >
              <option value="en">EN</option>
              <option value="fi">FI</option>
            </FieldSelect>
          );

          return (
            <Form className={classes} onSubmit={preventFormSubmit}>
              <Field
                name="location"
                format={identity}
                render={({ input, meta }) => {
                  const autocompleteInput = <div className={css.autocompleteStub}></div>;

                  const searchLinks = (
                    <div className={css.searchLinks}>
                      <NamedLink
                        name="ProjectSearchPage"
                        to={{ search: '' }}
                        className={css.searchLink}
                      >
                        <FormattedMessage id="TopbarSearchForm.searchForProjects" />
                      </NamedLink>
                      <NamedLink name="SearchPage" to={{ search: '' }} className={css.searchLink}>
                        <FormattedMessage id="TopbarSearchForm.searchForServices" />
                      </NamedLink>
                    </div>
                  );

                  return (
                    <>
                      {autocompleteInput}
                      {searchLinks}
                      {languageSelect}
                    </>
                  );
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
