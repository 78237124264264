import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { daysBetween } from '../../util/dates';
import config from '../../config';
import { Button } from '../../components';

import css from './SubscriptionAdviceBanner.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

const SubscriptionAdviceBannerComponent = props => {
  const {
    rootClassName,
    className,
    isSubscribed,
    isAuthenticated,
    currentUser,
    history,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const accountRole = currentUser && currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.account_role;
  const showBanner = isAuthenticated && !isSubscribed &&
    currentUser && currentUser.attributes.profile.privateData &&
    currentUser.attributes.profile.publicData &&
    ['freelancer', 'light-entrepreneur'].includes( accountRole );

  const trialDays = currentUser
    ? config.subscriptionTrialDays - daysBetween(currentUser.attributes.createdAt, new Date())
    : 0;

  const subscriptionMessage = trialDays > 0 ? (
    <FormattedMessage id="SubscriptionAdviceBanner.message" values={{ trialDays }}/>
  ) : (
    <FormattedMessage id="SubscriptionAdviceBanner.trialIsOverMessage" />
  );

  const onSubscribe = () => {
    history.push(createResourceLocatorString('SubscriptionPage', routeConfiguration()));
  };

  return showBanner ? (
    <div className={classes}>
      <p className={css.text}>
        {subscriptionMessage}
      </p>
      <Button rootClassName={css.button} onClick={onSubscribe}>
        <FormattedMessage id="SubscriptionAdviceBanner.subscribe"/>
      </Button>
    </div>
  ) : null;
};

SubscriptionAdviceBannerComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { bool, string } = PropTypes;

SubscriptionAdviceBannerComponent.propTypes = {
  rootClassName: string,
  className: string,
  isSubscribed: bool.isRequired,
};

const SubscriptionAdviceBanner = compose(
  withRouter,
  injectIntl
)(SubscriptionAdviceBannerComponent);

export default SubscriptionAdviceBanner;
