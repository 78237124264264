import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { propTypes } from '../../util/types';
import { textEditorValidation } from '../../util/validators';
import { InfoIcon, Form, Button, FieldTextEditor, UnsavedInfoPrompt } from '../../components';

import css from './SignUpProjectForms.css';

const SignUpProjectDescriptionFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          previousActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          dirty,
          onPrev,
          descriptionInfo,
        } = formRenderProps;

        const descriptionMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.description',
        });

        const descriptionInfoHeader = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoHeader',
        });
        const descriptionInfoFirst = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoFirst',
        });
        const descriptionInfoSecond = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoSecond',
        });
        const descriptionInfoThird = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionInfoThird',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.descriptionPlaceholder',
        });

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const externalLinksMessage = intl.formatMessage({
          id: 'RichTextEditor.externalLinksError',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const descriptionInfoFromContent = descriptionInfo
          ? descriptionInfo.map(item => (
              <li className={css.infoListItem}>
                <FormattedMessage id={item} />
              </li>
            ))
          : null;

        const descriptionInfoItems = descriptionInfoFromContent ? (
          descriptionInfoFromContent
        ) : (
          <>
            <li key={1} className={css.infoListItem}>
              {descriptionInfoFirst}
            </li>
            <li key={2} className={css.infoListItem}>
              {descriptionInfoSecond}
            </li>
            <li key={3} className={css.infoListItem}>
              {descriptionInfoThird}
            </li>
          </>
        );

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            {typeof window === 'object' ? <ReactTooltip /> : null}
            <div>
              <div className={css.titleBlock}>
                <label htmlFor="description" className={css.descriptionMessage}>
                  {descriptionMessage}
                </label>
                <InfoIcon
                  className={css.infoIcon}
                  data-tip={intl.formatMessage({
                    id: 'EditProjectListingDescriptionForm.descriptionTooltip',
                  })}
                  data-border={true}
                  data-text-color={'black'}
                  data-background-color={'white'}
                  data-border-color={'darkgrey'}
                  data-class={css.tooltipText}
                  data-place={'bottom'}
                />
              </div>
              <div>
                <FieldTextEditor
                  id="descriptionText"
                  name="descriptionText"
                  // label={descriptionMessage}
                  className={css.description}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessage}
                  validate={textEditorValidation(externalLinksMessage)}
                />
              </div>
              <div className={css.infoBlock}>
                <ul className={css.descriptionInfoList}>
                  <div className={css.descriptionInfoHeader}>{descriptionInfoHeader}</div>
                  {descriptionInfoItems}
                </ul>
              </div>
            </div>
            <div className={css.buttonContainer}>
              <Button className={css.previousButton} onClick={onPrev}>
                {previousActionMsg}
              </Button>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
            <UnsavedInfoPrompt when={dirty} />
          </Form>
        );
      }}
    />
  );
};

SignUpProjectDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

SignUpProjectDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,

  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(SignUpProjectDescriptionFormComponent);
