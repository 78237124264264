import React from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';

import css from './MultiPurposeInfo.css';

const MultiPurposeInfoComponent = props => {
  const { showWarning, warningMessage, icon } = props;

  return showWarning ? (
    <div className={css.warningContainer}>
      <div className={css.icon}>{icon}</div>
      <p className={css.warningMessage}>{warningMessage}</p>
    </div>
  ) : null;
};

const MultiPurposeInfo = compose(injectIntl)(MultiPurposeInfoComponent);

export default MultiPurposeInfo;
