// ================ Action types ================ //

export const SAVE_TAX_INFORMATION_REQUEST = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_TAX_INFORMATION_SUCCESS = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_TAX_INFORMATION_ERROR = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_ERROR';

export const SAVE_TAX_INFORMATION_CLEAR = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';

// ================ Reducer ================ //

const initialState = {
  saveTaxInformationInProgress: false,
  taxInformationChanged: false,
  taxInformationError: null,
  taxInformationSuccess: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_TAX_INFORMATION_REQUEST:
      return {
        ...state,
        saveTaxInformationInProgress: true,
        taxInformationChanged: false,
      };
    case SAVE_TAX_INFORMATION_SUCCESS:
      return {
        ...state,
        saveTaxInformationInProgress: false,
        taxInformationChanged: true,
        taxInformationSuccess: true,
      };

    case SAVE_TAX_INFORMATION_ERROR:
      return {
        ...state,
        saveTaxInformationInProgress: false,
        taxInformationChanged: false,
        taxInformationError: payload,
      };

    case SAVE_TAX_INFORMATION_CLEAR:
      return {
        ...state,
        saveTaxInformationInProgress: false,
        taxInformationChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveTaxInformationRequest = () => ({ type: SAVE_TAX_INFORMATION_REQUEST });
export const saveTaxInformationSuccess = () => ({ type: SAVE_TAX_INFORMATION_SUCCESS });
export const saveTaxInformationError = error => ({
  type: SAVE_TAX_INFORMATION_ERROR,
  payload: error,
  error: true,
});

export const saveContactDetailsClear = () => ({ type: SAVE_TAX_INFORMATION_CLEAR });

export const saveTaxInformation = (params, currentUser) => (dispatch, getState, sdk) => {
  dispatch(saveTaxInformationRequest());
  const {
    companyName,
    address,
    city,
    postalCode,
    taxIdentificationNumber,
    tinCountry,
    businessRegistrationNumber,
    businessRegistrationCountryOfIssue,
    dateOfBirth,
    birthLocation,
    vat_number,
  } = params;

  const isRequiredInformation = () => {
    const accountRole = currentUser.attributes.profile.publicData.account_role;
    const isCompanyAbroad = currentUser.attributes.profile.privateData.isCompanyAbroad;

    if (accountRole === 'freelancer') {
      return !!companyName && !!vat_number && !!address && city && !!postalCode;
    }
    if (accountRole === 'light-entrepreneur' && isCompanyAbroad) {
      return (
        !!companyName &&
        !!taxIdentificationNumber &&
        !!tinCountry &&
        !!businessRegistrationNumber &&
        !!businessRegistrationCountryOfIssue &&
        !!address &&
        !!city &&
        !!postalCode
      );
    }
    if (accountRole === 'light-entrepreneur' && !isCompanyAbroad) {
      return !!address && !!city && !!postalCode && !!dateOfBirth && !!birthLocation;
    }
  };

  return sdk.currentUser
    .updateProfile(
      {
        privateData: {
          companyName,
          address,
          city,
          postalCode,
          taxIdentificationNumber,
          tinCountry,
          businessRegistrationNumber,
          businessRegistrationCountryOfIssue,
          dateOfBirth,
          birthLocation,
          taxInformationCollected: isRequiredInformation(),
        },
        protectedData: {
          vat_number,
        },
      },
      { expand: true }
    )
    .then(response => {
      // if (typeof window === 'object') {
      //   const hmac = generateIntercomHmac(currentUser.id.uuid);
      //   Promise.resolve(hmac).then(hmac => {
      //     window.Intercom('update', {
      //       user_hash: hmac.hmac,
      //       email: currentUser.attributes.email,
      //       firstName: currentUser.attributes.profile.firstName,
      //       lastName: currentUser.attributes.profile.lastName,
      //       phone: currentUser.attributes.profile.protectedData.phoneNumber,
      //       textMessageNotifications:
      //         currentUser.attributes.profile.protectedData.textMessageNotifications,
      //       vatNumber: currentUser.attributes.profile.protectedData.vat_number,
      //       categories: categoryString,
      //       experience: experienceString,
      //       accountRole: currentUser.attributes.profile.publicData.account_role,
      //       emailVerified: currentUser.attributes.emailVerified,
      //     });
      //   });
      // }
      dispatch(saveTaxInformationSuccess());
    })
    .catch(e => {
      console.error(e);
      dispatch(saveTaxInformationError(e));
    });
};
