import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { injectIntl } from '../../util/reactIntl';
import {
  Form,
  FieldTextInput,
  SecondaryButton,
  Button,
  FieldSelectModern,
  IconClose,
  InlineTextButton,
  FieldCheckbox,
} from '../../components';
import { required } from '../../util/validators';
import { generateMonthsList, generateYearsList } from '../../util/dates';

import css from './SignupProviderForms.css';

const SignupProviderLinksForm = props => {
  const requiredMessage = id => required(props.intl.formatMessage({ id }));

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          invalid,
          values,
          isModal,
          pristine,
          initialValues,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid;
        const modalSubmitDisabled = invalid || pristine;

        const heading = intl.formatMessage({ id: 'SignupProviderForms.linksHeading' });
        const description = intl.formatMessage({ id: 'SignupProviderForms.linksDescription' });

        const websitePlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.linksWebsitePlaceholder',
        });
        const linkedinPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.linksLinkedInPlaceholder',
        });

        const websiteLabel = intl.formatMessage({ id: 'SignupProviderForms.linksWebsiteLabel' });
        const linkedinLabel = intl.formatMessage({ id: 'SignupProviderForms.linksLinkedInLabel' });

        const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.nextButton' });
        const previousButtonText = intl.formatMessage({ id: 'SignupProviderForms.previousButton' });
        const okButtonText = intl.formatMessage({ id: 'SignupProviderForms.okButton' });

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <h2 className={css.mainHeading}>
                {heading}
                {isModal ? (
                  <div className={css.submitContainer}>
                    <Button
                      rootClassName={css.nextButton}
                      type="Submit"
                      disabled={modalSubmitDisabled}
                    >
                      {okButtonText}
                    </Button>
                  </div>
                ) : null}
              </h2>
              <div className={css.column}>
                <p className={css.description}>{description}</p>
                <FieldTextInput
                  type="text"
                  id="website"
                  name="website"
                  maxLength="100"
                  placeholder={websitePlaceholder}
                  label={websiteLabel}
                />
                <FieldTextInput
                  type="text"
                  id="linkedIn"
                  name="linkedIn"
                  maxLength="100"
                  placeholder={linkedinPlaceholder}
                  label={linkedinLabel}
                />
              </div>
              <div className={css.submitContainer}>
                {!isModal && (
                  <SecondaryButton rootClassName={css.backButton} type="button" onClick={onPrev}>
                    {previousButtonText}
                  </SecondaryButton>
                )}
                {!isModal ? (
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {nextButtonText}
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

SignupProviderLinksForm.defaultProps = {
  isModal: false,
};

export default injectIntl(SignupProviderLinksForm);
