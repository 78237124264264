import React, { useState } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import Upload from 'rc-upload';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, SecondaryButton, IconSpinner } from '../../components';
import { propTypes } from '../../util/types';
import { apiBaseUrl } from '../../util/api';
import { CiWarning } from 'react-icons/ci';
import { ImAttachment } from 'react-icons/im';
import { IoSendSharp } from 'react-icons/io5';

import css from './SendMessageForm.css';

const AT_RE = /@/i;
//eslint-disable-next-line
const PH_NO_RE = /\s?(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{1,3}\)?[\s.-]?\d{3}[\s.-]?\d{4,5}/;
const PH_NO_RE2 = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/;
const LINK_RE = /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/;

const messageValidation = value => {
  if (value && AT_RE.test(value)) {
    return true;
  }
  if (value && LINK_RE.test(value)) {
    return true;
  }
  if (value && PH_NO_RE.test(value)) {
    return true;
  }
  if (value && PH_NO_RE2.test(value)) {
    return true;
  }
  return false;
};

const messageWarning = <FormattedMessage id="EnquiryForm.messageWarning" />;

const warningIcon = <CiWarning />;

const SendMessageFormComponent = props => {
  const [attachmentInProgress, setAttachmentInProgress] = useState(false);

  // const handleFocus = () => {
  //   this.props.onFocus();
  //   window.clearTimeout(this.blurTimeoutId);
  // };

  // const handleBlur = () => {
  //   // We only trigger a blur if another focus event doesn't come
  //   // within a timeout. This enables keeping the focus synced when
  //   // focus is switched between the message area and the submit
  //   // button.
  //   setBlurTimeoutId = window.setTimeout(() => {
  //     this.props.onBlur();
  //   }, BLUR_TIMEOUT_MS);
  // };

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          currentUser,
          messagePlaceholder,
          handleSubmit,
          onAttachmentSubmit,
          inProgress,
          sendMessageError,
          invalid,
          form,
          formId,
          openCustomOfferModalButton,
          values,
        } = formRenderProps;

        const isFreelancer =
          currentUser &&
          ['freelancer', 'light-entrepreneur'].includes(
            currentUser.attributes.profile.publicData.account_role
          );

        const uploaderProps = {
          action: `${apiBaseUrl()}/api/upload-message-attachment`,
          name: 'attachment',
          data: { name: 'Attachment', desc: 'Message attachment file' },
          multiple: false,
          beforeUpload(file) {
            console.log('beforeUpload', file.name);
          },
          onStart: file => {
            setAttachmentInProgress(true);

            console.log('onStart', file.name);
          },
          onSuccess(result) {
            setAttachmentInProgress(false);

            console.log('onSuccess', result);

            const message = `<<<${result.id}|${result.name}>>>`;
            onAttachmentSubmit({ message }, form);
          },
          onProgress(step, file) {
            setAttachmentInProgress(true);
            console.log('onProgress', Math.round(step.percent), file.name);
          },
          onError(err) {
            console.log('onError', err);
          },
          capture: 'user',
        };

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;
        return (
          <div>
            <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
              <div className={css.errorContainer}>
                {sendMessageError ? (
                  <p className={css.error}>
                    <FormattedMessage id="SendMessageForm.sendFailed" />
                  </p>
                ) : null}
              </div>
              <div className={css.messageFieldContainer}>
                <FieldTextInput
                  inputRootClass={css.textarea}
                  type="textarea"
                  id={formId ? `${formId}.message` : 'message'}
                  name="message"
                  placeholder={messagePlaceholder}
                  // onFocus={this.handleFocus}
                  // onBlur={this.handleBlur}
                  showWarning={messageValidation(formRenderProps.values.message)}
                  warningMessage={messageWarning}
                  icon={warningIcon}
                />
                <SecondaryButton
                  rootClassName={
                    values.message !== undefined ? css.submitButton : css.submitButtonHidden
                  }
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  // onFocus={this.handleFocus}
                  // onBlur={this.handleBlur}
                >
                  <IoSendSharp className={css.sendIcon} />
                </SecondaryButton>
                <Upload
                  {...uploaderProps}
                  className={values.message === undefined ? css.upload : css.hidden}
                >
                  {attachmentInProgress ? (
                    <div className={css.uploading}>
                      <IconSpinner />
                    </div>
                  ) : (
                    <div className={css.showUpload}>
                      {/* <div className={css.maxAttachmentSize}>
                        <FormattedMessage id="SendMessageForm.maxAttachmentSize" />
                      </div> */}
                      <div title="Upload message attachment" className={css.uploadLink}>
                        <ImAttachment className={css.uploadIcon} />
                      </div>
                    </div>
                  )}
                </Upload>
              </div>
              {/* <div className={css.submitContainer}>
                {isFreelancer ? <div>{openCustomOfferModalButton}</div> : null}
              </div> */}
            </Form>
          </div>
        );
      }}
    />
  );
};

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SendMessageForm = compose(injectIntl)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
