import React from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { MdMessage, MdPeople, MdRocketLaunch } from 'react-icons/md';
import classNames from 'classnames';
import {
  TRANSITION_DECLINE_OFFER,
  TRANSITION_EXPIRE_OFFER,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsRequested,
  txHasBeenDelivered,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsProjectConfirmed,
  txLastTransition,
  txIsProjectOfferMade,
  txIsProjectAccepted,
  txIsProjectDeclined,
  txIsProAccepted,
  txIsProDone,
  txIsProReadyAccepted,
} from '../../util/transaction';
import { propTypes, DATE_TYPE_DATE } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Avatar,
  BookingTimeInfo,
  NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  UserDisplayName,
} from '../../components';
import { TopbarContainer } from '../../containers';
import config from '../../config';

import { loadData } from './InboxPage.duck';
import css from './InboxPage.css';
import { BsArrow90DegRight, BsCheckCircleFill } from 'react-icons/bs';

const formatDate = (intl, date) => {
  return {
    short: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
    }),
    long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
  };
};

// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';

  if (['transition/enquire'].includes(txLastTransition(tx))) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateEnquiry',
      }),
    };
  } else if (txIsProjectOfferMade(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateOffer',
      }),
    };
  } else if (txIsRequested(tx)) {
    const requested = isOrder
      ? {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.stateOffer',
          }),
        }
      : {
          nameClassName: css.nameEmphasized,
          bookingClassName: css.bookingActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.stateOffer',
          }),
        };

    return requested;
  } else if (txIsPaymentPending(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (txIsPaymentExpired(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
  } else if (txIsDeclined(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if (txIsAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (txIsProAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (txIsCanceled(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (txIsProjectAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (txIsProjectDeclined(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if (txIsProjectConfirmed(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateFundsReady',
      }),
    };
  } else if (txHasBeenDelivered(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else if (txIsProDone(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else if ([TRANSITION_DECLINE_OFFER, TRANSITION_EXPIRE_OFFER].includes(txLastTransition(tx))) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if (
    ['transition/make-custom-offer', 'transition/make-custom-pro-offer'].includes(
      txLastTransition(tx)
    )
  ) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateOffer',
      }),
    };
  } else if (
    ['transition/job-done', 'transition/job-done-customer', 'transition/job-done-pro'].includes(
      txLastTransition(tx)
    )
  ) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateJobDone',
      }),
    };
  } else if (txIsProReadyAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateJobDone',
      }),
    };
  } else if (
    [
      'transition/accept',
      'transition/confirm-payment',
      'transition/accept-custom-pro-offer',
    ].includes(txLastTransition(tx))
  ) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (
    [
      'transition/review-1-by-provider',
      'transition/review-2-by-provider',
      'transition/review-1-by-customer',
      'transition/review-2-by-customer',
      'transition/expire-customer-review-period',
      'transition/expire-provider-review-period',
      'transition/expire-review-period',
    ].includes(txLastTransition(tx))
  ) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateJobDone',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

// Functional component as internal helper to print BookingTimeInfo if that is needed
const BookingInfoMaybe = props => {
  const { bookingClassName, isOrder, intl, tx, unitType } = props;
  const isEnquiry = txIsEnquired(tx);

  if (isEnquiry) {
    return null;
  }

  // If you want to show the booking price after the booking time on InboxPage you can
  // add the price after the BookingTimeInfo component. You can get the price by uncommenting
  // sthe following lines:

  // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
  // const price = bookingPrice ? formatMoney(intl, bookingPrice) : null;

  // Remember to also add formatMoney function from 'util/currency.js' and add this after BookingTimeInfo:
  // <div className={css.itemPrice}>{price}</div>

  return (
    <div className={classNames(css.bookingInfoWrapper, bookingClassName)}>
      <BookingTimeInfo
        bookingClassName={bookingClassName}
        isOrder={isOrder}
        intl={intl}
        tx={tx}
        unitType={unitType}
        dateType={DATE_TYPE_DATE}
      />
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  tx: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

const InboxItem = props => {
  const { type, tx, intl, stateData, notificationTxIds } = props;
  const { customer, provider } = tx;
  const isOrder = type === 'order';
  const deleted = tx.listing.attributes.deleted;
  const userDeleted = !tx?.customer.attributes.profile.displayName;

  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser?.attributes?.banned;

  const isSaleNotification = notificationTxIds?.includes(tx.id.uuid);
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const lastTransitionedAt = formatDate(intl, tx.attributes.lastTransitionedAt);

  const deletedTitle = intl.formatMessage({
    id: 'InboxPage.deletedListing',
  });
  const userDeletedTitle = intl.formatMessage({
    id: 'InboxPage.deletedUser',
  });

  const listingType = props.tx.listing.attributes.publicData?.listingType;
  const project = listingType === 'project';

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  if (deleted) {
    return (
      <div className={css.item}>
        <NamedLink
          className={linkClasses}
          name={!isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
          params={{ id: tx.id.uuid }}
        >
          <div className={css.itemInfo}>
            <div className={css.itemAvatar}>
              <Avatar disableProfileLink={true} />
            </div>
            <div className={classNames(css.itemUsername)}>{deletedTitle}</div>
          </div>
          <div className={css.itemState}>
            <div className={classNames(css.stateName, stateData.stateClassName)}>
              {!project ? stateData.state : <FormattedMessage id="InboxPage.application" />}
            </div>
          </div>
        </NamedLink>
        <div
          className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
          title={lastTransitionedAt.long}
        >
          {lastTransitionedAt.short}
        </div>
      </div>
    );
  } else if (!deleted && userDeleted) {
    return (
      <div className={css.item}>
        <NamedLink
          className={linkClasses}
          name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
          params={{ id: tx.id.uuid }}
        >
          <div className={css.itemInfo}>
            <div className={css.itemAvatar}>
              <Avatar disableProfileLink={true} />
            </div>
            <div className={classNames(css.itemUsername)}>{userDeletedTitle}</div>
          </div>
          <div className={css.itemState}>
            <div className={classNames(css.stateName, stateData.stateClassName)}>
              {!project ? stateData.state : <FormattedMessage id="InboxPage.application" />}
            </div>
          </div>
        </NamedLink>
        <div
          className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
          title={lastTransitionedAt.long}
        >
          {lastTransitionedAt.short}
        </div>
      </div>
    );
  } else {
    return (
      <div className={css.item}>
        <NamedLink
          className={linkClasses}
          name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
          params={{ id: tx.id.uuid }}
        >
          <div className={css.itemInfo}>
            <div className={css.itemAvatar}>
              <Avatar user={otherUser} disableProfileLink={true} />
            </div>
            <div className={classNames(css.itemUsername)}>{otherUserDisplayName}</div>
            <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
          </div>
          <div className={css.itemState}>
            <div className={classNames(css.stateName, stateData.stateClassName)}>
              {!project ? stateData.state : <FormattedMessage id="InboxPage.application" />}
            </div>
          </div>
          <div className={css.itemTitle}>{tx.attributes.protectedData?.listingTitle}</div>
        </NamedLink>
        <div
          className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
          title={lastTransitionedAt.long}
        >
          {lastTransitionedAt.short}
        </div>
      </div>
    );
  }
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = props => {
  const {
    unitType,
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    currentUserNotificationTxIds,
    scrollingDisabled,
    transactions,
  } = props;
  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  // const validTab = tab === 'orders' || tab === 'sales' || tab === 'enquiries';
  // if (!validTab) {
  //   return <NotFoundPage />;
  // }

  const isOrders =
    tab === 'orders' || tab === 'enquiries' || tab === 'completed' || tab === 'ongoing'; // && userRole === 'customer';

  const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  const title = isOrders ? ordersTitle : salesTitle;
  const isFreelancer =
    currentUser &&
    currentUser.attributes.profile.publicData &&
    ['freelancer', 'light-entrepreneur'].includes(
      currentUser.attributes.profile.publicData.account_role
    );
  const isCustomer =
    currentUser &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.account_role === 'customer';

  const toTxItem = tx => {
    const listingType = tx.listing?.attributes.publicData?.listingType;
    const type =
      !isCustomer && listingType === 'project'
        ? 'order'
        : !isCustomer && listingType === 'service'
        ? 'sale'
        : !isCustomer && listingType === 'profile'
        ? 'sale'
        : isCustomer && listingType === 'service'
        ? 'order'
        : isCustomer && listingType === 'project'
        ? 'sale'
        : isCustomer && listingType === 'profile'
        ? 'order'
        : null;
    const stateData = txState(intl, tx, type);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          notificationTxIds={currentUserNotificationTxIds}
        />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage id={isOrders ? 'InboxPage.noOrdersFound' : 'InboxPage.noSalesFound'} />
      </li>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab ? user.id && tx && tx.length > 0 : user.id && tx && tx.length > 0;
  };
  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);

  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;

  let tabs = [];
  if (isCustomer) {
    tabs = [];
    tabs.push(
      {
        text: (
          <span>
            <MdMessage className={css.icon} />
            <FormattedMessage id="InboxPage.ordersTabTitle" />
          </span>
        ),
        selected: window.location.href.includes('orders'),
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'orders' },
        },
      },
      {
        text: (
          <span>
            <MdPeople className={css.icon} />
            <FormattedMessage id="InboxPage.salesTabEnquiry" />
          </span>
        ),
        selected: window.location.href.includes('enquiries'),
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'enquiries' },
        },
      },
      {
        text: (
          <span>
            <MdRocketLaunch className={css.icon} />
            <FormattedMessage id="InboxPage.salesTabOngoing" />
          </span>
        ),
        selected: window.location.href.includes('ongoing'),
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'ongoing' },
        },
      },
      {
        text: (
          <span>
            <BsCheckCircleFill className={css.icon} />
            <FormattedMessage id="InboxPage.salesTabComplete" />
          </span>
        ),
        selected: window.location.href.includes('completed'),
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'completed' },
        },
      },
      {
        text: (
          <span>
            <BsArrow90DegRight className={css.icon} />
            <FormattedMessage id="InboxPage.applications" />
          </span>
        ),
        linkProps: {
          name: 'ApplicationInboxPage',
          params: { tab: 'applications' },
        },
      }
    );
  }
  if (isFreelancer) {
    tabs.push(
      {
        text: (
          <span>
            <MdMessage className={css.icon} />
            <FormattedMessage id="InboxPage.salesTabTitle" />
          </span>
        ),
        selected: window.location.href.includes('sales'),
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'sales' },
        },
      },
      {
        text: (
          <span>
            <MdPeople className={css.icon} />
            <FormattedMessage id="InboxPage.salesTabEnquiry" />
          </span>
        ),
        selected: window.location.href.includes('saleEnquiries'),
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'saleEnquiries' },
        },
      },
      {
        text: (
          <span>
            <MdRocketLaunch className={css.icon} />
            <FormattedMessage id="InboxPage.salesTabOngoing" />
          </span>
        ),
        selected: window.location.href.includes('saleOngoing'),
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'saleOngoing' },
        },
      },
      {
        text: (
          <span>
            <BsCheckCircleFill className={css.icon} />
            <FormattedMessage id="InboxPage.salesTabComplete" />
          </span>
        ),
        selected: window.location.href.includes('saleCompleted'),
        linkProps: {
          name: 'InboxPage',
          params: { tab: 'saleCompleted' },
        },
      },
      {
        text: (
          <span>
            <BsArrow90DegRight className={css.icon} />
            <FormattedMessage id="InboxPage.applications" />
          </span>
        ),
        linkProps: {
          name: 'ApplicationInboxPage',
        },
      }
    );
  }

  const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

  const visibleTxs = transactions.filter(
    tx => tx.listing?.id.uuid !== process.env.REACT_APP_LE_PROFILE_LISTING_ID
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={css.navigation}>
          <div className={css.navcontainer}>
            <h1 className={css.title}>
              <FormattedMessage id="InboxPage.title" />
              {providerNotificationBadge}
            </h1>
            {nav}
          </div>
        </LayoutWrapperSideNav>

        <LayoutWrapperMain className={css.listings}>
          <div>
            {error}
            <ul className={css.itemList}>
              {!fetchInProgress ? (
                visibleTxs.map(toTxItem)
              ) : (
                <li className={css.listItemsLoading}>
                  <IconSpinner />
                </li>
              )}
              {noResults}
            </ul>
            {pagingLinks}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    fetchProjectOffersInProgress,
    fetchProjectOffersError,
    pagination,
    transactionRefs,
  } = state.InboxPage;
  const {
    currentUser,
    currentUserNotificationCount: providerNotificationCount,
    currentUserNotificationTx,
  } = state.user;
  const transactions = getMarketplaceEntities(state, transactionRefs);
  const currentUserNotificationTxIds = currentUserNotificationTx.map(tx => tx.txId);

  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    fetchProjectOffersInProgress,
    fetchProjectOffersError,
    pagination,
    providerNotificationCount,
    currentUserNotificationTxIds,
    scrollingDisabled: isScrollingDisabled(state),
    transactions,
  };
};

const InboxPage = compose(connect(mapStateToProps), injectIntl)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
