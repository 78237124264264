import React from 'react';

import { MdVerified } from 'react-icons/md';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import css from './SolutionPages.css';

const SolutionPageInfoSection = props => {
  const {
    title,
    imgSrc,
    imageName,
    imageText1,
    imageText2,
    imageText3,
    imageText4,
    cardTitle1,
    cardSubtitle1,
    cardText11,
    cardText12,
    cardTitle2,
    cardSubtitle2,
    cardText2,
    cardText3,
  } = props;
  return (
    <div className={css.infoSection}>
      <div className={css.infoSectionTitle}>{title}</div>
      <div className={css.infoSectionContent}>
        <div className={css.infoSectionContentImageContainer}>
          <div className={css.infoSectionContentImage}>
            <img src={imgSrc} alt={imageName} />
          </div>
          <div className={css.infoSectionContentImageCard}>
            <div className={css.infoSectionContentImageCardTitle}>
              {imageName}
              <MdVerified className={css.infoSectionContentImageCardTitleIcon} />
            </div>
            <div className={css.infoSectionContentImageCardText}>
              <BsFillCheckCircleFill className={css.infoSectionContentImageCardListIcon} />
              {imageText1}
            </div>
            <div className={css.infoSectionContentImageCardText}>
              <BsFillCheckCircleFill className={css.infoSectionContentImageCardListIcon} />
              {imageText2}
            </div>
            <div className={css.infoSectionContentImageCardText}>
              <BsFillCheckCircleFill className={css.infoSectionContentImageCardListIcon} />
              {imageText3}
            </div>
            <div className={css.infoSectionContentImageCardText}>
              <BsFillCheckCircleFill className={css.infoSectionContentImageCardListIcon} />
              {imageText4}
            </div>
          </div>
        </div>
        <div className={css.infoSectionContentTextCard}>
          <div className={css.infoSectionContentTextCardContainer}>
            <div className={css.infoSectionContentTextCardTitle}>{cardTitle1}</div>
            <div className={css.infoSectionContentTextCardSubtitle}>{cardSubtitle1}</div>
            <div className={css.infoSectionContentTextCardText}>{cardText11}</div>
            <div className={css.infoSectionContentTextCardText}>{cardText12}</div>
          </div>
          <div className={css.infoSectionContentTextCardContainer}>
            <div className={css.infoSectionContentTextCardTitle}>{cardTitle2}</div>
            <div className={css.infoSectionContentTextCardSubtitle}>{cardSubtitle2}</div>
            <div className={css.infoSectionContentTextCardText}>{cardText2}</div>
            {cardText3 && <div className={css.infoSectionContentTextCardText}>{cardText3}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionPageInfoSection;
