import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button, VerifiedUserMark } from '../../components';

import css from './BookingPanel.css';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    contactOnly,
    showContactUser,
    onContactUser,
    onSubmit,
    title,
    subTitle,
    authorDisplayName,
    provider,
    onManageDisableScrolling,
    history,
    location,
    intl,
    currentUser,
    projectBidButtonText,
    updateFavoriteListings,
    favoriteListings,
  } = props;

  const hasListingState = !!listing.attributes.state;
  const { listingType = '' } = listing.attributes.publicData || {};
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const isProject = listingType === 'project';
  const showBookingPackagesForm = hasListingState && !isClosed && !contactOnly && !isProject;

  const userPublicData =
    (currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile &&
      currentUser.attributes.profile.publicData) ||
    {};
  const { account_role = '' } = userPublicData;
  const isProvider = ['freelancer', 'light-entrepreneur'].includes(account_role);
  const showContactButton =
    hasListingState && !isClosed && contactOnly && showContactUser && !isProject && !isProvider;
  const showProjectBidButton = hasListingState && !isClosed && isProject && isProvider;
  // const showModalHeading = !isProvider;

  const isTransactionPanel =
    location.pathname.includes('sale') || location.pathname.includes('order');

  const showClosedListingHelpText = listing.id && isClosed;
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const contactHelpSection =
    listingType === 'service' ? (
      <>
        <div className={css.contactHelp}>
          <FormattedMessage id="BookingPanel.contactHelpLine1" />
        </div>
        <div className={css.contactHelp}>
          <FormattedMessage id="BookingPanel.contactHelpLine2" />
        </div>
        <div className={css.contactHelp}>
          <FormattedMessage id="BookingPanel.contactHelpLine3" />
        </div>
      </>
    ) : listingType === 'project' ? (
      <>
        <div className={css.contactHelp}>
          <FormattedMessage id="BookingPanel.projectBidHelpLine" />
        </div>
      </>
    ) : null;

  const isFavoriteListing =
    favoriteListings && favoriteListings.some(x => x.uuid === listing.id.uuid);

  const handleAddRemoveFavorite = () => {
    // If not logged in redirect to signup.
    if (!currentUser) {
      const openModal = false;
      onContactUser(openModal);
    } else {
      const favoriteListingsUuids = favoriteListings
        .map(listing => listing.uuid)
        .filter(favoriteListing => favoriteListing !== listing.id.uuid);

      if (isFavoriteListing) {
        updateFavoriteListings(favoriteListingsUuids);
      } else {
        updateFavoriteListings([...favoriteListingsUuids, listing.id.uuid]);
      }
    }
  };

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        isTransactionPanel={isTransactionPanel}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
            <VerifiedUserMark user={provider} />
          </div>
        </div>
        <div className={css.bookingHeading}>
          <h2 className={titleClasses}>{title}</h2>
          {subTitleText && !isProvider ? (
            <div className={css.bookingHelp}>{subTitleText}</div>
          ) : null}
        </div>

        {/* {showBookingPackagesForm ? (
          <BookingPackagesForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listing={listing}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            authorStripeConnected={authorStripeConnected}
          />
        ) : null} */}
        {showProjectBidButton ? (
          <Button rootClassName={css.bookButton} onClick={onSubmit}>
            {projectBidButtonText}
          </Button>
        ) : null}
        {showContactButton ? (
          <div className={css.contactHelpBlock}>
            {contactHelpSection}
            <div className={css.ctaButtons}>
              <Button rootClassName={css.bookButton} onClick={onContactUser}>
                <FormattedMessage id="BookingPanel.contactButtonMessage" />
              </Button>
              <Button className={css.favoriteButton} onClick={() => handleAddRemoveFavorite()}>
                {isFavoriteListing ? <FaHeart /> : <FaRegHeart />}
              </Button>
            </div>
          </div>
        ) : !isTransactionPanel &&
          !showContactUser &&
          !showBookingPackagesForm &&
          listingType === 'service' ? (
          <div className={css.contactHelp}>
            <FormattedMessage id="ProjectListingPage.bookingSubTitleNoBidsCustomer" />
          </div>
        ) : !isTransactionPanel && isProvider && listingType === 'service' ? (
          <div className={css.contactHelp}>
            <FormattedMessage id="ProjectListingPage.bookingSubTitleNoBidsCustomer" />
          </div>
        ) : null}
      </ModalInMobile>
      {showProjectBidButton || showContactButton ? (
        <div className={css.openBookingForm}>
          {/* {showBookingPackagesForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null} */}

          {showProjectBidButton ? (
            <Button rootClassName={css.bookButton} onClick={onSubmit}>
              <FormattedMessage id="BookingPanel.ctaButtonProjectBid" />
            </Button>
          ) : null}

          {showContactButton ? (
            <div className={css.ctaButtons}>
              <Button rootClassName={css.bookButton} onClick={() => onContactUser(true)}>
                <FormattedMessage id="BookingPanel.contactButtonMessage" />
              </Button>
              <Button className={css.favoriteButton} onClick={() => handleAddRemoveFavorite()}>
                {isFavoriteListing ? <FaHeart /> : <FaRegHeart />}
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  updateFavoriteListings: func,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
