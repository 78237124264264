import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import config from '../../config';
import { injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withViewport } from '../../util/contextHelpers';

import { FormattedMessage } from '../../util/reactIntl';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';
import heroImage from '../../assets/about-us-hero-image.webp';
import quoteImage from '../../assets/about-us-quote-image.webp';
import css from './AboutUsPage.css';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

const AboutUsPageComponent = props => {
  const { scrollingDisabled, intl, viewport } = props;

  const schemaTitle = intl.formatMessage({ id: 'AboutUsPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({ id: 'AboutUsPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const heroBreadCrumbs = intl.formatMessage({ id: 'AboutUsPage.heroBreadcrumbs' });
  const heroTitle = intl.formatMessage({ id: 'AboutUsPage.heroTitle' });
  const heroSubtitle = intl.formatMessage({ id: 'AboutUsPage.heroSubtitle' });

  const MAX_MOBILE_SCREEN_WIDTH = 962;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AboutUsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <div className={css.heroLeftBlock}>
              <div className={css.heroBreadCrumbs}>{heroBreadCrumbs}</div>
              {viewport.width <= MAX_MOBILE_SCREEN_WIDTH ? (
                <div className={css.heroSectionMobile}>
                  <img
                    src={heroImage}
                    alt="About Us Hero"
                    className={css.proInfoSectionContentImage}
                    loading="lazy"
                  />
                </div>
              ) : null}
              <div className={css.heroMainTitle}>{heroTitle}</div>
              <div className={css.heroSubtitle}>{heroSubtitle}</div>
            </div>
            {viewport.width > MAX_MOBILE_SCREEN_WIDTH ? (
              <div className={css.heroRightBlock}>
                <img src={heroImage} alt="About Us Hero" className={css.heroImage} />
              </div>
            ) : null}
          </div>
          <div className={css.sections}>
            <header>
              <h2 className={css.sectionTitle}>
                <FormattedMessage id="AboutUsPage.contentTitle1" />
              </h2>
            </header>
            <article>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText11" />
              </p>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText12" />
              </p>
            </article>
            <header>
              <h2 className={css.sectionTitle}>
                <FormattedMessage id="AboutUsPage.contentTitle2" />
              </h2>
            </header>
            <article>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText21" />
              </p>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText22" />
              </p>
            </article>
            <header>
              <h2 className={css.sectionTitle}>
                <FormattedMessage id="AboutUsPage.contentTitle3" />
              </h2>
            </header>
            <article>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText31" />
              </p>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText32" />
              </p>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText33" />
              </p>
            </article>
            <div className={css.articleBlockWithBG}>
              <div className={css.articleQuote}>
                <FormattedMessage id="AboutUsPage.articleQuote" />
              </div>
              <div className={css.articleFromBlock}>
                <div className={css.quoteImage}>
                  <img src={quoteImage} alt="About Us Quote" />
                </div>
                <div className={css.quoteFrom}>
                  <FormattedMessage id="AboutUsPage.articleQuoteName" />
                  <FormattedMessage id="AboutUsPage.articleQuoteCompany" />
                </div>
              </div>
            </div>
            <header>
              <h2 className={css.sectionTitle}>
                <FormattedMessage id="AboutUsPage.contentTitle4" />
              </h2>
            </header>
            <article>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText41" />
              </p>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText42" />
              </p>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText43" />
              </p>
              <p className={css.sectionContent}>
                <FormattedMessage id="AboutUsPage.contentText44" />
              </p>
            </article>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return { scrollingDisabled: isScrollingDisabled(state), currentUser };
};

const AboutUsPage = compose(
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(AboutUsPageComponent);

export default AboutUsPage;
