import React from 'react';

import css from './SolutionPages.css';

import { BsFillCheckCircleFill } from 'react-icons/bs';

const SolutionPageProblemsSection = props => {
  const {
    title,
    cardTitle1,
    cardText1,
    cardTitle2,
    cardText2,
    cardTitle3,
    cardText3,
    cardTitle4,
    cardText4,
  } = props;

  const Card = props => {
    const { title, text } = props;
    return (
      <div className={css.problemsContentCard}>
        <div className={css.problemsContentCardTitleContainer}>
          <BsFillCheckCircleFill className={css.problemsContentCardIcon} />
          <div className={css.problemsContentCardTitle}>{title}</div>
        </div>
        <div className={css.problemsContentCardText}>{text}</div>
      </div>
    );
  };

  return (
    <div className={css.problemsSection}>
      <div className={css.problemsTitle}>{title}</div>
      <div className={css.problemsContent}>
        <Card title={cardTitle1} text={cardText1} />
        <Card title={cardTitle2} text={cardText2} />
        <Card title={cardTitle3} text={cardText3} />
        <Card title={cardTitle4} text={cardText4} />
      </div>
    </div>
  );
};

export default SolutionPageProblemsSection;
