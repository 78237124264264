import axios from 'axios';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { apiBaseUrl } from '../../util/api';

// ================ Action types ================ //

export const UPDATE_SUBSCRIPTION_STATE_REQUEST = 'app/SubscriptionPage/UPDATE_SUBSCRIPTION_STATE_REQUEST';
export const UPDATE_SUBSCRIPTION_STATE_SUCCESS = 'app/SubscriptionPage/UPDATE_SUBSCRIPTION_STATE_SUCCESS';
export const UPDATE_SUBSCRIPTION_STATE_ERROR = 'app/SubscriptionPage/UPDATE_SUBSCRIPTION_STATE_ERROR';
export const CANCEL_STRIPE_SUBSCRIPTION_REQUEST = 'app/SubscriptionPage/CANCEL_STRIPE_SUBSCRIPTION_REQUEST';
export const CANCEL_STRIPE_SUBSCRIPTION_SUCCESS = 'app/SubscriptionPage/CANCEL_STRIPE_SUBSCRIPTION_SUCCESS';
export const CANCEL_STRIPE_SUBSCRIPTION_ERROR = 'app/SubscriptionPage/CANCEL_STRIPE_SUBSCRIPTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateSubscriptionStateInProgress: false,
  updateSubscriptionStateError: null,
  stripeSubscriptionError: null,
  stripeSubscriptionInProgress: false,
};

export default function subscriptionPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_SUBSCRIPTION_STATE_REQUEST:
      return {
        ...state,
        updateSubscriptionStateInProgress: true,
        updateSubscriptionStateError: null
      };
    case UPDATE_SUBSCRIPTION_STATE_SUCCESS:
      return {
        ...state,
        updateSubscriptionStateInProgress: false,
        updateSubscriptionStateError: null,
      };
    case UPDATE_SUBSCRIPTION_STATE_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        updateSubscriptionStateInProgress: false,
        updateSubscriptionStateError: null
      };

    case CANCEL_STRIPE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        stripeSubscriptionInProgress: true,
        stripeSubscriptionError: null,
      };
    case CANCEL_STRIPE_SUBSCRIPTION_SUCCESS:
      return { ...state, stripeSubscriptionInProgress: false };
    case CANCEL_STRIPE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        stripeSubscriptionInProgress: false,
        stripeSubscriptionError: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const updateSubscriptionStateRequest = () => ({ type: UPDATE_SUBSCRIPTION_STATE_REQUEST });
export const updateSubscriptionStateSuccess = () => ({ type: UPDATE_SUBSCRIPTION_STATE_SUCCESS });
export const updateSubscriptionStateError = e => ({
  type: UPDATE_SUBSCRIPTION_STATE_ERROR,
  error: true,
  payload: e,
});

export const cancelStripeSubscriptionRequest = () => ({ type: CANCEL_STRIPE_SUBSCRIPTION_REQUEST });
export const cancelStripeSubscriptionSuccess = () => ({ type: CANCEL_STRIPE_SUBSCRIPTION_SUCCESS });
export const cancelStripeSubscriptionError = error => ({
  type: CANCEL_STRIPE_SUBSCRIPTION_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const updateSubscriptionState = () => (dispatch, getState, sdk) => {
  dispatch(updateSubscriptionStateRequest());

  const subscriptionDate = new Date();

  return sdk.currentUser.updateProfile({
      publicData: {
        subscriptionDate: {
          year: subscriptionDate.getFullYear(),
          month: subscriptionDate.getMonth() + 1,
          day: subscriptionDate.getDate(),
          hours: subscriptionDate.getHours(),
          minutes: subscriptionDate.getMinutes(),
          seconds: subscriptionDate.getSeconds(),
          milliseconds: subscriptionDate.getMilliseconds()
        }
      },
      privateData: { subscriptionStateKey: process.env.REACT_APP_SUBSCRIPTION_STATE_KEY },
    })
    .then(response => {
      dispatch(fetchCurrentUser());
      dispatch(updateSubscriptionStateSuccess());
      return response;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'update-subscription-state-failed');
      dispatch(updateSubscriptionStateError(error));
      return e;
    });
};

export const cancelStripeSubscription = userId => (dispatch, getState, sdk) => {
  dispatch(cancelStripeSubscriptionRequest());

  return axios({
    url: `${apiBaseUrl()}/api/cancel-stripe-subscription`,
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    data: JSON.stringify({ userId }),
  })
    .then(() => {
      dispatch(cancelStripeSubscriptionSuccess());

      return dispatch(fetchCurrentUser());
    })
    .catch(error => {
      console.error('Error cancelling existing Stripe subscription : ' + error.message);
      dispatch(cancelStripeSubscriptionError(storableError(error)));
    });
};

export const loadData = () => (dispatch) => {
  return dispatch(fetchCurrentUser());
};
