import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities, getListingsById } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import config from '../../config';

// ================ Action types ================ //

export const SEARCH_PROJECTS_REQUEST = 'app/ProjectSearchPage/SEARCH_PROJECTS_REQUEST';
export const SEARCH_PROJECTS_SUCCESS = 'app/ProjectSearchPage/SEARCH_PROJECTS_SUCCESS';
export const SEARCH_PROJECTS_ERROR = 'app/ProjectSearchPage/SEARCH_PROJECTS_ERROR';

export const SEARCH_MAP_PROJECTS_REQUEST = 'app/ProjectSearchPage/SEARCH_MAP_PROJECTS_REQUEST';
export const SEARCH_MAP_PROJECTS_SUCCESS = 'app/ProjectSearchPage/SEARCH_MAP_PROJECTS_SUCCESS';
export const SEARCH_MAP_PROJECTS_ERROR = 'app/ProjectSearchPage/SEARCH_MAP_PROJECTS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_PROJECT = 'app/ProjectSearchPage/SEARCH_MAP_SET_ACTIVE_PROJECT';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
};

export const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_PROJECTS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_PROJECTS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_PROJECTS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_PROJECTS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_PROJECTS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_PROJECTS_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_PROJECT:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_PROJECTS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_PROJECTS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_PROJECTS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_PROJECTS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_PROJECTS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_PROJECTS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
          start: formatDateStringToUTC(startDate),
          end: formatDateStringToUTC(endDate),
          // Availability can be full or partial. Default value is full.
          availability: 'full',
        }
      : {};
  };

  const { perPage, price, dates, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  if (rest['pub_categoryNodes']) rest['pub_categoryNodes'] = 'has_any:' + rest['pub_categoryNodes'];

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    per_page: perPage,
    pub_listingType: 'project',
    pub_hidden: false,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .then(response => {
      const pageListings = getListingsById(getState(), getState().SearchPage.currentPageResultIds);

      if (typeof window === 'object') {
        window.dataLayer.push({
          event: 'view_item_list',
          ecommerce: {
            items: pageListings.map((listing, index) => {
              return {
                item_name: listing.attributes.title,
                item_id: listing.id.uuid,
                price: '' + listing.attributes.price.amount / 100,
                item_brand: listing.author.attributes.profile.displayName,
                item_category: listing.attributes.publicData.category,
                item_list_name: 'search_results',
                item_list_id: '' + params.page || '1',
                index: index + 1,
                quantity: '1',
              };
            }),
          },
        });
      }

      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_PROJECT,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  if (rest['pub_categoryNodes']) rest['pub_categoryNodes'] = 'has_any:' + rest['pub_categoryNodes'];

  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};
