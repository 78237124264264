import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink, VerifiedUserMark, Avatar } from '../../components';

import css from './TransactionPanel.css';

export const HEADING_ENQUIRED = 'enquired';
export const HEADING_PAYMENT_PENDING = 'pending-payment';
export const HEADING_PAYMENT_EXPIRED = 'payment-expired';
export const HEADING_REQUESTED = 'requested';
export const HEADING_ACCEPTED = 'accepted';
export const HEADING_DECLINED = 'declined';
export const HEADING_CANCELED = 'canceled';
export const HEADING_PROJECT_CONFIRMED = 'project-confirmed';
export const HEADING_DELIVERED = 'delivered';
export const HEADING_OFFER_MADE = 'offer-made';
export const HEADING_OFFER_ACCEPTED = 'offer-accepted';
export const HEADING_OFFER_DECLINED = 'offer-declined';
export const HEADING_JOB_DONE = 'job-done';
export const HEADING_JOB_DONE_CUSTOMER = 'job-done-customer';
export const HEADING_JOB_ACCEPTED = 'job-accepted';

const createListingLink = (
  listingId,
  label,
  listingDeleted,
  listingType,
  searchParams = {},
  className = ''
) => {
  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return listingType === 'project' ? (
      <NamedLink className={className} name="ProjectListingPage" params={params} to={to}>
        {label}
      </NamedLink>
    ) : (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        {label}
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const ListingDeletedInfoMaybe = props => {
  return props.listingDeleted ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.messageDeletedListing" />
    </p>
  ) : null;
};

const HeadingCustomer = props => {
  const { id, idSecond, listingDeleted, nameWith, currentProvider } = props;
  return (
    <div className={css.headingContainer}>
      <div>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} />
          {idSecond ? <FormattedMessage id={idSecond} /> : null}
        </span>
      </div>
      <div className={css.headingContainerWith}>
        <NamedLink className={css.link} name="ProfilePage" params={{ id: currentProvider.id.uuid }}>
          <FormattedMessage id="TransactionPanel.conversationWith" values={{ name: nameWith }} />
        </NamedLink>
        <VerifiedUserMark user={currentProvider} />
        <Avatar user={currentProvider} className={css.avatar} />
      </div>
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </div>
  );
};

const HeadingCustomerWithSubtitle = props => {
  const { className, id, state, subtitleId, subtitleValues, children, listingDeleted } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} state={state} />
        </span>
        <FormattedMessage id={subtitleId} values={subtitleValues} />
      </h1>
      {children}
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const HeadingProvider = props => {
  const { id, idSecond, nameWith, currentCustomer, values, openCustomOfferModalButton } = props;
  return (
    <div className={css.headingContainer}>
      <div>
        {/* <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
          {idSecond ? <FormattedMessage id={idSecond} /> : null}
        </span> */}
        {openCustomOfferModalButton}
      </div>
      <div className={css.headingContainerWith}>
        <NamedLink className={css.link} name="ProfilePage" params={{ id: currentCustomer.id.uuid }}>
          <FormattedMessage id="TransactionPanel.conversationWith" values={{ name: nameWith }} />
        </NamedLink>
        <VerifiedUserMark user={currentCustomer} />
        <Avatar user={currentCustomer} className={css.avatar} />
      </div>
    </div>
  );
};

// Functional component as a helper to choose and show Order or Sale heading info:
// title, subtitle, and message
const PanelHeading = props => {
  const {
    className,
    rootClassName,
    panelHeadingState,
    customerName,
    providerName,
    provider,
    listingId,
    listingTitle,
    listingDeleted,
    listingType,
    isCustomerBanned,
    projectTransaction,
    currentCustomer,
    currentProvider,
    openCustomOfferModalButton,
  } = props;

  const isCustomer = props.transactionRole === 'customer';

  const defaultRootClassName = isCustomer ? css.headingOrder : css.headingSale;
  const titleClasses = classNames(rootClassName || defaultRootClassName, className);
  const listingLink = projectTransaction
    ? createListingLink(
        projectTransaction.listing.id.uuid,
        projectTransaction.listing.attributes.title,
        projectTransaction.listing.attributes.deleted,
        projectTransaction.listing.attributes.publicData.listingType
      )
    : createListingLink(listingId, listingTitle, listingDeleted, listingType);

  switch (panelHeadingState) {
    case HEADING_ENQUIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleEnquired"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleEnquired"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
          openCustomOfferModalButton={openCustomOfferModalButton}
        />
      );
    case HEADING_PAYMENT_PENDING:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitlePaymentPending"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitlePaymentPending"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
        />
      );
    case HEADING_PAYMENT_EXPIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitlePaymentExpired"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitlePaymentExpired"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
        />
      );
    case HEADING_REQUESTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          state={{ customerName }}
          subtitleId="TransactionPanel.conversationStateTitle"
          subtitleValues={{ listingLink }}
        >
          {!listingDeleted ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.orderPreauthorizedInfo"
                values={{ providerName }}
              />
              <VerifiedUserMark user={provider} />
            </p>
          ) : null}
        </HeadingCustomerWithSubtitle>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleRequestedTitle"
          values={{ customerName, listingLink }}
          openCustomOfferModalButton={openCustomOfferModalButton}
        >
          {!isCustomerBanned ? (
            <p className={titleClasses}>
              <FormattedMessage id="TransactionPanel.saleRequestedInfo" values={{ customerName }} />
            </p>
          ) : null}
        </HeadingProvider>
      );
    case HEADING_ACCEPTED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleAccepted"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleAccepted"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
        />
      );
    case HEADING_DECLINED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleDeclined"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleDeclined"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
          openCustomOfferModalButton={openCustomOfferModalButton}
        />
      );
    case HEADING_CANCELED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleCanceled"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleCanceled"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
          openCustomOfferModalButton={openCustomOfferModalButton}
        />
      );
    case HEADING_JOB_DONE:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleJobReady"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleJobReady"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
        />
      );
    case HEADING_JOB_DONE_CUSTOMER:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.jobDoneOwn"
          isCustomerBanned={isCustomerBanned}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.jobDoneMarker"
          values={{ markerName: customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
          currentCustomer={currentCustomer}
        />
      );
    case HEADING_JOB_ACCEPTED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleJobAccepted"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleJobAccepted"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
        />
      );
    case HEADING_PROJECT_CONFIRMED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleJobReady"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleJobReady"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
        />
      );
    case HEADING_DELIVERED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleFundsReleased"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleFundsReleased"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
        />
      );
    case HEADING_OFFER_MADE:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleOfferMade"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleOfferMade"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
          openCustomOfferModalButton={openCustomOfferModalButton}
        />
      );
    case HEADING_OFFER_ACCEPTED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleAccepted"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleAccepted"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
        />
      );
    case HEADING_OFFER_DECLINED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleDeclined"
          listingDeleted={listingDeleted}
          nameWith={providerName}
          currentProvider={currentProvider}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.conversationStateTitle"
          idSecond="TransactionPanel.conversationStateTitleDeclined"
          isCustomerBanned={isCustomerBanned}
          nameWith={customerName}
          currentCustomer={currentCustomer}
          openCustomOfferModalButton={openCustomOfferModalButton}
        />
      );

    default:
      console.warn('Unknown state given to panel heading.');
      return null;
  }
};

export default PanelHeading;
