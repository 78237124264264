import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  ListingCard,
  Page,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import config from '../../config';
import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';

import css from './FavoritesPage.css';

const siteTitle = config.siteTitle;

const FavoritesPageComponent = props => {
  const { favoriteListings, intl } = props;

  const schemaTitle = intl.formatMessage({ id: 'FavoriteListingsPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'FavoriteListingsPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      contentType="website"
      title={schemaTitle}
      description={schemaDescription}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="FavoritesPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.listingsContainer}>
            <h2>
              <FormattedMessage id="FavoriteListingsPage.favoriteListingsHeading" />
            </h2>
            <div className={css.listingCards}>
              {favoriteListings &&
                favoriteListings.map(listing => (
                  <ListingCard
                    className={css.listingCard}
                    key={listing.id.uuid}
                    listing={listing}
                  />
                ))}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { favoriteListingsIds } = state.user;

  const listings = getListingsById(state, favoriteListingsIds);
  return {
    favoriteListings: listings,
  };
};

const FavoritesPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(FavoritesPageComponent);

FavoritesPage.loadData = {};

export default FavoritesPage;
