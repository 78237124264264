import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldSelect, UnsavedInfoPrompt } from '../../components';
import ReactTooltip from 'react-tooltip';
import { required } from '../../util/validators';

import css from './EditProjectListingRequirementsForm.css';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

const EditProjectListingRequirementsFormComponent = props => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          values,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          categories,
          dirty,
        } = formRenderProps;
        const onCategoryChange = selectedOption => {
          setSelectedCategory(selectedOption.value);
          values.category = selectedCategory;
          values.category = selectedOption.value;
        };

        const projectTierTitle = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTierTitle',
        });

        const categoryInfoText = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.categoryInfoText',
        });

        const projectTier1 = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTier1',
        });

        const projectTier2 = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTier2',
        });

        const projectTier3 = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTier3',
        });

        const projectTier35 = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTier35',
        });

        const projectTier4 = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTier4',
        });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingBudgetForm.updateFailed" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingBudgetForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}
            {typeof window === 'object' ? <ReactTooltip /> : null}
            <CustomCategorySelectFieldMaybe
              id="category"
              name="category"
              categories={categories}
              selectedValue={values.category}
              onChange={onCategoryChange}
              intl={intl}
            />
            <div className={css.categoryInfoText}>{categoryInfoText}</div>
            <label htmlFor="projectTier" className={css.projectTierTitle}>
              {projectTierTitle}
            </label>
            <FieldSelect
              id="projectTier"
              name="projectTier"
              className={css.projectTier}
              validate={required(projectTierTitle)}
            >
              <option value="">{projectTierTitle}</option>
              <option value="all">{projectTier1}</option>
              <option value="entry">{projectTier2}</option>
              <option value="junior">{projectTier3}</option>
              <option value="midlevel">{projectTier35}</option>
              <option value="senior">{projectTier4}</option>
            </FieldSelect>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
            <UnsavedInfoPrompt when={dirty} />
          </Form>
        );
      }}
    />
  );
};

EditProjectListingRequirementsFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditProjectListingRequirementsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditProjectListingRequirementsFormComponent);
