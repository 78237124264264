// ================ Reducer ================ //

const initialState = {
  pagination: null,
};

const categoryKeywordAutocompleteInputImplReducer = (state = initialState, action = {}) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

export default categoryKeywordAutocompleteInputImplReducer;

export const findKeywordPredictions = keywords => (dispatch, getState, sdk) => {
  const params = {
    keywords,
    per_page: 10,
  };

  return sdk.listings.query(params)
    .then( results => {
      const listings = results.data.data;
      const predictions = [];

      if( Array.isArray(listings) && listings.length > 0 ){
        listings.forEach( listing => {
          predictions.push({
            id: listing.id.uuid,
            key: listing.id.uuid,
            label: listing.attributes.title,
            isCategory: false,
            category: listing.attributes.publicData.category,
          });
        })
      }

      return { search: keywords, predictions };
    });
};
