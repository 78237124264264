import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { required } from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldBirthdayInput,
  ExternalLink,
  LocationAutocompleteInputField,
} from '../../components';

import css from './TaxInformationForm.css';

class TaxInformationFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.emailSentTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            values,
            initialValues,
            taxInformationChanged,
            taxInformationSuccess,
            taxInformationError,
            pristine,
          } = fieldRenderProps;

          const accountRole = currentUser.attributes.profile.publicData.account_role;

          const isCompanyAbroad = currentUser.attributes.profile.privateData?.isCompanyAbroad;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          // VAT number

          const vatNumberOptionalPlaceholder = intl.formatMessage({
            id: 'SignupForm.vatNumberOptionalPlaceholder',
          });

          const vatNumberRequiredPlaceholder = intl.formatMessage({
            id: 'SignupForm.vatNumberRequiredPlaceholder',
          });

          const vatNumberRequiredMessage = intl.formatMessage({
            id: 'SignupForm.vatNumberRequired',
          });

          const informationRequired = intl.formatMessage({
            id: 'SignupProviderForms.informationRequired',
          });

          const identity = v => v;

          const tinLabel = intl.formatMessage({
            id: 'SignupProviderForms.tinLabel',
          });

          const tinCountryLabel = intl.formatMessage({
            id: 'SignupProviderForms.tinCountryLabel',
          });

          const businessRegistrationNumberLabel = intl.formatMessage({
            id: 'SignupProviderForms.businessRegistrationNumberLabel',
          });

          const businessRegistrationCountryOfIssueLabel = intl.formatMessage({
            id: 'SignupProviderForms.businessRegistrationCountryOfIssueLabel',
          });

          const vatNumberLabel = intl.formatMessage({
            id: 'SignupForm.vatNumberLabel',
          });

          const companyLabel = intl.formatMessage({
            id: 'SignupProviderForms.companyLabel',
          });

          const addressLabel = intl.formatMessage({
            id: 'SignupProviderForms.addressLabel',
          });

          const cityLabel = intl.formatMessage({
            id: 'SignupProviderForms.cityLabel',
          });

          const postalCodeLabel = intl.formatMessage({
            id: 'SignupProviderForms.postalCodeLabel',
          });

          const vatNumberRequired = validators.required(vatNumberRequiredMessage);

          const vatNumberOptional = validators.optional();

          const birthdateLabel = intl.formatMessage({
            id: 'SignupProviderForms.birthdateLabel',
          });

          const birthLocationLabel = intl.formatMessage({
            id: 'SignupProviderForms.birthLocationLabel',
          });

          const currentAddressInformationLabel = intl.formatMessage({
            id: 'SignupProviderForms.currentAddressInformationLabel',
          });

          const taxInformationInfo = (
            <FormattedMessage
              id="SignupForm.taxInformationInfo"
              values={{
                a: chunks => (
                  <ExternalLink
                    key="linkToInstagram"
                    href="https://www.vero.fi/syventavat-vero-ohjeet/ohje-hakusivu/209369/raportoivan-alustaoperaattorin-tiedonantovelvollisuus-dac7/"
                  >
                    {chunks}
                  </ExternalLink>
                ),
              }}
            />
          );

          const initiaDateOfBirth = () => {
            if (values.dateOfBirth) {
              const date = new Date(
                values.dateOfBirth.year,
                values.dateOfBirth.month,
                values.dateOfBirth.day
              );
              return date;
            }
            return null;
          };

          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || inProgress || pristine;

          const taxInformationElement = () => {
            if (accountRole === 'freelancer') {
              return (
                <div>
                  <p className={css.taxInformationDescription}>{taxInformationInfo}</p>
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.companyName` : 'companyName'}
                    name="companyName"
                    label={companyLabel}
                    placeholder={companyLabel}
                    validate={required(informationRequired)}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.vat_number` : 'vat_number'}
                    name="vat_number"
                    label={vatNumberLabel}
                    placeholder={vatNumberRequiredPlaceholder}
                    validate={vatNumberRequired}
                    key={values.accountRole ? 'freelancer' : 'customer'}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.address` : 'address'}
                    name="address"
                    label={addressLabel}
                    placeholder={addressLabel}
                    validate={required(informationRequired)}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.postalCode` : 'postalCode'}
                    name="postalCode"
                    label={postalCodeLabel}
                    placeholder={postalCodeLabel}
                    validate={required(informationRequired)}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.city` : 'city'}
                    name="city"
                    label={cityLabel}
                    placeholder={cityLabel}
                    validate={required(informationRequired)}
                  />
                </div>
              );
            } else if (accountRole === 'light-entrepreneur' && !isCompanyAbroad) {
              return (
                <div>
                  <p className={css.taxInformationDescription}>{taxInformationInfo}</p>
                  <LocationAutocompleteInputField
                    // className={css.vat}
                    labelClassName={css.label}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    name="birthLocation"
                    label={birthLocationLabel}
                    placeholder={birthLocationLabel}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.location}
                    validate={required(informationRequired)}
                    predictionType="place"
                  />
                  <FieldBirthdayInput
                    className={css.birthday}
                    type="text"
                    id={formId ? `${formId}.dateOfBirth` : 'dateOfBirth'}
                    name="dateOfBirth"
                    label={birthdateLabel}
                    placeholder={birthdateLabel}
                    validate={required(informationRequired)}
                    valueFromForm={initiaDateOfBirth()}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.address` : 'address'}
                    name="address"
                    label={currentAddressInformationLabel}
                    placeholder={addressLabel}
                    validate={required(informationRequired)}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.postalCode` : 'postalCode'}
                    name="postalCode"
                    // label={postalCodeLabel}
                    placeholder={postalCodeLabel}
                    validate={required(informationRequired)}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.city` : 'city'}
                    name="city"
                    // label={cityLabel}
                    placeholder={cityLabel}
                    validate={required(informationRequired)}
                  />
                </div>
              );
            } else if (accountRole === 'light-entrepreneur' && isCompanyAbroad) {
              return (
                <div>
                  <p className={css.taxInformationDescription}>{taxInformationInfo}</p>
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.companyName` : 'companyName'}
                    name="companyName"
                    label={companyLabel}
                    placeholder={companyLabel}
                    validate={required(informationRequired)}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.taxIdentificationNumber` : 'taxIdentificationNumber'}
                    name="taxIdentificationNumber"
                    label={tinLabel}
                    placeholder={tinLabel}
                    validate={required(informationRequired)}
                  />
                  <LocationAutocompleteInputField
                    // className={css.vat}
                    labelClassName={css.label}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    name="tinCountry"
                    label={tinCountryLabel}
                    placeholder={tinCountryLabel}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.location}
                    validate={required(informationRequired)}
                    // key={values.accountRole ? 'company-abroad' : 'freelancer'}
                    predictionType="country"
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={
                      formId ? `${formId}.businessRegistrationNumber` : 'businessRegistrationNumber'
                    }
                    name="businessRegistrationNumber"
                    label={businessRegistrationNumberLabel}
                    placeholder={businessRegistrationNumberLabel}
                    validate={required(informationRequired)}
                  />
                  <LocationAutocompleteInputField
                    // className={css.vat}
                    labelClassName={css.label}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    name="businessRegistrationCountryOfIssue"
                    label={businessRegistrationCountryOfIssueLabel}
                    placeholder={businessRegistrationCountryOfIssueLabel}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.location}
                    validate={required(informationRequired)}
                    // key={values.accountRole ? 'company-abroad' : 'freelancer'}
                    predictionType="country"
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.vat_number` : 'vat_number'}
                    name="vat_number"
                    label={vatNumberLabel}
                    placeholder={vatNumberOptionalPlaceholder}
                    validate={vatNumberOptional}
                    key={values.accountRole ? 'company-abroad' : 'freelancer'}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.address` : 'address'}
                    name="address"
                    label={addressLabel}
                    placeholder={addressLabel}
                    validate={required(informationRequired)}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.postalCode` : 'postalCode'}
                    name="postalCode"
                    label={postalCodeLabel}
                    placeholder={postalCodeLabel}
                    validate={required(informationRequired)}
                  />
                  <FieldTextInput
                    className={css.vat}
                    type="text"
                    id={formId ? `${formId}.city` : 'city'}
                    name="city"
                    label={cityLabel}
                    placeholder={cityLabel}
                    validate={required(informationRequired)}
                  />
                </div>
              );
            } else {
              return null;
            }
          };

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.contactDetailsSection}>{taxInformationElement()}</div>
              {taxInformationSuccess ? (
                <p className={css.successMessage}>
                  <FormattedMessage id="TaxInformationForm.saveSuccessMessage" />
                </p>
              ) : null}
              {taxInformationError ? (
                <p className={css.errorMessage}>
                  <FormattedMessage id="TaxInformationForm.saveErrorMessage" />
                </p>
              ) : null}
              <div className={css.bottomWrapper}>
                <PrimaryButton type="submit" inProgress={inProgress} disabled={submitDisabled}>
                  <FormattedMessage id="ContactDetailsForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

TaxInformationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
};

const { bool, string } = PropTypes;

TaxInformationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const TaxInformationForm = compose(injectIntl)(TaxInformationFormComponent);

TaxInformationForm.displayName = 'TaxInformationForm';

export default TaxInformationForm;
