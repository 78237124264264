import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import {
  TRANSITION_ENQUIRE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PROJECT,
  TRANSITION_ACCEPT_CUSTOM_PRO_OFFER,
  TRANSITION_RELEASE_FUNDS,
  TRANSITION_JOB_DONE,
  TRANSITION_JOB_DONE_CUSTOMER,
  TRANSITION_JOB_DONE_RELEASE_FUNDS,
  TRANSITION_JOB_DONE_PRO,
  TRANSITION_JOB_ACCEPTED,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
} from '../../util/transaction';
import { formatDate } from '../../util/dates';

import css from './TransactionPanel.css';

export const TransactionProcessTimeline = ({
  stateData,
  currentTransaction,
  intl,
  isCustomer,
  isProOrInvoiceCustomer,
}) => {
  const initTimelineItems = [
    {
      state: 'started',
      text: 'TransactionPanel.timelineStarted',
      checked: false,
      createdAt: null,
    },
    {
      state: 'accepted',
      text: isCustomer
        ? 'TransactionPanel.timelineAcceptOfferCustomer'
        : 'TransactionPanel.timelineAcceptOfferProvider',
      checked: false,
      createdAt: null,
    },
    {
      state: 'inProgress',
      text: 'TransactionPanel.timelineOrderInProgress',
      checked: false,
      createdAt: null,
    },
    {
      state: 'completed',
      text: 'TransactionPanel.timelineOrderCompleted',
      checked: false,
      createdAt: null,
    },
    {
      state: 'paymentReleased',
      text: isProOrInvoiceCustomer
        ? 'TransactionPanel.timelineProAccepted'
        : 'TransactionPanel.timelinePaymentReleased',
      checked: false,
      createdAt: null,
    },
    {
      state: 'reviews',
      text: 'TransactionPanel.timelineReviews',
      checked: false,
      createdAt: null,
    },
  ];

  if (isProOrInvoiceCustomer && !isCustomer) {
    initTimelineItems.splice(initTimelineItems.length - 1, 0, {
      state: 'readyToBill',
      text: 'TransactionPanel.readyToBill',
      checked: false,
      createdAt: null,
    });
  }

  const [timelineItems, setTimelineItems] = useState(initTimelineItems);

  const findCreationTime = (transitions, transition) => {
    return transitions.find(e => e.transition === transition).createdAt;
  };

  const mapTransitionsToTimelineState = transaction => {
    const transitions = transaction.attributes.transitions;

    if (transitions.some(e => e.transition === TRANSITION_ENQUIRE)) {
      setTimelineItems(currentData =>
        currentData.map(item =>
          item.state === 'started'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_ENQUIRE),
              }
            : item
        )
      );
    }

    if (transitions.some(e => e.transition === TRANSITION_CONFIRM_PROJECT)) {
      setTimelineItems(currentData =>
        currentData.map(item =>
          item.state === 'accepted' || item.state === 'inProgress'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_CONFIRM_PROJECT),
              }
            : item
        )
      );
    }

    if (transitions.some(e => e.transition === TRANSITION_CONFIRM_PAYMENT)) {
      setTimelineItems(currentData =>
        currentData.map(item =>
          item.state === 'accepted' || item.state === 'inProgress'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_CONFIRM_PAYMENT),
              }
            : item
        )
      );
    }

    if (transitions.some(e => e.transition === TRANSITION_ACCEPT_CUSTOM_PRO_OFFER)) {
      setTimelineItems(currentData =>
        currentData.map(item =>
          item.state === 'accepted' || item.state === 'inProgress'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_ACCEPT_CUSTOM_PRO_OFFER),
              }
            : item
        )
      );
    }

    if (transitions.some(e => e.transition === TRANSITION_JOB_DONE)) {
      setTimelineItems(currentData => {
        return currentData.map(item =>
          item.state === 'completed'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_JOB_DONE),
              }
            : item
        );
      });
    }

    if (transitions.some(e => e.transition === TRANSITION_JOB_DONE_CUSTOMER)) {
      setTimelineItems(currentData => {
        return currentData.map(item =>
          item.state === 'completed'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_JOB_DONE_CUSTOMER),
              }
            : item
        );
      });
    }

    if (transitions.some(e => e.transition === TRANSITION_JOB_DONE_PRO)) {
      setTimelineItems(currentData => {
        return currentData.map(item =>
          item.state === 'completed'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_JOB_DONE_PRO),
              }
            : item
        );
      });
    }

    // Job done marked completed if customer releases funds before provider marking job done.
    if (transitions.some(e => e.transition === TRANSITION_JOB_DONE_RELEASE_FUNDS)) {
      setTimelineItems(currentData => {
        return currentData.map(item =>
          item.state === 'paymentReleased' || item.state === 'completed'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_JOB_DONE_RELEASE_FUNDS),
              }
            : item
        );
      });
    }

    if (transitions.some(e => e.transition === TRANSITION_RELEASE_FUNDS)) {
      setTimelineItems(currentData => {
        return currentData.map(item =>
          item.state === 'paymentReleased'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_RELEASE_FUNDS),
              }
            : item
        );
      });
    }

    if (transitions.some(e => e.transition === TRANSITION_JOB_ACCEPTED)) {
      setTimelineItems(currentData => {
        return currentData.map(item =>
          item.state === 'readyToBill'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_JOB_ACCEPTED),
              }
            : item
        );
      });
    }

    if (transitions.some(e => e.transition === TRANSITION_JOB_ACCEPTED)) {
      setTimelineItems(currentData => {
        return currentData.map(item =>
          item.state === 'paymentReleased'
            ? {
                ...item,
                checked: true,
                createdAt: findCreationTime(transitions, TRANSITION_JOB_ACCEPTED),
              }
            : item
        );
      });
    }

    if (transitions.some(e => e.transition === TRANSITION_REVIEW_1_BY_PROVIDER)) {
      if (transitions.some(e => e.transition === TRANSITION_REVIEW_2_BY_CUSTOMER)) {
        setTimelineItems(currentData => {
          return currentData.map(item =>
            item.state === 'reviews'
              ? {
                  ...item,
                  checked: true,
                  createdAt: findCreationTime(transitions, TRANSITION_REVIEW_2_BY_CUSTOMER),
                }
              : item
          );
        });
      }
    }
    if (transitions.some(e => e.transition === TRANSITION_REVIEW_2_BY_PROVIDER)) {
      if (transitions.some(e => e.transition === TRANSITION_REVIEW_1_BY_CUSTOMER)) {
        setTimelineItems(currentData => {
          return currentData.map(item =>
            item.state === 'reviews'
              ? {
                  ...item,
                  checked: true,
                  createdAt: findCreationTime(transitions, TRANSITION_REVIEW_1_BY_CUSTOMER),
                }
              : item
          );
        });
      }
    }
  };

  useEffect(() => {
    mapTransitionsToTimelineState(currentTransaction);
  }, [stateData]);

  return (
    <div className={css.timelineContainer}>
      <div className={css.timelineItemsContainer}>
        <div className={css.timelineTitle}>
          <FormattedMessage id={'TransactionPanel.timelineHeader'} />
        </div>
        <ul className={css.timelineItemList}>
          {timelineItems.map(item => (
            <TransactionProcessTimelineItem
              key={item.text}
              item={item}
              intl={intl}
              currentTransaction={currentTransaction}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

const TransactionProcessTimelineItem = ({ item, intl, currentTransaction }) => {
  const [customer, setCustomer] = useState('');

  useEffect(() => {
    setCustomer(
      currentTransaction.attributes.processName === 'flex-project-process'
        ? currentTransaction.provider.attributes.profile.displayName
        : currentTransaction.customer.attributes.profile.displayName
    );
  }, [currentTransaction]);

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.timelineContent}>
      <div className={css.timelineItems}>
        <BsFillCheckCircleFill
          className={[css.checkMark, item.checked ? css.itemChecked : css.itemUnchecked].join(' ')}
        />
        <div className={css.timelineItemTexts}>
          <p className={item.checked ? css.itemChecked : css.itemUnchecked}>
            <FormattedMessage id={item.text} values={{ customerName: customer }} />
          </p>
          {item.createdAt ? (
            <p className={css.timelineDate}>{formatDate(intl, todayString, item.createdAt)}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};
