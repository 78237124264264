import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProfileSettingsContainer as Container, InlineTextButton } from '../../components';
import css from './Container.css';

const WorkedWithContainer = ({ profileUser, button }) => {
  const [toggleRestOfWorkedWith, setToggleRestOfWorkedWith] = useState(false);

  const workedWith = profileUser?.attributes?.profile?.publicData?.workedWith;

  const showToggleWorkedWithButton = workedWith?.length > 3;

  const workedWithToShow = toggleRestOfWorkedWith ? workedWith : workedWith?.slice(0, 3);

  const container = (
    <Container heading={<FormattedMessage id="ProfilePage.workedWith" />} button={button}>
      {workedWith &&
        workedWith[0]?.startYear !== null &&
        workedWithToShow.map(e => (
          <div key={e.customerName} className={css.container}>
            <div className={css.customerName}>{e.task}</div>
            <div className={css.heading2}>{e.customerName}</div>
            <div className={css.heading2}>
              {e.startMonth}/{e.startYear}
              {e.jobContinues ? (
                <FormattedMessage id="ProfilePage.workedWithOngoing" />
              ) : (
                ` - ${e.endMonth}/${e.endYear}`
              )}
            </div>
            <div className={css.description}>{e.description}</div>
            {/* <div className={css.heading}>
              <FormattedMessage id="ProfilePage.workedWithDuration" />
            </div> */}
          </div>
        ))}
      {showToggleWorkedWithButton && (
        <InlineTextButton
          type="button"
          onClick={() => setToggleRestOfWorkedWith(!toggleRestOfWorkedWith)}
        >
          {toggleRestOfWorkedWith ? (
            <FormattedMessage id="ProfilePage.less" />
          ) : (
            <FormattedMessage id="ProfilePage.more" />
          )}
        </InlineTextButton>
      )}
    </Container>
  );

  if (!button) {
    if (
      !workedWith?.length ||
      (workedWith?.length === 0 && Object.keys(workedWith[0]).length === 0)
    )
      return null;
  }
  return container;
};

export default WorkedWithContainer;
