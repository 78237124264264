import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IoIosArrowDown } from 'react-icons/io';
import { FaqDropdown } from '../../components';

import css from './ProFaqSection.css';

const ProFaqSection = () => {
  const faqQuestion1 = <FormattedMessage id="DashboardProPage.faqQuestion1" />;
  const faqQuestion2 = <FormattedMessage id="DashboardProPage.faqQuestion2" />;
  const faqQuestion3 = <FormattedMessage id="DashboardProPage.faqQuestion3" />;
  const faqQuestion4 = <FormattedMessage id="DashboardProPage.faqQuestion4" />;

  const faqAnswer1 = (
    <FormattedMessage
      id="DashboardProPage.faqAnswer1"
      values={{
        lineBreak: <br />,
      }}
    />
  );
  const faqAnswer2 = (
    <FormattedMessage
      id="DashboardProPage.faqAnswer2"
      values={{
        lineBreak: <br />,
      }}
    />
  );
  const faqAnswer3 = (
    <FormattedMessage
      id="DashboardProPage.faqAnswer3"
      values={{
        lineBreak: <br />,
      }}
    />
  );
  const faqAnswer4 = (
    <FormattedMessage
      id="DashboardProPage.faqAnswer4"
      values={{
        lineBreak: <br />,
      }}
    />
  );

  const [faqIsShown, setFaqIsShown] = useState([
    {
      question: faqQuestion1,
      answer: faqAnswer1,
      index: 0,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: faqQuestion2,
      answer: faqAnswer2,
      index: 1,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: faqQuestion3,
      answer: faqAnswer3,
      index: 2,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: faqQuestion4,
      answer: faqAnswer4,
      index: 3,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
  ]);

  return (
    <div className={css.faqSection}>
      <div className={css.faqSectionTitle}>
        <FormattedMessage id="DashboardProPage.faqSectionTitle" />
      </div>
      <div className={css.faqSectionContent}>
        <FaqDropdown faqIsShown={faqIsShown} setFaqIsShown={setFaqIsShown} />
      </div>
    </div>
  );
};

export default ProFaqSection;
