import React from 'react';
import { ExternalLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import miikkaImg from '../../assets/pro-miikka-salminen.webp';

import css from './ProContactSection.css';

const MAX_MOBILE_SCREEN_WIDTH = 962;

const ProContactSection = props => {
  const { viewport } = props;
  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  return (
    <div className={css.contactSection}>
      {viewport.width > MAX_MOBILE_SCREEN_WIDTH ? (
        <div className={css.proContactSectionContactCard}>
          <div className={css.contactCard}>
            <div className={css.contactCardAvatar}>
              <img src={miikkaImg} alt="Contact" />
            </div>
            <div className={css.contactCardName}>Miikka Salminen, CEO</div>
            <div className={css.contactCardInformation}>miikka@freedomly.io</div>
            <div className={css.contactCardInformation}>+358 45 268 2218</div>
            <div>
              <ExternalLink className={css.heroSectionContactButton} onClick={handleShowSpaceHome}>
                <FormattedMessage id="ProLandingPage.heroContactButton" />
              </ExternalLink>
            </div>
          </div>
        </div>
      ) : null}
      <div className={css.contactSectionTextContent}>
        <div className={css.contactSectionTitle}>
          <FormattedMessage id="SuccessStoryZervant.contactSectionTitle" />
        </div>
        {viewport.width <= MAX_MOBILE_SCREEN_WIDTH ? (
          <div className={css.proContactSectionContactCard}>
            <div className={css.contactCardMobile}>
              <div className={css.contactCardAvatar}>
                <img src={miikkaImg} alt="Contact" />
              </div>
              <div className={css.contactCardName}>Miikka Salminen, CEO</div>
              <div className={css.contactCardInformation}>miikka@freedomly.io</div>
              <div className={css.contactCardInformation}>+358 45 268 2218</div>
              <div>
                <ExternalLink
                  className={css.heroSectionContactButton}
                  onClick={handleShowSpaceHome}
                >
                  <FormattedMessage id="ProLandingPage.heroContactButton" />
                </ExternalLink>
              </div>
            </div>
          </div>
        ) : null}
        <div className={css.contactSectionContentText}>
          <FormattedMessage id="SuccessStoryZervant.contactSectionText" />
        </div>
      </div>
    </div>
  );
};

export default ProContactSection;
