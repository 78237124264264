import React from 'react';
import { FieldSelectEdit } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { FaSearch } from 'react-icons/fa';

import css from './EditListingDescriptionForm.css';

const Placeholder = () => (
  <div className={css.placeholderContainer}>
    <FaSearch className={css.searchIcon} />
    <FormattedMessage id="EditListingDescriptionForm.categoryPlaceholder" />
  </div>
);

const CustomCategorySelectFieldMaybe = props => {
  const { name, id, selectedValue, categories, onChange, intl } = props;
  const categoryLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.categoryLabel',
  });

  const selectOptions = categories.map(category => {
    return {
      value: category.key,
      label: category.parent
        ? `${intl.formatMessage({ id: category.label.props.id })}`
        : category.label,
      parent: category.parent,
    };
  });

  return categories ? (
    <FieldSelectEdit
      className={css.category}
      name={name}
      id={id}
      label={categoryLabel}
      selectedValue={selectedValue}
      onChange={onChange}
      options={selectOptions}
      components={{ Placeholder }}
    />
  ) : null;
};

export default CustomCategorySelectFieldMaybe;
