import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import css from './SectionChildCategories.css';

const SectionChildCategories = ({ categories }) => {
  return (
    <div className={css.root}>
      <div className={css.headerSection}>
        <h2 className={css.header}>
          <FormattedMessage id="SectionChildCategories.header" />
        </h2>
      </div>
      <div>
        <ul className={css.categoriesSection}>
          {categories.map(category => (
            <NamedLink name={category.to} className={css.categoryItem} key={category.id}>
              <li className={css.categoryLink}>
                {category.icon}
                <FormattedMessage id={category.categoryLabelId} />
              </li>
            </NamedLink>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SectionChildCategories;
