import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '..';
import ExternalLink from '../ExternalLink/ExternalLink';

import infoImage from '../../assets/pro-landingpage-expandingto.webp';

import css from './SectionExpandingToPro.css';

const SectionExpandingToPro = () => {
  const title = <FormattedMessage id="SectionExpandingToPro.SectionExpandingToTitle" />;
  const description = <FormattedMessage id="SectionExpandingToPro.SectionExpandingToDescription" />;
  const description2 = (
    <FormattedMessage id="SectionExpandingToPro.SectionExpandingToDescription2" />
  );
  const description3 = (
    <FormattedMessage id="SectionExpandingToPro.SectionExpandingToDescription3" />
  );
  const ctaText = <FormattedMessage id="SectionExpandingToPro.SectionExpandingToCtaText" />;

  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  return (
    <div className={css.container}>
      <div className={css.content}>
        <div className={css.title}>{title}</div>
        <div className={css.description}>{description}</div>
        <div className={css.description}>{description2}</div>
        <div className={css.description}>{description3}</div>
        <ExternalLink className={css.button} onClick={handleShowSpaceHome}>
          <FormattedMessage id="ProLandingPage.heroContactButton" />
        </ExternalLink>
      </div>
      <div className={css.imgContainer}>
        <img src={infoImage} alt="infoImage" />
      </div>
    </div>
  );
};

export default SectionExpandingToPro;
