import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import arrayMutators from 'final-form-arrays';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { required, textEditorValidation, packageValidation } from '../../util/validators';

import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldTextInput,
  FieldTextEditor,
  UnsavedInfoPrompt,
} from '../../components';

import css from './EditListingPricingForm.css';

const { Money } = sdkTypes;
const PACKAGE_NAME_MAX_LENGTH = 150;
const PACKAGE_DESCRIPTION_MAX_LENGTH = 500;

export const EditListingPricingFormComponent = props => {
  const { listingPackages, addPackage } = props;

  const checkFilledListingPackages = (props, addPackage) => {
    if (props.initialValues !== undefined) {
      addPackage([1]);
    }
    if (props.initialValues.package2Name !== undefined) {
      addPackage([1, 2]);
    }
    if (props.initialValues.package3Name !== undefined) {
      addPackage([1, 2, 3]);
    }
    if (props.initialValues.package4Name !== undefined) {
      addPackage([1, 2, 3, 4]);
    }
    if (props.initialValues.package5Name !== undefined) {
      addPackage([1, 2, 3, 4, 5]);
    }
  };

  React.useEffect(() => {
    checkFilledListingPackages(props, addPackage);
  }, [addPackage]);

  return (
    <FinalForm
      {...props}
      listingPackages={listingPackages}
      addPackage={addPackage}
      mutators={{ ...arrayMutators }}
      validate={values => {
        const package2Errors = packageValidation(
          'package2',
          values.package2Name,
          values.package2Description,
          values.package2Price,
          props.intl
        );

        const package3Errors = packageValidation(
          'package3',
          values.package3Name,
          values.package3Description,
          values.package3Price,
          props.intl
        );

        const package4Errors = packageValidation(
          'package4',
          values.package4Name,
          values.package4Description,
          values.package4Price,
          props.intl
        );

        const package5Errors = packageValidation(
          'package5',
          values.package5Name,
          values.package5Description,
          values.package5Price,
          props.intl
        );

        const errors = {
          ...package2Errors,
          ...package3Errors,
          ...package4Errors,
          ...package5Errors,
        };

        return errors;
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          dirty,
        } = formRenderProps;

        const unitType = config.bookingUnitType;
        const isNightly = unitType === LINE_ITEM_NIGHT;
        const isDaily = unitType === LINE_ITEM_DAY;
        const translationKey = isNightly
          ? 'EditListingPricingForm.pricePerNight'
          : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditListingPricingForm.pricePerUnit';

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const package1NameMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package1Name',
        });
        const package1NamePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package1NamePlaceholder',
        });
        const package1NameRequiredMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package1NameRequired',
        });

        const package1DescriptionMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package1Description',
        });
        const package1DescriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package1DescriptionPlaceholder',
        });

        const package2PriceMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package2Price',
        });

        const package2PricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package2PricePlaceholder',
        });

        const package2NameMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package2Name',
        });
        const package2NamePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package2NamePlaceholder',
        });

        const package2DescriptionMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package2Description',
        });
        const package2DescriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package2DescriptionPlaceholder',
        });

        const package3PriceMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package3Price',
        });

        const package3PricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package3PricePlaceholder',
        });

        const package3NameMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package3Name',
        });
        const package3NamePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package3NamePlaceholder',
        });

        const package3DescriptionMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package3Description',
        });
        const package3DescriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package3DescriptionPlaceholder',
        });

        const package4PriceMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package4Price',
        });

        const package4PricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package4PricePlaceholder',
        });

        const package4NameMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package4Name',
        });
        const package4NamePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package4NamePlaceholder',
        });

        const package4DescriptionMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package4Description',
        });
        const package4DescriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package4DescriptionPlaceholder',
        });

        const package5PriceMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package5Price',
        });

        const package5PricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package5PricePlaceholder',
        });

        const package5NameMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package5Name',
        });
        const package5NamePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package5NamePlaceholder',
        });

        const package5DescriptionMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package5Description',
        });
        const package5DescriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.package5DescriptionPlaceholder',
        });

        const pricePerUnitMessage = intl.formatMessage({
          id: translationKey,
        });

        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceInputPlaceholder',
        });

        const externalLinksMessage = intl.formatMessage({
          id: 'RichTextEditor.externalLinksError',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const { updateListingError, showListingsError } = fetchErrors || {};

        const descriptionRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.descriptionRequired',
          })
        );

        const descriptionValidators = validators.composeValidators(
          descriptionRequired,
          textEditorValidation(externalLinksMessage)
        );

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}
            <FieldTextInput
              id="package1Name"
              name="package1Name"
              type="text"
              className={css.nameInput}
              label={package1NameMessage}
              placeholder={package1NamePlaceholderMessage}
              maxLength={PACKAGE_NAME_MAX_LENGTH}
              validate={required(package1NameRequiredMessage)}
              autoFocus
            />
            <FieldTextEditor
              id="package1Description"
              name="package1Description"
              className={css.nameInput}
              label={package1DescriptionMessage}
              placeholder={package1DescriptionPlaceholderMessage}
              validate={value => descriptionValidators(value?.replace('<p><br></p>', ''))}
              maxLength={PACKAGE_DESCRIPTION_MAX_LENGTH}
              autoFocus
            />

            <FieldCurrencyInput
              id="price"
              name="price"
              className={css.priceInput}
              label={pricePerUnitMessage}
              placeholder={pricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />

            {listingPackages.length >= 2 ? (
              <div>
                <FieldTextInput
                  id="package2Name"
                  name="package2Name"
                  type="text"
                  className={css.nameInput}
                  label={package2NameMessage}
                  placeholder={package2NamePlaceholderMessage}
                  maxLength={PACKAGE_NAME_MAX_LENGTH}
                />
                <FieldTextEditor
                  id="package2Description"
                  name="package2Description"
                  className={css.nameInput}
                  label={package2DescriptionMessage}
                  placeholder={package2DescriptionPlaceholderMessage}
                  validate={textEditorValidation(externalLinksMessage)}
                />

                <FieldCurrencyInput
                  id="package2Price"
                  name="package2Price"
                  className={css.priceInput}
                  label={package2PriceMessage}
                  placeholder={package2PricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                />
              </div>
            ) : null}

            {listingPackages.length >= 3 ? (
              <div>
                <FieldTextInput
                  id="package3Name"
                  name="package3Name"
                  type="text"
                  className={css.nameInput}
                  label={package3NameMessage}
                  placeholder={package3NamePlaceholderMessage}
                  maxLength={PACKAGE_NAME_MAX_LENGTH}
                />
                <FieldTextEditor
                  id="package3Description"
                  name="package3Description"
                  className={css.nameInput}
                  label={package3DescriptionMessage}
                  placeholder={package3DescriptionPlaceholderMessage}
                  validate={textEditorValidation(externalLinksMessage)}
                />

                <FieldCurrencyInput
                  id="package3Price"
                  name="package3Price"
                  className={css.priceInput}
                  label={package3PriceMessage}
                  placeholder={package3PricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                />
              </div>
            ) : null}

            {listingPackages.length >= 4 ? (
              <div>
                <FieldTextInput
                  id="package4Name"
                  name="package4Name"
                  type="text"
                  className={css.nameInput}
                  label={package4NameMessage}
                  placeholder={package4NamePlaceholderMessage}
                  maxLength={PACKAGE_NAME_MAX_LENGTH}
                />
                <FieldTextEditor
                  id="package4Description"
                  name="package4Description"
                  className={css.nameInput}
                  label={package4DescriptionMessage}
                  placeholder={package4DescriptionPlaceholderMessage}
                  validate={textEditorValidation(externalLinksMessage)}
                />

                <FieldCurrencyInput
                  id="package4Price"
                  name="package4Price"
                  className={css.priceInput}
                  label={package4PriceMessage}
                  placeholder={package4PricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                />
              </div>
            ) : null}

            {listingPackages.length >= 5 ? (
              <div>
                <FieldTextInput
                  id="package5Name"
                  name="package5Name"
                  type="text"
                  className={css.nameInput}
                  label={package5NameMessage}
                  placeholder={package5NamePlaceholderMessage}
                  maxLength={PACKAGE_NAME_MAX_LENGTH}
                />
                <FieldTextEditor
                  id="package5Description"
                  name="package5Description"
                  className={css.nameInput}
                  label={package5DescriptionMessage}
                  placeholder={package5DescriptionPlaceholderMessage}
                  validate={textEditorValidation(externalLinksMessage)}
                />

                <FieldCurrencyInput
                  id="package5Price"
                  name="package5Price"
                  className={css.priceInput}
                  label={package5PriceMessage}
                  placeholder={package5PricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                />
              </div>
            ) : null}

            {listingPackages.length !== 5 ? (
              <Button
                className={css.addPackageButton}
                onClick={e => {
                  e.preventDefault();
                  addPackage([...listingPackages, listingPackages.length + 1]);
                }}
              >
                <FormattedMessage id="EditListingPricingForm.addPackageButton" />
              </Button>
            ) : null}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
            <UnsavedInfoPrompt when={dirty} />
          </Form>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
