import React from 'react';

import Instructions from './Instructions';
import Actions from './Actions';
import css from './ApplicationPanel.css';

const SectionActions = props => {
  const {
    transaction,
    transactionId,
    stateData,
    isCustomer,
    actionError,
    sendAcceptAfterSkipInProgress,
    sendAcceptApplicationInProgress,
    showProfileListingInProgress,
    sendCreateReverseTransactionInProgress,
    handleAcceptApplication,
    onSkipApplication,
    sendSkipApplicationInProgress,
    handleAcceptApplicationAfterSkip,
    user,
  } = props;

  return (
    <div className={css.actionPanel}>
      <Instructions
        instructionsState={stateData.instructionsState}
        isCustomer={isCustomer}
        actionError={actionError}
        user={user}
      />
      <Actions
        transaction={transaction}
        transactionId={transactionId}
        showActionButtons={stateData.showActionButtons}
        showAcceptAfterSkipButton={stateData.showAcceptAfterSkipButton}
        sendAcceptApplicationInProgress={sendAcceptApplicationInProgress}
        showProfileListingInProgress={showProfileListingInProgress}
        sendCreateReverseTransactionInProgress={sendCreateReverseTransactionInProgress}
        sendSkipApplicationInProgress={sendSkipApplicationInProgress}
        sendAcceptAfterSkipInProgress={sendAcceptAfterSkipInProgress}
        handleAcceptApplication={handleAcceptApplication}
        onSkipApplication={onSkipApplication}
        handleAcceptApplicationAfterSkip={handleAcceptApplicationAfterSkip}
        showToNewTransactionButton={stateData.showToNewTransactionButton}
        isCustomer={isCustomer}
      />
    </div>
  );
};

export default SectionActions;
