import React from 'react';
import css from './PaymentStepsInfo.css';
import { TiLightbulb } from 'react-icons/ti';

function PaymentStepsInfo(props) {
  const { steps, heading, firstParagraph } = props;

  const listItems = steps.map(step => (
    <li className={css.listItem} key={step.heading}>
      <div className={css.listItemTitle}>{step.heading}</div>
      <div className={css.listItemText}>{step.text}</div>
    </li>
  ));

  return (
    <div className={css.container}>
      <div className={css.triangleLeft}></div>
      <div className={css.innerTriangle}></div>
      <div className={css.infoContainer}>
        <div className={css.headingContainer}>
          <TiLightbulb className={css.icon} />
          <div className={css.title}>{heading}</div>
        </div>
        <div className={css.secondTitle}>{firstParagraph}</div>
        <ul className={css.listItems}>{listItems}</ul>
      </div>
    </div>
  );
}

export default PaymentStepsInfo;
