import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionCategoryDescription.css';

const SectionCategoryDescription = ({ content }) => {
  const { mainTitleId, mainTextId, subSections } = content;
  return (
    <div className={css.root}>
      <div>
        <h2 className={css.mainHeader}>
          <FormattedMessage id={mainTitleId} />
        </h2>
        <FormattedMessage id={mainTextId} />
      </div>
      {subSections.map(section => (
        <div key={section.id}>
          <h2>
            <FormattedMessage id={section.titleId} />
          </h2>
          <FormattedMessage id={section.textId} />
        </div>
      ))}
    </div>
  );
};

export default SectionCategoryDescription;
