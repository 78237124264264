import React from 'react';

import css from './SolutionPages.css';

import { Button } from '../../components';

const SolutionPageHeroSection = props => {
  const {
    heroCaption,
    title,
    subTitle,
    subTitle2,
    buttonText,
    imgSrc,
    handleShowSpaceHome,
  } = props;

  return (
    <div className={css.heroSection}>
      <div className={css.heroContent}>
        {heroCaption && <div className={css.heroCaption}>{heroCaption}</div>}
        <div className={css.heroTitle}>{title}</div>
        <div className={css.heroSubtitle}>{subTitle}</div>
        {subTitle2 && (
          <>
            &nbsp;<div className={css.heroSubtitle}>{subTitle2}</div>
          </>
        )}
        {buttonText && (
          <Button className={css.heroButton} onClick={handleShowSpaceHome}>
            {buttonText}
          </Button>
        )}
      </div>
      <div className={css.heroImage}>
        <img src={imgSrc} alt="Hero" />
      </div>
    </div>
  );
};

export default SolutionPageHeroSection;
