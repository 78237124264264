import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { injectIntl } from '../../util/reactIntl';
import {
  Form,
  Button,
  FieldCheckbox,
  FieldRadioButton,
  FieldCheckboxGroup,
} from '../../components';
import { required } from '../../util/validators';

import css from './SignupProviderForms.css';

const ProfileSettingsHourlyFeeIdealCustomerForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          form,
          intl,
          invalid,
          pristine,
        } = formRenderProps;

        const submitDisabled = invalid || pristine;

        const classes = classNames(rootClassName || css.root, className);

        const heading = intl.formatMessage({
          id: 'ProfileSettingsForm.hourlyFeeIdealCustomerFormHeading',
        });

        const okButtonText = intl.formatMessage({
          id: 'SignupProviderForms.okButton',
        });

        const hourlyFeeLabel = intl.formatMessage({ id: 'SignupProviderForms.hourlyFeeLabel' });

        const point1 = intl.formatMessage({ id: 'SignupProviderForms.hourlyFeePoint1' });
        const point2 = intl.formatMessage({ id: 'SignupProviderForms.hourlyFeePoint2' });

        const dontShowHourlyFeeLabel = intl.formatMessage({
          id: 'SignupProviderForm.hourlyFeeDontShowHourlyFee',
        });

        const hourlyFeeRequired = intl.formatMessage({
          id: 'SignupProviderForm.hourlyFeeRequired',
        });

        const overHundred = intl.formatMessage({
          id: 'SignupProviderForm.overHundred',
        });

        const idealCustomerLabel = intl.formatMessage({
          id: 'ProfileSettingsForm.idealCustomerLabel',
        });
        const idealCustomerInstructions = intl.formatMessage({
          id: 'ProfileSettingsForm.idealCustomerInstructions',
        });

        const idealCustomerB2B = intl.formatMessage({
          id: 'ProfileSettingsForm.idealCustomerB2B',
        });

        const idealCustomerB2C = intl.formatMessage({
          id: 'ProfileSettingsForm.idealCustomerB2C',
        });

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <h2 className={css.mainHeading}>
                {heading}
                <div className={css.submitContainer}>
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {okButtonText}
                  </Button>
                </div>
              </h2>
              <div>
                <label>{hourlyFeeLabel}</label>
                <FieldRadioButton
                  id="hourlyFeeOption1"
                  name="hourlyFee"
                  label="20-39 €"
                  value="20-39 / h"
                  checkboxIcon
                  validate={required(hourlyFeeRequired)}
                />
                <FieldRadioButton
                  id="hourlyFeeOption2"
                  name="hourlyFee"
                  label="40-59 €"
                  value="40-59 / h"
                  checkboxIcon
                  validate={required(hourlyFeeRequired)}
                />
                <FieldRadioButton
                  id="hourlyFeeOption3"
                  name="hourlyFee"
                  label="60-79 €"
                  value="60-79 / h"
                  checkboxIcon
                  validate={required(hourlyFeeRequired)}
                />
                <FieldRadioButton
                  id="hourlyFeeOption4"
                  name="hourlyFee"
                  label="80-99 €"
                  value="80-99 / h"
                  checkboxIcon
                  validate={required(hourlyFeeRequired)}
                />
                <FieldRadioButton
                  id="hourlyFeeOption5"
                  name="hourlyFee"
                  label={overHundred}
                  value="+ 100 / h"
                  checkboxIcon
                  validate={required(hourlyFeeRequired)}
                />
              </div>
              <ul className={css.pointerList}>
                <li>{point1}</li>
                <li>{point2}</li>
              </ul>
              <FieldCheckbox
                id="dontShowHourlyFee"
                name="dontShowHourlyFee"
                label={dontShowHourlyFeeLabel}
                defaultValue={false}
              />
              <div>
                <FieldCheckboxGroup
                  id="idealCustomer"
                  name="idealCustomer"
                  label={idealCustomerLabel}
                  options={[
                    { id: 0, key: 'B2B', label: idealCustomerB2B },
                    {
                      id: 1,
                      key: 'B2C',
                      label: idealCustomerB2C,
                    },
                  ]}
                />
                <ul className={css.pointerList}>
                  <li>{idealCustomerInstructions}</li>
                </ul>
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

export default injectIntl(ProfileSettingsHourlyFeeIdealCustomerForm);
