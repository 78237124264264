export const staticFreelancerPageContents = [
  {
    id: 'freelancer-cro',
    path: '/konversio-optimointi',
    name: 'FreelancerCroPage',
    schemaTitle: 'FreelancerCroPage.schemaTitle',
    schemaDescription: 'FreelancerCroPage.schemaDescription',
    heroTitle: 'FreelancerCroPage.heroTitle',
    heroSubtitle: 'FreelancerCroPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerCroPage.sectionHowItWorksTitle',
    sectionFAQTitle: 'FreelancerCroPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerCroPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerCroPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerCroPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerCroPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerCroPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerCroPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerCroPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerCroPage.sectionFAQAnswer4',
  },
  {
    id: 'freelancer-marketing',
    path: '/markkinointi',
    name: 'FreelancerMarketingPage',
    schemaTitle: 'FreelancerMarketingPage.schemaTitle',
    schemaDescription: 'FreelancerMarketingPage.schemaDescription',
    heroTitle: 'FreelancerMarketingPage.heroTitle',
    heroSubtitle: 'FreelancerMarketingPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerMarketingPage.sectionHowItWorksTitle',
    sectionFAQTitle: 'FreelancerMarketingPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerMarketingPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerMarketingPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerMarketingPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerMarketingPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerMarketingPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerMarketingPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerMarketingPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerMarketingPage.sectionFAQAnswer4',
  },
  {
    id: 'freelancer-copywriter',
    path: '/copywriter',
    name: 'FreelancerCopywriterPage',
    schemaTitle: 'FreelancerCopywriterPage.schemaTitle',
    schemaDescription: 'FreelancerCopywriterPage.schemaDescription',
    heroTitle: 'FreelancerCopywriterPage.heroTitle',
    heroSubtitle: 'FreelancerCopywriterPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerCopywriterPage.sectionHowItWorksTitle',
    sectionFAQTitle: 'FreelancerCopywriterPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerCopywriterPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerCopywriterPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerCopywriterPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerCopywriterPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerCopywriterPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerCopywriterPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerCopywriterPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerCopywriterPage.sectionFAQAnswer4',
  },
  {
    id: 'freelancer-google',
    path: '/google',
    name: 'FreelancerGooglePage',
    schemaTitle: 'FreelancerGooglePage.schemaTitle',
    schemaDescription: 'FreelancerGooglePage.schemaDescription',
    heroTitle: 'FreelancerGooglePage.heroTitle',
    heroSubtitle: 'FreelancerGooglePage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerGooglePage.sectionHowItWorksTitle',
    sectionFAQTitle: 'FreelancerGooglePage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerGooglePage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerGooglePage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerGooglePage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerGooglePage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerGooglePage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerGooglePage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerGooglePage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerGooglePage.sectionFAQAnswer4',
  },
  {
    id: 'freelancer-programming',
    path: '/koodarit',
    name: 'FreelancerProgrammingPage',
    schemaTitle: 'FreelancerProgrammingPage.schemaTitle',
    schemaDescription: 'FreelancerProgrammingPage.schemaDescription',
    heroTitle: 'FreelancerProgrammingPage.heroTitle',
    heroSubtitle: 'FreelancerProgrammingPage.heroSubtitle',
    sectionHowItWorksTitle: 'FreelancerProgrammingPage.sectionHowItWorksTitle',
    sectionFAQTitle: 'FreelancerProgrammingPage.sectionFAQTitle',
    sectionFAQQuestion1: 'FreelancerProgrammingPage.sectionFAQQuestion1',
    sectionFAQQuestion2: 'FreelancerProgrammingPage.sectionFAQQuestion2',
    sectionFAQQuestion3: 'FreelancerProgrammingPage.sectionFAQQuestion3',
    sectionFAQQuestion4: 'FreelancerProgrammingPage.sectionFAQQuestion4',
    sectionFAQAnswer1: 'FreelancerProgrammingPage.sectionFAQAnswer1',
    sectionFAQAnswer2: 'FreelancerProgrammingPage.sectionFAQAnswer2',
    sectionFAQAnswer3: 'FreelancerProgrammingPage.sectionFAQAnswer3',
    sectionFAQAnswer4: 'FreelancerProgrammingPage.sectionFAQAnswer4',
  },
];
