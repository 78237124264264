import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '..';

import css from './SectionReviewStats.css';

const StatCard = ({ title, value }) => {
  return (
    <div className={css.statCard}>
      <div className={css.statCardTitle}>{title}</div>
      <div className={css.statCardValue}>{value}</div>
    </div>
  );
};

const SectionReviewStats = () => {
  const statCardTitle1 = <FormattedMessage id="SectionReviewStats.statCardTitle1" />;
  const statCardTitle2 = <FormattedMessage id="SectionReviewStats.statCardTitle2" />;
  const statCardTitle3 = <FormattedMessage id="SectionReviewStats.statCardTitle3" />;

  const statCardValue1 = <FormattedMessage id="SectionReviewStats.statCardValue1" />;
  const statCardValue2 = <FormattedMessage id="SectionReviewStats.statCardValue2" />;
  const statCardValue3 = <FormattedMessage id="SectionReviewStats.statCardValue3" />;

  return (
    <div className={css.container}>
      <div className={css.statCards}>
        <StatCard title={statCardTitle1} value={statCardValue1} />
        <StatCard title={statCardTitle2} value={statCardValue2} />
        <StatCard title={statCardTitle3} value={statCardValue3} />
      </div>
    </div>
  );
};

export default SectionReviewStats;
