import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';
import { AiOutlineArrowRight } from 'react-icons/ai';

import css from './SectionLocationsCategoriesPage.css';

import writingAndTranslationImage from './images/writing_n_translations_landingpage.webp';
import ecommerceAndWebsitesImage from './images/ecommerce_n_websites_landingpage.webp';
import socialMediaContentImage from './images/social_media_content_landingpage.webp';
import digitalMarketingImage from './images/digital_marketing_landingpage.webp';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

class CategoryImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(CategoryImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name={searchQuery} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>{nameText}</div>
    </NamedLink>
  );
};

const SectionLocationsCategoriesPageComponent = props => {
  const { rootClassName, className, intl, categoryPage } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          intl.formatMessage({ id: 'SectionLocations.writingAndTranslationLabel' }),
          writingAndTranslationImage,
          'ArticlesAndBlogContent'
        )}
        {locationLink(
          intl.formatMessage({ id: 'SectionLocations.ecommerceAndWebsitesLabel' }),
          ecommerceAndWebsitesImage,
          'EcommerceAndWebsites'
        )}
        {locationLink(
          intl.formatMessage({ id: 'SectionLocations.socialMediaContentLabel' }),
          socialMediaContentImage,
          'SocialMediaContent'
        )}
        {locationLink(
          intl.formatMessage({ id: 'SectionLocations.digitalMarketingLabel' }),
          digitalMarketingImage,
          'DigitalMarketing'
        )}
      </div>
      {!categoryPage && (
        <div className={css.categoryLinkText}>
          <Link to="/s">
            <FormattedMessage
              id={'SectionLocations.allCategoriesLabel'}
              className={css.categoryLinkText}
            />{' '}
            <AiOutlineArrowRight />
          </Link>
        </div>
      )}
    </div>
  );
};

SectionLocationsCategoriesPageComponent.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocationsCategoriesPageComponent.propTypes = {
  rootClassName: string,
  className: string,
};

const SectionLocationsCategoriesPage = compose(injectIntl)(SectionLocationsCategoriesPageComponent);

export default SectionLocationsCategoriesPage;
