import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { injectIntl } from '../../util/reactIntl';
import {
  Form,
  Button,
  FieldSelectModern,
  LocationAutocompleteInputField,
  FieldTextInput,
} from '../../components';
import FieldRadioButtonGroup from '../../components/FieldRadioButtonGroup/FieldRadioButtonGroup';
import { required } from '../../util/validators';
import * as validators from '../../util/validators';

import config from '../../config';

import css from './SignupProviderForms.css';

const ProfileSettingsNameLocationWorkingMethodForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          form,
          intl,
          values,
          invalid,
          isModal,
          user,
          isValidProvider,
          pristine,
        } = formRenderProps;

        const industryOptions = config.custom.industryOptions;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid || pristine;

        const identity = v => v;

        const nextButtonText = intl.formatMessage({
          id: 'SignupProviderForms.nextButton',
        });
        const okButtonText = intl.formatMessage({
          id: 'SignupProviderForms.okButton',
        });

        const locationLabel = intl.formatMessage({
          id: 'SignupProviderForms.locationNLanguageLocationLabel',
        });

        const locationPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.locationNLanguageLocationPlaceholder',
        });

        const locationRequired = intl.formatMessage({
          id: 'SignupProviderForms.locationRequired',
        });

        // First name
        const firstNameLabel = intl.formatMessage({
          id: 'ProfileSettingsForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'ProfileSettingsForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'ProfileSettingsForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // Last name
        const lastNameLabel = intl.formatMessage({
          id: 'ProfileSettingsForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'ProfileSettingsForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'ProfileSettingsForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const workingMethods = [
          {
            key: 'remoteNInPerson',
            label: intl.formatMessage({
              id: 'WorkingMethods.remoteNInPerson',
            }),
          },
          {
            key: 'remote',
            label: intl.formatMessage({ id: 'WorkingMethods.remote' }),
          },
        ];

        const workingMethodLabel = intl.formatMessage({
          id: 'ProfileSettingsForm.workingMethodLabel',
        });

        // VAT number
        const vatNumberLabel = intl.formatMessage({
          id: 'ProfileSettingsForm.vatNumberLabel',
        });
        const vatNumberPlaceholder = intl.formatMessage({
          id: 'ProfileSettingsForm.vatNumberPlaceholder',
        });
        const vatNumberRequiredMessage = intl.formatMessage({
          id: 'SignupForm.vatNumberRequired',
        });

        const vatNumberRequired = validators.required(vatNumberRequiredMessage);

        const vatNumberOptional = validators.optional();

        // industry
        const industryLabel = intl.formatMessage({
          id: 'SignupForm.industryLabel',
        });

        const industryPlaceholder = intl.formatMessage({
          id: 'SignupForm.industryPlaceholder',
        });

        const industryRequired = validators.required();

        const vatNumberElement =
          user.attributes.profile.publicData.account_role === 'freelancer' ? (
            <FieldTextInput
              className={css.vat}
              type="text"
              id="vat_number"
              name="vat_number"
              label={vatNumberLabel}
              placeholder={vatNumberPlaceholder}
              validate={vatNumberRequired}
              key={user.attributes.profile.publicData.account_role ? 'freelancer' : 'customer'}
            />
          ) : user.attributes.profile.publicData.account_role === 'customer' ? (
            <FieldTextInput
              className={css.vat}
              type="text"
              id="vat_number"
              name="vat_number"
              label={vatNumberLabel}
              placeholder={vatNumberPlaceholder}
              validate={vatNumberOptional}
              key={user.attributes.profile.publicData.account_role ? 'customer' : 'freelancer'}
              autoFocus
            />
          ) : null;

        const industryElement =
          user.attributes.profile.publicData.account_role === 'customer' ? (
            <FieldSelectModern
              className={css.vat}
              id="industry"
              name="industry"
              label={industryLabel}
              validate={industryRequired}
              placeholder={industryPlaceholder}
              options={industryOptions}
            />
          ) : null;

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <div className={css.submitContainerNoHeading}>
                <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                  {isModal ? okButtonText : nextButtonText}
                </Button>
              </div>
              <div className={css.nameContainer}>
                <FieldTextInput
                  className={css.firstName}
                  type="text"
                  id="firstName"
                  name="firstName"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.lastName}
                  type="text"
                  id="lastName"
                  name="lastName"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  validate={lastNameRequired}
                />
              </div>
              <LocationAutocompleteInputField
                iconClassName={css.locationAutocompleteInputIcon}
                predictionsClassName={css.predictionsRoot}
                validClassName={css.validLocation}
                name="location"
                placeholder={locationPlaceholder}
                useDefaultPredictions={false}
                format={identity}
                valueFromForm={values.location}
                validate={required(locationRequired)}
                label={locationLabel}
                predictionType="place"
              />
              {isValidProvider ? (
                <FieldRadioButtonGroup
                  id="workingMethod"
                  name="workingMethod"
                  options={workingMethods}
                  twoColumns={true}
                  label={workingMethodLabel}
                />
              ) : null}

              {vatNumberElement}
              {industryElement}
            </Form>
          </div>
        );
      }}
    />
  );
};

ProfileSettingsNameLocationWorkingMethodForm.defaultProps = {
  filterConfig: config.custom.filters,
  isModal: false,
};

export default injectIntl(ProfileSettingsNameLocationWorkingMethodForm);
