import React from 'react';
import { FormattedMessage } from './util/reactIntl';

import websiteHeroCardImage1 from './assets/pro-freelancer-1.webp';
import websiteHeroCardImage2 from './assets/pro-freelancer-2.webp';
import websiteHeroCardImage3 from './assets/pro-freelancer-3.webp';
import websiteWhatWeOfferSectionImage from './assets/freedomly-pro-1.webp';
import websiteTestimonialSectionImage from './assets/freelanceri-at-cafe.webp';

import squareSpaceLogo from './assets/squarespace.svg';
import wixLogo from './assets/wix.svg';
import shopifyLogo from './assets/shopify.svg';
import webflowLogo from './assets/webflow.svg';
import wordpressLogo from './assets/wordpress.svg';

export const projectPageContents = [
  {
    id: 'website_development',
    path: 'verkkosivujen-tekija',
    name: 'WebsiteProjectLandingPage',
    schemaTitle: 'WebsiteProjectLandingPage.schemaTitle',
    schemaDescription: 'WebsiteProjectLandingPage.schemaDescription',
    heroTitle: 'WebsiteProjectLandingPage.heroTitle',
    heroSubtitle: 'WebsiteProjectLandingPage.heroSubtitle',
    heroCard1: 'WebsiteProjectLandingPage.heroCard1',
    heroCard2: 'WebsiteProjectLandingPage.heroCard2',
    heroCard3: 'WebsiteProjectLandingPage.heroCard3',
    heroCardName1: 'WebsiteProjectLandingPage.heroCardName1',
    heroCardName2: 'WebsiteProjectLandingPage.heroCardName2',
    heroCardName3: 'WebsiteProjectLandingPage.heroCardName3',
    heroCTAButton: 'WebsiteProjectLandingPage.heroCTAButton',
    whatWeOfferSectionTitle: (
      <FormattedMessage
        id="WebsiteProjectLandingPage.whatWeOfferSectionTitle"
        values={{
          b: chunks => (
            <b
              style={{
                fontWeight: 600,
                color: '#1683A3',
                textDecoration: 'none',
                cursor: 'default',
              }}
            >
              {chunks}
            </b>
          ),
        }}
      />
    ),
    whatWeOfferSectionText: 'WebsiteProjectLandingPage.whatWeOfferSectionText',
    whatWeOfferListTitle: 'WebsiteProjectLandingPage.whatWeOfferListTitle',
    whatWeOfferListItem1: 'WebsiteProjectLandingPage.whatWeOfferListItem1',
    whatWeOfferListItem2: 'WebsiteProjectLandingPage.whatWeOfferListItem2',
    whatWeOfferListItem3: 'WebsiteProjectLandingPage.whatWeOfferListItem3',
    howItWorksSectionTitle: (
      <FormattedMessage
        id="WebsiteProjectLandingPage.howItWorksSectionTitle"
        values={{
          b: chunks => (
            <b
              style={{
                fontWeight: 600,
                color: '#1683A3',
                textDecoration: 'none',
                cursor: 'default',
              }}
            >
              {chunks}
            </b>
          ),
        }}
      />
    ),
    howItWorksSectionText: (
      <FormattedMessage
        id="WebsiteProjectLandingPage.howItWorksSectionText"
        values={{
          b: chunks => <b style={{ fontWeight: 600 }}>{chunks}</b>,
        }}
      />
    ),
    howItWorksStepTitle1: 'WebsiteProjectLandingPage.howItWorksStepTitle1',
    howItWorksStepText1: 'WebsiteProjectLandingPage.howItWorksStepText1',
    howItWorksStepTitle2: 'WebsiteProjectLandingPage.howItWorksStepTitle2',
    howItWorksStepText2: 'WebsiteProjectLandingPage.howItWorksStepText2',
    howItWorksStepTitle3: 'WebsiteProjectLandingPage.howItWorksStepTitle3',
    howItWorksStepText3: 'WebsiteProjectLandingPage.howItWorksStepText3',
    howItWorksCTAButton: 'WebsiteProjectLandingPage.howItWorksCTAButton',
    solutionsLogosText: 'WebsiteProjectLandingPage.solutionsLogosText',
    testimonialsSectionTitle: 'WebsiteProjectLandingPage.testimonialsSectionTitle',
    testimonialText1: 'WebsiteProjectLandingPage.testimonialText1',
    testimonialFrom1: 'WebsiteProjectLandingPage.testimonialFrom1',
    testimonialText2: 'WebsiteProjectLandingPage.testimonialText2',
    testimonialForm2: 'WebsiteProjectLandingPage.testimonialForm2',
    footerSectionTitle: 'WebsiteProjectLandingPage.footerSectionTitle',
    footerSectionText: 'WebsiteProjectLandingPage.footerSectionText',
    footerCTAButton: 'WebsiteProjectLandingPage.footerCTAButton',
    footerFreedomly: 'WebsiteProjectLandingPage.footerFreedomly',
    footerPrivacyPolicy: 'WebsiteProjectLandingPage.footerPrivacyPolicy',
    footerTOS: 'WebsiteProjectLandingPage.footerTOS',
    heroCard1Image: websiteHeroCardImage1,
    heroCard2Image: websiteHeroCardImage2,
    heroCard3Image: websiteHeroCardImage3,
    whatWeOfferSectionImage: websiteWhatWeOfferSectionImage,
    testimonialSectionImage: websiteTestimonialSectionImage,
    showProductLogoSection: true,
    productLogoText: 'WebsiteProjectLandingPage.productLogoText',
    productLogos: [wordpressLogo, webflowLogo, wixLogo, squareSpaceLogo, shopifyLogo],
    formContent: {
      formTitle: 'WebsiteProjectLandingPage.formTitle',
      formListItem1: 'WebsiteProjectLandingPage.formListItem1',
      formListItem2: 'WebsiteProjectLandingPage.formListItem2',
      formListItem3: 'WebsiteProjectLandingPage.formListItem3',
      formCategory: 'WebsiteProjectLandingPage.formCategory',
      signUpFrom: '/verkkosivujen-tekijä',
      titlePlaceholder: 'WebsiteProjectLandingPage.titlePlaceholder',
      nameInfoListItem1: 'WebsiteProjectLandingPage.nameInfoListItem1',
      nameInfoListItem2: 'WebsiteProjectLandingPage.nameInfoListItem2',
      descriptionInfo: [
        'WebsiteProjectLandingPage.descriptionInfo1',
        'WebsiteProjectLandingPage.descriptionInfo2',
        'WebsiteProjectLandingPage.descriptionInfo3',
        'WebsiteProjectLandingPage.descriptionInfo4',
      ],
    },
  },
  {
    id: 'ecommerce_development',
    path: 'verkkokaupan-tekija',
    name: 'EcommerceProjectLandingPage',
    schemaTitle: 'EcommerceProjectLandingPage.schemaTitle',
    schemaDescription: 'EcommerceProjectLandingPage.schemaDescription',
    heroTitle: 'EcommerceProjectLandingPage.heroTitle',
    heroSubtitle: 'EcommerceProjectLandingPage.heroSubtitle',
    heroCard1: 'EcommerceProjectLandingPage.heroCard1',
    heroCard2: 'EcommerceProjectLandingPage.heroCard2',
    heroCard3: 'EcommerceProjectLandingPage.heroCard3',
    heroCardName1: 'EcommerceProjectLandingPage.heroCardName1',
    heroCardName2: 'EcommerceProjectLandingPage.heroCardName2',
    heroCardName3: 'EcommerceProjectLandingPage.heroCardName3',
    heroCTAButton: 'EcommerceProjectLandingPage.heroCTAButton',
    whatWeOfferSectionTitle: (
      <FormattedMessage
        id="EcommerceProjectLandingPage.whatWeOfferSectionTitle"
        values={{
          b: chunks => (
            <b
              style={{
                fontWeight: 600,
                color: '#1683A3',
                textDecoration: 'none',
                cursor: 'default',
              }}
            >
              {chunks}
            </b>
          ),
        }}
      />
    ),
    whatWeOfferSectionText: 'EcommerceProjectLandingPage.whatWeOfferSectionText',
    whatWeOfferListTitle: 'EcommerceProjectLandingPage.whatWeOfferListTitle',
    whatWeOfferListItem1: 'EcommerceProjectLandingPage.whatWeOfferListItem1',
    whatWeOfferListItem2: 'EcommerceProjectLandingPage.whatWeOfferListItem2',
    whatWeOfferListItem3: 'EcommerceProjectLandingPage.whatWeOfferListItem3',
    howItWorksSectionTitle: (
      <FormattedMessage
        id="EcommerceProjectLandingPage.howItWorksSectionTitle"
        values={{
          b: chunks => (
            <b
              style={{
                fontWeight: 600,
                color: '#1683A3',
                textDecoration: 'none',
                cursor: 'default',
              }}
            >
              {chunks}
            </b>
          ),
        }}
      />
    ),
    howItWorksSectionText: (
      <FormattedMessage
        id="EcommerceProjectLandingPage.howItWorksSectionText"
        values={{
          b: chunks => <b style={{ fontWeight: 600 }}>{chunks}</b>,
        }}
      />
    ),
    howItWorksStepTitle1: 'EcommerceProjectLandingPage.howItWorksStepTitle1',
    howItWorksStepText1: 'EcommerceProjectLandingPage.howItWorksStepText1',
    howItWorksStepTitle2: 'EcommerceProjectLandingPage.howItWorksStepTitle2',
    howItWorksStepText2: 'EcommerceProjectLandingPage.howItWorksStepText2',
    howItWorksStepTitle3: 'EcommerceProjectLandingPage.howItWorksStepTitle3',
    howItWorksStepText3: 'EcommerceProjectLandingPage.howItWorksStepText3',
    howItWorksCTAButton: 'EcommerceProjectLandingPage.howItWorksCTAButton',
    solutionsLogosText: 'EcommerceProjectLandingPage.solutionsLogosText',
    testimonialsSectionTitle: 'EcommerceProjectLandingPage.testimonialsSectionTitle',
    testimonialText1: 'EcommerceProjectLandingPage.testimonialText1',
    testimonialFrom1: 'EcommerceProjectLandingPage.testimonialFrom1',
    testimonialText2: 'EcommerceProjectLandingPage.testimonialText2',
    testimonialForm2: 'EcommerceProjectLandingPage.testimonialForm2',
    footerSectionTitle: 'EcommerceProjectLandingPage.footerSectionTitle',
    footerSectionText: 'EcommerceProjectLandingPage.footerSectionText',
    footerCTAButton: 'EcommerceProjectLandingPage.footerCTAButton',
    footerFreedomly: 'EcommerceProjectLandingPage.footerFreedomly',
    footerPrivacyPolicy: 'EcommerceProjectLandingPage.footerPrivacyPolicy',
    footerTOS: 'EcommerceProjectLandingPage.footerTOS',
    heroCard1Image: websiteHeroCardImage1,
    heroCard2Image: websiteHeroCardImage2,
    heroCard3Image: websiteHeroCardImage3,
    whatWeOfferSectionImage: websiteWhatWeOfferSectionImage,
    testimonialSectionImage: websiteTestimonialSectionImage,
    showProductLogoSection: false,
    formContent: {
      formTitle: 'EcommerceProjectLandingPage.formTitle',
      formListItem1: 'EcommerceProjectLandingPage.formListItem1',
      formListItem2: 'EcommerceProjectLandingPage.formListItem2',
      formListItem3: 'EcommerceProjectLandingPage.formListItem3',
      formCategory: 'EcommerceProjectLandingPage.formCategory',
      signUpFrom: '/verkkosivujen-tekijä',
      titlePlaceholder: 'EcommerceProjectLandingPage.titlePlaceholder',
      nameInfoListItem1: 'EcommerceProjectLandingPage.nameInfoListItem1',
      nameInfoListItem2: 'EcommerceProjectLandingPage.nameInfoListItem2',
    },
  },
];
