import React from 'react';
import { bool } from 'prop-types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsDelivered, txIsDeclined } from '../../util/transaction';
import { propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

const TAX_RATE_PERCENTAGE = 25.5;

const LineItemUnitPrice = props => {
  const { transaction, isProvider, intl } = props;
  const listingPublicData =
    (transaction && transaction.listing && transaction.listing.attributes.publicData) || {};
  const { listingType = '' } = listingPublicData;
  const isProject = listingType === 'project';

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  let totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );

  if (isProvider && isProject) {
    totalLabel = <FormattedMessage id="BookingBreakdown.payoutSum" />;
  }

  const totalPrice =
    (isProvider && !isProject) || (!isProvider && isProject)
      ? transaction.attributes.payoutTotal
      : transaction.attributes.payinTotal;
  let formattedTotalPrice = formatMoney(intl, totalPrice);
  if (isProvider && isProject) {
    formattedTotalPrice = formatMoney(
      intl,
      new Money(transaction.attributes.protectedData.projectBidAmount, 'EUR')
    );
  }
  const taxAmount = Math.round(
    (totalPrice.amount * TAX_RATE_PERCENTAGE) / (TAX_RATE_PERCENTAGE + 100)
  );
  const tax = new Money(taxAmount, 'EUR');
  const formattedTax = formatMoney(intl, tax);
  const payoutAmount = totalPrice.amount - taxAmount;
  const payout = new Money(payoutAmount, 'EUR');
  const formattedPayout = formatMoney(intl, payout);

  const payoutLines =
    (isProvider && !isProject) || (!isProvider && isProject) ? (
      <>
        <div className={css.lineItem}>
          <div className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.payoutSum" />
          </div>
          <div className={css.itemValue}>{formattedPayout}</div>
        </div>
        <div className={css.lineItem}>
          <div className={css.itemLabel}>
            <FormattedMessage id="BookingBreakdown.saleTax" />
          </div>
          <div className={css.itemValue}>{formattedTax}</div>
        </div>
      </>
    ) : null;

  return (
    <>
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
      <hr className={css.totalDivider} />
      {payoutLines}
    </>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
