import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { Form, SecondaryButton } from '../../components';

import css from './SubscriptionUpdateForm.css';

class SubscriptionUpdateFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            stripeSubscriptionError,
            currentUser,
            handleSubmit,
            inProgress,
            invalid,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          // Stripe integration error
          const stripeSubscriptionErrorMessage = stripeSubscriptionError ? (
            <span className={css.error}>
              <FormattedMessage id="SubscriptionUpdateForm.genericFailure" />
            </span>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || inProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                handleSubmit(e);
              }}
            >
              <div className={css.contactDetailsSection}>
                <FormattedMessage id="SubscriptionPage.subscribedMessage" />
              </div>

              <div className={css.bottomWrapper}>
                {stripeSubscriptionErrorMessage}
                <SecondaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={false}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="SubscriptionUpdateForm.cancelSubscription" />
                </SecondaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SubscriptionUpdateFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  savePhoneNumberError: null,
  inProgress: false,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
  email: null,
  phoneNumber: null,
};

const { bool, string } = PropTypes;

SubscriptionUpdateFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool,
};

const SubscriptionUpdateForm = compose(injectIntl)(SubscriptionUpdateFormComponent);

SubscriptionUpdateForm.displayName = 'SubscriptionUpdateForm';

export default SubscriptionUpdateForm;
