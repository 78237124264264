import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { ensureListing, ensureTransaction } from '../../util/data';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
  ApplicationPanel,
} from '../../components';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '../../containers';
import {
  createReverseTransaction,
  loadData,
  setInitialValues,
  showProfileListing,
  acceptApplication,
  skipApplication,
  acceptAfterSkip,
} from './ApplicationPage.duck.js';
import css from './ApplicationPage.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

export const ApplicationPageComponent = props => {
  const {
    intl,
    isScrollingDisabled,
    transaction,
    currentUser,
    transactionRole,
    params,
    fetchTransactionError,
    onAcceptApplication,
    onCreateReverseTransaction,
    onShowProfileListing,
    onSkipApplication,
    onAcceptAfterSkip,
    sendAcceptApplicationInProgress,
    showProfileListingInProgress,
    sendCreateReverseTransactionInProgress,
    sendSkipApplicationInProgress,
    sendAcceptAfterSkipInProgress,
    sendAcceptApplicationError,
    showProfileListingError,
    sendCreateReverseTransactionError,
    sendSkipApplicationError,
    sendAcceptAfterSkipError,
    history,
  } = props;

  const currentTransaction = ensureTransaction(transaction);
  const currentListing = ensureListing(currentTransaction.listing);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
  } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
  }

  const schemaTitle = intl.formatMessage({
    id: 'ApplicationPage.schemaTitle',
  });

  const handleAcceptApplication = id => {
    onShowProfileListing(currentTransaction.customer.id).then(profileListing => {
      const protectedData = currentTransaction.attributes.protectedData;
      const applicationId = currentTransaction.id.uuid;
      const listingId = currentListing.id.uuid;

      protectedData.applicationId = applicationId;
      protectedData.listingId = listingId;

      const isProCustomer = currentUser.attributes?.profile?.publicData?.proCustomer === true;
      const isInvoiceCustomer =
        currentUser.attributes?.profile?.publicData?.invoiceEnabled === true;

      const isProProcess = isProCustomer || isInvoiceCustomer;

      const isLightEntrepreneur =
        currentTransaction.customer.attributes.profile.publicData.account_role ===
        'light-entrepreneur';

      onCreateReverseTransaction(
        profileListing.id.uuid,
        protectedData,
        isLightEntrepreneur,
        isProProcess
      )
        .then(transactionId => {
          onAcceptApplication(id, transactionId).then(res => {
            const routes = routeConfiguration();

            window.open(
              createResourceLocatorString(
                'OrderDetailsPage',
                routes,
                { id: transactionId.uuid },
                {}
              ),
              '_blank' // This ensures that the URL opens in a new tab
            );
          });
        })
        .catch(() => {
          // Ignore, error handling in duck file
        });
    });
  };

  const handleAcceptApplicationAfterSkip = id => {
    onShowProfileListing(currentTransaction.customer.id).then(profileListing => {
      const protectedData = currentTransaction.attributes.protectedData;

      const applicationId = currentTransaction.id.uuid;
      const listingId = currentListing.id.uuid;

      protectedData.applicationId = applicationId;
      protectedData.listingId = listingId;

      const isProCustomer = currentUser.attributes?.profile?.publicData?.proCustomer === true;
      const isInvoiceCustomer =
        currentUser.attributes?.profile?.publicData?.invoiceEnabled === true;

      const isProProcess = isProCustomer || isInvoiceCustomer;

      const isLightEntrepreneur =
        currentTransaction.customer.attributes.profile.publicData.account_role ===
        'light-entrepreneur';

      onCreateReverseTransaction(
        profileListing.id.uuid,
        protectedData,
        isLightEntrepreneur,
        isProProcess
      )
        .then(transactionId => {
          onAcceptAfterSkip(id, transactionId).then(res => {
            const routes = routeConfiguration();

            window.open(
              createResourceLocatorString(
                'OrderDetailsPage',
                routes,
                { id: transactionId.uuid },
                {}
              ),
              '_blank' // This ensures that the URL opens in a new tab
            );
          });
        })
        .catch(() => {
          // Ignore, error handling in duck file
        });
    });
  };

  const loadingOrFailedFetching = fetchTransactionError ? (
    <p className={css.error}>
      <FormattedMessage id="ApplicationPage.fetchTransactionError" />
    </p>
  ) : (
    <p className={css.loading}>
      <FormattedMessage id="ApplicationPage.loading" />
    </p>
  );

  const panel = isDataAvailable ? (
    <ApplicationPanel
      intl={intl}
      currentUser={currentUser}
      transaction={currentTransaction}
      listing={currentListing}
      transactionRole={transactionRole}
      handleAcceptApplication={handleAcceptApplication}
      onSkipApplication={onSkipApplication}
      handleAcceptApplicationAfterSkip={handleAcceptApplicationAfterSkip}
      sendAcceptApplicationInProgress={sendAcceptApplicationInProgress}
      showProfileListingInProgress={showProfileListingInProgress}
      sendCreateReverseTransactionInProgress={sendCreateReverseTransactionInProgress}
      sendSkipApplicationInProgress={sendSkipApplicationInProgress}
      sendAcceptAfterSkipInProgress={sendAcceptAfterSkipInProgress}
      acceptApplicationError={sendAcceptApplicationError}
      profileListingError={showProfileListingError}
      reverseTransActionError={sendCreateReverseTransactionError}
      skipApplicationError={sendSkipApplicationError}
      acceptAferSkipError={sendAcceptAfterSkipError}
    />
  ) : (
    loadingOrFailedFetching
  );

  return (
    <Page title={schemaTitle} scrollingDisabled={isScrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ApplicationPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>{panel}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, func, oneOf, shape, string } = PropTypes;

ApplicationPageComponent.propTypes = {
  isScrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  params: shape({ id: string }).isRequired,
  fetchTransactionError: propTypes.error,
  onAcceptApplication: func.isRequired,
  onCreateReverseTransaction: func.isRequired,
  onShowProfileListing: func.isRequired,
  onSkipApplication: func.isRequired,
  onAcceptAfterSkip: func.isRequired,
  sendAcceptApplicationInProgress: bool.isRequired,
  showProfileListingInProgress: bool.isRequired,
  sendCreateReverseTransactionInProgress: bool.isRequired,
  sendSkipApplicationInProgress: bool.isRequired,
  sendAcceptAfterSkipInProgress: bool.isRequired,
  sendAcceptApplicationError: propTypes.error,
  showProfileListingError: propTypes.error,
  sendCreateReverseTransactionError: propTypes.error,
  sendSkipApplicationError: propTypes.error,
  sendAcceptAfterSkipError: propTypes.error,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    transactionRef,
    fetchTransactionError,
    sendAcceptApplicationInProgress,
    showProfileListingInProgress,
    sendCreateReverseTransactionInProgress,
    sendSkipApplicationInProgress,
    sendAcceptAfterSkipInProgress,
    sendAcceptApplicationError,
    showProfileListingError,
    sendCreateReverseTransactionError,
    sendSkipApplicationError,
    sendAcceptAfterSkipError,
  } = state.ApplicationPage;
  const { currentUser } = state.user;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);

  const transaction = transactions.length > 0 ? transactions[0] : null;

  return {
    isScrollingDisabled: isScrollingDisabled(state),
    transaction,
    currentUser,
    fetchTransactionError,
    sendAcceptApplicationInProgress,
    showProfileListingInProgress,
    sendCreateReverseTransactionInProgress,
    sendSkipApplicationInProgress,
    sendAcceptAfterSkipInProgress,
    sendAcceptApplicationError,
    showProfileListingError,
    sendCreateReverseTransactionError,
    sendSkipApplicationError,
    sendAcceptAfterSkipError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateReverseTransaction: (listingId, data, isLightEntrepreneur, isProProcess) =>
      dispatch(createReverseTransaction(listingId, data, isLightEntrepreneur, isProProcess)),
    onShowProfileListing: userId => dispatch(showProfileListing(userId)),
    onAcceptApplication: (id, transactionId) => dispatch(acceptApplication(id, transactionId)),
    onSkipApplication: id => dispatch(skipApplication(id)),
    onAcceptAfterSkip: (id, transactionId) => dispatch(acceptAfterSkip(id, transactionId)),
  };
};

const ApplicationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ApplicationPageComponent);

ApplicationPage.loadData = loadData;
ApplicationPage.setInitialValues = setInitialValues;

export default ApplicationPage;
