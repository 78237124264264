import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '../../components';

import css from './SectionReviewsVideo.css';

const SectionReviewsVideo = () => {
  const title = <FormattedMessage id="SectionReviewsVideo.title" />;
  const from = <FormattedMessage id="SectionReviewsVideo.from" />;
  const company = <FormattedMessage id="SectionReviewsVideo.company" />;
  const ctaText = <FormattedMessage id="SectionReviewsVideo.ctaText" />;

  return (
    <div className={css.container}>
      <div className={css.content}>
        <div className={css.title}>{title}</div>
        <div>
          <div className={css.from}>{from}</div>
          <div className={css.company}>{company}</div>
        </div>
        <NamedLink className={css.buttonDesktop} name="SuccessStoriesPage">
          {ctaText}
        </NamedLink>
      </div>
      <div className={css.videoContainer}>
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/siUsuYLRn7Y?modestbranding=1`}
          allowFullScreen
          title="YouTube Video Player"
        ></iframe>
      </div>
      <NamedLink className={css.buttonMobile} name="SuccessStoriesPage">
        {ctaText}
      </NamedLink>
    </div>
  );
};

export default SectionReviewsVideo;
