import React from 'react';
import renderHTML from 'react-render-html';
import { injectIntl } from '../../util/reactIntl';

import css from './ProjectProposalPage.css';

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

const SectionDetails = props => {
  const { intl, descriptionText, publicData, categoryOptions, listingTitle } = props;

  const descriptionTextHtml = descriptionText ? renderHTML(descriptionText) : '';
  const category =
    publicData && publicData.category ? (
      <span>{categoryLabel(categoryOptions, publicData.category)}</span>
    ) : null;

  const detailsHeading = intl.formatMessage({
    id: 'ProjectProposalPage.detailsHeading',
  });

  return (
    <div className={css.projectDetails}>
      <h3 className={css.detailsHeading}>{detailsHeading}</h3>

      <div className={css.link}>{category}</div>
      <h2 className={css.listingHeading}>{listingTitle}</h2>
      <div>{descriptionTextHtml}</div>
    </div>
  );
};

export default injectIntl(SectionDetails);
