import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import penPaperIcon from '../../assets/pen-paper-icon.svg';
import chatBubbleIcon from '../../assets/chat-bubble-icon.svg';
import handshakeIcon from '../../assets/handshake-icon.svg';

import css from './BuyerTileMarketplace.css';

const BuyerTileMarketplace = props => {
  const {
    buyerTileMarketplaceTitleId,
    buyerTileMarketplacesubTitleId,
    buyerTileMarketplaceContentTitle1Id,
    buyerTileMarketplaceContentTitle2Id,
    buyerTileMarketplaceContentTitle3Id,
    buyerTileMarketplaceContentText1Id,
    buyerTileMarketplaceContentText2Id,
    buyerTileMarketplaceContentText3Id,
    buyerTileMarketplacePrimaryCTATo,
    buyerTileMarketplacePrimaryCTAId,
    buyerTileMarketplaceSecondaryCTATo,
    buyerTileMarketplaceSecondaryCTAId,
    showSecondaryCTA,
  } = props;

  const buyerTileMarketplacePrimaryCTA = (
    <NamedLink
      name={buyerTileMarketplacePrimaryCTATo}
      className={showSecondaryCTA ? css.buyerTilePrimaryCTA : css.buyerTilePrimaryCTAFullWidth}
    >
      <FormattedMessage id={buyerTileMarketplacePrimaryCTAId} />
    </NamedLink>
  );

  const buyerTileMarketplaceSecondaryCTA = (
    <NamedLink name={buyerTileMarketplaceSecondaryCTATo} className={css.buyerTileSecondaryCTA}>
      <FormattedMessage id={buyerTileMarketplaceSecondaryCTAId} />
    </NamedLink>
  );

  return (
    <div className={css.buyerTile}>
      <div className={css.buyerTileTitle}>
        <FormattedMessage id={buyerTileMarketplaceTitleId} />
      </div>
      <div className={css.buyerTileSubtitle}>
        <FormattedMessage id={buyerTileMarketplacesubTitleId} />
      </div>
      <div className={css.buyerTileMarketplaceContents}>
        <div className={css.buyerTileMarketplaceContent}>
          <div className={css.buyerTileMarketplaceContentTitleRow}>
            <img src={penPaperIcon} className={css.buyerTileMarketplaceContentIcon} />
            <FormattedMessage id={buyerTileMarketplaceContentTitle1Id} />
          </div>
          <div className={css.buyerTileFreedomlyMarketplaceContentText}>
            <FormattedMessage id={buyerTileMarketplaceContentText1Id} />
          </div>
        </div>
        <div className={css.buyerTileMarketplaceContent}>
          <div className={css.buyerTileMarketplaceContent}>
            <div className={css.buyerTileMarketplaceContentTitleRow}>
              <img src={chatBubbleIcon} className={css.buyerTileMarketplaceContentIcon} />
              <FormattedMessage id={buyerTileMarketplaceContentTitle2Id} />
            </div>
            <div className={css.buyerTileFreedomlyMarketplaceContentText}>
              <FormattedMessage id={buyerTileMarketplaceContentText2Id} />
            </div>
          </div>
        </div>
        <div className={css.buyerTileMarketplaceContent}>
          <div className={css.buyerTileMarketplaceContent}>
            <div className={css.buyerTileMarketplaceContentTitleRow}>
              <img src={handshakeIcon} className={css.buyerTileMarketplaceContentIcon} />
              <FormattedMessage id={buyerTileMarketplaceContentTitle3Id} />
            </div>
            <div className={css.buyerTileFreedomlyMarketplaceContentText}>
              <FormattedMessage id={buyerTileMarketplaceContentText3Id} />
            </div>
          </div>
        </div>
      </div>
      <div className={css.buyerTileCTAContainer}>
        {showSecondaryCTA && buyerTileMarketplaceSecondaryCTA}
        {buyerTileMarketplacePrimaryCTA}
      </div>
    </div>
  );
};

export default BuyerTileMarketplace;
