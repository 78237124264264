import React from 'react';
import renderHTML from 'react-render-html';

import css from './ApplicationPanel.css';

const SectionProposal = ({ intl, bestFit, similarWork, questions }) => {
  const heading = intl.formatMessage({ id: 'ApplicationPanel.proposal' });
  const subHeadingFit = intl.formatMessage({ id: 'ApplicationPanel.bestFit' });
  const subHeadingWorkDone = intl.formatMessage({ id: 'ApplicationPanel.workDoneBefore' });
  const subHeadingQuestions = intl.formatMessage({ id: 'ApplicationPanel.projectQuestions' });

  return (
    <div className={css.proposal}>
      <h1 className={css.proposalHeading}>{heading}</h1>
      <br></br>
      <h3>{subHeadingFit}</h3>
      <div>{renderHTML(bestFit)}</div>
      <br></br>
      <h3>{subHeadingWorkDone}</h3>
      <div>{renderHTML(similarWork)}</div>
      <br></br>
      <h3>{subHeadingQuestions}</h3>
      <div>{renderHTML(questions)}</div>
    </div>
  );
};

export default SectionProposal;
