import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import logoStrongest from '../../assets/strongest-logo-black.svg';
import logoMobal from '../../assets/Mobal_Logo_Transparent symbol_Black.svg';
import schoffaLogo from '../../assets/schoffaLogo.svg';
import zervantLogo from '../../assets/zervant-logo.svg';
import olefitLogo from '../../assets/olefit-logo.svg';
import tulusLogo from '../../assets/tulus-logo.svg';
import systamLogo from '../../assets/systam-logo.svg';
import coregoLogo from '../../assets/corego-logo.png';
import piirlaLogo from '../../assets/piirla-logo.png';

import css from './SectionCustomerLogos.css';

const SectionCustomerLogos = props => {
  return (
    <div className={css.proLogoSection}>
      <div className={css.proLogosWrapper}>
        <div className={css.proLogosOurCustomers}>
          <FormattedMessage id="SectionCustomerLogos.ourCustomers" />
        </div>
        <div className={css.proLogosContainer}>
          <div className={css.proLandingPageLogoContainerMedium}>
            <img
              src={logoMobal}
              alt="Mobal Logo"
              className={css.proLandingPageLogo}
              loading="lazy"
            />
          </div>
          <div className={css.proLandingPageLogoContainerMedium}>
            <img
              src={logoStrongest}
              alt="Strongest Logo"
              className={css.proLandingPageLogo}
              loading="lazy"
            />
          </div>
          <div className={css.proLandingPageLogoContainerMedium}>
            <img
              src={zervantLogo}
              alt="Zervant Logo"
              className={css.proLandingPageLogo}
              loading="lazy"
            />
          </div>
          <div className={css.proLandingPageLogoContainerSmall}>
            <img
              src={coregoLogo}
              alt="Corego Logo"
              className={css.proLandingPageLogo}
              loading="lazy"
            />
          </div>
          <div className={css.proLandingPageLogoContainerXSmall}>
            <img
              src={olefitLogo}
              alt="OleFit Logo"
              className={css.proLandingPageLogo}
              loading="lazy"
            />
          </div>
          <div className={css.proLandingPageLogoContainerSmall}>
            <img
              src={systamLogo}
              alt="Systam Logo"
              className={css.proLandingPageLogo}
              loading="lazy"
            />
          </div>
          <div className={css.proLandingPageLogoContainerSmall}>
            <img
              src={tulusLogo}
              alt="Tulus Logo"
              className={css.proLandingPageLogo}
              loading="lazy"
            />
          </div>
          <div className={css.proLandingPageLogoContainerXSmall}>
            <img
              src={piirlaLogo}
              alt="Piirla Logo"
              className={css.proLandingPageLogo}
              loading="lazy"
            />
          </div>
          <div className={css.proLandingPageLogoContainerMedium}>
            <img
              src={schoffaLogo}
              alt="Schoffa Logo"
              className={css.proLandingPageLogo}
              loading="lazy"
            />
          </div>
        </div>
      </div>
      {/* <div className={css.proLogoSectionDivider}></div> */}
    </div>
  );
};

export default SectionCustomerLogos;
