import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { createSlug } from '../../util/urlHelpers';

import css from './ApplicationPanel.css';

const SectionHeading = ({ listing, listingDeleted }) => {
  const listingTitle = listing.attributes.title;
  const slug = createSlug(listingTitle);
  const id = listing.id.uuid;

  return (
    <div>
      <div>
        <NamedLink name="ApplicationInboxPage">
          &lt; <FormattedMessage id="ApplicationPanel.inboxLinkText" />
        </NamedLink>
      </div>
      <div>
        <span className={css.projectListingSpan}>
          <FormattedMessage id="ApplicationPanel.projectListingLink" />
        </span>
        {!listingDeleted ? (
          <NamedLink
            className={css.projectListingLink}
            name="ProjectListingPage"
            params={{ id, slug }}
          >
            {listingTitle}
          </NamedLink>
        ) : (
          <FormattedMessage id="TransactionPanel.deletedListingTitle" />
        )}
      </div>
    </div>
  );
};

export default SectionHeading;
