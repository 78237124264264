import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Form,
  IconSpinner,
  PrimaryButton,
  FieldDateRangeInput,
  FieldSelect,
  FieldCurrencyInput,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import css from './BookingPackagesForm.css';
import * as validators from '../../util/validators';

const { Money } = sdkTypes;

const identity = v => v;

export class BookingPackagesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    if (!this.props.authorStripeConnected) {
      this.props.onSubmit(e);
    } else {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() + 1);
      let endDate = new Date();
      endDate.setDate(endDate.getDate() + 2);

      if (!startDate) {
        e.preventDefault();
        this.setState({ focusedInput: START_DATE });
      } else if (!endDate) {
        e.preventDefault();
        this.setState({ focusedInput: END_DATE });
      } else {
        this.props.onSubmit(e);
      }
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { startDate, endDate } =
      formValues.values && formValues.values.bookingDates ? formValues.values.bookingDates : {};
    const { pricingPackage, suggestedPrice } = formValues.values;
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    if (startDate && endDate && pricingPackage && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, pricingPackage, suggestedPrice },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            listing,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            authorStripeConnected,
          } = fieldRenderProps;

          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};
          const price = listing ? listing.attributes.price : null;
          const publicData =
            listing && listing.attributes.publicData ? listing.attributes.publicData : {};
          const pricingPackages = {};
          const author = listing && listing.author;
          const isAuthorLightEntrepreneur =
            author &&
            author.attributes.profile.publicData &&
            author.attributes.profile.publicData.account_role === 'light-entrepreneur';

          if (publicData.package1Name) {
            pricingPackages.package1 = { price, label: publicData.package1Name };
          }
          if (publicData.package2Name && publicData.package2Price) {
            pricingPackages.package2 = {
              price: new Money(publicData.package2Price, 'EUR'),
              label: publicData.package2Name,
            };
          }
          if (publicData.package3Name && publicData.package3Price) {
            pricingPackages.package3 = {
              price: new Money(publicData.package3Price, 'EUR'),
              label: publicData.package3Name,
            };
          }
          if (publicData.package4Name && publicData.package4Price) {
            pricingPackages.package4 = {
              price: new Money(publicData.package4Price, 'EUR'),
              label: publicData.package4Name,
            };
          }
          if (publicData.package5Name && publicData.package5Price) {
            pricingPackages.package5 = {
              price: new Money(publicData.package5Price, 'EUR'),
              label: publicData.package5Name,
            };
          }

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });

          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          const packageLabel = intl.formatMessage({ id: 'BookingPackagesForm.packageLabel' });
          const packagePlaceholder = intl.formatMessage({
            id: 'BookingPackagesForm.packagePlaceholder',
          });
          const packageRequired = validators.required(
            intl.formatMessage({
              id: 'BookingPackagesForm.packageRequired',
            })
          );

          const suggestedPriceLabel = intl.formatMessage({
            id: 'BookingPackagesForm.suggestedPriceLabel',
          });

          const suggestedPricePlaceholder = intl.formatMessage({
            id: 'BookingPackagesForm.suggestedPricePlaceholder',
          });

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const formControls =
            authorStripeConnected || isAuthorLightEntrepreneur ? (
              <>
                {timeSlotsError}
                <FormSpy
                  subscription={{ values: true }}
                  onChange={values => {
                    this.handleOnChange(values);
                  }}
                />
                <FieldSelect
                  id={`${formId}.pricingPackage`}
                  name="pricingPackage"
                  className={css.packageSelect}
                  label={packageLabel}
                  validate={packageRequired}
                >
                  <option disabled value="">
                    {packagePlaceholder}
                  </option>
                  {Object.keys(pricingPackages).map(pricingPackage => {
                    return (
                      <option key={pricingPackage} value={pricingPackage}>
                        {pricingPackages[pricingPackage].label} &nbsp; (
                        {formatMoney(intl, pricingPackages[pricingPackage].price)})
                      </option>
                    );
                  })}
                </FieldSelect>
                <FieldCurrencyInput
                  id={`${formId}.suggestedPrice`}
                  name="suggestedPrice"
                  className={css.suggestedPriceInput}
                  label={suggestedPriceLabel}
                  placeholder={suggestedPricePlaceholder}
                  currencyConfig={config.currencyConfig}
                />

                <FieldDateRangeInput
                  className={css.bookingDates}
                  name="bookingDates"
                  unitType={unitType}
                  startDateId={`${formId}.bookingStartDate`}
                  startDateLabel={bookingStartLabel}
                  startDatePlaceholderText={startDatePlaceholderText}
                  endDateId={`${formId}.bookingEndDate`}
                  endDateLabel={bookingEndLabel}
                  endDatePlaceholderText={endDatePlaceholderText}
                  focusedInput={this.state.focusedInput}
                  onFocusedInputChange={this.onFocusedInputChange}
                  format={identity}
                  timeSlots={timeSlots}
                  useMobileMargins
                  disabled={fetchLineItemsInProgress}
                />

                {bookingInfoMaybe}
                {loadingSpinnerMaybe}
                {bookingInfoErrorMaybe}

                <p className={css.smallPrint}>
                  <FormattedMessage
                    id={
                      isOwnListing
                        ? 'BookingDatesForm.ownListing'
                        : 'BookingDatesForm.youWontBeChargedInfo'
                    }
                  />
                </p>
              </>
            ) : (
              <div className={css.contactAuthorContainer}>
                <h3 className={css.contactAuthorTitle}>
                  <FormattedMessage id="BookingDatesForm.contactAuthorMessage" />
                </h3>
              </div>
            );

          const buttonMessage =
            authorStripeConnected || isAuthorLightEntrepreneur ? (
              <FormattedMessage id="BookingDatesForm.requestToBook" />
            ) : (
              <FormattedMessage id="BookingDatesForm.requestToContact" />
            );

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              {formControls}
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit">{buttonMessage}</PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingPackagesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPackagesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingPackagesForm = compose(injectIntl)(BookingPackagesFormComponent);
BookingPackagesForm.displayName = 'BookingPackagesForm';

export default BookingPackagesForm;
