/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { ensureCurrentUser } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  Modal,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { ProjectBidForm, PayoutInfoMissingForm } from '../../forms';

import {
  sendEnquiry,
  sendProjectBid,
  loadData,
  setInitialValues,
  fetchTransactionLineItems,
} from './ProjectListingPage.duck';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionHostMaybe from './SectionHostMaybe';
import SectionIconsMaybe from './SectionIconsMaybe';
import css from './ProjectListingPage.css';
import ActionBarMaybe from './ActionBarMaybe';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

const mainCategoryKey = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat && cat.parent ? cat.parent : key;
};

export class ProjectListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, projectBidModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      projectBidModalOpen: projectBidModalOpenForListingId === params.id,
      payoutInfoMissingModalOpen: false,
      payoutMissingAlreadyShown: false,
      showHiringSteps: true,
    };

    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onProjectBid = this.onProjectBid.bind(this);
    this.onSubmitProjectBid = this.onSubmitProjectBid.bind(this);
    this.onSubmitFillPayoutInfo = this.onSubmitFillPayoutInfo.bind(this);
    this.redirectToProposalPage = this.redirectToProposalPage.bind(this);
  }

  onContactUser() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
      stripeAccount,
      showHiringSteps,
    } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      const ensuredCurrentUser = ensureCurrentUser(currentUser);
      const currentUserLoaded = !!ensuredCurrentUser.id;
      const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;
      const accountId = stripeConnected ? stripeAccount.id : null;
      const isLightEntrepreneur =
        ensuredCurrentUser &&
        ensuredCurrentUser.attributes &&
        ensuredCurrentUser.attributes.profile.publicData.account_role === 'light-entrepreneur';

      if (
        (stripeConnected && accountId) ||
        this.state.payoutMissingAlreadyShown ||
        isLightEntrepreneur
      ) {
        this.setState({ enquiryModalOpen: true, showHiringSteps: true });
      } else {
        this.setState({
          payoutInfoMissingModalOpen: true,
          payoutMissingAlreadyShown: true,
          showHiringSteps,
        });
      }
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, getListing, getOwnListing, currentUser } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;
    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingTitle = currentListing.attributes.title;

    onSendEnquiry(listingId, message.trim(), currentUser, listingTitle)
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        if (typeof window === 'object') {
          window.dataLayer.push({
            event: 'contact_author',
            ecommerce: {
              items: [
                {
                  item_name: currentListing.attributes.title,
                  item_id: currentListing.id.uuid,
                  price: '' + currentListing.attributes.price.amount / 100,
                  item_brand: currentListing.author.attributes.profile.displayName,
                  item_category: currentListing.attributes.publicData.category,
                  item_list_name: 'listing_page',
                },
              ],
            },
          });
        }

        // Redirect to ApplicationOrderPage
        history.push(
          createResourceLocatorString('ApplicationOrderPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  onProjectBid() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
      stripeAccount,
    } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { projectBidModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      const ensuredCurrentUser = ensureCurrentUser(currentUser);
      const currentUserLoaded = !!ensuredCurrentUser.id;
      const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;
      const accountId = stripeConnected ? stripeAccount.id : null;

      if ((stripeConnected && accountId) || this.state.payoutMissingAlreadyShown) {
        this.setState({ projectBidModalOpen: true }, () => {
          ReactTooltip.rebuild();
        });
      } else {
        this.setState({ payoutInfoMissingModalOpen: true, payoutMissingAlreadyShown: true });
      }
    }
  }

  onSubmitProjectBid(values) {
    const { history, params, onSendProjectBid } = this.props;
    const routes = routeConfiguration();
    const { description, amount } = values;

    onSendProjectBid(params.id, description, amount.amount)
      .then(txId => {
        this.setState({ projectBidModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  onSubmitFillPayoutInfo() {
    const { history } = this.props;
    const routes = routeConfiguration();

    history.push(createResourceLocatorString('StripePayoutPage', routes, {}, {}));
  }

  redirectToProposalPage() {
    const { currentUser, history, params, stripeAccount, showHiringSteps } = this.props;
    const routes = routeConfiguration();

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;
    const accountId = stripeConnected ? stripeAccount.id : null;
    const isLightEntrepreneur =
      ensuredCurrentUser &&
      ensuredCurrentUser.attributes &&
      ensuredCurrentUser.attributes.profile.publicData.account_role === 'light-entrepreneur';

    if ((stripeConnected && accountId) || isLightEntrepreneur) {
      history.push(createResourceLocatorString('ProjectProposalPage', routes, params, {}));
    } else {
      this.setState({
        payoutInfoMissingModalOpen: true,
        // payoutMissingAlreadyShown: true,
        showHiringSteps,
      });
    }
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      sendProjectBidInProgress,
      sendProjectBidError,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const { description = '', price = null, title = '' } = currentListing.attributes;
    const descriptionText = currentListing.attributes.publicData.descriptionText;
    const publicData = currentListing.attributes.publicData || {};

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const userPublicData =
      (currentUser &&
        currentUser.attributes &&
        currentUser.attributes.profile &&
        currentUser.attributes.profile.publicData) ||
      {};
    const { account_role = '' } = userPublicData;
    const isProvider = ['freelancer', 'light-entrepreneur'].includes(account_role);

    const bookingSubTitle = !currentUser
      ? intl.formatMessage({ id: 'ProjectListingPage.notLoggedIn' })
      : isProvider
      ? intl.formatMessage({ id: 'ProjectListingPage.bookingSubTitle' })
      : intl.formatMessage({ id: 'ProjectListingPage.bookingSubTitleNoBids' });

    const topbar = <TopbarContainer currentPage="ProjectListingPage" />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const isClosed = currentListing.attributes.state === 'closed';
    const showContactUser =
      authorAvailable &&
      (!currentUser || (currentUser && !isOwnListing)) &&
      isProvider &&
      !isClosed;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const authorStripeConnected = !!(
      currentAuthor &&
      currentAuthor.attributes.profile.publicData &&
      currentAuthor.attributes.profile.publicData.stripeConnected
    );

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const bookingTitle = (
      <FormattedMessage
        id="ProjectListingPage.bookingTitle"
        values={{ title: currentListing.attributes.title }}
      />
    );

    const projectBidButtonText = <FormattedMessage id="BookingPanel.ctaButtonProjectBid" />;

    const formattedPrice = formatMoney(intl, price);

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ProjectListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ProjectListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const editParams = {
      id: listingId.uuid,
      slug: listingSlug,
      type: listingType,
      tab: listingTab,
    };

    const actionBar = currentListing.id ? (
      <div className={css.actionBarContainer} onClick={e => e.stopPropagation()}>
        <ActionBarMaybe
          isOwnListing={isOwnListing}
          listing={currentListing}
          editParams={editParams}
        />
      </div>
    ) : null;

    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const category =
      publicData && publicData.category ? (
        <span>{categoryLabel(categoryOptions, publicData.category)}</span>
      ) : null;

    const schemaDescriptionPlaceholder = intl.formatMessage(
      { id: 'ListingPage.schemaDescriptionPlaceholder' },
      {
        title,
      }
    );

    const averageReviewRating = () => {
      const reviewRating = reviews.map(review => review.attributes.rating);
      const average = reviewRating.reduce((a, b) => a + b, 0) / reviewRating.length;
      return average;
    };

    const schemaReviews = reviews?.map(review => {
      const publishedDateTemp = new Date(review.attributes.createdAt);
      const reviewRating = review.attributes.rating;
      const reviewBody = review.attributes.content;
      const reviewAuthor = review.author.attributes.profile.displayName;
      const year = publishedDateTemp.getFullYear();
      const month = publishedDateTemp.getMonth();
      const day = publishedDateTemp.getDate();
      const datePublished = `${year}-${month + 1}-${day}`;

      return {
        '@type': 'Review',
        author: reviewAuthor,
        datePublished: datePublished,
        reviewBody: reviewBody,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: reviewRating,
          bestRating: '5',
          worstRating: '1',
        },
      };
    });

    const canonicalURL = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/pl/${createSlug(title)}/${
      currentListing.id.uuid
    }`;

    const schema = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: schemaTitle,
      image: facebookImages[0]?.url,
      description: descriptionText
        ? descriptionText.replace(/<\/?[^>]+(>|$)/g, '')
        : schemaDescriptionPlaceholder,
      review: schemaReviews,
      brand: {
        '@type': 'Brand',
        name: 'Freedomly',
      },

      offers: {
        '@type': 'AggregateOffer',
        offerCount: '1',
        price: price.amount / 100,
        priceCurrency: 'EUR',
        url: canonicalURL,
        availability: 'https://schema.org/InStock',
      },

      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: averageReviewRating(),
        reviewCount: reviews.length,
      },
    };

    if (currentListing.attributes.publicData.listingType === 'service') {
      return (
        <NamedRedirect
          name="ListingPage"
          params={{
            slug: createSlug(currentListing.attributes.title || ''),
            id: currentListing.id.uuid,
          }}
          search={location.search}
        />
      );
    }

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={schema}
        listingCanonicalURL={canonicalURL}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {actionBar}
            <div>
              <div className={css.contentContainer}>
                <div className={css.mainContent}>
                  <SectionHeading
                    richTitle={richTitle}
                    category={category}
                    categoryKey={publicData.category}
                    mainCategory={mainCategoryKey(categoryOptions, publicData.category)}
                    mainCategoryLabel={categoryLabel(
                      categoryOptions,
                      mainCategoryKey(categoryOptions, publicData.category)
                    )}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    user={ensuredAuthor}
                    onContactUser={this.redirectToProposalPage}
                  />
                  <SectionDescriptionMaybe publicData={publicData} />
                  <div>
                    <SectionIconsMaybe publicData={publicData} />
                  </div>
                  <SectionHostMaybe
                    title={title}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.redirectToProposalPage}
                    showHiringSteps={isAuthenticated && this.state.showHiringSteps}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                  {/* <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} /> */}
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  contactOnly={true}
                  showContactUser={showContactUser}
                  onContactUser={this.redirectToProposalPage}
                  unitType={unitType}
                  onSubmit={this.redirectToProposalPage}
                  title={bookingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  authorStripeConnected={authorStripeConnected}
                  currentUser={currentUser}
                  projectBidButtonText={projectBidButtonText}
                />
              </div>
            </div>
            <Modal
              id="ProjectListingPage.projectBid"
              contentClassName={css.enquiryModalContent}
              isOpen={this.state.projectBidModalOpen}
              onClose={() => {
                this.setState({ projectBidModalOpen: false });
                this.redirectToProposalPage();
              }}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <ProjectBidForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendProjectBidError}
                onSubmit={this.onSubmitProjectBid}
                inProgress={sendProjectBidInProgress}
              />
            </Modal>
            {typeof window === 'object' ? <ReactTooltip /> : null}
            <Modal
              id="ProjectListingPage.payoutInfoMissing"
              contentClassName={css.enquiryModalContent}
              isOpen={this.state.payoutInfoMissingModalOpen}
              onClose={() => {
                this.setState({ payoutInfoMissingModalOpen: false });
                // this.redirectToProposalPage();
              }}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <PayoutInfoMissingForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendProjectBidError}
                onSubmit={this.onSubmitFillPayoutInfo}
                inProgress={sendProjectBidInProgress}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProjectListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ProjectListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    projectBidModalOpenForListingId,
    sendProjectBidInProgress,
    sendProjectBidError,
  } = state.ProjectListingPage;
  const {
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    projectBidModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    sendProjectBidInProgress,
    sendProjectBidError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message, currentUser, listingTitle) =>
    dispatch(sendEnquiry(listingId, message, currentUser, listingTitle)),
  onSendProjectBid: (listingId, description, amount) =>
    dispatch(sendProjectBid(listingId, description, amount)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ProjectListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProjectListingPageComponent);

ProjectListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ProjectListingPage.loadData = loadData;

export default ProjectListingPage;
