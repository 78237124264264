import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { injectIntl } from '../../util/reactIntl';
import { Form, SecondaryButton, Button, FieldCheckbox, FieldRadioButton } from '../../components';
import { required } from '../../util/validators';

import css from './SignupProviderForms.css';

const SignupProviderHourlyFeeForm = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          invalid,
        } = formRenderProps;

        const submitDisabled = invalid;

        const classes = classNames(rootClassName || css.root, className);

        const heading = intl.formatMessage({ id: 'SignupProviderForms.hourlyFeeHeading' });

        const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.nextButton' });
        const previousButtonText = intl.formatMessage({ id: 'SignupProviderForms.previousButton' });

        const hourlyFeeLabel = intl.formatMessage({ id: 'SignupProviderForms.hourlyFeeLabel' });

        const point1 = intl.formatMessage({ id: 'SignupProviderForms.hourlyFeePoint1' });
        const point2 = intl.formatMessage({ id: 'SignupProviderForms.hourlyFeePoint2' });

        const dontShowHourlyFeeLabel = intl.formatMessage({
          id: 'SignupProviderForm.hourlyFeeDontShowHourlyFee',
        });

        const hourlyFeeRequired = intl.formatMessage({
          id: 'SignupProviderForm.hourlyFeeRequired',
        });

        const overHundred = intl.formatMessage({
          id: 'SignupProviderForm.overHundred',
        });

        return (
          <div className={classes}>
            <h2 className={css.mainHeading}>{heading}</h2>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <div>
                <label>{hourlyFeeLabel}</label>
                <FieldRadioButton
                  id="hourlyFeeOption1"
                  name="hourlyFee"
                  label="20-39 €"
                  value="20-39 / h"
                  checkboxIcon
                  validate={required(hourlyFeeRequired)}
                />
                <FieldRadioButton
                  id="hourlyFeeOption2"
                  name="hourlyFee"
                  label="40-59 €"
                  value="40-59 / h"
                  checkboxIcon
                  validate={required(hourlyFeeRequired)}
                />
                <FieldRadioButton
                  id="hourlyFeeOption3"
                  name="hourlyFee"
                  label="60-79 €"
                  value="60-79 / h"
                  checkboxIcon
                  validate={required(hourlyFeeRequired)}
                />
                <FieldRadioButton
                  id="hourlyFeeOption4"
                  name="hourlyFee"
                  label="80-99 €"
                  value="80-99 / h"
                  checkboxIcon
                  validate={required(hourlyFeeRequired)}
                />
                <FieldRadioButton
                  id="hourlyFeeOption5"
                  name="hourlyFee"
                  label={overHundred}
                  value="+ 100 / h"
                  checkboxIcon
                  validate={required(hourlyFeeRequired)}
                />
              </div>
              <ul className={css.pointerList}>
                <li>{point1}</li>
                <li>{point2}</li>
              </ul>
              <FieldCheckbox
                id="dontShowHourlyFee"
                name="dontShowHourlyFee"
                label={dontShowHourlyFeeLabel}
                defaultValue={false}
              />
              <div className={css.submitContainer}>
                <SecondaryButton rootClassName={css.backButton} onClick={onPrev}>
                  {previousButtonText}
                </SecondaryButton>
                <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                  {nextButtonText}
                </Button>
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

export default injectIntl(SignupProviderHourlyFeeForm);
