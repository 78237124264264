import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTwitter.css';

const IconSocialMediaTwitter = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    // <svg
    //   className={classes}
    //   width="16"
    //   height="14"
    //   viewBox="0 0 16 14"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M14.956 2.37c-.513.225-1.07.388-1.647.45.592-.36 1.044-.928 1.257-1.604-.55.334-1.17.577-1.816.703-.52-.568-1.265-.92-2.09-.92C9.077 1 7.8 2.307 7.8 3.912c0 .225.028.45.072.667C5.5 4.45 3.382 3.3 1.974 1.53c-.248.433-.39.928-.39 1.47 0 1.01.505 1.9 1.276 2.424-.47-.018-.912-.153-1.293-.37v.036c0 1.416.983 2.587 2.293 2.858-.24.063-.496.1-.752.1-.186 0-.363-.02-.54-.046.362 1.154 1.416 1.992 2.674 2.02-.983.783-2.214 1.243-3.55 1.243-.24 0-.462-.01-.692-.036 1.266.83 2.772 1.308 4.392 1.308 5.26 0 8.138-4.435 8.138-8.284 0-.126 0-.252-.008-.38.557-.413 1.045-.927 1.434-1.504z"
    //     fillRule="evenodd"
    //   />
    // </svg>
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 248 204">
      <path
        d="M221.95,51.29c0.15,2.17,0.15,4.34,0.15,6.53c0,66.73-50.8,143.69-143.69,143.69v-0.04
		C50.97,201.51,24.1,193.65,1,178.83c3.99,0.48,8,0.72,12.02,0.73c22.74,0.02,44.83-7.61,62.72-21.66
		c-21.61-0.41-40.56-14.5-47.18-35.07c7.57,1.46,15.37,1.16,22.8-0.87C27.8,117.2,10.85,96.5,10.85,72.46c0-0.22,0-0.43,0-0.64
		c7.02,3.91,14.88,6.08,22.92,6.32C11.58,63.31,4.74,33.79,18.14,10.71c25.64,31.55,63.47,50.73,104.08,52.76
		c-4.07-17.54,1.49-35.92,14.61-48.25c20.34-19.12,52.33-18.14,71.45,2.19c11.31-2.23,22.15-6.38,32.07-12.26
		c-3.77,11.69-11.66,21.62-22.2,27.93c10.01-1.18,19.79-3.86,29-7.95C240.37,35.29,231.83,44.14,221.95,51.29z"
      />
    </svg>
  );
};

IconSocialMediaTwitter.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTwitter.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTwitter;
