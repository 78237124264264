import React from 'react';

import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import { Form, FieldTextEditor, SecondaryButton, Button } from '../../components';
import { injectIntl } from '../../util/reactIntl';
import { required, textEditorValidation, composeValidators } from '../../util/validators';

import css from './SignupProviderForms.css';

const SignupProviderBioForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          invalid,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid;

        const heading = intl.formatMessage({ id: 'SignupProviderForms.bioHeading' });

        const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.nextButton' });
        const previousButtonText = intl.formatMessage({ id: 'SignupProviderForms.previousButton' });

        const bioLabel = intl.formatMessage({ id: 'SignupProviderForms.bioBioLabel' });
        const bioPlaceholder = intl.formatMessage({ id: 'SignupProviderForms.bioBioPlaceholder' });

        const pointsHeader = intl.formatMessage({ id: 'SignupProviderForms.bioPointsHeader' });
        const point1 = intl.formatMessage({ id: 'SignupProviderForms.bioPoint1' });
        const point2 = intl.formatMessage({ id: 'SignupProviderForms.bioPoint2' });
        const point3 = intl.formatMessage({ id: 'SignupProviderForms.bioPoint3' });
        const bioPoint4 = intl.formatMessage({ id: 'SignupProviderForms.bioPoint4' });

        const bioRequired = intl.formatMessage({ id: 'SignupProviderForms.bioRequired' });
        const externalLinksMessage = intl.formatMessage({
          id: 'RichTextEditor.externalLinksError',
        });

        return (
          <div className={classes}>
            <h2 className={css.mainHeading}>{heading}</h2>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <FieldTextEditor
                id="bio"
                name="bio"
                quillClass={css.quillClass}
                label={bioLabel}
                placeholder={bioPlaceholder}
                validate={composeValidators(
                  textEditorValidation(externalLinksMessage),
                  required(bioRequired)
                )}
              />
              <div className={css.bioPointerContainer}>
                <div className={css.pointsHeader}>{pointsHeader}</div>
                <ul className={css.pointerList}>
                  <li>{point1}</li>
                  <li>{point2}</li>
                  <li>{point3}</li>
                  <li>{bioPoint4}</li>
                </ul>
              </div>

              <div className={css.submitContainer}>
                <SecondaryButton rootClassName={css.backButton} onClick={onPrev}>
                  {previousButtonText}
                </SecondaryButton>
                <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                  {nextButtonText}
                </Button>
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

export default injectIntl(SignupProviderBioForm);
