/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import classNames from 'classnames';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  REVIEW_TYPE_OF_CUSTOMER,
  REVIEW_TYPE_OF_PROVIDER,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
  ensureCurrentUser,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { savePaymentMethod, deletePaymentMethod } from '../../ducks/paymentMethods.duck';
import {
  createStripeSetupIntent,
  stripeCustomer,
} from '../PaymentMethodsPage/PaymentMethodsPage.duck.js';
import { handleCardSetup } from '../../ducks/stripe.duck';
import {
  Page,
  AvatarLarge,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  ProfileSettingsContainer as Container,
  Reviews,
  VerifiedUserMark,
  EducationContainer,
  LanguagesContainer,
  TopSkillsContainer,
  WorkExperienceContainer,
  ProjectListingCard,
  ListingCard,
  WorkedWithContainer,
  CodeOfConductContainer,
  InlineTextButton,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';

import {
  sendEnquiry,
  loadData,
  setInitialValues,
  fetchTransactionLineItems,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionPricingPackages from './SectionPricingPackages';
import SectionRulesMaybe from './SectionRulesMaybe';
import renderHTML from 'react-render-html';

import { FaEuroSign, FaMapMarkerAlt, FaStar } from 'react-icons/fa';

import css from './ListingPage.css';
import { updateFavoriteListings } from '../../ducks/user.duck';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { Money, UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

const mainCategoryKey = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat && cat.parent ? cat.parent : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      showHiringSteps: false,
      paymentMethodModalOpen: false,
      paymentMethodFormSubmitting: false,
      paymentMethodModalState: 0,
      cardState: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.toggleReviews = this.showRestOfReviews.bind(this);
  }

  showRestOfReviews() {
    this.setState({
      toggleRestOfReviews: !this.state.toggleRestOfReviews,
    });
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { ...bookingData } = values;
    let startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 2);

    const initialValues = {
      listing,
      bookingData: bookingData,
      bookingDates: {
        bookingStart: startDate,
        bookingEnd: endDate,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser(openModal = true) {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    const hasPaymentMethod = !!currentUser?.stripeCustomer?.defaultPaymentMethod;
    const invoiceEnabled = currentUser?.attributes?.profile?.publicData?.invoiceEnabled === true;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      openModal &&
        callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else if (currentUser && !hasPaymentMethod && !invoiceEnabled) {
      this.setState({ paymentMethodModalOpen: true });
    } else {
      this.setState({ enquiryModalOpen: true, showHiringSteps: false });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry, getListing, getOwnListing, currentUser } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;
    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const author = currentListing && currentListing.author;
    const isLightEntrepreneur =
      author &&
      author.attributes &&
      author.attributes.profile.publicData &&
      author.attributes.profile.publicData.account_role === 'light-entrepreneur';
    const listingTitle = currentListing.attributes.title;

    onSendEnquiry(listingId, isLightEntrepreneur, message.trim(), currentUser, listingTitle)
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        if (typeof window === 'object') {
          window.dataLayer.push({
            event: 'contact_author',
            ecommerce: {
              items: [
                {
                  item_name: currentListing.attributes.title,
                  item_id: currentListing.id.uuid,
                  price: 0,
                  item_brand: currentListing.author.attributes.profile.displayName,
                  item_category: currentListing.attributes.publicData.category,
                  item_list_name: 'listing_page',
                },
              ],
            },
          });
        }

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onupdateFavoriteListings,
      favoriteListings,
      otherListings,
      addPaymentMethodError,
      deletePaymentMethodError,
      createStripeCustomerError,
      handleCardSetupError,
      onCreateSetupIntent,
      onHandleCardSetup,
      onSavePaymentMethod,
      onDeletePaymentMethod,
      fetchStripeCustomer,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    const isDataAvailable = isAuthenticated
      ? currentUser &&
        currentUser.id &&
        currentListing &&
        currentListing.id &&
        currentListing.id.uuid === params.id
      : currentListing && currentListing.id && currentListing.id.uuid === params.id;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const { description = '', price = null, title = '' } = currentListing.attributes;
    const descriptionText = currentListing.attributes.publicData.descriptionText;
    const publicData = currentListing.attributes.publicData || {};

    const userPublicData =
      (currentUser &&
        currentUser.attributes &&
        currentUser.attributes.profile &&
        currentUser.attributes.profile.publicData) ||
      {};
    const { accountRole = '' } = userPublicData;
    const isProvider = ['freelancer', 'light-entrepreneur'].includes(accountRole);

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingSubTitle = !isProvider
      ? intl.formatMessage({ id: 'ListingPage.bookingSubTitle' })
      : '';

    const topbar = <TopbarContainer currentPage="ListingPage" />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      console.error('Error loading service listing page');
      console.error(showListingError);
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser =
      authorAvailable && (!currentUser || (currentUser && !isOwnListing)) && !isProvider;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const authorStripeConnected = !!(
      currentAuthor &&
      currentAuthor.attributes.profile.publicData &&
      currentAuthor.attributes.profile.publicData.stripeConnected
    );

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: authorDisplayName }} />
    );

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const schemaPriceArray = [];
    schemaPriceArray.push(price?.amount);

    let {
      formattedPrice2,
      priceTitle2,
      formattedPrice3,
      priceTitle3,
      formattedPrice4,
      priceTitle4,
      formattedPrice5,
      priceTitle5,
    } = {};
    if (Number.isInteger(publicData.package2Price)) {
      const price2 = new Money(publicData.package2Price, 'EUR');
      schemaPriceArray.push(price2.amount);
      const { formattedPrice, priceTitle } = priceData(price2, intl);
      formattedPrice2 = formattedPrice;
      priceTitle2 = priceTitle;
    }
    if (Number.isInteger(publicData.package3Price)) {
      const price3 = new Money(publicData.package3Price, 'EUR');
      schemaPriceArray.push(price3.amount);
      const { formattedPrice, priceTitle } = priceData(price3, intl);
      formattedPrice3 = formattedPrice;
      priceTitle3 = priceTitle;
    }
    if (Number.isInteger(publicData.package4Price)) {
      const price4 = new Money(publicData.package4Price, 'EUR');
      schemaPriceArray.push(price4.amount);
      const { formattedPrice, priceTitle } = priceData(price4, intl);
      formattedPrice4 = formattedPrice;
      priceTitle4 = priceTitle;
    }
    if (Number.isInteger(publicData.package5Price)) {
      const price5 = new Money(publicData.package5Price, 'EUR');
      schemaPriceArray.push(price5.amount);
      const { formattedPrice, priceTitle } = priceData(price5, intl);
      formattedPrice5 = formattedPrice;
      priceTitle5 = priceTitle;
    }

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;

      if (!authorStripeConnected) {
        this.onContactUser();
      } else if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const siteTitle = config.siteTitle;
    // const checkThis = publicData?.priceArray ? publicData?.priceArray[0]?._packagePrice : price;
    // const money = new Money(checkThis?.amount || checkThis, config.currency);
    // const formattedPrice = formatMoney(intl, money);
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      {
        title,
        price: formattedPrice || 0,
        siteTitle,
      }
    );
    const schemaDescriptionPlaceholder = intl.formatMessage(
      { id: 'ListingPage.schemaDescriptionPlaceholder' },
      {
        title,
      }
    );

    const averageReviewRating = () => {
      const reviewRating = reviews.map(review => review.attributes.rating);
      const average = reviewRating.reduce((a, b) => a + b, 0) / reviewRating.length;
      return average;
    };

    const schemaReviews = reviews?.map(review => {
      const publishedDateTemp = new Date(review.attributes.createdAt);
      const reviewRating = review.attributes.rating;
      const reviewBody = review.attributes.content;
      const reviewAuthor = review.author.attributes.profile.displayName;
      const year = publishedDateTemp.getFullYear();
      const month = publishedDateTemp.getMonth();
      const day = publishedDateTemp.getDate();
      const datePublished = `${year}-${month + 1}-${day}`;

      return {
        '@type': 'Review',
        author: {
          '@type': 'Person',
          name: reviewAuthor,
        },
        datePublished: datePublished,
        reviewBody: reviewBody,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: reviewRating,
          bestRating: '5',
          worstRating: '1',
        },
      };
    });

    const canonicalURL = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/l/${createSlug(title)}/${
      currentListing.id.uuid
    }`;

    const schema = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: schemaTitle,
      image: facebookImages[0]?.url,
      description: description
        ? description
        : descriptionText
        ? descriptionText.replace(/<\/?[^>]+(>|$)/g, '')
        : schemaDescriptionPlaceholder,
      review: schemaReviews,
      brand: {
        '@type': 'Brand',
        name: 'Freedomly',
      },

      offers: {
        '@type': 'AggregateOffer',
        offerCount: schemaPriceArray.length,
        lowPrice: Math.min(...schemaPriceArray) / 100,
        highPrice: Math.max(...schemaPriceArray) / 100,
        priceCurrency: 'EUR',
        url: canonicalURL,
        availability: 'https://schema.org/InStock',
      },
    };

    if (reviews.length > 0) {
      schema.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: averageReviewRating(),
        reviewCount: reviews.length,
      };
    }

    const handleScrolltoHost = () => {
      const hostDiv = document.getElementById('host');
      const targetScrollPosition = hostDiv.offsetTop - 136;
      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
    };

    const hostLink = (
      <div
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
        onClick={handleScrolltoHost}
      >
        {authorDisplayName}
      </div>
    );

    const getClientSecret = setupIntent => {
      return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
    };
    const getPaymentParams = (currentUser, formValues) => {
      const { name, addressLine1, addressLine2, postal, state, city, country } = formValues;
      const addressMaybe =
        addressLine1 && postal
          ? {
              address: {
                city: city,
                country: country,
                line1: addressLine1,
                line2: addressLine2,
                postal_code: postal,
                state: state,
              },
            }
          : {};
      const billingDetails = {
        name,
        email: ensureCurrentUser(currentUser).attributes.email,
        ...addressMaybe,
      };

      const paymentParams = {
        payment_method_data: {
          billing_details: billingDetails,
        },
      };

      return paymentParams;
    };

    const handlePaymentMethodSubmit = params => {
      this.setState({ paymentMethodFormSubmitting: true });
      const ensuredCurrentUser = ensureCurrentUser(currentUser);
      const stripeCustomer = ensuredCurrentUser.stripeCustomer;
      const { stripe, card, formValues } = params;

      onCreateSetupIntent()
        .then(setupIntent => {
          const stripeParams = {
            stripe,
            card,
            setupIntentClientSecret: getClientSecret(setupIntent),
            paymentParams: getPaymentParams(currentUser, formValues),
          };

          return onHandleCardSetup(stripeParams);
        })
        .then(result => {
          const newPaymentMethod = result.setupIntent.payment_method;
          // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.

          return onSavePaymentMethod(stripeCustomer, newPaymentMethod);
        })
        .then(() => {
          // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
          fetchStripeCustomer();
          this.setState({ paymentMethodFormSubmitting: false });
          this.setState({ cardState: 'default' });
          this.setState({ paymentMethodModalState: 3 });
        })
        .catch(error => {
          console.error(error);
          this.setState({ paymentMethodFormSubmitting: false });
        });
    };

    const handleSetPaymentMethodModalState = state => {
      this.setState({ paymentMethodModalState: state });
    };

    const renderListing = l =>
      l.attributes.publicData.listingType === 'service' && !l.attributes.publicData.hidden ? (
        <li className={css.otherListing} key={l.id.uuid}>
          <ListingCard listing={l} />
        </li>
      ) : l.attributes.publicData.listingType === 'project' ? (
        <li className={css.otherListing} key={l.id.uuid}>
          <ProjectListingCard listing={l} />
        </li>
      ) : null;

    const hasServiceListings = otherListings?.find(
      l => l?.attributes?.publicData?.listingType === 'service'
    );

    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const category =
      publicData && publicData.category ? (
        <span>{categoryLabel(categoryOptions, publicData.category)}</span>
      ) : null;

    const hasBio = ensuredAuthor?.attributes?.profile?.publicData?.bio;
    const bio =
      ensuredAuthor && hasBio
        ? ensuredAuthor.attributes.profile.publicData.bio
        : ensuredAuthor.attributes.profile.bio
        ? ensuredAuthor.attributes.profile?.bio
        : '';

    const listingsContainerClasses = classNames(css.listingsContainer, {
      [css.withBioMissingAbove]: !hasBio,
    });

    const ensuderAuthorLocation = ensuredAuthor?.attributes?.profile?.publicData?.location;

    const topSkills = <TopSkillsContainer profileUser={ensuredAuthor} />;
    const languages = <LanguagesContainer profileUser={ensuredAuthor} />;
    const codeOfConduct = <CodeOfConductContainer profileUser={ensuredAuthor} />;

    const whoAmI = ensuredAuthor?.attributes?.profile?.publicData?.whoAmI;
    const displayName = ensuredAuthor?.attributes?.profile?.displayName;
    const ensuredAuthorLocation = ensuredAuthor?.attributes?.profile?.publicData?.location;

    const hourlyFeeData = ensuredAuthor?.attributes?.profile?.publicData?.hourlyFee;
    const dontShowHourlyFee = ensuredAuthor?.attributes?.profile?.publicData?.dontShowHourlyFee;
    const hourlyFee = (
      <h2>
        <FaEuroSign className={css.euroSign} />
        {hourlyFeeData}
      </h2>
    );

    const reviewsHeading = (
      <h2>
        <FaStar className={css.starIcon} />
        {reviews &&
          reviews.length !== 0 &&
          (reviews.reduce((a, b) => a + b?.attributes?.rating, 0) / reviews?.length).toFixed(1)}
        &nbsp;({reviews && reviews.length})
      </h2>
    );

    const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);

    const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);

    const reviewsLength =
      accountRole === 'customer' ? reviewsOfCustomer.length : reviewsOfProvider.length;

    const showToggleReviewsButton = reviewsLength > 3;

    const desktopReviews =
      reviewsLength > 0 ? (
        <Container>
          <div>
            <h2>
              <FormattedMessage
                id="ProfilePage.reviews"
                values={{
                  count:
                    accountRole === 'customer'
                      ? reviewsOfCustomer.length
                      : reviewsOfProvider.length,
                }}
              />
            </h2>

            {accountRole === 'customer' ? (
              <Reviews
                reviews={
                  this.state.toggleRestOfReviews ? reviewsOfCustomer : reviewsOfCustomer.slice(0, 3)
                }
              />
            ) : (
              <Reviews
                reviews={
                  this.state.toggleRestOfReviews ? reviewsOfProvider : reviewsOfProvider.slice(0, 3)
                }
              />
            )}
            {showToggleReviewsButton && (
              <InlineTextButton type="button" onClick={this.toggleReviews}>
                {this.state.toggleRestOfReviews ? (
                  <FormattedMessage id="ProfilePage.less" />
                ) : (
                  <FormattedMessage id="ProfilePage.more" />
                )}
              </InlineTextButton>
            )}
          </div>
        </Container>
      ) : null;

    const asideContent = (
      <div>
        <Container>
          <div className={classNames(css.asideContent, css.container)}>
            <div className={css.bioNameContainer}>
              <AvatarLarge className={css.avatar} user={ensuredAuthor} />
              <NamedLink
                className={css.desktopHeading}
                name="ProfilePage"
                params={{ id: ensuredAuthor.id.uuid }}
              >
                <div className={css.desktopHeading}>{displayName}</div>
              </NamedLink>

              {whoAmI && <div className={css.desktopWhoamI}>{whoAmI}</div>}
            </div>

            {reviews.length > 0 && reviewsHeading}
            {ensuredAuthor?.attributes?.profile?.metadata?.verified && (
              <h2>
                <VerifiedUserMark user={ensuredAuthor} />
                <FormattedMessage id="ProfilePage.verifiedTalent" />
              </h2>
            )}
            {ensuredAuthorLocation &&
              ensuredAuthorLocation !== '' &&
              ensuredAuthorLocation?.selectedPlace !== null && (
                <h2>
                  <>
                    <FaMapMarkerAlt className={css.mapMarkerIcon} />
                    {ensuderAuthorLocation?.search.split(',')[0]}
                  </>
                </h2>
              )}
            {hourlyFeeData && !dontShowHourlyFee && hourlyFee}
          </div>
        </Container>
        {codeOfConduct}

        {topSkills}
        {languages}
      </div>
    );

    if (currentListing.attributes.publicData.listingType === 'project') {
      return (
        <NamedRedirect
          name="ProjectListingPage"
          params={{
            slug: createSlug(currentListing.attributes.title || ''),
            id: currentListing.id.uuid,
          }}
          search={location.search}
        />
      );
    }

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={schema}
        listingCanonicalURL={canonicalURL}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <div className={css.contentContainer}>
                <div className={css.mainContent}>
                  <SectionHeading
                    richTitle={richTitle}
                    category={category}
                    categoryKey={publicData.category}
                    mainCategory={mainCategoryKey(categoryOptions, publicData.category)}
                    mainCategoryLabel={categoryLabel(
                      categoryOptions,
                      mainCategoryKey(categoryOptions, publicData.category)
                    )}
                    user={ensuredAuthor}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    filterConfig={filterConfig}
                    updateFavoriteListings={onupdateFavoriteListings}
                    favoriteListings={favoriteListings}
                    currentUser={currentUser}
                    listing={currentListing}
                    title={title}
                    authorDisplayName={authorDisplayName}
                    showHiringSteps={isAuthenticated && this.state.showHiringSteps}
                    isEnquiryModalOpen={
                      !isProvider && isAuthenticated && this.state.enquiryModalOpen
                    }
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    isPaymentMethodModalOpen={this.state.paymentMethodModalOpen}
                    onClosePaymentMethodModalOpen={() =>
                      this.setState({ paymentMethodModalOpen: false })
                    }
                    handlePaymentMethodSubmit={handlePaymentMethodSubmit}
                    paymentMethodFormInProgress={this.state.paymentMethodFormSubmitting}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    onManageDisableScrolling={onManageDisableScrolling}
                    addPaymentMethodError={addPaymentMethodError}
                    deletePaymentMethodError={deletePaymentMethodError}
                    createStripeCustomerError={createStripeCustomerError}
                    handleCardSetupError={handleCardSetupError}
                    paymentMethodModalState={this.state.paymentMethodModalState}
                    setPaymentMethodModalState={handleSetPaymentMethodModalState}
                    onOpenEnquiryModal={() => this.setState({ enquiryModalOpen: true })}
                  />
                  <SectionImages
                    title={title}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                    imageCarouselOpen={this.state.imageCarouselOpen}
                    onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                    handleViewPhotosClick={handleViewPhotosClick}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                  <SectionDescriptionMaybe description={description} publicData={publicData} />
                  <SectionPricingPackages
                    publicData={publicData}
                    intl={intl}
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    priceTitle2={priceTitle2}
                    formattedPrice2={formattedPrice2}
                    priceTitle3={priceTitle3}
                    formattedPrice3={formattedPrice3}
                    priceTitle4={priceTitle4}
                    formattedPrice4={formattedPrice4}
                    priceTitle5={priceTitle5}
                    formattedPrice5={formattedPrice5}
                  />
                  <SectionRulesMaybe publicData={publicData} />
                </div>
                {isDataAvailable ? (
                  <BookingPanel
                    className={css.bookingPanel}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    contactOnly={true}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    unitType={unitType}
                    onSubmit={handleBookingSubmit}
                    title={bookingTitle}
                    subTitle={bookingSubTitle}
                    authorDisplayName={authorDisplayName}
                    onManageDisableScrolling={onManageDisableScrolling}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                    authorStripeConnected={authorStripeConnected}
                    currentUser={currentUser}
                    richTitle={richTitle}
                    showHiringSteps={isAuthenticated && this.state.showHiringSteps}
                    updateFavoriteListings={onupdateFavoriteListings}
                    favoriteListings={favoriteListings}
                  />
                ) : null}
              </div>
              <div className={css.hostSection} id="host">
                <div className={css.hostSectionTitle}>
                  <FormattedMessage id="ListingPage.hostSectionTitle" />
                </div>
                <div className={css.hostSectionContent}>
                  <div className={css.hostSectionAsideContent}>{asideContent}</div>
                  <div className={css.hostSectionMainContent}>
                    <Container heading={<FormattedMessage id="ProfilePage.bio" />}>
                      <div className={css.bio}>{renderHTML(bio)}</div>
                    </Container>
                    {desktopReviews}
                    {/* <WorkExperienceContainer profileUser={ensuredAuthor} /> */}
                    <WorkedWithContainer profileUser={ensuredAuthor} />
                    <EducationContainer profileUser={ensuredAuthor} />
                  </div>
                </div>
                {hasServiceListings ? (
                  <div className={listingsContainerClasses}>
                    <h2 className={css.listingsTitle}>
                      <FormattedMessage
                        id="ListingPage.otherListingsTitle"
                        values={{ authorName: ensuredAuthor.attributes.profile.displayName }}
                      />
                    </h2>
                    <ul className={css.listings}>{otherListings.map(l => renderListing(l))}</ul>
                  </div>
                ) : null}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
  addPaymentMethodError: null,
  deletePaymentMethodError: null,
  createStripeCustomerError: null,
  handleCardSetupError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  onupdateFavoriteListings: func.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
  } = state.paymentMethods;

  const { handleCardSetupError } = state.stripe;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    userListingRefs,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const otherListings = getMarketplaceEntities(state, userListingRefs);

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);

    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);

    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    favoriteListings: state.user.favoriteListingsIds,
    otherListings,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, isLightEntrepreneur, message, currentUser, listingTitle) =>
    dispatch(sendEnquiry(listingId, isLightEntrepreneur, message, currentUser, listingTitle)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onupdateFavoriteListings: params => dispatch(updateFavoriteListings(params)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onHandleCardSetup: params => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: params => dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) =>
    dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod)),
  onDeletePaymentMethod: params => dispatch(deletePaymentMethod(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
