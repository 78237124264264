import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { v4 as uuidv4 } from 'uuid';
import cookie from 'cookie';
import routeConfiguration from '../../routeConfiguration';
import { parse } from '../../util/urlHelpers';
import { pathByRouteName } from '../../util/routes';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
} from '../../components';
import { SubscriptionForm, SubscriptionUpdateForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './SubscriptionPage.css';
import { updateSubscriptionState, cancelStripeSubscription } from './SubscriptionPage.duck.js';

export const SubscriptionPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    location,
    stripeSubscriptionError,
    stripeSubscriptionInProgress,
    onUpdateSubscriptionState,
    onCancelStripeSubscription,
  } = props;

  const [finalizingSubscription, setFinalizingSubscription] = useState(false);

  const initiateSubscription = () => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    const stripeState = uuidv4();

    document.cookie = `stripeState=${stripeState}`;

    stripePromise
      .then(stripe => {
        return stripe.redirectToCheckout({
          lineItems: [{ price: process.env.REACT_APP_SUBSCRIPTION_PRICE_ID, quantity: 1 }],
          mode: 'subscription',
          successUrl:
            process.env.REACT_APP_CANONICAL_ROOT_URL +
            pathByRouteName('SubscriptionPage', routeConfiguration(), {}) +
            `?stripeState=${stripeState}&user_id=${currentUser.id.uuid}`,
          cancelUrl:
            process.env.REACT_APP_CANONICAL_ROOT_URL +
            pathByRouteName('SubscriptionPage', routeConfiguration(), {}),
        });
      })
      .catch(error => {
        console.error('Error redirecting to Stripe Checkout page : ' + error.message);
      });
  };

  const submitCancelStripeSubscription = () => {
    onCancelStripeSubscription(currentUser.id.uuid);
  };

  const user = ensureCurrentUser(currentUser);
  const privateData = user.attributes.profile.privateData || {};
  const isUserSubscribed =
    privateData.subscriptionStateKey === process.env.REACT_APP_SUBSCRIPTION_STATE_KEY;
  const stripePriceId = isUserSubscribed ? process.env.REACT_APP_SUBSCRIPTION_PRICE_ID : '';

  const subscriptionForm = isUserSubscribed ? (
    <>
      <FormattedMessage id="SubscriptionPage.userSubscribedMessage" />
      <SubscriptionUpdateForm
        className={css.form}
        initialValues={{ priceId: stripePriceId }}
        stripeSubscriptionError={stripeSubscriptionError}
        currentUser={currentUser}
        onSubmit={submitCancelStripeSubscription}
        inProgress={stripeSubscriptionInProgress}
        ready={true}
        intl={intl}
      />
    </>
  ) : (
    <SubscriptionForm
      className={css.form}
      currentUser={currentUser}
      onSubmit={initiateSubscription}
      inProgress={false}
      ready={false}
      intl={intl}
    />
  );

  const title = intl.formatMessage({ id: 'ContactDetailsPage.title' });

  const urlParams = location && location.search ? parse(location.search) : {};
  const stripeState = urlParams.stripeState;
  const cookiesList = cookie.parse(document.cookie);
  const cookieStripeState = cookiesList.stripeState;

  if (
    !isUserSubscribed &&
    !finalizingSubscription &&
    typeof stripeState === 'string' &&
    stripeState === cookieStripeState
  ) {
    setFinalizingSubscription(true);

    onUpdateSubscriptionState().then(() => {
      window.location.href =
        process.env.REACT_APP_CANONICAL_ROOT_URL +
        pathByRouteName('SubscriptionPage', routeConfiguration(), {});
    });
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="SubscriptionPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav
          currentTab="SubscriptionPage"
          currentUser={currentUser}
        />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="SubscriptionPage.heading" />
            </h1>
            {subscriptionForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

SubscriptionPageComponent.defaultProps = {
  currentUser: null,
};

const { bool } = PropTypes;

SubscriptionPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;

  const { stripeSubscriptionError, stripeSubscriptionInProgress } = state.SubscriptionPage;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    stripeSubscriptionError,
    stripeSubscriptionInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateSubscriptionState: () => dispatch(updateSubscriptionState()),
  onCancelStripeSubscription: userId => dispatch(cancelStripeSubscription(userId)),
});

const SubscriptionPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SubscriptionPageComponent);

SubscriptionPage.loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};

export default SubscriptionPage;
