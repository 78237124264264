import React from 'react';
import NamedLink from '../NamedLink/NamedLink';

import css from './ParentCategoryTopbar.css';

const ParentCategoryTopbar = ({ filterConfig }) => {
  const categoriesAll = filterConfig[1]?.config.options;

  const parentCategories = categoriesAll
    ?.filter(x => x.parent === '')
    .sort((x, y) => x.parentOrder - y.parentOrder);

  return (
    <div className={css.topbarContainer}>
      <ul className={css.topbarContent}>
        {parentCategories.map(category => (
          <NavItem key={category.key} name={category.name} label={category.label}>
            <DropdownMenu
              className={css.dropdown}
              categoriesAll={categoriesAll}
              category={category}
            ></DropdownMenu>
          </NavItem>
        ))}
      </ul>
    </div>
  );
};

const NavItem = props => {
  return (
    <li className={css.navItem}>
      <NamedLink className={css.categoryLink} name={props.name}>
        {props.label}
      </NamedLink>
      <div className={css.dropdown}>{props.children}</div>
    </li>
  );
};
const DropdownItem = props => {
  return <div>{props.children}</div>;
};

const DropdownMenu = ({ categoriesAll, category }) => {
  return (
    <DropdownItem>
      {category && (
        <ul>
          {categoriesAll
            .filter(child => child.parent === category.key)
            .map(x => (
              <li key={x.key} className={css.dropdownItem}>
                <NamedLink name={x.name}>{x.label}</NamedLink>
              </li>
            ))}
        </ul>
      )}
    </DropdownItem>
  );
};

export default ParentCategoryTopbar;
