import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon = props => {
  // const { className, ...rest } = props;

  return (
    // <svg
    //   className={className}
    //   {...rest}
    //   width="16"
    //   height="16"
    //   viewBox="0 0 16 16"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M8 0C3.582 0 0 3.582 0 8c0 4.418 3.582 8 8 8s8-3.582 8-8C16 3.582 12.418 0 8 0zM8 4c.552 0 1 .448 1 1 0 .553-.448 1-1 1s-1-.447-1-1C7 4.448 7.448 4 8 4zM9.5 12h-3c-.276 0-.5-.224-.5-.5 0-.276.224-.5.5-.5h.5v-3h-.5c-.276 0-.5-.224-.5-.5 0-.276.224-.5.5-.5h2c.276 0 .5.224 .5.5v3.5h.5c.276 0 .5.224 .5.5C10 11.776 9.776 12 9.5 12z"
    //     fillRule="evenodd"
    //   />
    // </svg>
    <></>
  );
};

const { string } = PropTypes;

InfoIcon.defaultProps = {
  className: null,
};

InfoIcon.propTypes = {
  className: string,
};

export default InfoIcon;
