import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ProjectSearchPageBanner.css';

const ProjectSearchPageBanner = ({ content }) => {
  const { bannerTitleId, bannerTextId } = content;

  return (
    <div className={css.root}>
      <div className={css.content}>
        <div className={css.header}>
          <h1>
            <FormattedMessage id={bannerTitleId} />
          </h1>
        </div>
        <div className={css.textContent}>
          <div className={css.bannerText}>
            <FormattedMessage id={bannerTextId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSearchPageBanner;
