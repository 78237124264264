import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  InfoIcon,
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCurrencyInput,
  IconEnquiry,
  FieldDateInput,
} from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';

import css from './ProjectBidForm.css';
import config from '../../config';

const { Money } = sdkTypes;
const VAT_FACTOR = 125.5;

const ProjectBidFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        sendProjectBidError,
        values,
      } = fieldRenderProps;

      const descriptionLabel = intl.formatMessage({ id: 'ProjectBidForm.descriptionLabel' });
      const descriptionPlaceholder = intl.formatMessage({
        id: 'ProjectBidForm.descriptionPlaceholder',
      });
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'ProjectBidForm.descriptionRequired',
      });
      const descriptionRequired = validators.requiredAndNonEmptyString(descriptionRequiredMessage);

      const deliveryDatePlaceHolder = intl.formatMessage({
        id: 'CustomOfferForm.deliveryDatePlaceholder',
      });

      const deliveryDateRequiredMessage = intl.formatMessage({
        id: 'CustomOfferForm.deliveryDateRequired',
      });
      const deliveryDateRequired = validators.required(deliveryDateRequiredMessage);

      const amountLabel = intl.formatMessage({ id: 'ProjectBidForm.amountLabel' });
      const amountPlaceholder = intl.formatMessage({ id: 'ProjectBidForm.amountPlaceholder' });
      const amountRequiredMessage = intl.formatMessage({ id: 'ProjectBidForm.amountRequired' });
      const amountRequired = validators.required(amountRequiredMessage);

      const deliveryDateLabel = intl.formatMessage({ id: 'ProjectBidForm.deliveryDateLabel' });

      const totalPriceLabel = intl.formatMessage({ id: 'ProjectBidForm.totalPriceLabel' });
      const totalPricePlaceholder = intl.formatMessage({
        id: 'ProjectBidForm.totalPricePlaceholder',
      });

      const taxedAmount = Math.round((values.amount?.amount * VAT_FACTOR) / 100);
      const totalPrice = new Money(taxedAmount, 'EUR');

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <IconEnquiry className={css.icon} />
          <div className={css.titleBlock}>
            <h2 className={css.heading}>
              <FormattedMessage id="ProjectBidForm.heading" />
            </h2>
            <InfoIcon
              className={css.infoIcon}
              data-tip={intl.formatMessage({ id: 'ProjectBidForm.headingTooltip' })}
              data-border={true}
              data-text-color={'black'}
              data-background-color={'white'}
              data-border-color={'darkgrey'}
              data-class={css.tooltipText}
              data-place={'bottom'}
            />
          </div>

          <FieldTextInput
            className={css.field}
            type="textarea"
            name="description"
            id={formId ? `${formId}.description` : 'description'}
            label={descriptionLabel}
            placeholder={descriptionPlaceholder}
            validate={descriptionRequired}
          />
          <FieldCurrencyInput
            id={formId ? `${formId}.amount` : 'amount'}
            name="amount"
            className={css.field}
            label={amountLabel}
            placeholder={amountPlaceholder}
            currencyConfig={config.currencyConfig}
            validate={amountRequired}
          />

          <div className={css.field}>
            <label>{totalPriceLabel}</label>
            <div className={css.totalPrice}>
              {values.amount ? (
                <span>{formatMoney(intl, totalPrice)}</span>
              ) : (
                <span className={css.totalPricePlaceholder}>{totalPricePlaceholder}</span>
              )}
            </div>
          </div>

          <FieldDateInput
            className={css.field}
            label={deliveryDateLabel}
            id="deliveryDate"
            name="deliveryDate"
            placeholderText={deliveryDatePlaceHolder}
            validate={deliveryDateRequired}
          />

          <div className={css.submitButtonWrapper}>
            {sendProjectBidError ? (
              <p className={css.error}>
                <FormattedMessage id="ProjectBidForm.sendBidError" />
              </p>
            ) : null}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="ProjectBidForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ProjectBidFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

ProjectBidFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProjectBidForm = compose(injectIntl)(ProjectBidFormComponent);

ProjectBidForm.displayName = 'ProjectBidForm';

export default ProjectBidForm;
