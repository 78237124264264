import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './SectionQuestionCards.css';

const freedomlyProLink = (
  <NamedLink name="ProLandingPage" className={css.freedomlyProLink}>
    <FormattedMessage id="SectionQuestionCards.questionCardInfoText3ProLink" />
  </NamedLink>
);

const questionCardTitle1 = <FormattedMessage id="SectionQuestionCards.questionCardTitle1" />;
const questionCardTitle2 = <FormattedMessage id="SectionQuestionCards.questionCardTitle2" />;
const questionCardTitle3 = <FormattedMessage id="SectionQuestionCards.questionCardTitle3" />;

const questionCardInfoText1 = <FormattedMessage id="SectionQuestionCards.questionCardInfoText1" />;
const questionCardInfoText2 = <FormattedMessage id="SectionQuestionCards.questionCardInfoText2" />;
const questionCardInfoText3 = (
  <FormattedMessage
    id="SectionQuestionCards.questionCardInfoText3"
    values={{ freedomlyProLink: freedomlyProLink }}
  />
);

const QuestionCard = ({ title, infoText }) => {
  return (
    <div className={css.questionCard}>
      <div className={css.questionCardTitle}>{title}</div>
      <div className={css.questionCardInfoText}>{infoText}</div>
    </div>
  );
};

const SectionQuestionCards = () => {
  return (
    <div className={css.QuestionCardSection}>
      <div>
        <div className={css.questionCardSectionTitle}>
          <FormattedMessage id="SectionQuestionCards.title1" />
          <FormattedMessage id="SectionQuestionCards.title2" />
        </div>
      </div>
      <div className={css.QuestionCards}>
        <QuestionCard title={questionCardTitle1} infoText={questionCardInfoText1} />
        <QuestionCard title={questionCardTitle2} infoText={questionCardInfoText2} />
        <QuestionCard title={questionCardTitle3} infoText={questionCardInfoText3} />
      </div>
    </div>
  );
};

export default SectionQuestionCards;
