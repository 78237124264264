import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import {
  createResourceLocatorString,
  pathByRouteName,
  handleListingSearch,
} from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Button,
  LimitedAccessBanner,
  Logo,
  Modal,
  ModalMissingInformation,
  NamedLink,
  SubscriptionAdviceBanner,
  TopbarDesktop,
  TopbarMobileMenu,
  ModalKeywordSearch,
} from '../../components';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import css from './Topbar.css';

const MAX_MOBILE_SCREEN_WIDTH = 962;

const URL_PARAM = 'keywords';

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleKeywordSubmit = this.handleKeywordSubmit.bind(this);
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { history, isAuthenticated, currentUser } = this.props;
    const selectedOption = values.location.selectedPlace;

    if (selectedOption.isCategory) {
      const searchParams = {
        pub_category: selectedOption.key,
      };

      const accountRole = currentUser.attributes.profile.publicData?.account_role;
      if (isAuthenticated && ['freelancer', 'light-entrepreneur'].includes(accountRole)) {
        history.push(
          createResourceLocatorString('ProjectSearchPage', routeConfiguration(), {}, searchParams)
        );
      } else {
        history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
        );
      }
    } else {
      history.push(
        createResourceLocatorString('ListingPageCanonical', routeConfiguration(), {
          id: selectedOption.key,
        })
      );
    }
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());
      const appId = process.env.REACT_APP_INTERCOM_APP_KEY;

      if (typeof window === 'object') {
        window.Intercom('shutdown');
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: appId,
        });
      }
      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  handleKeywordSubmit(values, history) {
    const queryParams = values ? `?${stringify(values)}` : '';
    if (values && values.keywords) {
      history.push(handleListingSearch('SearchPage', routeConfiguration(), queryParams));
    }
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentUserNotificationTxs,
      currentUserApplicationTxs,
      currentPage,
      notificationCount,
      applicationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      notifications,
      applications,
      showCategoryBar,
    } = this.props;

    const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const isSubscribed = true; /* SUBSCRIPTION DISABLED: !!( currentUser &&
      typeof(currentUser.attributes.profile.privateData) === 'object' &&
      typeof(currentUser.attributes.profile.privateData.subscriptionStateKey) === 'string'
    );*/

    const notificationDot =
      notificationCount > 0 || applicationCount > 0 ? (
        <div className={css.notificationDot} />
      ) : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        applicationNotificationCount={applicationCount}
        currentPage={currentPage}
        initialSearchFormValues={initialSearchFormValues}
        onSearchSubmit={this.handleSubmit}
        history={this.props.history}
      />
    );

    const classes = classNames(rootClassName || css.root, className);

    const showSearchBar = [
      'ParentCategoryPage',
      'ChildCategoryPage',
      'SearchPage',
      'CategoriesPage',
    ];

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <SubscriptionAdviceBanner
          isAuthenticated={isAuthenticated}
          isSubscribed={isSubscribed}
          currentUser={currentUser}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {notificationDot}
          </Button>
          {currentUser ? (
            <NamedLink
              className={css.home}
              name="DashboardPage"
              title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            >
              <Logo format="mobile" />
            </NamedLink>
          ) : (
            <NamedLink
              className={css.home}
              name="LandingPage"
              title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            >
              <Logo format="mobile" />
            </NamedLink>
          )}

          <div className={css.signupSearch}>
            {!isAuthenticated && (
              <NamedLink className={css.signupLink} name="SignupPage">
                <FormattedMessage id="Topbar.signup" />
              </NamedLink>
            )}
            {showSearchBar.includes(currentPage) ? (
              <Button
                rootClassName={css.searchMenu}
                onClick={this.handleMobileSearchOpen}
                title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
              >
                <SearchIcon className={css.searchMenuIcon} />
              </Button>
            ) : null}
          </div>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            currentUserNotificationTxs={currentUserNotificationTxs}
            currentUserApplicationTxs={currentUserApplicationTxs}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            applicationCount={applicationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            history={this.props.history}
            notifications={notifications}
            applications={applications}
            urlParam={URL_PARAM}
            handleKeyWordSubmit={this.handleKeywordSubmit}
            onManageDisableScrolling={onManageDisableScrolling}
            showCategoryBar={showCategoryBar}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          containerClassName={css.menuModalContainer}
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>

        <ModalKeywordSearch
          modalId="TopbarMobileSearch"
          searchId="TopbarMobileKeywordSearch"
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          onManageDisableScrolling={onManageDisableScrolling}
          handleSubmit={this.handleKeywordSubmit}
          urlParam={URL_PARAM}
        />

        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentUserNotificationTxs: [],
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  notifications: [],
};

const { array, func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentUserNotificationTxs: array,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,
  notifications: array,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
