import React from 'react';
import { Avatar, NamedLink } from '..';
import { BsArrow90DegRight } from 'react-icons/bs';
import { formatDate } from '../../util/dates';
import {
  TRANSITION_ENQUIRE,
  TRANSITION_ACCEPT_APPLICATION,
  TRANSITION_SKIP_APPLICATION,
  TRANSITION_ACCEPT_AFTER_SKIP,
} from '../../util/transaction';

import css from './ApplicationNotificationFeed.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

const ApplicationNotificationFeed = ({
  currentUser,
  currentUserApplicationTxs,
  applications,
  isFreelancer,
  intl,
}) => {
  const allMessagesMessage = intl.formatMessage({
    id: 'ApplicationNotificationFeed.allApplications',
  });

  const fetchedNotifications = applications.filter(item =>
    currentUserApplicationTxs.some(tx => tx.id.uuid === item.txId)
  );

  // TODO: Lint to application page

  return (
    <div className={css.container}>
      <NamedLink
        className={css.linkToInbox}
        name="ApplicationInboxPage"
        params={{ tab: isFreelancer ? 'sales' : 'orders' }}
      >
        <span>{allMessagesMessage}</span>
        <BsArrow90DegRight />
      </NamedLink>
      {fetchedNotifications.map(item => {
        const transaction = currentUserApplicationTxs.find(tx => tx.id.uuid === item.txId);
        return transaction ? (
          <ApplicationNotificationFeedItem
            key={item.txId}
            currentUser={currentUser}
            message={item}
            transaction={transaction}
            intl={intl}
          />
        ) : null;
      })}
    </div>
  );
};

const ApplicationNotificationFeedItem = ({ currentUser, transaction, intl }) => {
  const lastTransition = transaction.attributes.lastTransition;

  const sender =
    currentUser.id.uuid === transaction.provider.id.uuid
      ? transaction.customer
      : transaction.provider;

  const listingTitle = transaction.attributes.protectedData?.listingTitle;

  const transactionRole =
    transaction.provider.id.uuid === currentUser.id.uuid ? PROVIDER : CUSTOMER;
  const isCustomerRole = transactionRole === CUSTOMER;

  const findTransitionTranslation = transition => {
    switch (transition) {
      case TRANSITION_ENQUIRE:
        return intl.formatMessage({ id: 'ApplicationNotificationFeed.newApplication' });
      case TRANSITION_ACCEPT_APPLICATION:
      case TRANSITION_ACCEPT_AFTER_SKIP:
        return intl.formatMessage({ id: 'ApplicationNotificationFeed.applicationAccepted' });
      case TRANSITION_SKIP_APPLICATION:
        return intl.formatMessage({ id: 'ApplicationNotificationFeed.applicationFreezed' });
      default:
        return intl.formatMessage({ id: 'ApplicationNotificationFeed.newEvent' });
    }
  };

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  const timeStamp = formatDate(intl, todayString, transaction.attributes.lastTransitionedAt);

  return (
    <div className={css.item}>
      <Avatar user={sender} />
      <NamedLink
        className={css.linkToDetails}
        name={isCustomerRole ? 'ApplicationOrderPage' : 'ApplicationSalePage'}
        params={{ id: transaction.id.uuid }}
      >
        <div>
          <p className={css.message}>{findTransitionTranslation(lastTransition)}</p>
          <p className={css.listingTitle}>{listingTitle}</p>
          <p className={css.timeStamp}>{timeStamp}</p>
        </div>
      </NamedLink>
    </div>
  );
};

export default ApplicationNotificationFeed;
