// ================ Action types ================ //

export const SET_SIGNUP_FROM_REQUEST = 'app/ProjectCategoryLandingPage/SET_SIGNUP_FROM_REQUEST';
export const SET_SIGNUP_FROM_SUCCESS = 'app/ProjectCategoryLandingPage/SET_SIGNUP_FROM_SUCCESS';
export const SET_SIGNUP_FROM_ERROR = 'app/ProjectCategoryLandingPage/SET_SIGNUP_FROM_ERROR';

// ================ Reducer ================ //

const initialState = {
  signUpFrom: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_SIGNUP_FROM_REQUEST:
      return {
        ...state,
        signUpFrom: payload,
      };
    case SET_SIGNUP_FROM_SUCCESS:
      return {
        ...state,
        signUpFrom: payload,
      };
    case SET_SIGNUP_FROM_ERROR:
      return {
        ...state,
        signUpFrom: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setSignUpFromRequest = () => ({
  type: SET_SIGNUP_FROM_REQUEST,
});

export const setSignUpFromSuccess = from => ({
  type: SET_SIGNUP_FROM_SUCCESS,
  payload: from,
});

export const setSignUpFromError = error => ({
  type: SET_SIGNUP_FROM_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const setSignUpFrom = from => dispatch => {
  dispatch(setSignUpFromRequest());
  dispatch(setSignUpFromSuccess(from));
  return from;
};
