import React, { useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  Form,
  FieldTextInput,
  SecondaryButton,
  Button,
  FieldSelectModern,
  IconClose,
  InlineTextButton,
  FieldCheckbox,
} from '../../components';
import { required } from '../../util/validators';
import { generateMonthsList, generateYearsList } from '../../util/dates';
import { CiWarning } from 'react-icons/ci';

import css from './SignupProviderForms.css';

const AT_RE = /@/i;
//eslint-disable-next-line
const PH_NO_RE = /\s?(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{1,3}\)?[\s.-]?\d{3}[\s.-]?\d{4,5}/;
const PH_NO_RE2 = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/;
const LINK_RE = /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/;

const SignupProviderWorkedWithForm = props => {
  const [descriptionNotValid, setDescriptionNotValid] = useState(false);
  const requiredMessage = id => required(props.intl.formatMessage({ id }));

  const validateWorkedWith = (fieldSet, dontAddWorkedWith) => {
    if (dontAddWorkedWith) return;

    const fieldsToValidate = ['customerName', 'task', 'description', 'startMonth', 'startYear'];
    const error = {};

    fieldsToValidate.forEach(field => {
      error[field] = requiredMessage(`SignupProviderForms.${field}Required`)(fieldSet[field]);
    });

    if (!fieldSet.jobContinues) {
      error.endMonth = requiredMessage('SignupProviderForms.dateRequired')(fieldSet.endMonth);
      error.endYear = requiredMessage('SignupProviderForms.dateRequired')(fieldSet.endYear);
    }

    return error;
  };

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      validate={values => {
        const errors = {};
        errors.workedWith = values.workedWith?.map(item =>
          validateWorkedWith(item, values.dontAddWorkedWith)
        );
        return errors;
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          form: {
            mutators: { push },
          },
          invalid,
          values,
          isModal,
          pristine,
          initialValues,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled =
          (invalid || pristine || descriptionNotValid) && !values.dontAddWorkedWith;

        const changed =
          JSON.stringify(initialValues.workedWith) !== JSON.stringify(values.workedWith);
        const modalSubmitDisabled = invalid || !changed || descriptionNotValid;

        const heading = intl.formatMessage({ id: 'SignupProviderForms.workedWithHeading' });

        const description = (
          <FormattedMessage
            id="SignupProviderForms.workedWithDescription"
            values={{
              lineBreak: <br />,
            }}
          />
        );

        const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.nextButton' });
        const previousButtonText = intl.formatMessage({ id: 'SignupProviderForms.previousButton' });
        const addButtonText = intl.formatMessage({ id: 'SignupProviderForms.addButton' });
        const okButtonText = intl.formatMessage({ id: 'SignupProviderForms.okButton' });

        const customerNameLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceCustomerNameLabel',
        });
        const customerNamePlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceCustomerNamePlaceholder',
        });

        const taskLabel = intl.formatMessage({ id: 'SignupProviderForms.workExperienceTaskLabel' });
        const taskPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceTaskPlaceholder',
        });

        const startLabel = intl.formatMessage({
          id: 'SignupProviderForms.workedWithStartLabel',
        });
        const endLabel = intl.formatMessage({ id: 'SignupProviderForms.workedWithEndLabel' });

        const monthPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.monthPlaceholder',
        });
        const yearPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.yearPlaceholder',
        });

        const jobContinuesLabel = intl.formatMessage({
          id: 'SignupProviderForms.workedWithContinuesLabel',
        });

        const descriptionLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceDescriptionLabel',
        });
        const descriptionPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceDescriptionPlaceholder',
        });

        const dontAddLabel = intl.formatMessage({
          id: 'SignupProviderForms.workedWithDontAddLabel',
        });

        const warningIcon = <CiWarning />;

        const descriptionErrorMessage = intl.formatMessage({
          id: 'SignupProviderForms.workedWithDescriptionErrorMessage',
        });

        const hideEndDate = index => values.workedWith[index].jobContinues;

        const descriptionValidation = value => {
          if (value && AT_RE.test(value)) {
            setDescriptionNotValid(true);
            return true;
          }
          if (value && LINK_RE.test(value)) {
            setDescriptionNotValid(true);
            return true;
          }
          if (value && PH_NO_RE.test(value)) {
            setDescriptionNotValid(true);
            return true;
          }
          if (value && PH_NO_RE2.test(value)) {
            setDescriptionNotValid(true);
            return true;
          }
          if (value && value === '') {
            setDescriptionNotValid(true);
            return true;
          }
          setDescriptionNotValid(false);
          return false;
        };

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <h2 className={css.mainHeading}>
                {heading}
                {isModal ? (
                  <div className={css.submitContainer}>
                    <Button
                      rootClassName={css.nextButton}
                      type="Submit"
                      disabled={modalSubmitDisabled}
                    >
                      {okButtonText}
                    </Button>
                  </div>
                ) : null}
              </h2>
              <div className={css.column}>
                <p className={css.description}>{description}</p>
                <FieldArray name="workedWith">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <div key={name} className={css.form}>
                          <span
                            className={css.alignEnd}
                            onClick={() =>
                              fields?.length === 1 ? fields.update(index, {}) : fields.remove(index)
                            }
                          >
                            <IconClose rootClassName={css.deleteIcon} />
                          </span>

                          <FieldTextInput
                            id={`${name}.customerName`}
                            name={`${name}.customerName`}
                            type="text"
                            label={customerNameLabel}
                            placeholder={customerNamePlaceholder}
                            disabled={values.dontAddWorkedWith}
                          />
                          <FieldTextInput
                            id={`${name}.task`}
                            name={`${name}.task`}
                            type="text"
                            label={taskLabel}
                            placeholder={taskPlaceholder}
                            disabled={values.dontAddWorkedWith}
                          />

                          <div className={css.datesContainer}>
                            <div className={css.datesItem}>
                              <FieldSelectModern
                                id={`${name}.startMonth`}
                                name={`${name}.startMonth`}
                                options={generateMonthsList()}
                                placeholder={monthPlaceholder}
                                label={startLabel}
                                disabled={values.dontAddWorkedWith}
                              />
                              <FieldSelectModern
                                id={`${name}.startYear`}
                                name={`${name}.startYear`}
                                className={css.years}
                                options={generateYearsList(1900)}
                                placeholder={yearPlaceholder}
                                disabled={values.dontAddWorkedWith}
                              />
                            </div>

                            {!hideEndDate(index) && (
                              <div className={css.datesItem}>
                                <FieldSelectModern
                                  id={`${name}.endMonth`}
                                  name={`${name}.endMonth`}
                                  options={generateMonthsList()}
                                  placeholder={monthPlaceholder}
                                  label={endLabel}
                                  disabled={values.dontAddWorkedWith}
                                />
                                <FieldSelectModern
                                  id={`${name}.endYear`}
                                  name={`${name}.endYear`}
                                  className={css.years}
                                  options={generateYearsList(1900, 5)}
                                  placeholder={yearPlaceholder}
                                  disabled={values.dontAddWorkedWith}
                                />
                              </div>
                            )}
                          </div>

                          <FieldCheckbox
                            id={`${name}.jobContinues`}
                            name={`${name}.jobContinues`}
                            defaultValue={false}
                            label={jobContinuesLabel}
                            disabled={values.dontAddWorkedWith}
                          />

                          <FieldTextInput
                            id={`${name}.description`}
                            name={`${name}.description`}
                            className={css.textFieldMedium}
                            type="textarea"
                            label={descriptionLabel}
                            placeholder={descriptionPlaceholder}
                            disabled={values.dontAddWorkedWith}
                            icon={warningIcon}
                            showWarning={descriptionValidation(
                              values.workedWith[index].description
                            )}
                            warningMessage={descriptionErrorMessage}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
                <div className={css.column}>
                  <InlineTextButton
                    type="button"
                    className={css.addToArray}
                    onClick={() => push('workedWith', {})}
                    disabled={values.dontAddWorkedWith}
                  >
                    {addButtonText}
                  </InlineTextButton>
                  <FieldCheckbox
                    id="dontAddWorkedWith"
                    name="dontAddWorkedWith"
                    label={dontAddLabel}
                    defaultValue={false}
                  />
                </div>
              </div>
              <div className={css.submitContainer}>
                {!isModal && (
                  <SecondaryButton rootClassName={css.backButton} type="button" onClick={onPrev}>
                    {previousButtonText}
                  </SecondaryButton>
                )}
                {!isModal ? (
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {nextButtonText}
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

SignupProviderWorkedWithForm.defaultProps = {
  isModal: false,
};

export default injectIntl(SignupProviderWorkedWithForm);
