import React from 'react';
import { Logo, NamedLink } from '../../components';

import css from './TopbarProjectCategoryPage.css';
import { FormattedMessage } from 'react-intl';

const TopbarProjectCategoryPage = props => {
  const {
    handleStartProjectClick,
    dontShowprojectCategoryTopBarButton,
    authenticationPageTopBar,
  } = props;

  return (
    <div className={css.topbarContainer}>
      <NamedLink className={css.logoLinkDesktop} name="LandingPage">
        <Logo format="desktop" className={css.logo} />
      </NamedLink>
      {authenticationPageTopBar ? null : !dontShowprojectCategoryTopBarButton ? (
        <div className={css.button} onClick={handleStartProjectClick}>
          <FormattedMessage id="TopbarProjectCategoryPage.button" />
        </div>
      ) : null}
    </div>
  );
};

export default TopbarProjectCategoryPage;
