import { getUsersForLandingPage } from '../../util/api';

// ================ Action types ================ //

export const LANDINGPAGE_TALENT_REQUEST = 'app/LandingPage/LANDINGPAGE_TALENT_REQUEST';
export const LANDINGPAGE_TALENT_SUCCESS = 'app/LandingPage/LANDINGPAGE_TALENT_SUCCESS';
export const LANDINGPAGE_TALENT_ERROR = 'app/LandingPage/LANDINGPAGE_TALENT_ERROR';

// ================ Reducer ================ //

const initialState = {
  usersAtSectionTalent: [],
  loadingError: null,
  loadingInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LANDINGPAGE_TALENT_REQUEST:
      return {
        ...state,
        loadingInProgress: true,
        loadingError: null,
      };
    case LANDINGPAGE_TALENT_SUCCESS:
      return { ...state, loadingInProgress: false, usersAtSectionTalent: payload };
    case LANDINGPAGE_TALENT_ERROR:
      return { ...state, verificationInProgress: false, loadingError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const loadingInProgress = state => {
  return state.LandingPage.loadingInProgress;
};

// ================ Action creators ================ //

export const loadingRequest = () => ({ type: LANDINGPAGE_TALENT_REQUEST });

export const loadingSuccess = users => ({
  type: LANDINGPAGE_TALENT_SUCCESS,
  payload: users,
});

export const loadingError = error => ({
  type: LANDINGPAGE_TALENT_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(loadingRequest());
  // Note that the thunk does not reject when the loading fails, it
  // just dispatches the login error action.

  getUsersForLandingPage()
    .then(response => {
      dispatch(loadingSuccess(response.data));
    })
    .catch(e => dispatch(loadingError(e)));
};
