import React, { Component, useState } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  AddImages,
  Button,
  Form,
  ValidationError,
  Modal,
  UnsavedInfoPrompt,
} from '../../components';

import css from './EditListingPhotosForm.css';

const ACCEPT_IMAGES = 'image/*';

function CropImage({ src, onCropChange, onImageLoaded }) {
  const [crop, setCrop] = useState({
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    aspect: 3 / 2,
    locked: true,
  });

  const updateCrop = image => {
    const newCrop = {
      unit: '%',
      x: 0,
      y: 0,
      width: 100,
      aspect: 3 / 2,
      locked: true,
    };

    setCrop(newCrop);
    onCropChange(newCrop);
    onImageLoaded(image);

    return false;
  };

  const imageStyle = window.innerWidth > 1024 ? { maxWidth: '65vw', maxHeight: '65vh' } : {};

  return (
    <ReactCrop
      src={src}
      className={css.cropImage}
      imageStyle={{ ...imageStyle }}
      crop={crop}
      ruleOfThirds={true}
      circularCrop={false}
      onChange={newCrop => {
        setCrop(newCrop);
        onCropChange(newCrop);
      }}
      onImageLoaded={updateCrop}
    />
  );
}

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.imageReader = new FileReader();
    this.imageReader.addEventListener(
      'load',
      () => {
        // convert image file to base64 string
        this.setState({ cropImageSrc: this.imageReader.result });
      },
      false
    );

    this.state = { imageUploadRequested: false, isSelectionModalOpen: false, imageLoading: false };
    this.submittedImages = [];

    this.onCropChange = this.onCropChange.bind(this);
    this.handleSubmitSelection = this.handleSubmitSelection.bind(this);
    this.onImageLoaded = this.onImageLoaded.bind(this);

    this.setImageLoading = this.setImageLoading.bind(this);
  }

  onCropChange(newCrop) {
    this.setState({ imageCrop: newCrop });
  }

  onImageLoaded(newCropElement) {
    this.setState({ imageCropElement: newCropElement });
  }

  setImageLoading(value) {
    console.log(this.state);
    this.setState({ imageLoading: value });
  }

  /**
   * @param {HTMLImageElement} image - Image File Object
   * @param {Object} crop - crop Object
   * @param {String} fileName - Name of the returned file in Promise
   */
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // canvas.width = crop.width;
    // canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = scaleX * crop.width * pixelRatio;
    canvas.height = scaleY * crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  }

  handleSubmitSelection() {
    const imageFile = this.state.imageCropElement;
    const tempId = `${imageFile.name}_${Date.now()}`;

    this.setState({ isSelectionModalOpen: false });
    this.getCroppedImg(imageFile, this.state.imageCrop, imageFile.name).then(resultFile => {
      this.props.onImageUpload({ id: tempId, file: resultFile });
    });
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={{ images: this.props.images }}
        imageLoading={this.state.imageLoading}
        state={this.state}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            intl,
            invalid,
            onManageDisableScrolling,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            dirty,
            state,
          } = formRenderProps;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseImage" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypes" />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            state.imageLoading = false;
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            state.imageLoading = false;
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            state.imageLoading ||
            invalid ||
            disabled ||
            submitInProgress ||
            imageUploadRequested ||
            ready;

          const imageUploadInProgress = value => {
            state.imageLoading = value;
          };

          const classes = classNames(css.root, className);

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                </p>
              ) : null}
              <AddImages
                className={css.imagesField}
                images={images}
                thumbnailClassName={css.thumbnail}
                savedImageAltText={intl.formatMessage({
                  id: 'EditListingPhotosForm.savedImageAltText',
                })}
                onRemoveImage={onRemoveImage}
                imageUploadInProgress={imageUploadInProgress}
              >
                <Field
                  id="addImage"
                  name="addImage"
                  accept={ACCEPT_IMAGES}
                  form={null}
                  label={chooseImageText}
                  type="file"
                  disabled={imageUploadRequested}
                >
                  {fieldprops => {
                    const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`addImage`, file);
                      form.blur(`addImage`);
                      console.log(form.getState());

                      this.imageReader.readAsDataURL(file);
                      this.setState({ isSelectionModalOpen: true });

                      form.blur('submit');
                    };

                    const inputProps = { accept, id: name, name, onChange, type };
                    return (
                      <div className={css.addImageWrapper}>
                        <div className={css.aspectRatioWrapper}>
                          {fieldDisabled ? null : (
                            <input {...inputProps} className={css.addImageInput} />
                          )}
                          <label htmlFor={name} className={css.addImage}>
                            {label}
                          </label>
                        </div>
                      </div>
                    );
                  }}
                </Field>

                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                  validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                />
              </AddImages>
              {uploadImageFailed}

              <ul className={css.tip}>
                <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
                <li>
                  <FormattedMessage id="EditListingPhotosForm.noContactInformation" />
                </li>
                <li>
                  <FormattedMessage id="EditListingPhotosForm.personalizedImages" />
                </li>
                <li>
                  <FormattedMessage id="EditListingPhotosForm.portfolio" />
                </li>
                <li>
                  <FormattedMessage id="EditListingPhotosForm.noCompanyLogo" />
                </li>
              </ul>
              {publishListingFailed}
              {showListingFailed}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
              <Modal
                id="EditListingPhotosForm.selectImageArea"
                contentClassName={css.selectImageAreaModalContent}
                isOpen={this.state.isSelectionModalOpen}
                onClose={() => this.setState({ isSelectionModalOpen: false })}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.cropImageWrapper}>
                  <CropImage
                    src={this.state.cropImageSrc}
                    onCropChange={this.onCropChange}
                    onImageLoaded={this.onImageLoaded}
                  />
                </div>
                <Button
                  className={css.submitImageButton}
                  type="button"
                  onClick={this.handleSubmitSelection}
                  inProgress={false}
                  disabled={false}
                  ready={false}
                >
                  <FormattedMessage id="EditListingPhotosForm.saveChanges" />
                </Button>
              </Modal>
              <UnsavedInfoPrompt when={dirty} />
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
