import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import { BsFillCheckCircleFill } from 'react-icons/bs';

import css from './BuyerTileFreedomlyPro.css';

const BuyerTileFreedomlyPro = props => {
  const {
    buyerTileFreedomlyProTitleId,
    buyerTileFreedomlyProSubtitleId,
    buyerTileFreedomlyProContent1,
    buyerTileFreedomlyProContent2,
    buyerTileFreedomlyProContent3,
    buyerTileFreedomlyProContent4,
    buyerTileFreedomlyProSecondaryCTAId,
    buyerTileFreedomlyProSecondaryCTATo,
    buyerTileFreedomlyProPrimaryCTAId,
    showSecondaryCTA,
  } = props;

  const handleOpenIntercom = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  return (
    <div className={css.buyerTile}>
      <div className={css.buyerTileTitle}>
        <FormattedMessage id={buyerTileFreedomlyProTitleId} />
      </div>
      <div className={css.buyerTileSubtitle}>
        <FormattedMessage id={buyerTileFreedomlyProSubtitleId} />
      </div>
      <div className={css.buyerTileProContents}>
        <div className={css.buyerTileProContent}>
          <BsFillCheckCircleFill className={css.buyerTileContentIcon} />
          <FormattedMessage id={buyerTileFreedomlyProContent1} />
        </div>
        <div className={css.buyerTileProContent}>
          <BsFillCheckCircleFill className={css.buyerTileContentIcon} />
          <FormattedMessage id={buyerTileFreedomlyProContent2} />
        </div>
        <div className={css.buyerTileProContent}>
          <BsFillCheckCircleFill className={css.buyerTileContentIcon} />
          <FormattedMessage id={buyerTileFreedomlyProContent3} />
        </div>
        <div className={css.buyerTileProContent}>
          <BsFillCheckCircleFill className={css.buyerTileContentIcon} />
          <FormattedMessage id={buyerTileFreedomlyProContent4} />
        </div>
      </div>
      <div className={css.buyerTileCTAContainer}>
        {showSecondaryCTA ? (
          <NamedLink
            name={buyerTileFreedomlyProSecondaryCTATo}
            className={css.buyerTileSecondaryCTA}
          >
            <FormattedMessage id={buyerTileFreedomlyProSecondaryCTAId} />
          </NamedLink>
        ) : null}

        <div
          name="DashboardProPage"
          className={showSecondaryCTA ? css.buyerTilePrimaryCTA : css.buyerTilePrimaryCTAFullWidth}
          onClick={() => handleOpenIntercom()}
        >
          <FormattedMessage id={buyerTileFreedomlyProPrimaryCTAId} />
        </div>
      </div>
    </div>
  );
};

export default BuyerTileFreedomlyPro;
