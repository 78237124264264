import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import config from '../../config';
import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';

import { Page, NamedLink, SectionLocationsCategoriesPage } from '../../components';

import css from './CategoriesPage.css';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { pickSearchParamsOnly } from '../SearchPage/SearchPage.helpers';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import SectionContact from '../../components/SectionContact/SectionContact';

const siteTitle = config.siteTitle; // CHANGE THIS
const schemaImage = `${config.canonicalRootURL}${facebookImage}`; // CHANGE THIS

const CategoriesPageComponent = props => {
  const isMobileModalOpen = false;

  const { intl, filterConfig, sortConfig, location } = props;
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle }); // CHANGE THIS
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' }); // CHANGE THIS

  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

  const topbarClasses = isMobileModalOpen
    ? classNames(css.topbarBehindModal, css.topbar)
    : css.topbar;

  const categoriesAll = filterConfig[1]?.config.options;

  const parentCategories = categoriesAll
    ?.filter(x => x.parent === '')
    .sort((x, y) => x.parentOrder - y.parentOrder);

  const allCategories = intl.formatMessage({ id: 'SectionLocations.allCategoriesLabel' });

  return (
    <Page
      className={css.root}
      scrollingDisabled={false}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[
        { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
      ]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <TopbarContainer
        className={topbarClasses}
        currentSearchParams={urlQueryParams}
        currentPage="CategoriesPage"
      />
      <div className={css.container}>
        <div className={css.parentCategoriesContainer}>
          <SectionLocationsCategoriesPage categoryPage="true" />
          <div className={css.allCategoriesContainer}>
            <h2>{allCategories}</h2>
            <ul className={css.allCategories}>
              {parentCategories.map(category => (
                <ParentCategory {...category} categoriesAll={categoriesAll}>
                  <ChildCategories
                    {...category}
                    keyProp={category.key}
                    categoriesAll={categoriesAll}
                  ></ChildCategories>
                </ParentCategory>
              ))}
            </ul>
          </div>
          <div className={css.bottomBanner}>
            <SectionContact
              title="SectionContact.titleOption1"
              subtitle="SectionContact.subtitleOption1"
              btnText="SectionContact.btnTextOption1"
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

const ParentCategory = props => {
  return (
    <ul className={css.outerList}>
      <li>
        <h2>
          <NamedLink name={props.name}>{props.label}</NamedLink>
        </h2>
      </li>
      {props.children}
    </ul>
  );
};

const ChildCategories = props => {
  return (
    <ChildCategory>
      {props.categoriesAll
        .filter(child => child.parent === props.keyProp)
        .map(x => (
          <li key={x.key}>
            <NamedLink name={x.name}>{x.label}</NamedLink>
          </li>
        ))}
    </ChildCategory>
  );
};

const ChildCategory = props => {
  return <>{props.children}</>;
};

CategoriesPageComponent.defaultProps = {
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

CategoriesPageComponent.propTypes = {
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,
};

const CategoriesPage = compose(withRouter, connect(), injectIntl)(CategoriesPageComponent);

export default CategoriesPage;
