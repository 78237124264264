import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { ensureUser } from '../../util/data';
import {
  TRANSITION_ENQUIRE,
  TRANSITION_SKIP_APPLICATION,
  txLastTransition,
  TRANSITION_ACCEPT_APPLICATION,
  TRANSITION_ACCEPT_AFTER_SKIP,
  TRANSITION_EXPIRE_APPLICATION,
  TRANSITION_EXPIRE_ENQUIRY,
} from '../../util/transaction';
import {
  INSTRUCTION_ENQUIRED,
  INSTRUCTION_SKIPPED,
  INSTRUCTION_ACCEPTED,
  INSTRUCTION_EXPIRED,
} from './Instructions';
import { propTypes } from '../../util/types';
import SectionProposal from './SectionProposal';
import SectionProfileCard from './SectionProfileCard';
import SectionActions from './SectionActions';
import SectionHeading from './SectionHeading';

import css from './ApplicationPanel.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

const ApplicationPanelComponent = props => {
  const {
    intl,
    currentUser,
    transaction,
    listing,
    transactionRole,
    handleAcceptApplication,
    onSkipApplication,
    handleAcceptApplicationAfterSkip,
    sendAcceptApplicationInProgress,
    showProfileListingInProgress,
    sendCreateReverseTransactionInProgress,
    sendSkipApplicationInProgress,
    sendAcceptAfterSkipInProgress,
    acceptApplicationError,
    profileListingError,
    reverseTransActionError,
    skipApplicationError,
    acceptAferSkipError,
  } = props;

  const { bestFit = '', similarWork = '', questions = '' } = transaction.attributes.protectedData;
  const transactionId = transaction.id.uuid;

  const currentProvider = ensureUser(transaction.provider);
  // const currentCustomer = ensureUser(transaction.customer);

  const isCustomer = transactionRole === 'customer';
  const isProvider = transactionRole === 'provider';

  // Should we do more checks with these?
  const listingLoaded = !!listing.id;
  const listingDeleted = listingLoaded && listing.attributes.deleted;
  // const iscustomerLoaded = !!currentCustomer.id;
  // const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
  // const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
  const isProviderLoaded = !!currentProvider.id;
  const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
  // const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

  const stateDataFn = tx => {
    if ([TRANSITION_ENQUIRE].includes(txLastTransition(tx))) {
      return {
        showActionButtons: isProvider && !isProviderBanned,
        instructionsState: INSTRUCTION_ENQUIRED,
      };
    } else if ([TRANSITION_SKIP_APPLICATION].includes(txLastTransition(tx))) {
      return {
        showAcceptAfterSkipButton: isProvider && !isProviderBanned,
        instructionsState: INSTRUCTION_SKIPPED,
      };
    } else if (
      [TRANSITION_ACCEPT_APPLICATION, TRANSITION_ACCEPT_AFTER_SKIP].includes(txLastTransition(tx))
    ) {
      return {
        instructionsState: INSTRUCTION_ACCEPTED,
        showToNewTransactionButton: true,
      };
    } else if (
      [TRANSITION_EXPIRE_APPLICATION, TRANSITION_EXPIRE_ENQUIRY].includes(txLastTransition(tx))
    ) {
      return {
        instructionsState: INSTRUCTION_EXPIRED,
      };
    } else {
      return {};
    }
  };

  const stateData = stateDataFn(transaction);

  const user =
    transactionRole === 'provider'
      ? ensureUser(transaction.customer)
      : ensureUser(transaction.provider);

  const actionError =
    acceptApplicationError ||
    profileListingError ||
    reverseTransActionError ||
    skipApplicationError ||
    acceptAferSkipError;

  return (
    <div className={css.root}>
      <SectionHeading isCustomer={isCustomer} listing={listing} listingDeleted={listingDeleted} />
      <div className={css.main}>
        <SectionProposal
          intl={intl}
          bestFit={bestFit}
          similarWork={similarWork}
          questions={questions}
        />
        <SectionProfileCard user={user} />
      </div>
      <SectionActions
        currentUserv={currentUser}
        transaction={transaction}
        stateData={stateData}
        transactionId={transactionId}
        isCustomer={isCustomer}
        actionError={actionError}
        sendAcceptAfterSkipInProgress={sendAcceptAfterSkipInProgress}
        sendAcceptApplicationInProgress={sendAcceptApplicationInProgress}
        showProfileListingInProgress={showProfileListingInProgress}
        sendCreateReverseTransactionInProgress={sendCreateReverseTransactionInProgress}
        handleAcceptApplication={handleAcceptApplication}
        onSkipApplication={onSkipApplication}
        sendSkipApplicationInProgress={sendSkipApplicationInProgress}
        handleAcceptApplicationAfterSkip={handleAcceptApplicationAfterSkip}
        user={user}
      />
    </div>
  );
};

const { bool, func, oneOf } = PropTypes;

ApplicationPanelComponent.propTypes = {
  transaction: propTypes.transaction,
  listing: propTypes.listing,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  handleAcceptApplication: func.isRequired,
  onSkipApplication: func.isRequired,
  handleAcceptApplicationAfterSkip: func.isRequired,
  sendAcceptApplicationInProgress: bool.isRequired,
  showProfileListingInProgress: bool.isRequired,
  sendCreateReverseTransactionInProgress: bool.isRequired,
  sendSkipApplicationInProgress: bool.isRequired,
  sendAcceptAfterSkipInProgress: bool.isRequired,
  acceptApplicationError: propTypes.error,
  profileListingError: propTypes.error,
  reverseTransActionError: propTypes.error,
  skipApplicationError: propTypes.error,
  acceptAferSkipError: propTypes.error,
  // from injectIntl
  intl: intlShape.isRequired,
};

const ApplicationPanel = injectIntl(ApplicationPanelComponent);

export default ApplicationPanel;
