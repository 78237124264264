import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import { ProfileSettingsContainer as Container } from '../../components';
import css from './Container.css';

const HobbiesContainer = ({ profileUser, button }) => {
  const hobbies = profileUser?.attributes?.profile?.publicData?.hobbies;

  const container = (
    <Container heading={<FormattedMessage id="ProfilePage.hobbies" />} button={button}>
      {hobbies &&
        hobbies.map(e => (
          <div key={e.category} className={css.skillContainer}>
            <div className={css.heading}>
              {config.custom.hobbyOptions.find(c => c.key === e.category)?.label}
            </div>
          </div>
        ))}
    </Container>
  );

  // Check whether there is a single object with null values.
  if (!button) {
    if (
      !hobbies?.length ||
      (Object.values(hobbies[0])[0] === null && Object.values(hobbies[0])[1] === null)
    )
      return null;
  }

  return container;
};

export default HobbiesContainer;
