import React from 'react';
import { injectIntl } from '../../util/reactIntl';

import css from './OptionBanner.css';

const OptionBanner = injectIntl(props => {
  const {
    bgColor,
    optionLabelId,
    titleId,
    titleDescriptionId,
    step1TitleId,
    step2TitleId,
    step3TitleId,
    step1DescriptionId,
    step2DescriptionId,
    step3DescriptionId,
    footerElement,
    intl,
  } = props;

  const rootClassName = bgColor === 'white' ? css.backgroundWhite : css.backgroundSuperLight;

  const optionLabel = intl.formatMessage({ id: optionLabelId });
  const title = intl.formatMessage({ id: titleId });
  const titleDescription = intl.formatMessage({ id: titleDescriptionId });
  const step1Title = intl.formatMessage({ id: step1TitleId });
  const step2Title = intl.formatMessage({ id: step2TitleId });
  const step3Title = intl.formatMessage({ id: step3TitleId });
  const step1Description = intl.formatMessage({ id: step1DescriptionId });
  const step2Description = intl.formatMessage({ id: step2DescriptionId });
  const step3Description = intl.formatMessage({ id: step3DescriptionId });

  return (
    <div className={rootClassName}>
      <div className={css.containerTitle}>
        <span className={css.optionLabel}>{optionLabel}</span>
        <h2 className={css.titleText}>{title}</h2>
        <div className={css.titleDescription}>
          <span>{titleDescription}</span>
        </div>
      </div>
      <div className={css.containerDivider}></div>
      <div className={css.containerContent}>
        <div className={css.step}>
          <div className={css.stepHeading}>
            <svg
              className={css.stepBadge}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10ZM12.0833 5C12.0833 4.83521 12.0344 4.67413 11.9428 4.53712C11.8513 4.40011 11.7211 4.29333 11.5689 4.23027C11.4166 4.16721 11.2491 4.15071 11.0875 4.18285C10.9258 4.21499 10.7774 4.29433 10.6608 4.41083L8.5775 6.49417C8.49791 6.57104 8.43442 6.66299 8.39075 6.76466C8.34707 6.86633 8.32409 6.97568 8.32312 7.08633C8.32216 7.19698 8.34325 7.30671 8.38515 7.40913C8.42705 7.51154 8.48893 7.60458 8.56717 7.68283C8.64541 7.76107 8.73846 7.82295 8.84087 7.86485C8.94328 7.90675 9.05302 7.92784 9.16367 7.92688C9.27432 7.92591 9.38367 7.90293 9.48534 7.85925C9.58701 7.81558 9.67896 7.75209 9.75583 7.6725C9.9997 7.42864 10.4167 7.60135 10.4167 7.94623V15C10.4167 15.221 10.5045 15.433 10.6607 15.5893C10.817 15.7455 11.029 15.8333 11.25 15.8333C11.471 15.8333 11.683 15.7455 11.8393 15.5893C11.9955 15.433 12.0833 15.221 12.0833 15V5Z"
                fill="#2ECC71"
              />
            </svg>
            <span className={css.stepTitle}>{step1Title}</span>
          </div>
          <div className={css.stepDescriptionContainer}>
            <span>{step1Description}</span>
          </div>
        </div>
        <div className={css.step}>
          <div className={css.stepHeading}>
            <svg
              className={css.stepBadge}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 0H0V20H20V0ZM12.5 7.5C12.5 7.05797 12.3244 6.63405 12.0118 6.32149C11.6993 6.00893 11.2754 5.83333 10.8333 5.83333H9.16667C8.82195 5.83328 8.48568 5.94007 8.20415 6.139C7.92262 6.33793 7.70966 6.61922 7.59458 6.94417C7.5181 7.14871 7.36439 7.31507 7.1665 7.40744C6.96862 7.4998 6.74239 7.51081 6.53648 7.43807C6.33057 7.36534 6.16144 7.21469 6.06547 7.01853C5.9695 6.82236 5.95437 6.59637 6.02333 6.38917C6.25325 5.73922 6.67897 5.17652 7.24189 4.7785C7.80481 4.38049 8.47725 4.16674 9.16667 4.16667H10.8333C11.5069 4.16669 12.1647 4.37079 12.72 4.75207C13.2753 5.13335 13.7021 5.67392 13.944 6.30255C14.186 6.93118 14.2319 7.61837 14.0755 8.27357C13.9192 8.92878 13.5681 9.52125 13.0683 9.97292L8.72833 14.1667H13.3333C13.5543 14.1667 13.7663 14.2545 13.9226 14.4107C14.0789 14.567 14.1667 14.779 14.1667 15C14.1667 15.221 14.0789 15.433 13.9226 15.5893C13.7663 15.7455 13.5543 15.8333 13.3333 15.8333H6.66667C6.50065 15.8333 6.33841 15.7837 6.20076 15.6909C6.06311 15.5981 5.95633 15.4663 5.8941 15.3124C5.83187 15.1584 5.81703 14.9894 5.85149 14.827C5.88595 14.6646 5.96813 14.5162 6.0875 14.4008L11.9208 8.76417L11.9442 8.7425C12.1193 8.58642 12.2594 8.39499 12.3553 8.18082C12.4511 7.96666 12.5004 7.73462 12.5 7.5Z"
                fill="#2ECC71"
              />
            </svg>
            <span className={css.stepTitle}>{step2Title}</span>
          </div>
          <div className={css.stepDescriptionContainer}>
            <span>{step2Description}</span>
          </div>
        </div>
        <div className={css.step}>
          <div className={css.stepHeading}>
            <svg
              className={css.stepBadge}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 0H0V20H20V0ZM12.5 7.5C12.5 6.59625 11.7467 5.83333 10.7792 5.83333H9.07583C8.31625 5.83333 7.6825 6.30708 7.45083 6.95C7.37569 7.15792 7.22103 7.32747 7.02087 7.42136C6.82071 7.51524 6.59146 7.52577 6.38354 7.45062C6.17562 7.37548 6.00607 7.22082 5.91219 7.02066C5.8183 6.82051 5.80777 6.59125 5.88292 6.38333C6.35208 5.08417 7.61083 4.16667 9.07583 4.16667H10.7787C12.6333 4.16667 14.1667 5.64208 14.1667 7.5C14.1668 7.97474 14.0644 8.4439 13.8664 8.87538C13.6684 9.30687 13.3796 9.69049 13.0196 10C13.3796 10.3095 13.6684 10.6931 13.8664 11.1246C14.0644 11.5561 14.1668 12.0253 14.1667 12.5C14.1667 14.3579 12.6329 15.8333 10.7792 15.8333H9.07583C7.61083 15.8333 6.35208 14.9158 5.88292 13.6167C5.84571 13.5137 5.82914 13.4045 5.83415 13.2952C5.83916 13.1858 5.86566 13.0786 5.91212 12.9795C5.95859 12.8804 6.02412 12.7914 6.10497 12.7177C6.18582 12.6439 6.28041 12.5868 6.38333 12.5496C6.5912 12.4744 6.8204 12.4849 7.02051 12.5788C7.1196 12.6253 7.20857 12.6908 7.28233 12.7716C7.35609 12.8525 7.41321 12.9471 7.45042 13.05C7.6825 13.6929 8.31625 14.1667 9.07583 14.1667H10.7787C11.7467 14.1667 12.5 13.4037 12.5 12.5C12.5 11.5962 11.7467 10.8333 10.7792 10.8333H9.07583C8.85482 10.8333 8.64286 10.7455 8.48658 10.5893C8.3303 10.433 8.2425 10.221 8.2425 10C8.2425 9.77899 8.3303 9.56702 8.48658 9.41074C8.64286 9.25446 8.85482 9.16667 9.07583 9.16667H10.7787C11.7467 9.16667 12.5 8.40375 12.5 7.5Z"
                fill="#2ECC71"
              />
            </svg>
            <span className={css.stepTitle}>{step3Title}</span>
          </div>
          <div className={css.stepDescriptionContainer}>
            <span>{step3Description}</span>
          </div>
        </div>
      </div>
      {footerElement}
    </div>
  );
});

export default OptionBanner;
