import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import { ProfileSettingsContainer as Container } from '../../components';
import css from './Container.css';

const TopSkillsContainer = ({ profileUser, button }) => {
  const experience = profileUser?.attributes?.profile?.publicData?.experience;
  const skillLevelOptions = config.custom.skillLevelOptions;

  // Define the order of the skill levels
  const levelOrder = {
    entry: 1,
    junior: 2,
    midlevel: 3,
    senior: 4,
  };

  // Sort the experience array based on the skill level
  const sortedExperience = experience?.slice().sort((a, b) => {
    const levelA = levelOrder[a.level] || 0;
    const levelB = levelOrder[b.level] || 0;
    return levelB - levelA;
  });

  const container = (
    <Container heading={<FormattedMessage id="ProfilePage.skills" />} button={button}>
      {sortedExperience &&
        sortedExperience.map(e => (
          <div key={e.category} className={css.skillContainer}>
            <div className={css.heading}>
              {config.custom.skillOptions.find(c => c.key === e.category)?.label}
            </div>
            <div className={css.years}>
              {e.category && e.level && (
                <FormattedMessage id={skillLevelOptions.find(s => s.key === e.level)?.label} />
              )}
            </div>
          </div>
        ))}
    </Container>
  );

  // Check whether there is a single object with null values.
  if (!button) {
    if (
      !sortedExperience?.length ||
      (Object.values(sortedExperience[0])[0] === null &&
        Object.values(sortedExperience[0])[1] === null)
    )
      return null;
  }

  return container;
};

export default TopSkillsContainer;
