import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { ensureCurrentUser } from '../../util/data';
import { InfoIcon, Form, PrimaryButton } from '../../components';

import css from './SubscriptionForm.css';

class SubscriptionFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            currentUser,
            handleSubmit,
            inProgress,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = false;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                handleSubmit(e);
              }}
            >
              <div>
                <FormattedMessage id="SubscriptionForm.subscriptionNote" />
                { typeof window === 'object' ? <ReactTooltip /> : null }
                <InfoIcon
                  className={css.infoIcon}
                  data-tip={intl.formatMessage({ id: 'SubscriptionForm.subscriptionTooltip'})}
                  data-border={true}
                  data-text-color={'black'}
                  data-background-color={'white'}
                  data-border-color={'darkgrey'}
                  data-class={css.tooltipText}
                  data-place={'bottom'}
                />
              </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={false}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="SubscriptionForm.subscribe" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SubscriptionFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
};

const { bool, string } = PropTypes;

SubscriptionFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
};

const SubscriptionForm = compose(injectIntl)(SubscriptionFormComponent);

SubscriptionForm.displayName = 'SubscriptionForm';

export default SubscriptionForm;
