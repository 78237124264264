import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './CategoryBanner.css';

const CategoryBanner = ({ content }) => {
  const { categoryLabelId, parentCategory, bannerTitleId, bannerTextId } = content;
  const isMainCategory = parentCategory === null;

  const handleOpenArticle = () => {
    if (typeof window === 'object') {
      window.Intercom('showArticle', 6840140);
    }
  };

  return (
    <div className={css.root}>
      <div className={css.content}>
        <div className={css.breadCrumbs}>
          <NamedLink name="LandingPage" className={css.icon}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 24"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.0095 0.164212C11.873 0.0577932 11.7049 0 11.5318 0C11.3587 0 11.1906 0.0577932 11.0541 0.164212L0 8.78362L0.956173 9.99287L2.30774 8.93901V18.4615C2.30855 18.8693 2.4709 19.2601 2.75925 19.5485C3.0476 19.8368 3.43845 19.9992 3.84623 20H19.2312C19.6389 19.9992 20.0298 19.8368 20.3181 19.5485C20.6065 19.2601 20.7688 18.8693 20.7697 18.4615V8.94593L22.1212 9.9998L23.0774 8.79054L12.0095 0.164212ZM13.0772 18.4615H10.0002V12.3075H13.0772V18.4615ZM14.6157 18.4615V12.3075C14.6153 11.8996 14.4531 11.5085 14.1646 11.2201C13.8762 10.9317 13.4851 10.7695 13.0772 10.769H10.0002C9.5923 10.7695 9.20121 10.9317 8.91278 11.2201C8.62434 11.5085 8.46212 11.8996 8.46171 12.3075V18.4615H3.84623V7.73975L11.5387 1.74732L19.2312 7.74744V18.4615H14.6157Z" />
            </svg>
          </NamedLink>
          {isMainCategory ? (
            <>
              <span>&gt;</span>
              <FormattedMessage id={categoryLabelId} />
            </>
          ) : (
            <>
              <span>&gt;</span>
              <NamedLink className={css.parentLink} name={parentCategory.to}>
                <FormattedMessage id={parentCategory.labelId} />
              </NamedLink>
              <span>&gt;</span>
              <FormattedMessage id={categoryLabelId} />
            </>
          )}
        </div>
        <div className={css.header}>
          <h1>
            <FormattedMessage id={bannerTitleId} />
          </h1>
        </div>
        <div className={css.textContent}>
          <div className={css.bannerText}>
            <FormattedMessage id={bannerTextId} />
          </div>
          <div className={css.howItWorks} onClick={handleOpenArticle}>
            <span>
              <FormattedMessage id="CategoryBanner.howItWorks" />
            </span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7.5 14.5L14.5 10L7.5 5.5V14.5ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20Z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryBanner;
