import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './Tooltip.css';

const Tooltip = props => {
  const { children, messageId, rootClassName, className } = props;
  const [active, setActive] = useState(false);

  const toggleActive = () => setActive(!active);

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div onMouseEnter={toggleActive} onMouseLeave={toggleActive}>
        {children}
      </div>
      {active && (
        <div className={css.container}>
          <div className={css.tip}>
            <FormattedMessage id={messageId} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
