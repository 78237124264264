import React, { useState } from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, Button, UnsavedInfoPrompt } from '../../components';
import ReactTooltip from 'react-tooltip';

import css from './SignUpProjectForms.css';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

const SignUpProjectCategoryFormComponent = props => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          values,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          updated,
          updateInProgress,
          fetchErrors,
          categories,
          dirty,
        } = formRenderProps;
        const onCategoryChange = selectedOption => {
          setSelectedCategory(selectedOption.value);
          values.category = selectedCategory;
          values.category = selectedOption.value;
        };

        const nextButtonMessage = intl.formatMessage({
          id: 'SignUpProjectCategoryForm.nextButtonMessage',
        });

        const categoryInfoText = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.categoryInfoText',
        });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingBudgetForm.updateFailed" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingBudgetForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || !values.category;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}
            {typeof window === 'object' ? <ReactTooltip /> : null}
            <div>
              <CustomCategorySelectFieldMaybe
                id="category"
                name="category"
                className={css.categorySelect}
                categories={categories}
                selectedValue={values.category}
                onChange={onCategoryChange}
                intl={intl}
              />
              <div className={css.categoryInfoText}>{categoryInfoText}</div>
            </div>

            <div className={css.buttonContainer}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {nextButtonMessage}
              </Button>
            </div>
            <UnsavedInfoPrompt when={dirty} />
          </Form>
        );
      }}
    />
  );
};

export default compose(injectIntl)(SignUpProjectCategoryFormComponent);
