import React from 'react';

import css from './StaticFreelancerPage.css';

import { Button, NamedLink } from '../../components';

const StaticFreelancerPageHeroSection = props => {
  const { title, subTitle, buttonText, imgSrc } = props;

  return (
    <div className={css.heroSection}>
      <div className={css.heroContent}>
        <div className={css.heroTitle}>{title}</div>
        <div className={css.heroSubtitle}>{subTitle}</div>

        {buttonText && (
          <NamedLink className={css.heroButton} name="SignupPage">
            {buttonText}
          </NamedLink>
        )}
      </div>
      <div className={css.heroImage}>
        <img src={imgSrc} alt="Hero" />
      </div>
    </div>
  );
};

export default StaticFreelancerPageHeroSection;
