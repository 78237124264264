import React from 'react';
import renderHTML from 'react-render-html';
import { injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';

import { ProfileSettingsContainer as Container } from '../../components';
import css from './Container.css';

const BioWhoAmIContainerComponent = ({ profileUser, button, intl, isValidProvider }) => {
  const whoAmIHeading = intl.formatMessage({ id: 'ProfileSettingsForm.whoAmI' });
  const bioHeading = intl.formatMessage({ id: 'ProfileSettingsForm.bioHeading' });

  const whoAmI = profileUser?.attributes?.profile?.publicData?.whoAmI;
  const bio = profileUser?.attributes?.profile?.publicData?.bio || '';

  const container = (
    <Container heading={' '} button={button}>
      <div>
        {isValidProvider ? (
          <div>
            <div className={css.sectionTitle}>{whoAmIHeading}</div>
            <div className={css.whoAmI}>{whoAmI}</div>
          </div>
        ) : null}

        <div className={css.sectionTitle}>{bioHeading}</div>
        <div className={css.bio}>{renderHTML(bio)}</div>
      </div>
    </Container>
  );

  if (!button) {
    return null;
  }
  return container;
};

const BioWhoAmIContainer = compose(injectIntl)(BioWhoAmIContainerComponent);

export default BioWhoAmIContainer;
