import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ensureOwnListing } from '../../util/data';
import { EditProjectListingRequirementsForm } from '../../forms';
import { findOptionsForSelectFilter, buildCategoryHash } from '../../util/search';
import config from '../../config';
import { NewJobInfoPanel } from '../../components';

import css from './EditProjectListingRequirementsPanel.css';

const EditProjectListingRequirementsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const categoryHash = buildCategoryHash(categoryOptions);
  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      <NewJobInfoPanel />

      <EditProjectListingRequirementsForm
        className={css.form}
        publicData={publicData}
        initialValues={{ projectTier: publicData.projectTier, category: publicData.category }}
        onSubmit={values => {
          const { projectTier = '', category = 'seo' } = values;
          const categoryObject = categoryHash[category];
          const categoryNodes = [category];
          if (typeof categoryObject.parent === 'string' && categoryObject.parent.length > 0)
            categoryNodes.push(categoryObject.parent);
          const updateValues = {
            publicData: {
              projectTier,
              category,
              categoryNodes,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditProjectListingRequirementsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditProjectListingRequirementsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditProjectListingRequirementsPanel;
