import React from 'react';
import { bool, func, node, number, string, array, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { GrClose } from 'react-icons/gr';

import css from './SearchFiltersPrimary.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    getReviewsInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    selectedFilters,
    resetAll,
    handleDeleteFilter,
    filterConfig,
    routeName,
    intl,
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);

  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <button
      className={toggleSecondaryFiltersOpenButtonClasses}
      onClick={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </button>
  ) : null;

  const selectedFilterEntries = Object.entries(selectedFilters);

  const handleDelete = queryParamName => {
    handleDeleteFilter({ [queryParamName]: null });
  };

  const resolveFilterLabel = (key, val) => {
    const type = filterConfig.find(filter => filter.queryParamNames[0] === key);

    switch (key) {
      case 'price':
        const priceValues = val.split(',');
        return priceValues.join(' - ');
      case 'pub_categoryNodes':
        const categoryElement = type.config.options.find(option => {
          return option.key === val;
        });
        return categoryElement?.label || '';
      case 'pub_languages':
        const languageValues = val.split(',');
        if (languageValues.length === 1) {
          const languageElement = type.config.options.find(option => option.key === val);
          return intl.formatMessage({ id: languageElement.label.props.id });
        } else {
          let label = [];
          languageValues.forEach(value => {
            const element = type.config.options.find(option => option.key === value);
            label.push(intl.formatMessage({ id: element.label.props.id }));
          });
          return label.join(' / ');
        }
      default:
        return val;
    }
  };

  return (
    <div className={classes}>
      <div className={css.filters}>
        {routeName === undefined ? sortByComponent : null}
        {children}
        {toggleSecondaryFiltersOpenButton}
      </div>

      {!isEmpty(selectedFilters) ? (
        <div>
          {selectedFilterEntries.map(([key, val]) => (
            <button onClick={() => handleDelete(key)} className={css.badge} key={val}>
              {resolveFilterLabel(key, val)}
              <GrClose />
            </button>
          ))}
          {routeName === undefined ? (
            <button className={css.clear} onClick={resetAll}>
              <FormattedMessage id="SearchFiltersPrimary.clearAllFilters" />
            </button>
          ) : null}
        </div>
      ) : null}

      <div className={css.searchResultSummary}>
        <span className={css.resultsFound}>
          <FormattedMessage
            id="SearchFiltersPrimary.foundResults"
            values={{ count: resultsCount }}
          />
        </span>
      </div>

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <FormattedMessage id="SearchFiltersPrimary.noResults" />
        </div>
      ) : null}

      {searchInProgress && getReviewsInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
  selectedFilters: {},
  resetAll: null,
  handleDeleteFilter: null,
  filterConfig: [],
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
  selectedFilters: object,
  resetAll: func,
  handleDeleteFilter: func,
  filterConfig: array,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
