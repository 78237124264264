import React from 'react';
import {
  FaBook,
  FaBookOpen,
  FaBuilding,
  FaBullhorn,
  FaCamera,
  FaCameraRetro,
  FaComment,
  FaCopy,
  FaDesktop,
  FaEllipsisH,
  FaFileAudio,
  FaFileVideo,
  FaLaptop,
  FaLaptopCode,
  FaMailBulk,
  FaMicrochip,
  FaMobileAlt,
  FaNetworkWired,
  FaPenFancy,
  FaPencilAlt,
  FaPencilRuler,
  FaPhotoVideo,
  FaPrint,
  FaPuzzlePiece,
  FaRegCircle,
  FaRegStar,
  FaRobot,
  FaSearchDollar,
  FaShoppingBag,
  FaUserCheck,
  FaUserFriends,
  FaUsers,
  FaWrench,
} from 'react-icons/fa';

export const pageContents = [
  {
    id: 'content_creating',
    categoryLabelId: 'CategoryPage.contentCreating',
    bannerTitleId: 'CategoryPage.contentCreating',
    bannerTextId: 'CategoryPage.contentCreatingPageBannerTextId',
    parentCategory: null,
    showDescription: true,
    seoContent: {
      mainTitleId: 'CategoryPage.contentCreatingPageSeoContentMainTitleId',
      mainTextId: 'CategoryPage.contentCreatingPageSeoContentMainTextId',
      subSections: [
        {
          id: 1,
          titleId: 'CategoryPage.contentCreatingPageSeoContentMainTitleId',
          textId: 'CategoryPage.contentCreatingPageSeoContentTextId',
        },
      ],
    },
    childCategories: [
      {
        id: 'social_media_content',
        categoryLabelId: 'CategoryPage.socialMediaContent',
        to: 'SocialMediaContent',
        icon: <FaMobileAlt />,
        bannerTitleId: 'CategoryPage.socialMediaContent',
        bannerTextId: 'CategoryPage.socialMediaContentPageBannerTextId',
        parentCategory: {
          to: 'ContentCreating',
          labelId: 'CategoryPage.contentCreating',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.socialMediaContentPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.socialMediaContentPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.socialMediaContentPageSeoContentTitleId',
              textId: 'CategoryPage.socialMediaContentPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'articles_n_blog_content',
        categoryLabelId: 'CategoryPage.articlesAndBlogContent',
        to: 'ArticlesAndBlogContent',
        icon: <FaPencilAlt />,
        bannerTitleId: 'CategoryPage.articlesAndBlogContent',
        bannerTextId: 'CategoryPage.articlesAndBlogContentPageBannerTextId',
        parentCategory: {
          to: 'ContentCreating',
          labelId: 'CategoryPage.contentCreating',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.articlesAndBlogContentPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.articlesAndBlogContentPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.articlesAndBlogContentPageSeoContentTitleId',
              textId: 'CategoryPage.articlesAndBlogContentPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'ugc_content',
        categoryLabelId: 'CategoryPage.ugcContent',
        to: 'UgcContent',
        icon: <FaComment />,
        bannerTitleId: 'CategoryPage.ugcContent',
        bannerTextId: 'CategoryPage.ugcContentPageBannerTextId',
        parentCategory: {
          to: 'ContentCreating',
          labelId: 'CategoryPage.contentCreating',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.ugcContentPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.ugcContentPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.ugcContentPageSeoContentTitleId',
              textId: 'CategoryPage.ugcContentPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'other_content_creating',
        categoryLabelId: 'CategoryPage.otherContent',
        to: 'OtherContentCreating',
        icon: <FaEllipsisH />,
        bannerTitleId: 'CategoryPage.otherContent',
        bannerTextId: 'CategoryPage.otherContent',
        parentCategory: {
          to: 'ContentCreating',
          labelId: 'CategoryPage.contentCreating',
        },
        showDescription: false,
        seoContent: {
          mainTitleId: '',
          mainTextId: '',
          subSections: [
            {
              id: 1,
              titleId: '',
              textId: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'graphic_design',
    categoryLabelId: 'CategoryPage.graphicDesign',
    bannerTitleId: 'CategoryPage.graphicDesign',
    bannerTextId: 'CategoryPage.graphicDesignPageBannerTextId',
    parentCategory: null,
    showDescription: true,
    seoContent: {
      mainTitleId: 'CategoryPage.graphicDesignPageSeoContentMainTitleId',
      mainTextId: 'CategoryPage.graphicDesignPageSeoContentMainTextId',
      subSections: [
        {
          id: 69,
          titleId: 'CategoryPage.graphicDesignPageSeoContentTitleId',
          textId: 'CategoryPage.graphicDesignPageSeoContentTextId',
        },
      ],
    },
    childCategories: [
      {
        id: 'logos',
        categoryLabelId: 'CategoryPage.logos',
        to: 'Logos',
        icon: <FaRegStar />,
        bannerTitleId: 'CategoryPage.logos',
        bannerTextId: 'CategoryPage.logosPageBannerTextId',
        parentCategory: {
          to: 'GraphicDesign',
          labelId: 'CategoryPage.graphicDesign',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.logosPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.logosPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.logosPageSeoContentTitleId',
              textId: 'CategoryPage.logosPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'corporate_identity',
        categoryLabelId: 'CategoryPage.corporateIdentity',
        to: 'CorporateIdentity',
        icon: <FaBuilding />,
        bannerTitleId: 'CategoryPage.corporateIdentity',
        bannerTextId: 'CategoryPage.corporateIdentityPageBannerTextId',
        parentCategory: {
          to: 'GraphicDesign',
          labelId: 'CategoryPage.graphicDesign',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.corporateIdentityPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.corporateIdentityPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.corporateIdentityPageSeoContentTitleId',
              textId: 'CategoryPage.corporateIdentityPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'illustration',
        categoryLabelId: 'CategoryPage.illustrations',
        to: 'Illustrations',
        icon: <FaPenFancy />,
        bannerTitleId: 'CategoryPage.illustrations',
        bannerTextId: 'CategoryPage.illustrationsPageBannerTextId',
        parentCategory: {
          to: 'GraphicDesign',
          labelId: 'CategoryPage.graphicDesign',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.illustrationsPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.illustrationsPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.illustrationsPageSeoContentTitleId',
              textId: 'CategoryPage.illustrationsPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'printout',
        categoryLabelId: 'CategoryPage.printDesign',
        to: 'PrintDesign',
        icon: <FaPrint />,
        bannerTitleId: 'CategoryPage.printDesign',
        bannerTextId: 'CategoryPage.printDesignPageBannerTextId',
        parentCategory: {
          to: 'GraphicDesign',
          labelId: 'CategoryPage.graphicDesign',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.printDesignPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.printDesignPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.printDesignPageSeoContentTitleId',
              textId: 'CategoryPage.printDesignPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'ui_and_ux_design',
        categoryLabelId: 'CategoryPage.uiUxDesign',
        to: 'UiUxDesign',
        icon: <FaPencilRuler />,
        bannerTitleId: 'CategoryPage.uiUxDesign',
        bannerTextId: 'CategoryPage.uiUxDesignPageBannerTextId',
        parentCategory: {
          to: 'GraphicDesign',
          labelId: 'CategoryPage.graphicDesign',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.uiUxDesignPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.uiUxDesignPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.uiUxDesignPageSeoContentTitleId',
              textId: 'CategoryPage.uiUxDesignPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'other_graphic_design',
        categoryLabelId: 'CategoryPage.otherContent',
        to: 'OtherGraphicDesign',
        icon: <FaEllipsisH />,
        bannerTitleId: 'CategoryPage.otherContent',
        bannerTextId: 'CategoryPage.otherContent',
        parentCategory: {
          to: 'GraphicDesign',
          labelId: 'CategoryPage.graphicDesign',
        },
        showDescription: false,
        seoContent: {
          mainTitleId: '',
          mainTextId: '',
          subSections: [
            {
              id: 1,
              titleId: '',
              textId: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'ecommerce_n_websites',
    categoryLabelId: 'CategoryPage.ecommerceAndWebsites',
    bannerTitleId: 'CategoryPage.ecommerceAndWebsites',
    bannerTextId: 'CategoryPage.ecommerceAndWebsitesPageBannerTextId',
    parentCategory: null,
    showDescription: true,
    seoContent: {
      mainTitleId: 'CategoryPage.ecommerceAndWebsitesPageSeoContentMainTitleId',
      mainTextId: 'CategoryPage.ecommerceAndWebsitesPageSeoContentMainTextId',
      subSections: [
        {
          id: 1,
          titleId: 'CategoryPage.ecommerceAndWebsitesPageSeoContentTitleId',
          textId: 'CategoryPage.ecommerceAndWebsitesPageSeoContentTextId',
        },
      ],
    },
    childCategories: [
      {
        id: 'website_development',
        categoryLabelId: 'CategoryPage.websiteDevelopment',
        to: 'WebsiteDevelopment',
        icon: <FaLaptop />,
        bannerTitleId: 'CategoryPage.websiteDevelopment',
        bannerTextId: 'CategoryPage.websiteDevelopmentPageBannerTextId',
        parentCategory: {
          to: 'EcommerceAndWebsites',
          labelId: 'CategoryPage.ecommerceAndWebsites',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.websiteDevelopmentPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.websiteDevelopmentPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.websiteDevelopmentPageSeoContentTitleId',
              textId: 'CategoryPage.websiteDevelopmentPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'ecommerce_development',
        categoryLabelId: 'CategoryPage.ecommerceDevelopment',
        to: 'EcommerceDevelopment',
        icon: <FaShoppingBag />,
        bannerTitleId: 'CategoryPage.ecommerceDevelopment',
        bannerTextId: 'CategoryPage.ecommerceDevelopmentPageBannerTextId',
        parentCategory: {
          to: 'EcommerceAndWebsites',
          labelId: 'CategoryPage.ecommerceAndWebsites',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.ecommerceDevelopmentPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.ecommerceDevelopmentPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.ecommerceDevelopmentPageSeoContentTitleId',
              textId: 'CategoryPage.ecommerceDevelopmentPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'other_ecommerce_n_websites',
        categoryLabelId: 'CategoryPage.otherContent',
        to: 'OtherEcommerceAndWebsites',
        icon: <FaEllipsisH />,
        bannerTitleId: 'CategoryPage.otherContent',
        bannerTextId: 'CategoryPage.otherContent',
        parentCategory: {
          to: 'EcommerceAndWebsites',
          labelId: 'CategoryPage.ecommerceAndWebsites',
        },
        showDescription: false,
        seoContent: {
          mainTitleId: '',
          mainTextId: '',
          subSections: [
            {
              id: 1,
              titleId: '',
              textId: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'digital_marketing',
    categoryLabelId: 'CategoryPage.digitalMarketing',
    bannerTitleId: 'CategoryPage.digitalMarketing',
    bannerTextId: 'CategoryPage.digitalMarketingPageBannerTextId',
    parentCategory: null,
    showDescription: true,
    seoContent: {
      mainTitleId: 'CategoryPage.digitalMarketingPageSeoContentMainTitleId',
      mainTextId: 'CategoryPage.digitalMarketingPageSeoContentMainTextId',
      subSections: [
        {
          id: 1,
          titleId: 'CategoryPage.digitalMarketingPageSeoContentTitleId',
          textId: 'CategoryPage.digitalMarketingPageSeoContentTextId',
        },
      ],
    },
    childCategories: [
      {
        id: 'seo',
        categoryLabelId: 'CategoryPage.seo',
        to: 'Seo',
        icon: <FaNetworkWired />,
        bannerTitleId: 'CategoryPage.seo',
        bannerTextId: 'CategoryPage.seoPageBannerTextId',
        parentCategory: {
          to: 'DigitalMarketing',
          labelId: 'CategoryPage.digitalMarketing',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.seoPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.seoPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.seoPageSeoContentTitleId',
              textId: 'CategoryPage.seoPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'search_engine_marketing',
        categoryLabelId: 'CategoryPage.searchEngineMarketing',
        to: 'SearchEngineMarketing',
        icon: <FaSearchDollar />,
        bannerTitleId: 'CategoryPage.searchEngineMarketing',
        bannerTextId: 'CategoryPage.searchEngineMarketingPageBannerTextId',
        parentCategory: {
          to: 'DigitalMarketing',
          labelId: 'CategoryPage.digitalMarketing',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.searchEngineMarketingPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.searchEngineMarketingPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.searchEngineMarketingPageSeoContentTitleId',
              textId: 'CategoryPage.searchEngineMarketingPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'social_media_advertising',
        categoryLabelId: 'CategoryPage.socialMediaAdvertising',
        to: 'SocialMediaAdvertising',
        icon: <FaUsers />,
        bannerTitleId: 'CategoryPage.socialMediaAdvertising',
        bannerTextId: 'CategoryPage.socialMediaAdvertisingPageBannerTextId',
        parentCategory: {
          to: 'DigitalMarketing',
          labelId: 'CategoryPage.digitalMarketing',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.socialMediaAdvertisingPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.socialMediaAdvertisingPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.socialMediaAdvertisingPageSeoContentTitleId',
              textId: 'CategoryPage.socialMediaAdvertisingPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'email_marketing',
        categoryLabelId: 'CategoryPage.emailMarketing',
        to: 'EmailMarketing',
        icon: <FaMailBulk />,
        bannerTitleId: 'CategoryPage.emailMarketing',
        bannerTextId: 'CategoryPage.emailMarketingPageBannerTextId',
        parentCategory: {
          to: 'DigitalMarketing',
          labelId: 'CategoryPage.digitalMarketing',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.emailMarketingPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.emailMarketingPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.emailMarketingPageSeoContentTitleId',
              textId: 'CategoryPage.emailMarketingPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'marketing_strategy',
        categoryLabelId: 'CategoryPage.marketingStrategy',
        to: 'MarketingStrategy',
        icon: <FaPuzzlePiece />,
        bannerTitleId: 'CategoryPage.marketingStrategy',
        bannerTextId: 'CategoryPage.marketingStrategyPageBannerTextId',
        parentCategory: {
          to: 'DigitalMarketing',
          labelId: 'CategoryPage.digitalMarketing',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.marketingStrategyPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.marketingStrategyPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.marketingStrategyPageSeoContentTitleId',
              textId: 'CategoryPage.marketingStrategyPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'other_digital_marketing',
        categoryLabelId: 'CategoryPage.otherContent',
        to: 'OtherDigitalMarketing',
        icon: <FaEllipsisH />,
        bannerTitleId: 'CategoryPage.otherContent',
        bannerTextId: 'CategoryPage.otherContent',
        parentCategory: {
          to: 'DigitalMarketing',
          labelId: 'CategoryPage.digitalMarketing',
        },
        showDescription: false,
        seoContent: {
          mainTitleId: '',
          mainTextId: '',
          subSections: [
            {
              id: 1,
              titleId: '',
              textId: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'photography_n_video',
    categoryLabelId: 'CategoryPage.photographyAndVideo',
    bannerTitleId: 'CategoryPage.photographyAndVideo',
    bannerTextId: 'CategoryPage.photographyAndVideoPageBannerTextId',
    parentCategory: null,
    showDescription: true,
    seoContent: {
      mainTitleId: 'CategoryPage.photographyAndVideoPageSeoContentMainTitleId',
      mainTextId: 'CategoryPage.photographyAndVideoPageSeoContentMainTextId',
      subSections: [
        {
          id: 1,
          titleId: 'CategoryPage.photographyAndVideoPageSeoContentTitleId',
          textId: 'CategoryPage.photographyAndVideoPageSeoContentTextId',
        },
      ],
    },
    childCategories: [
      {
        id: 'editing',
        categoryLabelId: 'CategoryPage.editing',
        to: 'Editing',
        icon: <FaPhotoVideo />,
        bannerTitleId: 'CategoryPage.editing',
        bannerTextId: 'CategoryPage.editingPageBannerTextId',
        parentCategory: {
          to: 'PhotographyAndVideo',
          labelId: 'CategoryPage.contentCreating',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.editingPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.editingPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.editingPageSeoContentTitleId',
              textId: 'CategoryPage.editingPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'product_photography',
        categoryLabelId: 'CategoryPage.productPhotography',
        to: 'ProductPhotography',
        icon: <FaCamera />,
        bannerTitleId: 'CategoryPage.productPhotography',
        bannerTextId: 'CategoryPage.productPhotographyPageBannerTextId',
        parentCategory: {
          to: 'PhotographyAndVideo',
          labelId: 'CategoryPage.photographyAndVideo',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.productPhotographyPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.productPhotographyPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.productPhotographyPageSeoContentTitleId',
              textId: 'CategoryPage.productPhotographyPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'photoshoots',
        categoryLabelId: 'CategoryPage.photoshoots',
        to: 'Photoshoots',
        icon: <FaCameraRetro />,
        bannerTitleId: 'CategoryPage.photoshoots',
        bannerTextId: 'CategoryPage.photoshootsPageBannerTextId',
        parentCategory: {
          to: 'PhotographyAndVideo',
          labelId: 'CategoryPage.photographyAndVideo',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.photoshootsPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.photoshootsPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.photoshootsPageSeoContentTitleId',
              textId: 'CategoryPage.photoshootsPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: '3d_modeling',
        categoryLabelId: 'CategoryPage.3dModeling',
        to: '3dModeling',
        icon: <FaDesktop />,
        bannerTitleId: 'CategoryPage.3dModeling',
        bannerTextId: 'CategoryPage.3dModelingPageBannerTextId',
        parentCategory: {
          to: 'PhotographyAndVideo',
          labelId: 'CategoryPage.photographyAndVideo',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.3dModelingPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.3dModelingPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.3dModelingPageSeoContentTitleId',
              textId: 'CategoryPage.3dModelingPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'video_production',
        categoryLabelId: 'CategoryPage.videoProduction',
        to: 'VideoProduction',
        icon: <FaFileVideo />,
        bannerTitleId: 'CategoryPage.videoProduction',
        bannerTextId: 'CategoryPage.videoProductionPageBannerTextId',
        parentCategory: {
          to: 'PhotographyAndVideo',
          labelId: 'CategoryPage.photographyAndVideo',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.videoProductionPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.videoProductionPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.videoProductionPageSeoContentTitleId',
              textId: 'CategoryPage.videoProductionPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'other_photography_n_video',
        categoryLabelId: 'CategoryPage.otherContent',
        to: 'OtherPhotographyAndVideo',
        icon: <FaEllipsisH />,
        bannerTitleId: 'CategoryPage.otherContent',
        bannerTextId: 'CategoryPage.otherContent',
        parentCategory: {
          to: 'PhotographyAndVideo',
          labelId: 'CategoryPage.photographyAndVideo',
        },
        showDescription: false,
        seoContent: {
          mainTitleId: '',
          mainTextId: '',
          subSections: [
            {
              id: 1,
              titleId: '',
              textId: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'programming_n_web_development',
    categoryLabelId: 'CategoryPage.programmingAndWebDevelopment',
    bannerTitleId: 'CategoryPage.programmingAndWebDevelopment',
    bannerTextId: 'CategoryPage.programmingAndWebDevelopmentPageBannerTextId',
    parentCategory: null,
    showDescription: true,
    seoContent: {
      mainTitleId: 'CategoryPage.programmingAndWebDevelopmentPageSeoContentMainTitleId',
      mainTextId: 'CategoryPage.programmingAndWebDevelopmentPageSeoContentMainTextId',
      subSections: [
        {
          id: 1,
          titleId: 'CategoryPage.programmingAndWebDevelopmentPageSeoContentTitleId',
          textId: 'CategoryPage.programmingAndWebDevelopmentPageSeoContentTextId',
        },
      ],
    },
    childCategories: [
      {
        id: 'application_development',
        categoryLabelId: 'CategoryPage.applicationDevelopment',
        to: 'ApplicationDevelopment',
        icon: <FaMicrochip />,
        bannerTitleId: 'CategoryPage.applicationDevelopment',
        bannerTextId: 'CategoryPage.applicationDevelopmentPageBannerTextId',
        parentCategory: {
          to: 'ProgrammingAndWebDevelopment',
          labelId: 'CategoryPage.programmingAndWebDevelopment',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.applicationDevelopmentPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.applicationDevelopmentPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.applicationDevelopmentPageSeoContentTitleId',
              textId: 'CategoryPage.applicationDevelopmentPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'front_end_development',
        categoryLabelId: 'CategoryPage.frontEndDevelopment',
        to: 'FrontEndDevelopment',
        icon: <FaLaptopCode />,
        bannerTitleId: 'CategoryPage.frontEndDevelopment',
        bannerTextId: 'CategoryPage.frontEndDevelopmentPageBannerTextId',
        parentCategory: {
          to: 'ProgrammingAndWebDevelopment',
          labelId: 'CategoryPage.programmingAndWebDevelopment',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.frontEndDevelopmentPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.frontEndDevelopmentPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.frontEndDevelopmentPageSeoContentTitleId',
              textId: 'CategoryPage.frontEndDevelopmentPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'back_end_development',
        categoryLabelId: 'CategoryPage.backEndDevelopment',
        to: 'BackEndDevelopment',
        icon: <FaWrench />,
        bannerTitleId: 'CategoryPage.backEndDevelopment',
        bannerTextId: 'CategoryPage.backEndDevelopmentPageBannerTextId',
        parentCategory: {
          to: 'ProgrammingAndWebDevelopment',
          labelId: 'CategoryPage.programmingAndWebDevelopment',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.backEndDevelopmentPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.backEndDevelopmentPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.backEndDevelopmentPageSeoContentTitleId',
              textId: 'CategoryPage.backEndDevelopmentPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'user_testing',
        categoryLabelId: 'CategoryPage.userTesting',
        to: 'UserTesting',
        icon: <FaUserCheck />,
        bannerTitleId: 'CategoryPage.userTesting',
        bannerTextId: 'CategoryPage.userTestingPageBannerTextId',
        parentCategory: {
          to: 'ProgrammingAndWebDevelopment',
          labelId: 'CategoryPage.programmingAndWebDevelopment',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.userTestingPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.userTestingPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.userTestingPageSeoContentTitleId',
              textId: 'CategoryPage.userTestingPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'other_programming_n_web_development',
        categoryLabelId: 'CategoryPage.otherContent',
        to: 'OtherProgrammingAndWebDevelopment',
        icon: <FaEllipsisH />,
        bannerTitleId: 'CategoryPage.otherContent',
        bannerTextId: 'CategoryPage.otherContent',
        parentCategory: {
          to: 'ProgrammingAndWebDevelopment',
          labelId: 'CategoryPage.programmingAndWebDevelopment',
        },
        showDescription: false,
        seoContent: {
          mainTitleId: '',
          mainTextId: '',
          subSections: [
            {
              id: 1,
              titleId: '',
              textId: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'writing_and_translation',
    categoryLabelId: 'CategoryPage.writingAndTranslation',
    bannerTitleId: 'CategoryPage.writingAndTranslation',
    bannerTextId: 'CategoryPage.writingAndTranslationPageBannerTextId',
    parentCategory: null,
    showDescription: true,
    seoContent: {
      mainTitleId: 'CategoryPage.writingAndTranslationPageSeoContentMainTitleId',
      mainTextId: 'CategoryPage.writingAndTranslationPageSeoContentMainTextId',
      subSections: [
        {
          id: 1,
          titleId: 'CategoryPage.writingAndTranslationPageSeoContentTitleId',
          textId: 'CategoryPage.writingAndTranslationPageSeoContentTextId',
        },
      ],
    },
    childCategories: [
      {
        id: 'translations',
        categoryLabelId: 'CategoryPage.translations',
        to: 'Translations',
        icon: <FaBook />,
        bannerTitleId: 'CategoryPage.translations',
        bannerTextId: 'CategoryPage.translationsPageBannerTextId',
        parentCategory: {
          to: 'WritingAndTranslation',
          labelId: 'CategoryPage.writingAndTranslation',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.translationsPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.translationsPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.translationsPageSeoContentTitleId',
              textId: 'CategoryPage.translationsPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'sales_copy',
        categoryLabelId: 'CategoryPage.salesCopy',
        to: 'SalesCopy',
        icon: <FaCopy />,
        bannerTitleId: 'CategoryPage.salesCopy',
        bannerTextId: 'CategoryPage.salesCopyPageBannerTextId',
        parentCategory: {
          to: 'WritingAndTranslation',
          labelId: 'CategoryPage.writingAndTranslation',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.salesCopyPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.salesCopyPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.salesCopyPageSeoContentTitleId',
              textId: 'CategoryPage.salesCopyPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'proofreading',
        categoryLabelId: 'CategoryPage.proofreading',
        to: 'Proofreading',
        icon: <FaBookOpen />,
        bannerTitleId: 'CategoryPage.proofreading',
        bannerTextId: 'CategoryPage.proofreadingPageBannerTextId',
        parentCategory: {
          to: 'WritingAndTranslation',
          labelId: 'CategoryPage.writingAndTranslation',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.proofreadingPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.proofreadingPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.proofreadingPageSeoContentTitleId',
              textId: 'CategoryPage.proofreadingPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'other_writing_and_translation',
        categoryLabelId: 'CategoryPage.otherContent',
        to: 'OtherWritingAndTranslation',
        icon: <FaEllipsisH />,
        bannerTitleId: 'CategoryPage.otherContent',
        bannerTextId: 'CategoryPage.otherContent',
        parentCategory: {
          to: 'WritingAndTranslation',
          labelId: 'CategoryPage.writingAndTranslation',
        },
        showDescription: false,
        seoContent: {
          mainTitleId: '',
          mainTextId: '',
          subSections: [
            {
              id: 1,
              titleId: '',
              textId: '',
            },
          ],
        },
      },
    ],
  },
  {
    id: 'other_categories',
    categoryLabelId: 'CategoryPage.otherCategories',
    bannerTitleId: 'CategoryPage.otherCategories',
    bannerTextId: 'CategoryPage.otherCategoriesPageBannerTextId',
    parentCategory: null,
    showDescription: false,
    seoContent: {
      mainTitleId: 'CategoryPage.otherCategoriesPageSeoContentMainTitleId',
      mainTextId: 'CategoryPage.otherCategoriesPageSeoContentMainTextId',
      subSections: [
        {
          id: 1,
          titleId: '',
          textId: '',
        },
      ],
    },
    childCategories: [
      {
        id: 'ai_n_machine_learning',
        categoryLabelId: 'CategoryPage.aiAndMachineLearning',
        to: 'AIAndMachineLearning',
        icon: <FaRobot />,
        bannerTitleId: 'CategoryPage.aiAndMachineLearning',
        bannerTextId: 'CategoryPage.aiAndMachineLearningPageBannerTextId',
        parentCategory: {
          to: 'OtherCategories',
          labelId: 'CategoryPage.otherCategories',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.aiAndMachineLearningPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.aiAndMachineLearningPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.aiAndMachineLearningPageSeoContentTitleId',
              textId: 'CategoryPage.aiAndMachineLearningPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'virtual_assistant',
        categoryLabelId: 'CategoryPage.virtualAssistant',
        to: 'VirtualAssistant',
        icon: <FaRegCircle />,
        bannerTitleId: 'CategoryPage.virtualAssistant',
        bannerTextId: 'CategoryPage.virtualAssistantPageBannerTextId',
        parentCategory: {
          to: 'OtherCategories',
          labelId: 'CategoryPage.otherCategories',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.virtualAssistantPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.virtualAssistantPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.virtualAssistantPageSeoContentTitleId',
              textId: 'CategoryPage.virtualAssistantPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'influencer_marketing',
        categoryLabelId: 'CategoryPage.influencerMarketing',
        to: 'InfluencerMarketing',
        icon: <FaBullhorn />,
        bannerTitleId: 'CategoryPage.influencerMarketing',
        bannerTextId: 'CategoryPage.influencerMarketingPageBannerTextId',
        parentCategory: {
          to: 'OtherCategories',
          labelId: 'CategoryPage.otherCategories',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.influencerMarketingPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.influencerMarketingPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.influencerMarketingPageSeoContentTitleId',
              textId: 'CategoryPage.influencerMarketingPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'consulting',
        categoryLabelId: 'CategoryPage.consulting',
        to: 'Consulting',
        icon: <FaUserFriends />,
        bannerTitleId: 'CategoryPage.consulting',
        bannerTextId: 'CategoryPage.consultingPageBannerTextId',
        parentCategory: {
          to: 'OtherCategories',
          labelId: 'CategoryPage.otherCategories',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.consultingPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.consultingPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.consultingPageSeoContentTitleId',
              textId: 'CategoryPage.consultingPageSeoContentTextId',
            },
          ],
        },
      },
      {
        id: 'audio',
        categoryLabelId: 'CategoryPage.audio',
        to: 'Audio',
        icon: <FaFileAudio />,
        bannerTitleId: 'CategoryPage.audio',
        bannerTextId: 'CategoryPage.audioPageBannerTextId',
        parentCategory: {
          to: 'OtherCategories',
          labelId: 'CategoryPage.otherCategories',
        },
        showDescription: true,
        seoContent: {
          mainTitleId: 'CategoryPage.audioPageSeoContentMainTitleId',
          mainTextId: 'CategoryPage.audioPageSeoContentMainTextId',
          subSections: [
            {
              id: 1,
              titleId: 'CategoryPage.audioPageSeoContentTitleId',
              textId: 'CategoryPage.audioPageSeoContentTextId',
            },
          ],
        },
      },
    ],
  },
];
