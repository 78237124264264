import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { maxLength, required, composeValidators } from '../../util/validators';
import { InfoIcon, Form, Button, FieldTextInput, UnsavedInfoPrompt } from '../../components';

import css from './SignUpProjectForms.css';

const TITLE_MAX_LENGTH = 60;

const SignUpProjectNameFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          dirty,
          onPrev,
          infoListFirst,
          infoListSecond,
          previousActionMsg,
          infoContainer,
          projectCategory,
          titlePlaceholder,
        } = formRenderProps;

        const titleMessage = intl.formatMessage({ id: 'EditProjectListingDescriptionForm.title' });

        const infoListHeader = intl.formatMessage({
          id: 'EditProjectListingNameForm.infoListHeader',
        });

        const titleRequiredMessage = intl.formatMessage({
          id: 'EditProjectListingDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditProjectListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            {typeof window === 'object' ? <ReactTooltip /> : null}
            <div>
              <div className={css.titleBlock}>
                <label htmlFor="title">{titleMessage}</label>
                <InfoIcon
                  className={css.infoIcon}
                  data-tip={intl.formatMessage({
                    id: 'EditProjectListingDescriptionForm.titleTooltip',
                  })}
                  data-border={true}
                  data-text-color={'black'}
                  data-background-color={'white'}
                  data-border-color={'darkgrey'}
                  data-class={css.tooltipText}
                  data-place={'bottom'}
                />
              </div>
              <FieldTextInput
                id="title"
                name="title"
                className={css.title}
                type="text"
                placeholder={titlePlaceholder}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              />
              <div className={css.infoBlock}>
                <div className={css.infoList}>
                  <ul>
                    <div className={css.infoListHeader}>{infoListHeader}</div>
                    <li className={css.infoListItem}>{infoListFirst}</li>
                    <li className={css.infoListItem}>{infoListSecond}</li>
                  </ul>
                </div>
              </div>
              {infoContainer}
            </div>

            <div className={css.buttonContainer}>
              {!projectCategory ? (
                <Button className={css.previousButton} onClick={onPrev}>
                  {previousActionMsg}
                </Button>
              ) : null}

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>

            <UnsavedInfoPrompt when={dirty} />
          </Form>
        );
      }}
    />
  );
};

export default compose(injectIntl)(SignUpProjectNameFormComponent);
