import React from 'react';
import renderHTML from 'react-render-html';
import { FormattedMessage } from '../../util/reactIntl';
import { Avatar, NamedLink } from '../../components';
import css from './ApplicationPanel.css';

const SectionProfileCard = ({ user }) => {
  const bio = user?.attributes?.profile?.publicData?.bio || user?.attributes?.profile?.bio;
  const displayName = user?.attributes?.profile?.displayName;
  const hasBio = !!bio;
  return (
    <div className={css.profileCard}>
      <div className={css.profileCardHeader}>
        <Avatar user={user} />
        <NamedLink className={css.link} name="ProfilePage" params={{ id: user.id.uuid }}>
          <p>{displayName}</p>
        </NamedLink>
      </div>
      {hasBio && <div className={css.profileCardBody}>{renderHTML(bio)}</div>}
      <div className={css.profileCardFooter}>
        <NamedLink className={css.link} name="ProfilePage" params={{ id: user.id.uuid }}>
          <p>
            <FormattedMessage id="ApplicationPanel.viewProfile" />
          </p>
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionProfileCard;
