import React from 'react';
import { Button } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './LinkedInAuthButton.css';

const rootUrl = process.env.REACT_APP_CANONICAL_ROOT_URL;

const LinkedInAuthButton = props => {
  const { redirectUri, modal } = props;
  const handleClick = event => {
    event.preventDefault();
    // Generate a unique state parameter
    const state = Math.random()
      .toString(36)
      .substring(2, 15);

    // Save the state to sessionStorage for later verification
    sessionStorage.setItem('linkedin_oauth_state', state);

    if (modal) {
      sessionStorage.setItem('profileSettingsModal', modal);
    }

    const fullRedirectUri = rootUrl + '/' + redirectUri;

    // Construct the LinkedIn authorization URL
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77t6d51sn46pw9&redirect_uri=${fullRedirectUri}&state=${state}&scope=r_dma_portability_3rd_party`;

    // Redirect the user to the LinkedIn authorization URL
    window.location.href = authUrl;
  };

  const buttonText = <FormattedMessage id="LinkedInAuthButton.connectWithLinkedIn" />;

  return (
    <Button rootClassName={css.button} onClick={handleClick}>
      {buttonText}
    </Button>
  );
};

export default LinkedInAuthButton;
