import React from 'react';
import css from './TalentCard.css';
import { IoStar } from 'react-icons/io5';
import { VerifiedUserMark } from '../../components';

const TalentCardStars = () => {
  return (
    <div className={css.talentCardStars}>
      <IoStar className={css.talentCardStar} />
      <IoStar className={css.talentCardStar} />
      <IoStar className={css.talentCardStar} />
      <IoStar className={css.talentCardStar} />
      <IoStar className={css.talentCardStar} />
    </div>
  );
};

const TalentCard = ({ talent }) => {
  return (
    <div className={css.talentCardWrapper}>
      <div className={css.talentCard}>
        <div className={css.talentCardAvatar}>
          <img src={talent.imageUrl} alt={talent.alt} />
        </div>
        <div className={css.talentCardName}>
          {talent.name}
          <VerifiedUserMark heroSection={true} />
        </div>
        <div className={css.talentCardCategory}>{talent.category}</div>
        <TalentCardStars />
      </div>
      <div className={css.talentCardBehinds} />
    </div>
  );
};

export default TalentCard;
