import React from 'react';
import classNames from 'classnames';

import { Button } from '../../components';

import { injectIntl } from '../../util/reactIntl';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import css from './SignupProviderForms.css';

const SignupProviderInfoStep = props => {
  const { onSubmit, intl, rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const heading = intl.formatMessage({ id: 'SignupProviderForms.infoHeading' });
  const description = intl.formatMessage({ id: 'SignupProviderForms.infoDescription' });
  const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.startButton' });

  const infoListItem1 = intl.formatMessage({ id: 'SignupProviderForms.infoListItem1' });
  const infoListItem2 = intl.formatMessage({ id: 'SignupProviderForms.infoListItem2' });
  const infoListItem3 = intl.formatMessage({ id: 'SignupProviderForms.infoListItem3' });

  return (
    <div className={classes}>
      <h2 className={css.mainHeading}>{heading}</h2>
      <div className={css.form}>
        <p className={css.description}>{description}</p>

        <div className={css.infoList}>
          <div className={css.infoListItem}>
            <BsFillCheckCircleFill className={css.infoListIcon} />
            {infoListItem1}
          </div>
          <div className={css.infoListItem}>
            <BsFillCheckCircleFill className={css.infoListIcon} />
            {infoListItem2}
          </div>
          <div className={css.infoListItem}>
            <BsFillCheckCircleFill className={css.infoListIcon} />
            {infoListItem3}
          </div>
        </div>

        <div className={css.submitContainerNoHeading}>
          <Button rootClassName={css.nextButton} onClick={onSubmit}>
            {nextButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(SignupProviderInfoStep);
