import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { NamedLink } from '../../components';
import css from './SectionHero.css';

const SectionHero = withRouter(props => {
  const {
    rootClassName,
    className,
    heroTitle1,
    heroTitle2,
    heroSubtitle,
    atLandingPage,
    noButton,
    atProLandingPage,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroLeftBlock}>
          {atProLandingPage ? (
            <h1 className={css.proLandingPageTitle}>
              {heroTitle1}
              {heroTitle2}
            </h1>
          ) : (
            <h1 className={atLandingPage ? css.heroMainTitle : css.otherMainTitle}>
              {heroTitle1}
              <br />
              {heroTitle2}
            </h1>
          )}

          <h2 className={atProLandingPage ? css.proLandingPageSubTitle : css.heroSubTitle}>
            {heroSubtitle}
          </h2>

          {!noButton ? (
            <div className={css.heroButtonContainer}>
              <NamedLink className={css.heroButton} name="SignupPage">
                <FormattedMessage id="SectionHero.heroButton" />
              </NamedLink>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
});

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
