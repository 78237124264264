import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedIn.css';

const IconSocialMediaLinkedIn = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      // style="enable-background:new 0 0 50 50;"
      // xml:space="preserve"
      xmlSpace="preserve"
    >
      <g className={css.g}>
        <path
          className={css.outer}
          d="M42.9,2.7H7.1c-2.5,0-4.5,2-4.5,4.5v35.7c0,2.5,2,4.5,4.5,4.5h35.7c2.5,0,4.5-2,4.5-4.5V7.1C47.3,4.7,45.3,2.7,42.9,2.7z"
        />

        <path
          className={css.inner}
          d="M12.7,9.8c-2.2,0-4.1,1.8-4.1,4.1s1.9,4.1,4.1,4.1c2.2,0,4.1-1.8,4.1-4.1S14.9,9.8,12.7,9.8z M9.4,40.6h6.7V20.5H9.4V40.6z M32.9,20.3c-2.7,0-4.8,1.2-5.8,2.8v-2.5h-6.6v20.1h6.7v-8.4c0-3.1,1.1-5,3.5-5c1.8,0,3.2,1.2,3.2,4.4v9h6.7V28.5C40.6,22.7,38,20.3,32.9,20.3z"
        />
      </g>
    </svg>
  );
};

IconSocialMediaLinkedIn.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSocialMediaLinkedIn.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedIn;
