import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProfileSettingsContainer as Container } from '../../components';
import css from './Container.css';

const EducationContainer = ({ profileUser, button }) => {
  const education = profileUser?.attributes?.profile?.publicData?.education;

  const container = (
    <Container heading={<FormattedMessage id="ProfilePage.education" />} button={button}>
      {education &&
        education[0]?.when !== null &&
        education.map(e => (
          <div key={e.degree} className={css.container}>
            <div className={css.heading}>{e.where}</div>
            <div className={css.text}>
              {e.degree}, {e.industry}
            </div>
            <div className={css.text}>
              <FormattedMessage id="ProfilePage.educationGraduation" values={{ year: e.when }} />
            </div>
          </div>
        ))}
    </Container>
  );

  if (!button) {
    if (!education?.length || (education?.length === 0 && Object.keys(education[0]).length !== 0))
      return null;
  }
  return container;
};

export default EducationContainer;
