import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import useInitialValues from '../../components/UseInitialValuesHook/useInitialValues';
import { LinkedInAuthButton } from '../../components';
import { getLinkedInToken } from '../../util/api';

import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  Modal,
  InlineTextButton,
  TopSkillsContainer,
  HobbiesContainer,
  LanguagesContainer,
  WorkExperienceContainer,
  WorkedWithContainer,
  EducationContainer,
  NameLocationWorkingMethodContainer,
  BioWhoAmIContainer,
  LinksContainer,
  HourlyFeeIdealCustomerContainer,
  CodeOfConductContainer,
  IconSpinner,
} from '../../components';
import {
  ProfileSettingsForm,
  SignupProviderEducationForm,
  SignupProviderLocationNLanguageForm,
  SignupProviderSkillsForm,
  SignupProviderWorkExperienceForm,
  SignupProviderWorkedWithForm,
  SignupProviderLinksForm,
  SignupProviderCOC,
  ProfileSettingsNameLocationWorkingMethodForm,
  ProfileSettingsBioWhoAmIForm,
  ProfileSettingsHourlyFeeIdealCustomerForm,
  ProfileSettingsHobbiesForm,
} from '../../forms';
import { TopbarContainer } from '../../containers';
import { updateProfile, uploadImage, setInitialState } from './ProfileSettingsPage.duck';
import css from './ProfileSettingsPage.css';
import { FaPenAlt } from 'react-icons/fa';
import { withViewport } from '../../util/contextHelpers';
import { use } from 'passport';

const categoriesOptions = config.custom.categoriesOptions;
const skillOptions = config.custom.skillOptions;
const skillLevelOptions = config.custom.skillLevelOptions;
const languageOptions = config.custom.filters[3].config.options;
const languageLevelOptions = config.custom.languageLevelOptions;
const hobbyOptions = config.custom.hobbyOptions;

const onImageUploadHandler = (values, fn, setImageToBeDeleted) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
  setImageToBeDeleted(false);
};

const extractMonthAndYear = input => {
  // Define a regular expression to match the month and year
  const regex = /(\w+) (\d{4})/;
  const match = input.match(regex);

  if (!match) {
    return { month: null, year: null };
  }

  // Extract the month name and year from the match
  const monthName = match[1];
  const year = parseInt(match[2], 10);

  // Map month names to month numbers
  const monthMap = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  // Get the month number
  const month = monthMap[monthName];

  if (!month) {
    return { month: null, year: null };
  }

  // Return the month and year as numbers
  return { month, year };
};

const ProfileSettingsPageComponent = props => {
  const {
    currentUser,
    currentUserFetchProgress,
    currentUserFetchSuccess,
    image,
    onImageUpload,
    onUpdateProfile,
    onManageDisableScrolling,
    scrollingDisabled,
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
    intl,
    onSetInitialState,
    viewport,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const { firstName, lastName, bio, protectedData, publicData } = user.attributes.profile;
  const [initialValSet, updateInitialValSet] = useState(false);
  const [imageToBeDeleted, setImageToBeDeleted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [dataFetchInProgress, setDataFetchInProgress] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [error, setError] = useState(null);
  const [linkedInData, setLinkedInData] = useState(null);
  const [linkedInModal, setLinkedInModal] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      const redirect_uri = encodeURIComponent('profile-settings');

      const originalState = sessionStorage.getItem('linkedin_oauth_state');
      sessionStorage.removeItem('linkedin_oauth_state');

      if (state !== originalState) {
        console.error('State mismatch! Potential CSRF attack.');
        return;
      }

      if (code) {
        setDataFetchInProgress(true);
        getLinkedInToken(code, redirect_uri)
          .then(data => {
            if (data.error) {
              console.error('Error exchanging code:', data.error);
              throw new Error('LinkedIn token exchange failed.');
            }

            sessionStorage.setItem('linkedin_data', JSON.stringify(data.linkedInData));
            const linkedInData = data.linkedInData;

            const linkedInEducation = linkedInData
              .filter(item => item.snapshotDomain === 'EDUCATION')
              .map(item => item.snapshotData);

            const linkedInExperience = linkedInData
              .filter(item => item.snapshotDomain === 'POSITIONS')
              .map(item => item.snapshotData);

            const education = linkedInEducation[0].map(item => ({
              where: item['School Name'],
              degree: item['Degree Name'],
              when: {
                key: parseInt(item['End Date'], 10),
                value: parseInt(item['End Date'], 10),
                label: parseInt(item['End Date'], 10),
              },
              industry: '',
            }));

            const workExperience = linkedInExperience[0].map(item => ({
              task: item['Title'],
              customerName: item['Company Name'],
              startMonth: extractMonthAndYear(item['Started On']).month
                ? {
                    key: extractMonthAndYear(item['Started On']).month,
                    value: extractMonthAndYear(item['Started On']).month,
                    label: extractMonthAndYear(item['Started On']).month,
                  }
                : null,
              startYear: extractMonthAndYear(item['Started On']).year
                ? {
                    key: extractMonthAndYear(item['Started On']).year,
                    value: extractMonthAndYear(item['Started On']).year,
                    label: extractMonthAndYear(item['Started On']).year,
                  }
                : null,
              endMonth: item['Finished On']
                ? {
                    key: extractMonthAndYear(item['Finished On']).month,
                    value: extractMonthAndYear(item['Finished On']).month,
                    label: extractMonthAndYear(item['Finished On']).month,
                  }
                : null,
              endYear: item['Finished On']
                ? {
                    key: extractMonthAndYear(item['Finished On']).year,
                    value: extractMonthAndYear(item['Finished On']).year,
                    label: extractMonthAndYear(item['Finished On']).year,
                  }
                : null,
              jobContinues: !item['Finished On'],
              description: item['Description'],
            }));

            return { education, workExperience };
          })
          .then(({ education, workExperience }) => {
            setLinkedInData({ education, workExperience });
            setDataFetched(true);
            setLinkedInModal(sessionStorage.getItem('profileSettingsModal'));
            sessionStorage.removeItem('profileSettingsModal');
            sessionStorage.removeItem('linkedin_data');
          })
          .catch(error => {
            setError(error);
            setDataFetchInProgress(false);
            console.error('Error exchanging code:', error);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (linkedInModal && linkedInData) {
      const content =
        linkedInModal === 'workExperience'
          ? workExperienceModalContent
          : linkedInModal === 'education'
          ? educationModalContent
          : null;

      if (content) {
        setDataFetchInProgress(false);
        setModalContent(content);
        handleOpenModal();
      }
    }
  }, [linkedInModal, linkedInData]);

  const profileImageId = user.profileImage ? user.profileImage.id : null;
  const profileImage = image || { imageId: profileImageId };
  let oldInitialValues = {};
  oldInitialValues = {
    ...oldInitialValues,
    firstName: firstName || '',
    lastName: lastName || '',
    bio: bio || '',
    vat_number: protectedData?.vat_number || '',
    phoneNumber: protectedData?.phoneNumber || '',
    website: protectedData?.website || '',
    linkedIn: protectedData?.linkedIn || '',
    companyName: publicData?.companyName || '',
    industry: protectedData?.industry || '',
    address: publicData?.address || '',
    zipCode: publicData?.zipCode || '',
    city: publicData?.city || '',
    country: publicData?.country || '',
    categories: publicData?.categories?.length
      ? publicData.categories
          .map(each => {
            return typeof each === 'string' ? categoriesOptions.find(e => e.key === each) : each;
          })
          // Legacy categories causes element to be undefined so filter those out
          .filter(each => each !== undefined)
      : [],
    profileImage: user.profileImage,
    experience: publicData?.experience?.length
      ? publicData.experience.map(each => ({
          category: skillOptions.find(e => e.key === each.category),
          level: each.level
            ? {
                key: each.level,
                value: each.level,
                label: intl.formatMessage({
                  id: skillLevelOptions.find(e => e.key === each.level)?.label,
                }),
              }
            : '',
          isMainSkill: each.isMainSkill,
        }))
      : [{}],
    hobbies: publicData?.hobbies?.length
      ? publicData.hobbies.map(each => ({
          category: hobbyOptions.find(e => e.key === each.category),
        }))
      : [{}],
    workExperience: publicData?.workExperience?.length
      ? publicData?.workExperience?.map(e => ({
          ...e,
          startYear: { key: e?.startYear, label: e?.startYear, value: e?.startYear },
          startMonth: { key: e?.startMonth, label: e?.startMonth, value: e?.startMonth },
          endYear: { key: e?.endYear, label: e?.endYear, value: e?.endYear },
          endMonth: { key: e?.endMonth, label: e?.endMonth, value: e?.endMonth },
        }))
      : [{}],
    workedWith: publicData?.workedWith?.length
      ? publicData?.workedWith?.map(e => ({
          ...e,
          startYear: { key: e?.startYear, label: e?.startYear, value: e?.startYear },
          startMonth: { key: e?.startMonth, label: e?.startMonth, value: e?.startMonth },
          endYear: { key: e?.endYear, label: e?.endYear, value: e?.endYear },
          endMonth: { key: e?.endMonth, label: e?.endMonth, value: e?.endMonth },
        }))
      : [{}],
    education: publicData?.education?.length
      ? publicData?.education?.map(e => ({
          ...e,
          when: { key: e?.when, label: e?.when, value: e?.when },
        }))
      : [{}],
    languages: publicData?.languages?.length
      ? publicData?.languages?.map(each => ({
          language: languageOptions.find(e => e.key === each.language),
          level: each.level
            ? {
                key: each?.level,
                value: each?.level,
                label:
                  each.level &&
                  intl.formatMessage({
                    id: languageLevelOptions.find(e => e.key === each.level)?.label,
                  }),
              }
            : null,
        }))
      : [{}],
    hourlyFee: publicData?.hourlyFee,
    dontShowHourlyFee: publicData?.dontShowHourlyFee,
    idealCustomer: publicData?.idealCustomer,
    whoAmI: publicData?.whoAmI,
    workingMethod: publicData?.workingMethod,
    location: publicData?.location,
    cocAccepted: publicData?.cocAccepted,
  };

  const { initialValues, updateInitialValues } = useInitialValues(oldInitialValues);
  if (currentUserFetchSuccess && !currentUserFetchProgress && !initialValSet) {
    updateInitialValSet(true);
    updateInitialValues(oldInitialValues);
  }
  const handleSubmit = values => {
    const {
      firstName,
      lastName,
      bio: rawBio,
      vat_number,
      phoneNumber,
      industry,
      companyName,
      address,
      zipCode,
      city,
      country,
      categories,
      experience,
      hobbies,
      workExperience,
      dontAddWorkExperience,
      workedWith,
      dontAddWorkedWith,
      education,
      dontAddEducation,
      languages,
      hourlyFee,
      dontShowHourlyFee,
      idealCustomer,
      whoAmI,
      workingMethod,
      location,
      website,
      linkedIn,
      cocAccepted,
    } = values;

    // Ensure that the optional bio is a string
    const bio = rawBio || '';
    updateInitialValues({
      firstName,
      lastName,
      bio: rawBio,
      vat_number,
      phoneNumber,
      industry,
      companyName,
      address,
      zipCode,
      city,
      country,
      categories,
      experience,
      hobbies,
      workExperience: !dontAddWorkExperience ? workExperience : [],
      workedWith: !dontAddWorkedWith ? workedWith : [],
      education: !dontAddEducation ? education : [],
      languages,
      hourlyFee,
      dontShowHourlyFee,
      idealCustomer,
      whoAmI,
      workingMethod,
      location,
      website,
      linkedIn,
    });

    const profile = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      bio,
      protectedData: { vat_number, phoneNumber, industry, website, linkedIn },
      publicData: {
        categories: categories?.map(e => e.key) || [],
        companyName,
        address,
        zipCode,
        city,
        country,
        bio,
        experience:
          experience?.map(e => ({
            category: e?.category?.key,
            level: e?.level?.key,
            isMainSkill: e?.isMainSkill ? e?.isMainSkill : false,
          })) || [],
        hobbies:
          hobbies?.map(e => ({
            category: e?.category?.key,
          })) || [],
        workExperience:
          Object.keys(workExperience[0]).length !== 0 && !dontAddWorkExperience
            ? workExperience.map(e => {
                return {
                  ...e,
                  startYear: e.startYear?.key,
                  endYear: e.endYear?.key,
                  startMonth: e.startMonth?.key,
                  endMonth: e.endMonth?.key,
                };
              })
            : [],
        workedWith:
          Object.keys(workedWith[0]).length !== 0 && !dontAddWorkedWith
            ? workedWith?.map(e => {
                return {
                  ...e,
                  startYear: e?.startYear?.key,
                  endYear: e?.endYear?.key,
                  startMonth: e?.startMonth?.key,
                  endMonth: e?.endMonth?.key,
                };
              })
            : [],
        education:
          Object.keys(education[0]).length !== 0 && !dontAddEducation
            ? education?.map(e => {
                return { ...e, when: e?.when?.key };
              })
            : [],
        languages:
          Object.keys(languages[0]).length !== 0
            ? languages?.map(l => ({
                language: l?.language?.key,
                level: l?.level?.key,
              }))
            : [],
        hourlyFee: hourlyFee !== undefined ? hourlyFee : [],
        dontShowHourlyFee: dontShowHourlyFee !== undefined ? dontShowHourlyFee : [],
        idealCustomer: idealCustomer !== undefined ? idealCustomer : [],
        whoAmI: whoAmI !== undefined ? whoAmI : '',
        workingMethod: workingMethod !== undefined ? workingMethod : '',
        location: location !== undefined ? location : '',
        cocAccepted: cocAccepted !== undefined ? cocAccepted : false,
      },
    };
    const uploadedImage = props.image;

    // Update profileImage only if file system has been accessed or image is set to be deleted
    const updatedValues =
      (uploadedImage && uploadedImage.imageId && uploadedImage.file) || imageToBeDeleted
        ? {
            ...profile,
            profileImageId: imageToBeDeleted ? null : uploadedImage.imageId,
          }
        : profile;

    onUpdateProfile(updatedValues);
    setImageToBeDeleted(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setLinkedInData(null);
    setLinkedInModal(null);
    setIsModalOpen(false);
  };

  const handleButtonClick = content => () => {
    setModalContent(content);
    handleOpenModal();
  };

  const handleSubmitClick = values => {
    handleSubmit(values);
    handleCloseModal();
  };

  const modal = (
    <Modal
      id="ProfileSettingsPage"
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal={true}
      moreBasis={true}
    >
      <h3>{modalContent}</h3>
    </Modal>
  );

  const isValidCustomer =
    currentUser?.attributes?.profile?.publicData?.account_role === 'customer' &&
    !currentUser?.attributes?.banned &&
    !currentUser?.attributes?.deleted;

  const isValidProvider =
    (currentUser?.attributes?.profile?.publicData?.account_role === 'freelancer' &&
      !currentUser?.attributes?.banned &&
      !currentUser?.attributes?.deleted) ||
    (currentUser?.attributes?.profile?.publicData?.account_role === 'light-entrepreneur' &&
      !currentUser?.attributes?.banned &&
      !currentUser?.attributes?.deleted);

  const showContainers = !isValidCustomer && isValidProvider;

  const nameLocationWorkingMethodModalContent = (
    <ProfileSettingsNameLocationWorkingMethodForm
      initialValues={initialValues}
      onSubmit={handleSubmitClick}
      isModal={true}
      user={user}
      isValidCustomer={isValidCustomer}
      isValidProvider={isValidProvider}
    />
  );

  const bioWhoAmImodalContent = (
    <ProfileSettingsBioWhoAmIForm
      initialValues={initialValues}
      onSubmit={handleSubmitClick}
      isModal={true}
      isValidProvider={isValidProvider}
    />
  );

  const linksModalContent = (
    <SignupProviderLinksForm
      initialValues={initialValues}
      onSubmit={handleSubmitClick}
      isModal={true}
    />
  );

  const codeOfConductModalContent = (
    <SignupProviderCOC initialValues={initialValues} onSubmit={handleSubmitClick} isModal={true} />
  );

  const hourlyFeeIdealCustomerModalContent = (
    <ProfileSettingsHourlyFeeIdealCustomerForm
      initialValues={initialValues}
      onSubmit={handleSubmitClick}
      isModal={true}
    />
  );

  const skillsModalContent = (
    <SignupProviderSkillsForm
      initialValues={initialValues}
      onSubmit={handleSubmitClick}
      isModal={true}
    />
  );

  const hobbiesModalContent = (
    <ProfileSettingsHobbiesForm
      initialValues={initialValues}
      onSubmit={handleSubmitClick}
      isModal={true}
    />
  );

  const languagesModalContent = (
    <SignupProviderLocationNLanguageForm
      initialValues={initialValues}
      onSubmit={handleSubmitClick}
      isModal={true}
    />
  );

  const workExperienceModalContent = (
    <SignupProviderWorkExperienceForm
      initialValues={
        linkedInData
          ? {
              ...initialValues,
              workExperience: [...linkedInData.workExperience, ...initialValues.workExperience],
            }
          : initialValues
      }
      onSubmit={handleSubmitClick}
      isModal={true}
    />
  );

  const workedWithModalContent = (
    <SignupProviderWorkedWithForm
      initialValues={initialValues}
      onSubmit={handleSubmitClick}
      isModal={true}
    />
  );

  const educationModalContent = (
    <SignupProviderEducationForm
      initialValues={
        linkedInData
          ? {
              ...initialValues,
              education: [...linkedInData.education, ...initialValues.education],
            }
          : initialValues
      }
      onSubmit={handleSubmitClick}
      isModal={true}
    />
  );

  const editButton = content => (
    <InlineTextButton type="button" onClick={handleButtonClick(content)}>
      + <FaPenAlt />
    </InlineTextButton>
  );

  const profileUser = ensureUser(user);

  const nameLocationWorkingMethod = (
    <NameLocationWorkingMethodContainer
      profileUser={profileUser}
      button={editButton(nameLocationWorkingMethodModalContent)}
      isValidCustomer={isValidCustomer}
      isValidProvider={isValidProvider}
    />
  );

  const bioWhoAmI = (
    <BioWhoAmIContainer
      profileUser={profileUser}
      button={editButton(bioWhoAmImodalContent)}
      isValidProvider={isValidProvider}
    />
  );

  const links = <LinksContainer profileUser={profileUser} button={editButton(linksModalContent)} />;

  const codeOfConduct = (
    <CodeOfConductContainer
      profileUser={profileUser}
      button={editButton(codeOfConductModalContent)}
      isValidProvider={isValidProvider}
    />
  );

  const hourlyFeeIdealCustomer = (
    <HourlyFeeIdealCustomerContainer
      profileUser={profileUser}
      button={editButton(hourlyFeeIdealCustomerModalContent)}
    />
  );

  const topSkills = (
    <TopSkillsContainer profileUser={profileUser} button={editButton(skillsModalContent)} />
  );

  const hobbies = (
    <HobbiesContainer profileUser={profileUser} button={editButton(hobbiesModalContent)} />
  );

  const languageSkills = (
    <LanguagesContainer profileUser={profileUser} button={editButton(languagesModalContent)} />
  );

  const workExperienceContainer = (
    <WorkExperienceContainer
      profileUser={profileUser}
      button={editButton(workExperienceModalContent)}
      isModal={true}
    />
  );

  const workedWithContainer = (
    <WorkedWithContainer profileUser={profileUser} button={editButton(workedWithModalContent)} />
  );

  const educationContainer = (
    <EducationContainer profileUser={profileUser} button={editButton(educationModalContent)} />
  );

  const profileSettingsForm = user.id ? (
    <ProfileSettingsForm
      className={css.form}
      currentUser={currentUser}
      categoriesOptions={categoriesOptions}
      initialValues={initialValues}
      profileImage={imageToBeDeleted ? { imageId: null } : profileImage}
      onImageUpload={e => onImageUploadHandler(e, onImageUpload, setImageToBeDeleted)}
      onManageDisableScrolling={onManageDisableScrolling}
      uploadInProgress={uploadInProgress}
      updateInProgress={updateInProgress}
      uploadImageError={uploadImageError}
      updateProfileError={updateProfileError}
      onSubmit={handleSubmit}
      setImageToBeDeleted={setImageToBeDeleted}
      languageOptions={languageOptions}
      nameLocationWorkingMethod={nameLocationWorkingMethod}
      bioWhoAmI={bioWhoAmI}
      links={links}
      codeOfConduct={codeOfConduct}
      hourlyFeeIdealCustomer={hourlyFeeIdealCustomer}
      topSkills={topSkills}
      hobbies={hobbies}
      languages={languageSkills}
      workExperience={workExperienceContainer}
      workedWith={workedWithContainer}
      education={educationContainer}
      viewport={viewport}
      showContainers={showContainers}
      isValidCustomer={isValidCustomer}
      isValidProvider={isValidProvider}
    />
  ) : null;

  const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

  useEffect(() => {
    onSetInitialState();
  }, [onSetInitialState]);

  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.mainContent}>
          <div className={css.content}>
            <div className={css.headingContainer}>
              <h1 className={css.heading}>
                <FormattedMessage id="ProfileSettingsPage.heading" />
              </h1>
              {/* <LinkedInAuthButton redirectUri={'profile-settings'} /> */}
              {user.id ? (
                <NamedLink
                  className={css.profileLink}
                  name="ProfilePage"
                  params={{ id: user.id.uuid }}
                >
                  <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                </NamedLink>
              ) : null}
            </div>
            {profileSettingsForm}
            {modal}
          </div>
          {dataFetchInProgress ?? (
            <div className={css.loadingSpinner}>
              <IconSpinner />
            </div>
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

const { bool, func, object, shape, string } = PropTypes;

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserFetchProgress, currentUserFetchSuccess } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    currentUserFetchProgress,
    currentUserFetchSuccess,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onSetInitialState: () => dispatch(setInitialState()),
});

const ProfileSettingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ProfileSettingsPageComponent);

export default ProfileSettingsPage;
