import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FaCheckCircle } from 'react-icons/fa';

import css from './NewJobInfoPanel.css';

const NewJobInfoPanel = () => {
  const infoTitle = <FormattedMessage id="SignUpProjectCategoryForm.formTitle" />;
  const infoListItem1 = <FormattedMessage id="SignUpProjectCategoryForm.formListItem1" />;
  const infoListItem2 = <FormattedMessage id="SignUpProjectCategoryForm.formListItem2" />;
  const infoListItem3 = <FormattedMessage id="SignUpProjectCategoryForm.formListItem3" />;

  return (
    <div className={css.infoContainerDesktop}>
      <div className={css.infoContent}>
        <h1 className={css.infoTitle}>{infoTitle}</h1>
        <ul className={css.infoList}>
          <li className={css.infoListItem}>
            <FaCheckCircle className={css.infoIcon} />
            {infoListItem1}
          </li>
          <li className={css.infoListItem}>
            <FaCheckCircle className={css.infoIcon} />
            {infoListItem2}
          </li>
          <li className={css.infoListItem}>
            <FaCheckCircle className={css.infoIcon} />
            {infoListItem3}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NewJobInfoPanel;
