import React from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { propTypes } from '../../util/types';
import { sendVerificationEmail, hasCurrentUserErrors } from '../../ducks/user.duck';
import { logout, authenticationInProgress } from '../../ducks/Auth.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Topbar,
  NamedRedirect,
  ParentCategoryTopbar,
  TopbarProjectCategoryPage,
} from '../../components';
import config from '../../config';

export const TopbarContainerComponent = props => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationTxs,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    applicationCount,
    onLogout,
    onManageDisableScrolling,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    notifications,
    applications,
    filterConfig,
    projectCategoryTopBar,
    authenticationPageTopBar,
    handleStartProjectClick,
    dontShowprojectCategoryTopBarButton,
    ...rest
  } = props;

  if (typeof window === 'object' && location && location.hash === '#logged_in_with_facebook') {
    window.dataLayer.push({
      event: 'login',
      params: {
        method: 'facebook',
      },
    });

    return <NamedRedirect name="LandingPage" />;
  }

  const pagesShowingCategoryNav = [
    'ParentCategoryPage',
    'ChildCategoryPage',
    'SearchPage',
    'CategoriesPage',
  ];

  const showCategoryBar = pagesShowingCategoryNav.includes(currentPage);

  if (!projectCategoryTopBar && !authenticationPageTopBar) {
    return (
      <div>
        <Topbar
          authInProgress={authInProgress}
          currentPage={currentPage}
          currentSearchParams={currentSearchParams}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          currentUserNotificationTxs={currentUserNotificationTxs}
          history={history}
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          location={location}
          notificationCount={notificationCount}
          applicationCount={applicationCount}
          onLogout={onLogout}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
          showGenericError={hasGenericError}
          notifications={notifications}
          applications={applications}
          showCategoryBar={showCategoryBar}
          {...rest}
        />
        {showCategoryBar && <ParentCategoryTopbar filterConfig={filterConfig} />}
      </div>
    );
  }
  if (projectCategoryTopBar || authenticationPageTopBar) {
    return (
      <TopbarProjectCategoryPage
        handleStartProjectClick={handleStartProjectClick}
        dontShowprojectCategoryTopBarButton={dontShowprojectCategoryTopBarButton}
        authenticationPageTopBar={authenticationPageTopBar}
      />
    );
  }
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  currentUser: null,
  currentUserHasOrders: null,
  currentUserNotificationTxs: [],
  notificationCount: 0,
  currentUserApplicationTxs: [],
  applicationCount: 0,
  sendVerificationEmailError: null,
  authScopes: null,
  notifications: [],
  applications: [],
  filterConfig: config.custom.filters,
};

TopbarContainerComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentPage: string,
  currentSearchParams: object,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentUserNotificationTxs: array,
  currentUserApplicationTxs: array,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  notificationCount: number,
  applicationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  hasGenericError: bool.isRequired,
  notifications: array,
  applications: array,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({ state: object }).isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, logoutError, authScopes } = state.Auth;
  // Topbar needs user info.
  const {
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationCount: notificationCount,
    currentUserNotificationTxRefs,
    currentUserNotificationTx: notifications,
    currentUserApplicationCount: applicationCount,
    currentUserApplicationTxRefs,
    currentUserApplicationTx: applications,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  } = state.user;
  const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));

  if (typeof window === 'object') {
    window.dataLayer.push({ user_login: '' + !!isAuthenticated });
  }

  const currentUserNotificationTxs = getMarketplaceEntities(state, currentUserNotificationTxRefs);

  const currentUserApplicationTxs = getMarketplaceEntities(state, currentUserApplicationTxRefs);

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentUserNotificationTxs,
    currentUserApplicationTxs,
    notificationCount,
    applicationCount,
    isAuthenticated,
    authScopes,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    hasGenericError,
    notifications,
    applications,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TopbarContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TopbarContainerComponent);

export default TopbarContainer;
