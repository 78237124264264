import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, InfoIcon } from '../../components';

import css from './PayoutInfoMissingForm.css';

const PayoutInfoMissingFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          submitButtonWrapperClassName,
          handleSubmit,
          intl,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            { typeof window === 'object' ? <ReactTooltip /> : null }
            <h2 className={css.heading}>
              <FormattedMessage id="PayoutInfoMissingForm.heading" />
              <InfoIcon
                className={css.infoIcon}
                data-tip={intl.formatMessage({ id: 'PayoutInfoMissingForm.hintText'})}
                data-border={true}
                data-text-color={'black'}
                data-background-color={'white'}
                data-border-color={'darkgrey'}
                data-class={css.tooltipText}
                data-place={'bottom'}
              />
            </h2>
            <div className={css.mainText}>
              <FormattedMessage id="PayoutInfoMissingForm.mainText" />
            </div>
            <div className={submitButtonWrapperClassName}>
              <PrimaryButton
                type="submit"
                inProgress={false}
                disabled={false}
              >
                <p>
                  <FormattedMessage id="PayoutInfoMissingForm.button" />
                </p>
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

PayoutInfoMissingFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
};

PayoutInfoMissingFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PayoutInfoMissingForm = compose(injectIntl)(PayoutInfoMissingFormComponent);

PayoutInfoMissingForm.displayName = 'PayoutInfoMissingForm';

export default PayoutInfoMissingForm;
