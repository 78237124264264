import React, { useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import { Form, Button, SecondaryButton, FieldTextInput } from '../../components';

import { injectIntl } from '../../util/reactIntl';
import { FormattedMessage } from '../../util/reactIntl';

import { BsFillCheckCircleFill } from 'react-icons/bs';
import * as validators from '../../util/validators';

import css from './SignupProviderForms.css';

const SignupProviderCOC = props => {
  const { onSubmit, intl, rootClassName, className, isModal } = props;

  const classes = classNames(rootClassName || css.root, className);

  const headingNotAccepted = intl.formatMessage({ id: 'SignupProviderForms.cocHeading' });
  const headingAccepted = intl.formatMessage({ id: 'SignupProviderForms.cocHeadingAccepted' });
  const descriptionNotAccepted = (
    <FormattedMessage
      id="SignupProviderForms.cocDescription"
      values={{
        lineBreak: <br />,
      }}
    />
  );
  const descriptionAccepted = (
    <FormattedMessage
      id="SignupProviderForms.cocDescriptionAccepted"
      values={{
        lineBreak: <br />,
      }}
    />
  );
  const subHeading = intl.formatMessage({ id: 'SignupProviderForms.cocSubHeading' });

  const listItemTitle1 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem1' });
  const listItemTitle2 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem2' });
  const listItemTitle3 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem3' });
  const listItemTitle4 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem4' });
  const listItemTitle5 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem5' });
  const listItemTitle6 = intl.formatMessage({ id: 'SignupProviderForms.cocListItem6' });

  const listItemText1 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText1' });
  const listItemText2 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText2' });
  const listItemText3 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText3' });
  const listItemText4 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText4' });
  const listItemText5 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText5' });
  const listItemText6 = intl.formatMessage({ id: 'SignupProviderForms.cocListItemText6' });
  const acceptButton = intl.formatMessage({ id: 'SignupProviderForms.cocAcceptButton' });

  const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.startButton' });

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          invalid,
          values,
          isModal,
          formId,
          pristine,
          initialValues,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid;

        const nextButtonText = intl.formatMessage({
          id: 'SignupProviderForms.nextButton',
        });
        const previousButtonText = intl.formatMessage({
          id: 'SignupProviderForms.previousButton',
        });
        const okButtonText = intl.formatMessage({
          id: 'SignupProviderForms.okButton',
        });

        const heading = values?.cocAccepted ? headingAccepted : headingNotAccepted;
        const description = values?.cocAccepted ? descriptionAccepted : descriptionNotAccepted;

        // firstName
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const handleFormSubmit = event => {
          event.preventDefault();

          // Set cocAccepted to true before submitting
          formRenderProps.form.change('cocAccepted', true);

          // Proceed with the form submission
          handleSubmit(values, form);
        };

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={handleFormSubmit}>
              <h2 className={css.mainHeading}>{heading}</h2>
              <p className={css.description}>{description}</p>
              <div className={css.subHeadingCOC}>{subHeading}</div>
              <div className={css.listItem}>
                <div className={css.listItemTitle}>{listItemTitle1}</div>
                <div className={css.listItemText}>{listItemText1}</div>
              </div>
              <div className={css.listItem}>
                <div className={css.listItemTitle}>{listItemTitle2}</div>
                <div className={css.listItemText}>{listItemText2}</div>
              </div>
              <div className={css.listItem}>
                <div className={css.listItemTitle}>{listItemTitle3}</div>
                <div className={css.listItemText}>{listItemText3}</div>
              </div>
              <div className={css.listItem}>
                <div className={css.listItemTitle}>{listItemTitle4}</div>
                <div className={css.listItemText}>{listItemText4}</div>
              </div>
              <div className={css.listItem}>
                <div className={css.listItemTitle}>{listItemTitle5}</div>
                <div className={css.listItemText}>{listItemText5}</div>
              </div>
              <div className={css.listItem}>
                <div className={css.listItemTitle}>{listItemTitle6}</div>
                <div className={css.listItemText}>{listItemText6}</div>
              </div>
              {!isModal ? (
                <div className={css.nameContainerCOC}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fnameProvider` : 'fnameProvider'}
                    name="fnameProvider"
                    autoComplete="given-name"
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lnameProvider` : 'lnameProvider'}
                    name="lnameProvider"
                    autoComplete="family-name"
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
              ) : null}

              <div className={css.submitContainer}>
                {!isModal && (
                  <SecondaryButton rootClassName={css.backButton} type="button" onClick={onPrev}>
                    {previousButtonText}
                  </SecondaryButton>
                )}
                {!values?.cocAccepted ? (
                  <Button
                    rootClassName={css.cocAcceptButton}
                    type="Submit"
                    disabled={submitDisabled}
                  >
                    {acceptButton}
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

SignupProviderCOC.defaultProps = {
  isModal: false,
};

export default injectIntl(SignupProviderCOC);
