import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { loadData } from './DashboardPage.duck';
import { getStripeConnectAccountLink } from '../../ducks/stripeConnectAccount.duck';
import { withViewport } from '../../util/contextHelpers';

import { ProjectListingCard, ListingCard } from '../../components';
import {
  AvatarLarge,
  Page,
  Footer,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  ModalCompleteProfile,
  NamedLink,
  ExternalLink,
  StripeConnectAccountStatusBox,
  RecommendCustomerContainer,
  BuyerTileFreedomlyPro,
  BuyerTileMarketplace,
} from '../../components';
import { BsHeadset, BsTelephoneFill } from 'react-icons/bs';
import { MdArticle } from 'react-icons/md';
import { AiFillPlayCircle } from 'react-icons/ai';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import { FaEnvelope, FaToolbox, FaUserCog } from 'react-icons/fa';

import penPaperIcon from '../../assets/pen-paper-icon.svg';
import chatBubbleIcon from '../../assets/chat-bubble-icon.svg';
import handshakeIcon from '../../assets/handshake-icon.svg';

import { TopbarContainer } from '../../containers';
import css from './DashboardPage.css';

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';

const MAX_MOBILE_SCREEN_WIDTH = 720;

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes) => {
  const path = createResourceLocatorString(
    'StripePayoutOnboardingPage',
    routes,
    { returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then(url => {
      window.location.href = url;
    })
    .catch(err => console.error(err));
};

export const DashboardPageComponent = props => {
  const {
    scrollingDisabled,
    currentUser,
    getAccountLinkInProgress,
    stripeAccount,
    onGetStripeConnectAccountLink,
    params,
    viewport,
  } = props;
  const { returnURLType } = params;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const displayName = ensuredCurrentUser.attributes.profile.displayName;
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

  const rootURL = config.canonicalRootURL;
  const routes = routeConfiguration();
  const successURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_SUCCESS, rootURL, routes);
  const failureURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_FAILURE, rootURL, routes);

  const accountId = stripeConnected ? stripeAccount.id : null;
  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
    onGetStripeConnectAccountLink,
    {
      accountId,
      successURL,
      failureURL,
    }
  );

  const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
  const showVerificationNeeded = stripeConnected && requirementsMissing;

  const { interestListings, favoriteListings, currentUserNotificationCount } = useSelector(
    store => {
      const {
        interestListingsLoading,
        interestListingIds,
        interestListingsError,
        currentUserNotificationCount,
        favoriteListingsIds,
      } = store.user;

      const interestListings = getListingsById(store, interestListingIds);
      const favoriteListings = getListingsById(store, favoriteListingsIds);

      return {
        interestListingsLoading,
        interestListings,
        interestListingsError,
        currentUserNotificationCount,
        favoriteListings,
      };
    }
  );

  // Panel width relative to the viewport
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) 100vw`,
    `(max-width: 1920px) 100vw`,
    `100vw`,
  ].join(', ');

  const isFreelancer =
    currentUser &&
    ['freelancer', 'light-entrepreneur'].includes(
      currentUser.attributes.profile.publicData.account_role
    );

  const isNotLE =
    currentUser &&
    ['freelancer', 'customer'].includes(currentUser.attributes.profile.publicData.account_role);

  const notificationDot =
    currentUserNotificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const isCustomer =
    currentUser && currentUser.attributes.profile.publicData.account_role === 'customer';

  const buyerTileFreedomlyProTitleId = 'DashboardPage.buyerTileFreedomlyProTitle';
  const buyerTileFreedomlyProSubtitleId = 'DashboardPage.buyerTileFreedomlyProSubtitle';
  const buyerTileFreedomlyProContent1 = 'DashboardPage.buyerTileFreedomlyProContent1';
  const buyerTileFreedomlyProContent2 = 'DashboardPage.buyerTileFreedomlyProContent2';
  const buyerTileFreedomlyProContent3 = 'DashboardPage.buyerTileFreedomlyProContent3';
  const buyerTileFreedomlyProContent4 = 'DashboardPage.buyerTileFreedomlyProContent4';

  const buyerTileFreedomlyProPrimaryCTAId = 'DashboardPage.buyerTileContactCTA';
  const buyerTileFreedomlyProSecondaryCTATo = 'DashboardProPage';
  const buyerTileFreedomlyProSecondaryCTAId = 'DashboardPage.buyerTileSecondaryCTA';

  const buyerTileMarketplaceTitleId = 'DashboardPage.buyerTileFreedomlyMarketplaceTitle';
  const buyerTileMarketplacesubTitleId = 'DashboardPage.buyerTileFreedomlyMarketplaceSubtitle';
  const buyerTileMarketplaceContentTitle1Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentTitle1';
  const buyerTileMarketplaceContentTitle2Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentTitle2';
  const buyerTileMarketplaceContentTitle3Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentTitle3';
  const buyerTileMarketplaceContentText1Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentText1';
  const buyerTileMarketplaceContentText2Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentText2';
  const buyerTileMarketplaceContentText3Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentText3';

  const buyerTileMarketplacePrimaryCTATo = 'NewProjectListingPage';
  const buyerTileMarketplacePrimaryCTAId = 'DashboardPage.buyerTilePostJobCTA';

  const buyerTileMarketplaceSecondaryCTATo = 'DashboardMarketplacePage';
  const buyerTileMarketplaceSecondaryCTAId = 'DashboardPage.buyerTileSecondaryCTA';

  const sideBar = (
    <div className={css.sidebar}>
      <div className={css.profileCard}>
        <div className={css.avatarContainer}>
          <AvatarLarge user={currentUser} disableProfileLink />
        </div>
        <div className={css.profileCardLinks}>
          <NamedLink name="ProfileSettingsPage" className={css.profileCardLink}>
            <FaUserCog className={css.profileCardLinkIconProfile} />
            <FormattedMessage id="DashboardPage.profileSettingsLink" />
          </NamedLink>
          {isFreelancer ? (
            <NamedLink name="ManageListingsPage" className={css.profileCardLink}>
              <FaToolbox className={css.profileCardLinkIcon} />

              <FormattedMessage id="DashboardPage.listingsLink" />
            </NamedLink>
          ) : (
            <NamedLink name="ManageProjectListingsPage" className={css.profileCardLink}>
              <FaToolbox className={css.profileCardLinkIcon} />

              <FormattedMessage id="DashboardPage.projectsLink" />
            </NamedLink>
          )}
          <NamedLink
            className={css.profileCardLink}
            name="InboxPage"
            params={{ tab: isFreelancer ? 'sales' : 'orders' }}
          >
            <FaEnvelope className={css.profileCardLinkIcon} />

            <FormattedMessage id="DashboardPage.inboxLink" />
            {notificationDot}
          </NamedLink>
        </div>
      </div>
      {!isCustomer && viewport.width >= MAX_MOBILE_SCREEN_WIDTH ? (
        <div className={css.articleBox}>
          <div className={css.needHelpHeaderContainer}>
            <h3 className={css.articleBoxHeader}>
              <FormattedMessage id="DashboardPage.articleBoxTitle" />
            </h3>
          </div>
          <ExternalLink
            className={css.articleBoxArticle}
            href="https://help.freedomly.io/fi/collections/3885878-palveluiden-myyminen-ohjeita-freelancerille"
          >
            <MdArticle className={css.articleBoxArticleIcon} />
            <FormattedMessage id="DashboardPage.articleBoxText1" />
          </ExternalLink>
          <ExternalLink
            className={css.articleBoxArticle}
            href="https://www.youtube.com/playlist?list=PL_STUE7EQFofQeNkHRn14qkLxS0OkuY0U"
          >
            <AiFillPlayCircle className={css.articleBoxArticleIcon} />
            <FormattedMessage id="DashboardPage.articleBoxText2" />
          </ExternalLink>
        </div>
      ) : null}

      {isCustomer && viewport.width >= MAX_MOBILE_SCREEN_WIDTH ? (
        <div className={css.articleBox}>
          <div className={css.needHelpHeaderContainer}>
            <h3 className={css.articleBoxHeader}>
              <FormattedMessage id="DashboardPage.articleBoxTitleCustomer" />
            </h3>
          </div>
          <ExternalLink
            className={css.articleBoxArticle}
            href="https://help.freedomly.io/fi/collections/3778256-palveluiden-ostaminen-ohjeita-asiakkaalle"
          >
            <MdArticle className={css.articleBoxArticleIcon} />
            <FormattedMessage id="DashboardPage.articleBoxText1Customer" />
          </ExternalLink>
          <ExternalLink
            className={css.articleBoxArticle}
            href="https://calendly.com/miikka-freedomly/freedomly-demotapaaminen-20min?month=2023-03"
          >
            <BsTelephoneFill className={css.articleBoxArticleIconSmall} />
            <FormattedMessage id="DashboardPage.needHelpLinkText" />
          </ExternalLink>
          <div className={css.needHelpText}>
            <FormattedMessage id="DashboardPage.needHelpText" />
          </div>
        </div>
      ) : null}
      {!isCustomer ? <RecommendCustomerContainer /> : null}
    </div>
  );

  const content = (
    <div className={css.contentContainer}>
      {stripeConnected && !returnedAbnormallyFromStripe && showVerificationNeeded && isNotLE ? (
        <StripeConnectAccountStatusBox
          type="verificationNeeded"
          inProgress={getAccountLinkInProgress}
          onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
            'custom_account_verification'
          )}
        />
      ) : null}
      <div>
        {!isCustomer ? (
          <div className={css.freelancerTiles}>
            <ModalCompleteProfile id="CompleteProfileReminder" className={css.completeProfile} />
            <div className={css.freelancerJobTipTile}>
              <h1 className={css.freelancerJobTipTileTitle}>
                <FormattedMessage id="DashboardPage.freelancerJobTipTileTitle" />
              </h1>
              <p className={css.freelancerJobTipTileText}>
                <FormattedMessage id="DashboardPage.freelancerJobTipTileText" />
              </p>
              <p className={css.freelancerJobTipTileText}>
                <FormattedMessage id="DashboardPage.freelancerJobTipTileText2" />
              </p>
            </div>
          </div>
        ) : (
          <div className={css.buyerTiles}>
            <BuyerTileFreedomlyPro
              buyerTileFreedomlyProTitleId={buyerTileFreedomlyProTitleId}
              buyerTileFreedomlyProSubtitleId={buyerTileFreedomlyProSubtitleId}
              buyerTileFreedomlyProContent1={buyerTileFreedomlyProContent1}
              buyerTileFreedomlyProContent2={buyerTileFreedomlyProContent2}
              buyerTileFreedomlyProContent3={buyerTileFreedomlyProContent3}
              buyerTileFreedomlyProContent4={buyerTileFreedomlyProContent4}
              buyerTileFreedomlyProPrimaryCTAId={buyerTileFreedomlyProPrimaryCTAId}
              buyerTileFreedomlyProSecondaryCTATo={buyerTileFreedomlyProSecondaryCTATo}
              buyerTileFreedomlyProSecondaryCTAId={buyerTileFreedomlyProSecondaryCTAId}
              showSecondaryCTA={true}
            />
            <BuyerTileMarketplace
              buyerTileMarketplaceTitleId={buyerTileMarketplaceTitleId}
              buyerTileMarketplacesubTitleId={buyerTileMarketplacesubTitleId}
              buyerTileMarketplaceContentTitle1Id={buyerTileMarketplaceContentTitle1Id}
              buyerTileMarketplaceContentTitle2Id={buyerTileMarketplaceContentTitle2Id}
              buyerTileMarketplaceContentTitle3Id={buyerTileMarketplaceContentTitle3Id}
              buyerTileMarketplaceContentText1Id={buyerTileMarketplaceContentText1Id}
              buyerTileMarketplaceContentText2Id={buyerTileMarketplaceContentText2Id}
              buyerTileMarketplaceContentText3Id={buyerTileMarketplaceContentText3Id}
              buyerTileMarketplacePrimaryCTATo={buyerTileMarketplacePrimaryCTATo}
              buyerTileMarketplacePrimaryCTAId={buyerTileMarketplacePrimaryCTAId}
              buyerTileMarketplaceSecondaryCTATo={buyerTileMarketplaceSecondaryCTATo}
              buyerTileMarketplaceSecondaryCTAId={buyerTileMarketplaceSecondaryCTAId}
              showSecondaryCTA={true}
            />
          </div>
        )}
      </div>

      <div>
        {favoriteListings.length > 0 ? (
          <div className={css.interestContainer}>
            <div className={css.interesListings}>
              <div className={css.interestListingsHeader}>
                <FormattedMessage id="DashboardPage.favoriteListingsHeader" />
                <NamedLink className={css.showAllFavorites} name="FavoritesPage">
                  <FormattedMessage id="DashboardPage.favoriteListingsShowAll" />
                </NamedLink>
              </div>
              <div className={css.interestListingsContainer}>
                {favoriteListings.map((l, index) =>
                  l.attributes.publicData.listingType === 'service' ? (
                    index < 6 ? (
                      <ListingCard
                        className={css.interestListing}
                        key={l.id.uuid}
                        listing={l}
                        renderSizes={cardRenderSizes}
                      />
                    ) : null
                  ) : (
                    <ProjectListingCard
                      className={css.interestListing}
                      key={l.id.uuid}
                      listing={l}
                      renderSizes={cardRenderSizes}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {!isCustomer && viewport.width <= MAX_MOBILE_SCREEN_WIDTH ? (
        <div className={css.articleBox}>
          <div className={css.needHelpHeaderContainer}>
            <h3 className={css.articleBoxHeader}>
              <FormattedMessage id="DashboardPage.articleBoxTitle" />
            </h3>
          </div>
          <ExternalLink
            className={css.articleBoxArticle}
            href="https://help.freedomly.io/fi/collections/3885878-palveluiden-myyminen-ohjeita-freelancerille"
          >
            <MdArticle className={css.articleBoxArticleIcon} />
            <FormattedMessage id="DashboardPage.articleBoxText1" />
          </ExternalLink>
          <ExternalLink
            className={css.articleBoxArticle}
            href="https://www.youtube.com/playlist?list=PL_STUE7EQFofQeNkHRn14qkLxS0OkuY0U"
          >
            <AiFillPlayCircle className={css.articleBoxArticleIcon} />
            <FormattedMessage id="DashboardPage.articleBoxText2" />
          </ExternalLink>
        </div>
      ) : null}

      {isCustomer && viewport.width <= MAX_MOBILE_SCREEN_WIDTH ? (
        <div className={css.articleBox}>
          <div className={css.needHelpHeaderContainer}>
            <h3 className={css.articleBoxHeader}>
              <FormattedMessage id="DashboardPage.articleBoxTitleCustomer" />
            </h3>
          </div>
          <ExternalLink
            className={css.articleBoxArticle}
            href="https://help.freedomly.io/fi/collections/3778256-palveluiden-ostaminen-ohjeita-asiakkaalle"
          >
            <MdArticle className={css.articleBoxArticleIcon} />
            <FormattedMessage id="DashboardPage.articleBoxText1Customer" />
          </ExternalLink>
          <ExternalLink
            className={css.articleBoxArticle}
            href="https://calendly.com/miikka-freedomly/freedomly-demotapaaminen-20min?month=2023-03"
          >
            <BsTelephoneFill className={css.articleBoxArticleIconSmall} />
            <FormattedMessage id="DashboardPage.needHelpLinkText" />
          </ExternalLink>
          <div className={css.needHelpText}>
            <FormattedMessage id="DashboardPage.needHelpText" />
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <Page title="Dashboard" scrollingDisabled={scrollingDisabled}>
      <LayoutWrapperTopbar>
        <TopbarContainer currentPage="DashboardPage" />
      </LayoutWrapperTopbar>
      {!!currentUser ? (
        <LayoutWrapperMain>
          <div className={css.dashboardMain}>
            <div>
              <div className={css.dashboardHeader}>
                <FormattedMessage id="DashboardPage.welcome" />
                <span>&nbsp;{displayName}</span>
              </div>
            </div>
            <div className={css.dashboardMainContent}>
              {sideBar}
              {content}
            </div>
          </div>
        </LayoutWrapperMain>
      ) : null}
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </Page>
  );
};

const { bool, object } = PropTypes;

DashboardPageComponent.defaultProps = {
  filterConfig: config.custom.filters,
};

DashboardPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;
  const { currentUser } = state.user;
  const { payoutDetailsSaveInProgress, payoutDetailsSaved } = state.StripePayoutPage;
  return {
    currentUser,
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onGetStripeConnectAccountLink: params => dispatch(getStripeConnectAccountLink(params)),
});

const DashboardPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(DashboardPageComponent);

DashboardPage.loadData = loadData;

export default DashboardPage;
