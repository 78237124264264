import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { PrimaryButton, FieldTextInput } from '..';
import { IoSearch } from 'react-icons/io5';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './KeywordSearchBar.css';

const KeywordSearchBar = injectIntl(props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        inputRootClass,
        rootClassName,
        submitButtonClassName,
        handleSubmit,
        name,
        id,
        intl,
      } = fieldRenderProps;
      const classes = classNames(rootClassName || css.root, className);
      // invalid, pristine & submitting in fieldRenderProps if ever needed.
      // const submitDisabled = invalid || pristine || submitting;
      const inputClassName = inputRootClass || css.searchInput;
      const submitClassName = submitButtonClassName || css.submitButton;
      const placeholder = intl.formatMessage({ id: 'KeywordSearchBar.placeholder' });

      return (
        <form
          className={classes}
          onSubmit={e => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <FieldTextInput
            inputRootClass={inputClassName}
            type="text"
            id={id}
            name={name}
            placeholder={placeholder}
            autoComplete="off"
          />
          <div className={css.submitContainer}>
            <PrimaryButton className={submitClassName} type="submit">
              <IoSearch />
            </PrimaryButton>
          </div>
        </form>
      );
    }}
  />
));

export default KeywordSearchBar;
