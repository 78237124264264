import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import config from '../../config';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  FieldPhoneNumberInput,
  FieldSelectModern,
} from '../../components';

import css from './ConfirmSignupForm.css';

const KEY_CODE_ENTER = 13;
const industryOptions = config.custom.industryOptions;
const categoryOptions = config.custom.categoriesOptions;

const ConfirmSignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        values,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        authInfo,
        isSignupProvider,
      } = fieldRenderProps;

      // If we don't have authentication information from idp provider, redirect user back to normal signup page
      //      if (!authInfo) {
      //        return <NamedRedirect name="SignupPage" />;
      //      }

      // email
      const emailPlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // phoneNumber

      const phoneNumberPlaceholder = intl.formatMessage({
        id: 'SignupForm.phoneNumberPlaceholder',
      });

      // firstName

      const firstNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName

      const lastNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // Account Role

      const accountRolePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.accountRolePlaceholder',
      });
      const accountRoleRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.accountRoleRequired',
      });
      const accountRoleRequired = validators.required(accountRoleRequiredMessage);

      const accountRoleFreelancerOption = intl.formatMessage({
        id: 'SignupForm.accountRoleFreelancerOption',
      });
      const accountRoleLightEntrepreneurOption = intl.formatMessage({
        id: 'SignupForm.accountRoleLightEntrepreneurOption',
      });
      const accountRoleCompanyAbroadOption = intl.formatMessage({
        id: 'SignupForm.accountRoleCompanyAbroadOption',
      });

      // VAT number

      const vatNumberOptionalPlaceholder = intl.formatMessage({
        id: 'SignupForm.vatNumberOptionalPlaceholder',
      });

      const vatNumberRequiredPlaceholder = intl.formatMessage({
        id: 'SignupForm.vatNumberRequiredPlaceholder',
      });
      const vatNumberRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.vatNumberRequired',
      });
      const phoneNumberRequiredMessage = intl.formatMessage({
        id: 'SignupForm.phoneNumberRequired',
      });
      const vatNumberRequired = validators.required(vatNumberRequiredMessage);
      const vatNumberOptional = validators.optional();
      const phoneNumberRequired = validators.phoneNumberRequired(phoneNumberRequiredMessage);

      // const taxInformationElement =
      //   values.accountRole != undefined ? (
      //     <FieldTextInput
      //       className={css.vat}
      //       type="text"
      //       id={formId ? `${formId}.vat_number` : 'vat_number'}
      //       name="vat_number"
      //       label={vatNumberLabel}
      //       placeholder={
      //         values.accountRole === 'freelancer'
      //           ? vatNumberRequiredPlaceholder
      //           : vatNumberOptionalPlaceholder
      //       }
      //       validate={vatNumberOptional}
      //     />
      //   ) : null;

      const taxInformationElement = () => {
        if (values.accountRole === 'freelancer') {
          return (
            <FieldTextInput
              className={css.vat}
              type="text"
              id={formId ? `${formId}.vat_number` : 'vat_number'}
              name="vat_number"
              // label={vatNumberLabel}
              placeholder={vatNumberRequiredPlaceholder}
              validate={vatNumberRequired}
              key={values.accountRole ? 'freelancer' : 'customer'}
              autoFocus
            />
          );
        } else if (!isSignupProvider) {
          return (
            <FieldTextInput
              className={css.vat}
              type="text"
              id={formId ? `${formId}.vat_number` : 'vat_number'}
              name="vat_number"
              // label={vatNumberLabel}
              placeholder={vatNumberOptionalPlaceholder}
              validate={vatNumberOptional}
              key={values.accountRole ? 'customer' : 'freelancer'}
              autoFocus
            />
          );
        } else {
          return null;
        }
      };
      const phoneNumberElement = () => {
        return (
          <div>
            <FieldPhoneNumberInput
              className={css.phoneNumber}
              type="text"
              id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
              name="phoneNumber"
              autoComplete="phoneNumber"
              // label={phoneNumberLabel}
              placeholder={phoneNumberPlaceholder}
              validate={phoneNumberRequired}
            />
            <div className={css.underFieldLabel}>
              <FormattedMessage id="SignupForm.privatePhoneNumberNote" />
            </div>
          </div>
        );
      };

      // Industry

      const industryPlaceholder = intl.formatMessage({
        id: 'SignupForm.industryPlaceholder',
      });

      const industryOptional = validators.optional();

      const industryElement = () => {
        if (!isSignupProvider) {
          return (
            <FieldSelectModern
              className={css.industry}
              id={formId ? `${formId}.industry` : 'industry'}
              name="industry"
              // label={industryLabel}
              validate={industryOptional}
              options={industryOptions}
              placeholder={industryPlaceholder}
            />
          );
        } else {
          return null;
        }
      };

      const lookingForPlaceholder = intl.formatMessage({
        id: 'SignupForm.lookingForPlaceholder',
      });

      const lookingForElement = !isSignupProvider ? (
        <FieldSelectModern
          className={css.lookingFor}
          id={formId ? `${formId}.lookingFor` : 'lookingFor'}
          name="lookingFor"
          // label={lookingForLabel}
          validate={industryOptional}
          options={categoryOptions}
          placeholder={lookingForPlaceholder}
        />
      ) : null;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="ConfirmSignupForm.termsAndConditionsLinkText" />
        </span>
      );

      // If authInfo is not available we should not show the ConfirmForm
      if (!authInfo) {
        return;
      }

      // Initial values from idp provider
      const { email, firstName, lastName, idpId } = authInfo;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              // label={emailLabel}
              placeholder={emailPlaceholder}
              initialValue={email}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="firstName"
                autoComplete="given-name"
                // label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                initialValue={firstName}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lastName` : 'lastName'}
                name="lastName"
                autoComplete="family-name"
                // label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                initialValue={lastName}
                validate={lastNameRequired}
              />
            </div>
            {isSignupProvider ? (
              <FieldSelect
                className={css.role}
                id={formId ? `${formId}.accountRole` : 'accountRole'}
                name="accountRole"
                // label={accountRoleLabel}
                validate={accountRoleRequired}
              >
                <option value="">{accountRolePlaceholder}</option>
                <option value="freelancer">{accountRoleFreelancerOption}</option>
                <option value="light-entrepreneur">{accountRoleLightEntrepreneurOption}</option>
                <option value="company-abroad">{accountRoleCompanyAbroadOption}</option>
              </FieldSelect>
            ) : null}
            {taxInformationElement()}
            {phoneNumberElement()}
            {industryElement()}
            {lookingForElement}
          </div>

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="ConfirmSignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="ConfirmSignupForm.signUp" values={{ idp: idpId }} />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ConfirmSignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
