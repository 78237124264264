import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_SALE_TAX, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or negative.
const isValidSaleTax = saleTaxLineItem => {
  return saleTaxLineItem.lineTotal instanceof Money && saleTaxLineItem.lineTotal.amount >= 0;
};

const LineItemSaleTaxMaybe = props => {
  const { transaction, intl } = props;

  const saleTaxLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SALE_TAX && !item.reversal
  );

  // If sale tax is passed it will be shown as an add-on to the base price
  let saleTaxItem = null;

  // Flex Template for Web is using the default transaction process (https://www.sharetribe.com/docs/background/transaction-process/#sharetribe-flex-default-transaction-process)
  // We add up sale tax to it.
  if (saleTaxLineItem) {
    if (!isValidSaleTax(saleTaxLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid sale tax line item:', saleTaxLineItem);
      throw new Error('Sale tax should be present and the value should be zero or positive');
    }

    const saleTax = saleTaxLineItem.lineTotal;
    const formattedSaleTax = saleTax ? formatMoney(intl, saleTax) : null;

    saleTaxItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.saleTax" />
        </span>
        <span className={css.itemValue}>{formattedSaleTax}</span>
      </div>
    );
  }

  return saleTaxItem;
};

LineItemSaleTaxMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSaleTaxMaybe;
