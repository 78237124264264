import React, { Component } from 'react';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import { propTypes, DATE_TYPE_DATE } from '../../util/types';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureBooking,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
} from '../../util/data';
import getCountryCodes from '../../translations/countryCodes';
import { dateFromLocalToAPI, minutesBetween } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { TRANSITION_ENQUIRE, txIsPaymentPending, txIsPaymentExpired } from '../../util/transaction';
import {
  InfoIcon,
  AvatarMedium,
  BookingBreakdown,
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
} from '../../components';
import { StripePaymentForm } from '../../forms';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  confirmCardPayment,
  retrievePaymentIntent,
  retrievePaymentMethod,
} from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';

import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  confirmPayment,
  cancelPayment,
  sendMessage,
} from './CheckoutPage.duck';
import { storeData, storedData, clearData } from './CheckoutPageSessionHelpers';
import css from './CheckoutPage.css';

const { Money } = sdkTypes;

const STORAGE_KEY = 'CheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.navigateBack = this.navigateBack.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
      projectTransaction,
    } = this.props;

    const projectProtectedData =
      (projectTransaction && projectTransaction.attributes.protectedData) || {};
    const { projectBidDescription } = projectProtectedData;
    const amount =
      (projectTransaction && projectTransaction.attributes.protectedData.projectBidAmount) || null;
    const projectTransactionId = (projectTransaction && projectTransaction.id.uuid) || null;

    if (projectTransaction) {
      bookingData.projectBidAmount = amount;
      bookingData.projectBidDescription = projectBidDescription;
      bookingData.projectTransactionId = projectTransactionId;
    }

    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.bookingData &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      !isBookingCreated;

    if (shouldFetchSpeculatedTransaction) {
      const listingId = pageData.listing.id;
      const transactionId = tx ? tx.id : null;
      const { bookingStart, bookingEnd } = pageData.bookingDates;
      const { pricingPackage, suggestedPrice, isCustomOffer } = pageData.bookingData;
      const { listing } = pageData;
      const author = listing.author;
      const isLightEntrepreneur =
        author &&
        author.attributes.profile.publicData &&
        author.attributes.profile.publicData.account_role === 'light-entrepreneur';

      // Convert picked date to date that will be converted on the API as
      // a noon of correct year-month-date combo in UTC
      const bookingStartForAPI = dateFromLocalToAPI(bookingStart);
      const bookingEndForAPI = dateFromLocalToAPI(bookingEnd);

      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData
      fetchSpeculatedTransaction(
        {
          listingId,
          bookingStart: bookingStartForAPI,
          bookingEnd: bookingEndForAPI,
          pricingPackage,
          suggestedPrice,
          isCustomOffer,
          projectBidAmount: amount,
          projectBidDescription: projectBidDescription,
          projectTransactionId,
          isLightEntrepreneur,
        },
        transactionId
      );
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onConfirmCardPayment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      onRetrievePaymentIntent,
      onRetrievePaymentMethod,
      projectTransaction,
      payoutProfileListing,
    } = this.props;
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    const projectProtectedData =
      (projectTransaction && projectTransaction.attributes.protectedData) || {};
    const description = projectProtectedData.projectBidDescription;
    const amount =
      (projectTransaction && projectTransaction.attributes.protectedData.projectBidAmount) || null;
    const projectTransactionId = (projectTransaction && projectTransaction.id.uuid) || null;
    const deliveryDate =
      (projectTransaction && projectTransaction.attributes.protectedData.projectBidDeliveryDate) ||
      null;

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    let lePayoutTransaction = null;
    let lePayoutPaymentIntent = null;

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      const { listing } = pageData;
      const author = listing.author;
      const isLightEntrepreneur =
        author &&
        author.attributes.profile.publicData &&
        author.attributes.profile.publicData.account_role === 'light-entrepreneur';
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      if (isLightEntrepreneur) {
        const leParams = cloneDeep(fnParams);
        leParams.listingId = payoutProfileListing.id;
        leParams.isPayout = true;
        fnParams.isLightEntrepreneur = true;

        if (!Number.isInteger(fnParams.projectBidAmount) && !fnParams.suggestedPrice) {
          if (fnParams.pricingPackage === 'package1') {
            leParams.suggestedPrice = listing.attributes.price;
          } else {
            leParams.suggestedPrice = new Money(
              listing.attributes.publicData[`${fnParams.pricingPackage}Price`],
              'EUR'
            );
          }
        }

        return onInitiateOrder(leParams, null).then(order => {
          lePayoutTransaction = order;
          fnParams.lePayoutTransactionId = lePayoutTransaction.id.uuid;

          return hasPaymentIntents
            ? Promise.resolve(storedTx)
            : onInitiateOrder(fnParams, storedTx.id);
        });
      }

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents ? Promise.resolve(storedTx) : onInitiateOrder(fnParams, storedTx.id);
    };

    // Step 2: pay using Stripe SDK
    const fnConfirmCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }
      const { listing } = pageData;
      const author = listing.author;
      const isLightEntrepreneur =
        author &&
        author.attributes.profile.publicData &&
        author.attributes.profile.publicData.account_role === 'light-entrepreneur';

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents && !isLightEntrepreneur) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } =
        hasPaymentIntents && !isLightEntrepreneur
          ? order.attributes.protectedData.stripePaymentIntents.default
          : {};

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method: {
                billing_details: billingDetails,
                card: card,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
      };

      // If paymentIntent status is not waiting user action,
      // confirmCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

      if (isLightEntrepreneur) {
        const {
          stripePaymentIntentClientSecret,
        } = lePayoutTransaction.attributes.protectedData.stripePaymentIntents.default;
        const leParams = {
          stripePaymentIntentClientSecret,
          orderId: lePayoutTransaction.id,
          stripe,
          ...stripeElementMaybe,
          paymentParams,
        };

        return hasPaymentIntentUserActionsDone
          ? Promise.resolve({ transactionId: order.id, paymentIntent: null })
          : onConfirmCardPayment(leParams).then(() =>
              Promise.resolve({ transactionId: order.id, paymentIntent: null })
            );
      }

      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent })
        : onConfirmCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      const { listing } = pageData;
      const currentListing = ensureListing(listing);
      const currentAuthor = ensureUser(currentListing.author);
      const isLightEntrepreneur =
        currentAuthor &&
        currentAuthor.attributes.profile.publicData &&
        currentAuthor.attributes.profile.publicData.account_role === 'light-entrepreneur';

      createdPaymentIntent = fnParams.paymentIntent;
      const paymentMethodId = isLightEntrepreneur ? null : createdPaymentIntent.payment_method;

      const getPaymentMethod = isLightEntrepreneur
        ? Promise.resolve({ stripe: this.stripe, paymentMethodId: null })
        : onRetrievePaymentMethod({ stripe: this.stripe, paymentMethodId });

      return getPaymentMethod.then(paymentMethod => {
        if (!isLightEntrepreneur) {
          const countryCodes = getCountryCodes();
          const country = countryCodes.find(
            country => country.code === paymentMethod.billing_details.address.country
          );

          if (typeof country === 'object') {
            paymentMethod.billing_details.address.country = country.name;
          }
        }

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        let periodStart = null;
        let periodEnd = null;
        let subscriptionPeriodStart = null;
        let subscriptionPeriodEnd = null;

        if (
          currentAuthor.attributes.profile.publicData &&
          typeof currentAuthor.attributes.profile.publicData.subscriptionDate === 'object'
        ) {
          const userSubscriptionDate = currentAuthor.attributes.profile.publicData.subscriptionDate;
          periodStart = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            userSubscriptionDate.day,
            0,
            0,
            0,
            0
          );

          if (periodStart.getTime() > currentDate) {
            periodStart.setMonth(periodStart.getMonth() - 1);
          }

          periodEnd = new Date(
            periodStart.getFullYear(),
            periodStart.getMonth() + 1,
            periodStart.getDate(),
            0,
            0,
            0,
            0
          );

          subscriptionPeriodStart = {
            year: periodStart.getFullYear(),
            month: periodStart.getMonth() + 1,
            day: periodStart.getDate(),
            hours: periodStart.getHours(),
            minutes: periodStart.getMinutes(),
            seconds: periodStart.getSeconds(),
            milliseconds: periodStart.getMilliseconds(),
          };

          subscriptionPeriodEnd = {
            year: periodEnd.getFullYear(),
            month: periodEnd.getMonth() + 1,
            day: periodEnd.getDate(),
            hours: periodEnd.getHours(),
            minutes: periodEnd.getMinutes(),
            seconds: periodEnd.getSeconds(),
            milliseconds: periodEnd.getMilliseconds(),
          };
        }
        let payInWithoutVatAmount = 0;
        let payInVatAmount = 0;
        let payInWithoutVat = 0;
        let payInVat = 0;
        let payOutWithoutVatAmount = 0;
        let payOutVatAmount = 0;
        let payOutWithoutVat = 0;
        let payOutVat = 0;
        let pricingPackage = 0;

        if (isLightEntrepreneur) {
          const { stripe } = handlePaymentParams;
          const {
            stripePaymentIntentClientSecret,
          } = lePayoutTransaction.attributes.protectedData.stripePaymentIntents.default;

          return onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret }).then(
            response => {
              lePayoutPaymentIntent = response.paymentIntent;

              payInWithoutVatAmount = Math.round((lePayoutPaymentIntent.amount * 100) / 125.5);
              payInVatAmount = lePayoutPaymentIntent.amount - payInWithoutVatAmount;
              payInWithoutVat = { amount: payInWithoutVatAmount / 100, currency: 'EUR' };
              payInVat = { amount: payInVatAmount / 100, currency: 'EUR' };
              payOutWithoutVatAmount = (lePayoutPaymentIntent.amount * 85) / 125.5;
              payOutVatAmount = (lePayoutPaymentIntent.amount * 25.5) / 100;
              payOutWithoutVat = { amount: payOutWithoutVatAmount / 100, currency: 'EUR' };
              payOutVat = { amount: payOutVatAmount / 100, currency: 'EUR' };
              pricingPackage = pageData.bookingData.pricingPackage;

              const protectedData = {
                subscriptionPeriodStart,
                subscriptionPeriodEnd,
                payInVat,
                payInWithoutVat,
                payOutWithoutVat,
                payOutVat,
                pricingPackage,
                //paymentMethod,
              };
              const leParams = {
                paymentIntent: lePayoutPaymentIntent,
                transactionId: lePayoutTransaction.id,
              };

              return onConfirmPayment({ protectedData, ...leParams }).then(() => {
                delete protectedData.paymentMethod;

                return onConfirmPayment({ projectTransactionId, protectedData, ...fnParams });
              });
            }
          );
        } else {
          payInWithoutVatAmount = Math.round((fnParams.paymentIntent.amount * 100) / 125.5);
          payInVatAmount = fnParams.paymentIntent.amount - payInWithoutVatAmount;
          payInWithoutVat = { amount: payInWithoutVatAmount / 100, currency: 'EUR' };
          payInVat = { amount: payInVatAmount / 100, currency: 'EUR' };
          payOutWithoutVatAmount = (fnParams.paymentIntent.amount * 85) / 125.5;
          payOutVatAmount = (payOutWithoutVatAmount * 25.5) / 100;
          payOutWithoutVat = { amount: payOutWithoutVatAmount / 100, currency: 'EUR' };
          payOutVat = { amount: payOutVatAmount / 100, currency: 'EUR' };
          pricingPackage = pageData.bookingData.pricingPackage;
        }

        const protectedData = {
          subscriptionPeriodStart,
          subscriptionPeriodEnd,
          payInVat,
          payInWithoutVat,
          payOutWithoutVat,
          payOutVat,
          pricingPackage,
        };

        if (!isLightEntrepreneur) protectedData.paymentMethod = paymentMethod;

        return onConfirmPayment({
          projectTransactionId,
          protectedData,
          ...fnParams,
        });
      });
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({
        ...fnParams,
        message,
        bidAmount: amount,
        bidDescription: description,
        intl: this.props.intl,
      });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const { listing } = pageData;
      const currentListing = ensureListing(listing);
      const currentAuthor = ensureUser(currentListing.author);
      const isLightEntrepreneur =
        currentAuthor &&
        currentAuthor.attributes.profile.publicData &&
        currentAuthor.attributes.profile.publicData.account_role === 'light-entrepreneur';
      const pi = isLightEntrepreneur
        ? lePayoutPaymentIntent
        : createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnConfirmCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnConfirmCardPayment,
      fnConfirmPayment,
      fnSendMessage,
      fnSavePaymentMethod
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.confirmCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const orderParams = {
      listingId: pageData.listing.id,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      pricingPackage: pageData.bookingData.pricingPackage,
      suggestedPrice: pageData.bookingData.suggestedPrice,
      isCustomOffer: pageData.bookingData.isCustomOffer,
      projectBidAmount: amount,
      projectBidDescription: description,
      projectBidDeliveryDate: deliveryDate,
      projectTransactionId,
      ...optionalPaymentParams,
    };

    const packagePriceAmount =
      pageData.bookingData.pricingPackage === 'package1'
        ? pageData.listing.attributes?.price?.amount
        : pageData.bookingData.pricingPackage &&
          pageData.listing.attributes.publicData[`${pageData.bookingData.pricingPackage}Price`]
        ? pageData.listing.attributes.publicData[`${pageData.bookingData.pricingPackage}Price`]
        : 0;
    const eventPrice = pageData.bookingData.suggestedPrice
      ? pageData.bookingData.suggestedPrice.amount / 100
      : packagePriceAmount / 100;
    const pricingPackageName =
      pageData.bookingData.pricingPackage &&
      pageData.listing.attributes.publicData[`${pageData.bookingData.pricingPackage}Name`]
        ? pageData.listing.attributes.publicData[`${pageData.bookingData.pricingPackage}Name`]
        : '';

    if (typeof window === 'object') {
      window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
          payment_type: 'credit_card',
          items: [
            {
              item_name: pageData.listing.attributes.title,
              item_id: pageData.listing.id.uuid,
              price: '' + eventPrice,
              item_brand: pageData.listing.author.attributes.profile.displayName,
              item_variant: pageData.bookingData.suggestedPrice
                ? 'custom_offer'
                : pricingPackageName,
              item_category: pageData.listing.attributes.publicData.category,
              item_list_name: 'chat_window',
              custom_offer: !!pageData.bookingData.suggestedPrice,
              custom_offer_value: pageData.bookingData.suggestedPrice
                ? '' + pageData.bookingData.suggestedPrice.amount / 100
                : null,
              quantity: '1',
            },
          ],
        },
      });
    }

    return handlePaymentIntentCreation(orderParams);
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { history, speculatedTransaction, currentUser, paymentIntent, dispatch } = this.props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
    };

    this.handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { orderId, messageSuccess, paymentMethodSaved } = res;
        this.setState({ submitting: false });

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, { id: orderId.uuid });
        const initialValues = {
          initialMessageFailedToTransaction,
          savePaymentMethodFailed: !paymentMethodSaved,
        };

        initializeOrderPage(initialValues, routes, dispatch);
        clearData(STORAGE_KEY);
        history.push(orderDetailsPath);
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }

  handleCancel() {
    const routes = routeConfiguration();

    if (this.props.projectTransaction) {
      const slug = createSlug(this.props.projectTransaction.listing.attributes.title);
      const listingPath = pathByRouteName('ProjectListingPage', routes, {
        id: this.props.projectTransaction.listing.id.uuid,
        slug,
      });
      this.props.history.push(listingPath);
    } else if (this.props.transaction) {
      if (txIsPaymentPending(this.props.transaction)) {
        this.props.onCancelPayment(this.props.transaction.id).then(() => {
          const slug = createSlug(this.props.listing.attributes.title);
          const listingPath = pathByRouteName('ListingPage', routes, {
            id: this.props.listing.id.uuid,
            slug,
          });
          this.props.history.push(listingPath);
        });
      } else {
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
          id: this.props.transaction.id.uuid,
        });
        this.props.history.push(orderDetailsPath);
      }
    } else {
      if (this.props.listing) {
        const slug = createSlug(this.props.listing.attributes.title);
        const listingPath = pathByRouteName('ListingPage', routes, {
          id: this.props.listing.id.uuid,
          slug,
        });
        this.props.history.push(listingPath);
      } else {
        const landingPath = pathByRouteName('LandingPage', routes, {});
        this.props.history.push(landingPath);
      }
    }
  }

  navigateBack() {
    this.props.history.goBack();
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      confirmCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      projectTransaction,
    } = this.props;

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, bookingDates, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const listingTitle = transaction?.attributes.protectedData?.listingTitle || '';

    const title = intl.formatMessage(
      { id: 'CheckoutPage.title' },
      { listingTitle: currentAuthor.attributes.profile.displayName }
    );

    const pageProps = { title, scrollingDisabled };
    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );

    const hasRequiredData = hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        bookingDates,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const breakdown =
      tx.id && txBooking.id ? (
        <BookingBreakdown
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={config.bookingUnitType}
          transaction={tx}
          booking={txBooking}
          dateType={DATE_TYPE_DATE}
        />
      ) : null;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired
    );

    const navigateBackLink = (
      <span onClick={this.navigateBack} className={css.errorLink}>
        <FormattedMessage id="CheckoutPage.errorNavigateBackLinkText" />
      </span>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ navigateBackLink }} />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const showInitialMessageInput = !(
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
    );

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // confirmCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const initalValuesForStripePayment = { name: userName };

    const stripePaymentFormDescription = projectTransaction
      ? projectTransaction.attributes.protectedData?.projectBidDescription
      : tx
      ? tx.attributes.protectedData?.customOfferDescription
      : null;

    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.contentContainer}>
          <div className={classNames(css.avatarWrapper, css.avatarMobile)}>
            <AvatarMedium user={currentAuthor} disableProfileLink />
          </div>
          <div className={css.bookListingContainer}>
            <div className={css.heading}>
              <h1 className={css.title}>{title}</h1>
              <div className={css.author}>
                <FormattedMessage
                  id="CheckoutPage.hostedBy"
                  values={{ name: currentAuthor.attributes.profile.displayName }}
                />
                {typeof window === 'object' ? <ReactTooltip /> : null}
                <InfoIcon
                  className={css.infoIcon}
                  data-tip={intl.formatMessage({ id: 'StripePaymentForm.paymentHeadingTooltip' })}
                  data-border={true}
                  data-text-color={'black'}
                  data-background-color={'white'}
                  data-border-color={'darkgrey'}
                  data-class={css.tooltipText}
                  data-place={'top'}
                />
              </div>
            </div>

            <div className={css.priceBreakdownContainer}>
              {speculateTransactionErrorMessage}
              {breakdown}
            </div>

            <section className={css.paymentContainer}>
              {initiateOrderErrorMessage}
              {listingNotFoundErrorMessage}
              {speculateErrorMessage}
              {retrievePaymentIntentError ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.retrievingStripePaymentIntentFailed"
                    values={{ navigateBackLink }}
                  />
                </p>
              ) : null}
              {showPaymentForm ? (
                <StripePaymentForm
                  className={css.paymentForm}
                  onSubmit={this.handleSubmit}
                  onCancel={this.handleCancel}
                  inProgress={this.state.submitting}
                  formId="CheckoutPagePaymentForm"
                  paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                  authorDisplayName={currentAuthor.attributes.profile.displayName}
                  showInitialMessageInput={showInitialMessageInput}
                  initialValues={initalValuesForStripePayment}
                  initiateOrderError={initiateOrderError}
                  confirmCardPaymentError={confirmCardPaymentError}
                  confirmPaymentError={confirmPaymentError}
                  hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                  loadingData={!stripeCustomerFetched}
                  defaultPaymentMethod={
                    hasDefaultPaymentMethod ? currentUser.stripeCustomer.defaultPaymentMethod : null
                  }
                  paymentIntent={paymentIntent}
                  onStripeInitialized={this.onStripeInitialized}
                  projectTransaction={projectTransaction}
                  listing={listing}
                  description={stripePaymentFormDescription}
                />
              ) : null}
              {isPaymentExpired ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.paymentExpiredMessage"
                    values={{ navigateBackLink }}
                  />
                </p>
              ) : null}
            </section>
          </div>

          <div className={css.detailsContainerDesktop}>
            <div className={css.avatarWrapper}>
              <AvatarMedium user={currentAuthor} disableProfileLink />
            </div>
            <div className={css.detailsHeadings}>
              <h2 className={css.detailsTitle}>{listingTitle}</h2>
            </div>
            {speculateTransactionErrorMessage}
            {breakdown}
          </div>
        </div>
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onConfirmCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // confirmCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  confirmCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    projectTransaction,
  } = state.CheckoutPage;
  const { currentUser, payoutProfileListing } = state.user;
  const { confirmCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    payoutProfileListing,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    confirmCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    projectTransaction,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: (params, transactionId) =>
    dispatch(speculateTransaction(params, transactionId)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onRetrievePaymentMethod: params => dispatch(retrievePaymentMethod(params)),
  onConfirmCardPayment: params => dispatch(confirmCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onCancelPayment: transactionId => dispatch(cancelPayment(transactionId)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
  const { listing, bookingData, bookingDates } = initialValues;

  if (saveToSessionStorage) {
    storeData(bookingData, bookingDates, listing, null, STORAGE_KEY);
  }

  const packagePriceAmount =
    bookingData.pricingPackage === 'package1'
      ? listing.attributes?.price?.amount
      : bookingData.pricingPackage &&
        listing.attributes.publicData[`${bookingData.pricingPackage}Price`]
      ? listing.attributes.publicData[`${bookingData.pricingPackage}Price`]
      : 0;
  const eventPrice = bookingData.suggestedPrice
    ? bookingData.suggestedPrice.amount / 100
    : packagePriceAmount / 100;
  const pricingPackageName =
    bookingData.pricingPackage && listing.attributes.publicData[`${bookingData.pricingPackage}Name`]
      ? listing.attributes.publicData[`${bookingData.pricingPackage}Name`]
      : '';

  if (typeof window === 'object') {
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        items: [
          {
            item_name: listing.attributes.title,
            item_id: listing.id.uuid,
            price: '' + eventPrice,
            item_brand: listing.author.attributes.profile.displayName,
            item_category: listing.attributes.publicData.category,
            item_variant: bookingData.suggestedPrice ? 'custom_offer' : pricingPackageName,
            item_list_name: 'chat_window',
            custom_offer: !!bookingData.suggestedPrice,
            custom_offer_value: bookingData.suggestedPrice
              ? '' + bookingData.suggestedPrice.amount / 100
              : null,
            quantity: '1',
          },
        ],
      },
    });
  }

  return setInitialValues(initialValues);
};

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
