import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withViewport } from '../../util/contextHelpers';
import classNames from 'classnames';

import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  NamedLink,
  IntegrateFreelancersBanner,
  BuyerTileFreedomlyPro,
  BuyerTileMarketplace,
} from '../../components';
import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';
import { stringify } from '../../util/urlHelpers';

import SolutionPageHeroSection from './SolutionPageHeroSection';
import SolutionPageProblemsSection from './SolutionPageProblemsSection';

import heroImage from '../../assets/solutions-page-heroimage-freelancer-businessowners.webp';
import testimonialSectionImage from '../../assets/freelanceri-at-cafe.webp';
import IntegrateFreelancersBannerImg from '../../assets/integrate-freelancers-banner-image.webp';

import css from './SolutionPages.css';

const SmallBusinessSolutionPageComponent = props => {
  const { intl, scrollingDisabled, history } = props;

  const schemaTitle = intl.formatMessage({ id: 'SmallBusinessSolutionPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.schemaDescription',
  });

  const heroTitle = intl.formatMessage({ id: 'SmallBusinessSolutionPage.heroTitle' });
  const heroSubtitle = intl.formatMessage({ id: 'SmallBusinessSolutionPage.heroSubtitle' });
  const heroSubtitle2 = intl.formatMessage({ id: 'SmallBusinessSolutionPage.heroSubtitle2' });

  const optionBannersTitle = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.optionBannersTitle',
  });

  const problemsTitle = intl.formatMessage({ id: 'SmallBusinessSolutionPage.problemsTitle' });
  const problemsCardTitle1 = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.problemsCardTitle1',
  });
  const problemsCardText1 = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.problemsCardText1',
  });
  const problemsCardTitle2 = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.problemsCardTitle2',
  });
  const problemsCardText2 = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.problemsCardText2',
  });
  const problemsCardTitle3 = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.problemsCardTitle3',
  });
  const problemsCardText3 = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.problemsCardText3',
  });
  const problemsCardTitle4 = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.problemsCardTitle4',
  });
  const problemsCardText4 = intl.formatMessage({
    id: 'SmallBusinessSolutionPage.problemsCardText4',
  });
  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  const buyerTileFreedomlyProTitleId = 'DashboardPage.buyerTileFreedomlyProTitle';
  const buyerTileFreedomlyProSubtitleId = 'DashboardPage.buyerTileFreedomlyProSubtitle';
  const buyerTileFreedomlyProContent1 = 'DashboardPage.buyerTileFreedomlyProContent1';
  const buyerTileFreedomlyProContent2 = 'DashboardPage.buyerTileFreedomlyProContent2';
  const buyerTileFreedomlyProContent3 = 'DashboardPage.buyerTileFreedomlyProContent3';
  const buyerTileFreedomlyProContent4 = 'DashboardPage.buyerTileFreedomlyProContent4';

  const buyerTileFreedomlyProPrimaryCTAId = 'DashboardPage.buyerTileContactCTA';
  const buyerTileFreedomlyProSecondaryCTATo = 'DashboardProPage';
  const buyerTileFreedomlyProSecondaryCTAId = 'DashboardPage.buyerTileSecondaryCTA';

  const buyerTileMarketplaceTitleId = 'DashboardPage.buyerTileFreedomlyMarketplaceTitle';
  const buyerTileMarketplacesubTitleId = 'DashboardPage.buyerTileFreedomlyMarketplaceSubtitle';
  const buyerTileMarketplaceContentTitle1Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentTitle1';
  const buyerTileMarketplaceContentTitle2Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentTitle2';
  const buyerTileMarketplaceContentTitle3Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentTitle3';
  const buyerTileMarketplaceContentText1Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentText1';
  const buyerTileMarketplaceContentText2Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentText2';
  const buyerTileMarketplaceContentText3Id =
    'DashboardPage.buyerTileFreedomlyMarketplaceContentText3';

  const buyerTileMarketplacePrimaryCTATo = 'NewProjectListingPage';
  const buyerTileMarketplacePrimaryCTAId = 'SmallBusinessSolutionPage.buyerTilePostJobCTA';

  const buyerTileMarketplaceSecondaryCTATo = 'DashboardMarketplacePage';
  const buyerTileMarketplaceSecondaryCTAId = 'DashboardPage.buyerTileSecondaryCTA';

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="SmallBusinessSolutionPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.sections}>
            <SolutionPageHeroSection
              title={heroTitle}
              subTitle={heroSubtitle}
              subTitle2={heroSubtitle2}
              imgSrc={heroImage}
              handleShowSpaceHome={handleShowSpaceHome}
            />
            <SolutionPageProblemsSection
              title={problemsTitle}
              cardTitle1={problemsCardTitle1}
              cardText1={problemsCardText1}
              cardTitle2={problemsCardTitle2}
              cardText2={problemsCardText2}
              cardTitle3={problemsCardTitle3}
              cardText3={problemsCardText3}
              cardTitle4={problemsCardTitle4}
              cardText4={problemsCardText4}
            />
            <IntegrateFreelancersBanner
              imgSrc={IntegrateFreelancersBannerImg}
              titleId="DigitalAgencySolutionPage.integrateFreelancersBannerTitle"
              textId="DigitalAgencySolutionPage.integrateFreelancersBannerText"
            />
            <div className={css.optionBannersSection}>
              <h2 className={css.optionBannersTitle}>{optionBannersTitle}</h2>
              <BuyerTileMarketplace
                buyerTileMarketplaceTitleId={buyerTileMarketplaceTitleId}
                buyerTileMarketplacesubTitleId={buyerTileMarketplacesubTitleId}
                buyerTileMarketplaceContentTitle1Id={buyerTileMarketplaceContentTitle1Id}
                buyerTileMarketplaceContentTitle2Id={buyerTileMarketplaceContentTitle2Id}
                buyerTileMarketplaceContentTitle3Id={buyerTileMarketplaceContentTitle3Id}
                buyerTileMarketplaceContentText1Id={buyerTileMarketplaceContentText1Id}
                buyerTileMarketplaceContentText2Id={buyerTileMarketplaceContentText2Id}
                buyerTileMarketplaceContentText3Id={buyerTileMarketplaceContentText3Id}
                buyerTileMarketplacePrimaryCTATo={buyerTileMarketplacePrimaryCTATo}
                buyerTileMarketplacePrimaryCTAId={buyerTileMarketplacePrimaryCTAId}
                buyerTileMarketplaceSecondaryCTATo={buyerTileMarketplaceSecondaryCTATo}
                buyerTileMarketplaceSecondaryCTAId={buyerTileMarketplaceSecondaryCTAId}
                showSecondaryCTA={false}
              />
              <BuyerTileFreedomlyPro
                buyerTileFreedomlyProTitleId={buyerTileFreedomlyProTitleId}
                buyerTileFreedomlyProSubtitleId={buyerTileFreedomlyProSubtitleId}
                buyerTileFreedomlyProContent1={buyerTileFreedomlyProContent1}
                buyerTileFreedomlyProContent2={buyerTileFreedomlyProContent2}
                buyerTileFreedomlyProContent3={buyerTileFreedomlyProContent3}
                buyerTileFreedomlyProContent4={buyerTileFreedomlyProContent4}
                buyerTileFreedomlyProPrimaryCTAId={buyerTileFreedomlyProPrimaryCTAId}
                buyerTileFreedomlyProSecondaryCTATo={buyerTileFreedomlyProSecondaryCTATo}
                buyerTileFreedomlyProSecondaryCTAId={buyerTileFreedomlyProSecondaryCTAId}
                showSecondaryCTA={false}
              />
            </div>
            <div className={css.testimonialSection}>
              <div className={css.testimonialSectionTitle}>
                <FormattedMessage id="SmallBusinessSolutionPage.testimonialSectionTitle" />
              </div>
              <div className={css.testimonialSectionContent}>
                <div className={css.testimonialSectionContentText}>
                  <div className={css.testimonialSectionListItem}>
                    <div className={css.testimonialSectionListItemText}>
                      <FormattedMessage id="SmallBusinessSolutionPage.testimonialSectionText1" />
                    </div>
                    <div className={css.testimonialSectionListItemUser}>
                      <FormattedMessage id="ProLandingPage.proTestimonialSectionUser1" />
                    </div>
                  </div>
                  <div className={css.testimonialSectionListItem}>
                    <div className={css.testimonialSectionListItemText}>
                      <FormattedMessage id="SmallBusinessSolutionPage.testimonialSectionText2" />
                    </div>
                    <div className={css.testimonialSectionListItemUser}>
                      <FormattedMessage id="ProLandingPage.proTestimonialSectionUser2" />
                    </div>
                  </div>
                </div>
                <div className={css.testimonialSectionImageContainer}>
                  <img
                    src={testimonialSectionImage}
                    alt="Testimonial Section"
                    className={css.testimonialSectionContentImage}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={css.smallBusinessContactSection}>
            <div className={css.smallBusinessContactSectionTitle}>
              <FormattedMessage id="SmallBusinessSolutionPage.contactSectionTitle" />
            </div>
            <div className={css.smallBusinessContactSectionSubTitle}>
              <FormattedMessage id="SmallBusinessSolutionPage.contactSectionSubtitle" />
            </div>
            <NamedLink name="SignupPage" className={css.smallBusinessContactSectionCTA}>
              <FormattedMessage id="SmallBusinessSolutionPage.heroCTA" />
            </NamedLink>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { number, shape } = PropTypes;

SmallBusinessSolutionPageComponent.propTypes = {
  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired, // from injectIntl
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const SmallBusinessSolutionPage = compose(
  withRouter,
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(SmallBusinessSolutionPageComponent);

export default SmallBusinessSolutionPage;
