import React from 'react';
import renderHTML from 'react-render-html';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ProjectListingPage.css';

const SectionDescriptionMaybe = props => {
  const { publicData } = props;
  const { projectProblem, descriptionText, projectIndustryExperience } = publicData || {};

  return (
    <div className={css.sectionDescription}>
      {projectProblem ? (
        <div>
          <h2 className={css.descriptionTitle}>
            <FormattedMessage id="ProjectListingPage.problemTitle" />
          </h2>
          <div className={css.description}>{renderHTML(projectProblem)}</div>
        </div>
      ) : null}

      <div>
        <h2 className={css.descriptionTitle}>
          <FormattedMessage id="ProjectListingPage.descriptionTitle" />
        </h2>
        <div className={css.description}>{renderHTML(descriptionText)}</div>
      </div>
      {projectIndustryExperience ? (
        <div>
          <h2 className={css.descriptionTitle}>
            <FormattedMessage id="ProjectListingPage.industryExperienceTitle" />
          </h2>
          <div className={css.description}>{renderHTML(projectIndustryExperience)}</div>
        </div>
      ) : null}
    </div>
  );
};

export default SectionDescriptionMaybe;
