import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, SecondaryButton } from '../../components';
import { propTypes } from '../../util/types';

import css from './QuickReplyForm.css';

const BLUR_TIMEOUT_MS = 100;

class QuickReplyFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    return (
      <FinalForm
        initialValues={{ message: this.props.message }}
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;
          return (
            <div className={css.root}>
              <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
                <div className={css.submitContainer}>
                  <div className={css.errorContainer}>
                    {sendMessageError ? (
                      <p className={css.error}>
                        <FormattedMessage id="SendMessageForm.sendFailed" />
                      </p>
                    ) : null}
                  </div>
                  <SecondaryButton
                    rootClassName={css.submitButton}
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                  >
                    {this.props.message}
                  </SecondaryButton>
                </div>
              </Form>
            </div>
          );
        }}
      />
    );
  }
}

QuickReplyFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

QuickReplyFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const QuickReplyForm = compose(injectIntl)(QuickReplyFormComponent);

QuickReplyForm.displayName = 'QuickReplyForm';

export default QuickReplyForm;
