import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, Button, FieldSelect, UnsavedInfoPrompt } from '../../components';
import ReactTooltip from 'react-tooltip';
import { required } from '../../util/validators';

import css from './SignUpProjectForms.css';

const SignUpProjectRequirementsFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          previousActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          dirty,
          onPrev,
        } = formRenderProps;

        const projectTierTitle = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTierTitle',
        });

        const projectTier1 = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTier1',
        });

        const projectTier2 = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTier2',
        });

        const projectTier3 = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTier3',
        });

        const projectTier35 = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTier35',
        });

        const projectTier4 = intl.formatMessage({
          id: 'EditProjectListingRequirementsForm.projectTier4',
        });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingBudgetForm.updateFailed" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditProjectListingBudgetForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}
            {typeof window === 'object' ? <ReactTooltip /> : null}
            <div>
              <label htmlFor="projectTier" className={css.projectTierTitle}>
                {projectTierTitle}
              </label>
              <FieldSelect
                id="projectTier"
                name="projectTier"
                className={css.projectTier}
                validate={required(projectTierTitle)}
              >
                <option value="">{projectTierTitle}</option>
                <option value="all">{projectTier1}</option>
                <option value="entry">{projectTier2}</option>
                <option value="junior">{projectTier3}</option>
                <option value="midlevel">{projectTier35}</option>
                <option value="senior">{projectTier4}</option>
              </FieldSelect>
            </div>
            <div className={css.buttonContainer}>
              <Button className={css.previousButton} onClick={onPrev}>
                {previousActionMsg}
              </Button>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
            <UnsavedInfoPrompt when={dirty} />
          </Form>
        );
      }}
    />
  );
};

export default compose(injectIntl)(SignUpProjectRequirementsFormComponent);
